<script setup>
import { ref, onMounted, useSSRContext } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import { EnumStringBody } from '@babel/types'
/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
// filters
const filter_products = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
//////////////////
// Data Sources //
//////////////////
const products = ref([])
const details = ref()
const search_term = ref('')
const result_count = ref(100)
const product_details_slider = ref(false)
const sage_group_filter = ref([])
const sage_groups = ref([])

const result_counts = [
    {label: '200', value: 100},
    {label: '200', value: 200},
    {label: '1000', value: 1000},
    {label: '2000', value: 2000},
    {label: '5000', value: 5000},
    {label: '10000', value: 10000},
]

onMounted( async () => {
    search_products()
    get_sage_groups()
})

const search_products = () => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gerstlv2/search-products', {search: search_term.value, limit: result_count.value})
    .then(response => {
        console.log(response.data)
        products.value = response.data.data
        loader.value = false
    })
}

const get_sage_groups = () => {
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gerstlv2/get-sage-groups')
    .then(response => {
        console.log("sage_groups", response.data)
        sage_groups.value = response.data.filters3.sageGroups
    })
}

const product_details = (e) => {
    loader.value = true
    details.value = e.data
    product_details_slider.value = true
    loader.value = false
}


const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}

const format_currency_without_prefix = (value) => {
    return Number(value).toLocaleString('de-CH', {minimumFractionDigits: 2});
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const format_swiss_date_time = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4) + ' ' + value.substring(11,16)
}


const copy_to_clipboard = (value) => {
    navigator.clipboard.writeText(value)
    toast.add({severity:'success', summary: 'Erfolgreich', detail: 'In Zwischenablage kopiert', life: 3000});
}

</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>


<template>
    <Toast />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :fullScreen="true" :blocked="loader">
        <div class="col-12 mb-2">
            <Toolbar>
                <template #start>
                    <span class="p-input-icon-left mr-2">
                        <i class="pi pi-search" />
                        <InputText v-model="search_term" v-on:keyup.enter="search_products()" placeholder="Suche" style="width: 300px"  />
                    </span>
                    <Button label="Suchen" icon="pi pi-search" class="mr-2" @click="search_products()" />
                    <Dropdown @change="search_products()" v-model="result_count" :options="result_counts" optionLabel="label" optionValue="value" placeholder="Resultate" style="width: 150px" class="mr-2" />
                    <MultiSelect v-model="sage_group_filter" :options="sage_groups" optionLabel="data.name" optionValue="data.key" placeholder="Sage Gruppen" style="width: 250px" />
                </template>
            </Toolbar>
        </div>
        <div rounded class="overflow-hidden shadow-3 border-200">
            <DataTable :value="products" @row-click="product_details" :rows="200" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[200,500,1000]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                <Column field="sku" header="SKU">
                    <template #body="slotProps">
                        <span class="font-bold">{{ slotProps.data.sku }}</span>
                    </template>
                </Column>
                <Column field="title3" header="Name">
                    <template #body="slotProps">
                        <span>{{ slotProps.data.title3 }} - {{ slotProps.data.title2 }}</span>
                    </template>
                </Column>
                <Column field="title1" header="Produzent" />
                <Column field="year" header="Jahr">
                    <template #body="slotProps">
                        <span v-if="slotProps.data.year">{{ slotProps.data.year.name }}</span>
                    </template>
                </Column>
                <Column field="country" header="Land">
                    <template #body="slotProps">
                        <span v-if="slotProps.data.country">{{ slotProps.data.country.name }}</span>
                    </template>
                </Column>
                <Column field="region" header="Region">
                    <template #body="slotProps">
                        <span v-if="slotProps.data.region">{{ slotProps.data.region.name }}</span>
                    </template>
                </Column>
            </DataTable>
        </div>
    </BlockUI>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <Sidebar v-model:visible="product_details_slider" position="right" style="width: 60rem">
        <div class="col-12 formgrid grid">
            Hello
        </div>
        <template #header>
            <div class="flex">
                <button class="p-sidebar-icon p-link mr-2">
                    <span class="pi pi-print" />
                </button>
            </div>
        </template>
    </Sidebar>
</template>