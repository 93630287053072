<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from "primevue/useconfirm"
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useAuthStore } from '@/core/store/AuthStore'

////////////
// System //
////////////

const channel = ref("krediflow")
const auth = useAuthStore()

/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
// filters
const filter_blog_categories = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
const filter_event_categories = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
//////////////////
// Data Sources //
//////////////////
const blog_categories = ref()
const event_categories = ref()
const new_category = ref({name: null, value: null})

const createCategoryDialog = ref(false)

onMounted( async () => {
    init_data()
})

const init_data = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gerstl/get-system')
    .then(response => {
        blog_categories.value = response.data.blog_categories
        event_categories.value = response.data.event_categories
        loader.value = false
    })
}

</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <div>
        <Toast />
        <Card class="mt-4">
            <template #title>Magazin Kategorien</template>
            <template #content>
                <Toolbar>
                    <template #start>
                        <span class="p-input-icon-left mr-3">
                            <i class="pi pi-search" />
                            <InputText v-model="filter_blog_categories['global'].value" placeholder="Suche" style="width: 300px" />
                        </span>
                        <Button label="Magazin Kategorie hinzufügen" icon="pi pi-plus" class="p-button" @click="createCategoryDialog = true" />
                    </template>
                </Toolbar>
                <DataTable class="mt-3 inbox-datatable" v-model:filters="filter_blog_categories" :value="blog_categories" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                    <Column field="name" header="Name" sortable></Column>
                    <Column field="value" header="Wert" sortable></Column>
                </DataTable>
            </template>
        </Card>
        <Card class="mt-4">
            <template #title>Event Kategorien</template>
            <template #content>
                <Toolbar>
                    <template #start>
                        <span class="p-input-icon-left mr-3">
                            <i class="pi pi-search" />
                            <InputText v-model="filter_blog_categories['global'].value" placeholder="Suche" style="width: 300px" />
                        </span>
                        <Button label="Event Kategorie hinzufügen" icon="pi pi-plus" class="p-button" @click="createCategoryDialog = true" />
                    </template>
                </Toolbar>
                <DataTable class="mt-3 inbox-datatable" v-model:filters="filter_event_categories" :value="event_categories" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                    <Column field="name" header="Name" sortable></Column>
                    <Column field="value" header="Wert" sortable></Column>
                </DataTable>
            </template>
        </Card>
        <!---------------------------------------------------->
        <!------------------ Invite User --------------------->
        <!---------------------------------------------------->
        <Dialog v-model:visible="createCategoryDialog" :style="{width: '700px'}" header="Kategorie hinzufügen" :modal="true" class="p-fluid z-5">
            <div class="col-12 formgrid grid">
                <div class="row">
                    <div class="col-12">
                        <div class="p-field">
                            <label for="name">Name</label>
                            <InputText id="name" v-model="new_category.name" />
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="p-field">
                            <label for="value">Wert</label>
                            <InputText id="value" v-model="new_category.value" />
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    </div>
</template>