<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import { Cropper, Preview } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';

const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

const weinalarm = ref([])
const weinalarm_details = ref([])
const weinalarmDetailsDialog = ref(false)
const dt_wein_alarm_details = ref()
const filter_weinalarm = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
const filter_weinalarm_details = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})

onMounted( async () => {
    await axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/get-weinalarm")
    .then(response => {
        weinalarm.value = response.data.data
    })
})

const showWineDetails = (details) => {
    let payload = {
        "wineIds": details
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/get-weinalarm-details", payload)
    .then(response => {
        console.log(response.data.data)
        weinalarm_details.value = response.data.data
        weinalarmDetailsDialog.value = true
    })
}

const exportCSV = () => {
    dt_wein_alarm_details.value.exportCSV()
}

</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <Toast />
    <Toolbar class="mt-2">
        <template #start>
            <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filter_weinalarm['global'].value" placeholder="Suche" style="width: 400px" class="mr-2" />
            </span>
        </template>
    </Toolbar>
    <!--------------------------------------------------->
    <!------------------ DataTable ---------------------->
    <!--------------------------------------------------->
    <DataTable class="mt-3" v-model:filters="filter_weinalarm" :value="weinalarm" :rows="50" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[50,100,200]"  :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
        <Column field="firstName" header="Vornamen" sortable>
            <template #body="slotProps">
                <span>{{ slotProps.data.firstName }}</span>
            </template>
        </Column>
        <Column field="lastName" header="Nachnamen" sortable>
            <template #body="slotProps">
                <span>{{ slotProps.data.lastName }}</span>
            </template>
        </Column>
        <Column field="email" header="E-Mail" sortable>
            <template #body="slotProps">
                <span>{{ slotProps.data.email }}</span>
            </template>
        </Column>
        <Column header="Weine">
            <template #body="slotProps">
                <span>{{ String(slotProps.data.wineAlarm.length) }}</span>
            </template>
        </Column>
        <Column>
            <template #body="slotProps">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-text" @click="showWineDetails(slotProps.data.wineAlarm)" />
            </template>
        </Column>
    </DataTable>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="weinalarmDetailsDialog" :style="{width: '1200px'}" header="Weinalarm Weine" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <Toolbar>
                    <template #start>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filter_weinalarm_details['global'].value" placeholder="Suche" style="width: 400px" class="mr-2" />
                        </span>
                        <Button icon="pi pi-file-excel" class="bg-green-50 text-green-500 border-green-500 pi-button" label="Export" @click="exportCSV()" />
                    </template>
                </Toolbar>
                <DataTable ref="dt_wein_alarm_details" :value="weinalarm_details" v-model:filters="filter_weinalarm_details" :rows="50" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[50,100,200]"  :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                    <Column field="sku" header="Artikelnr." sortable />
                    <Column field="title1" header="Titel1" sortable />
                    <Column field="title2" header="Titel2" sortable />
                    <Column field="title3" header="Titel3" sortable />
                    <Column field="year" header="Jahr" sortable>
                        <template #body="slotProps">
                            {{ slotProps.data.year.name }}
                        </template>
                    </Column>
                    <Column field="slug">
                        <template #body="slotProps">
                            <a class="font-bold" style="color: #3751ff;" :href="'https://www.gerstl.ch/' + slotProps.data.slug + '/p'" target="_blank">Produktseite</a>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </Dialog>
</template>