<script setup>
import { ref, onMounted, useSSRContext } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import { EnumStringBody } from '@babel/types'

import LibaryPDFFiles from './Dialogs/Library-PDF-Files.vue'
import LibraryGPTRecipes from './Dialogs/Library-GPT-Recipes.vue'
import LibrarySEOPatterns from './Dialogs/Library-SEO-Patterns.vue'

/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
// filters
const filter_libraries = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
//////////////////
// Data Sources //
//////////////////
const libraries = ref([])
const details = ref()
const libraryDetailsDialog = ref(false)

onMounted( async () => {
    loader.value = true
    await get_libraries()
    loader.value = false
})

const get_libraries = () => {
    return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/get-libraries")
        .then((response) => {
            libraries.value = response.data
            console.log("libraries", libraries.value)
            resolve()
        })
        .catch((error) => {
            toast.add({severity:'error', summary: 'Fehler', detail: 'Fehler beim Laden der Bibliotheken'})
            reject()
        })
    })
}

const library_details = (event) => {
    details.value = event.data
    libraryDetailsDialog.value = true
}
</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>


<template>
    <Toast />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :fullScreen="true" :blocked="loader">
        <div class="col-12 formgrid grid">
            <div class="col-12 mb-2">
                <Toolbar>
                    <template #start>
                        <span class="p-input-icon-left mr-2">
                            <i class="pi pi-search" />
                            <InputText v-model="filter_libraries['global'].value" placeholder="Suche" style="width: 300px"  />
                        </span>
                        <Button icon="pi pi-refresh" @click="get_libraries()" class="mr-2 bg-primary text-white" v-tooltip.top="'Liste aktualisieren'"></Button>
                    </template>
                </Toolbar>
            </div>
            <div class="col-12 mb-2">
                <DataTable v-model:filters="filter_libraries" :value="libraries" @row-click="library_details" :rows="50" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                    <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                    <Column field="name" header="Bibliothek" sortable>
                        <template #body="slotProps">
                            <Avatar icon="pi pi-book" class="mr-2 bg-primary text-white" shape="circle" />
                            <span class="font-bold">{{slotProps.data.name}}</span>
                        </template>
                    </Column>
                    <Column header="Elemente">
                        <template #body="slotProps">
                            <Avatar :label="String(slotProps.data.count)" class="mr-2 bg-primary text-white" shape="circle" v-tooltip.top="String(slotProps.data.count) + ' Dateien'" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </BlockUI>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <Sidebar v-model:visible="libraryDetailsDialog" position="right" style="width: 70rem">
        <div class="col-12 formgrid grid">
            <LibaryPDFFiles v-if="details.id == '12345'" :redis_key="details.key" />
            <LibaryPDFFiles v-if="details.id == '12346'" :redis_key="details.key" />
            <LibraryGPTRecipes v-if="details.id == 'gpt'" :redis_key="details.key" />
            <LibrarySEOPatterns v-if="details.id == 'seopatterns'" :redis_key="details.key" />
        </div>
    </Sidebar>
</template>