<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import { Cropper, Preview } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';


const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
const export_loader = ref(false)

const weinalarm = ref([])
const weinalarm_details = ref([])
const dt_wein_alarm_details = ref();
const weinalarmDetailsDialog = ref(false)
const dialog_title = ref("")
const filter_weinalarm = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
const filter_weinalarm_details = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})

onMounted( async () => {
    await axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/get-weinalarm-products")
    .then(response => {
        weinalarm.value = response.data
    })
})

const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}

const showWineDetails = (sku) => {
    dialog_title.value = sku
    let payload = {
        "wineId": sku
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/get-weinalarm-subscription-details", payload)
    .then(response => {
        weinalarm_details.value = response.data
        weinalarmDetailsDialog.value = true
    })
}

const exportCSV = () => {
    dt_wein_alarm_details.value.exportCSV()
}


const exportExcel = async () => {
    export_loader.value = true
    const res = await axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/export-weinalarm-products", { responseType: 'blob' });
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    const contentDisposition = res.headers['content-disposition'];
    const date = new Date();
    const timestamp = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate() + '_' + date.getHours() + '-' + date.getMinutes() + '-' + date.getSeconds();
    let filename = `Gerstl-Weinalarm-Produkte-export-${timestamp}.xlsx`;
    if (contentDisposition) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
        }
    }
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    export_loader.value = false
}
</script>
<!-- <style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style> -->


<template>
    <Toast />
    <Toolbar class="mt-2">
        <template #start>
            <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filter_weinalarm['global'].value" placeholder="Suche" style="width: 400px" class="mr-2" />
            </span>
        </template>
        <template #end>
            <Button icon="pi pi-file-excel" :loading="export_loader" class="bg-primary-50 text-primary-500 border-primary-500 pi-button" label="Excel Export" @click="exportExcel" />
        </template>
    </Toolbar>
    <!--------------------------------------------------->
    <!------------------ DataTable ---------------------->
    <!--------------------------------------------------->
    <DataTable class="mt-3" v-model:filters="filter_weinalarm" :value="weinalarm" :rows="50" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[50,100,200]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
        <Column field="sku" header="Artikelnr." sortable></Column>
        <Column field="title1" header="Titel1" sortable></Column>
        <Column field="title2" header="Titel2" sortable></Column>
        <Column field="title3" header="Titel3" sortable></Column>
        <Column field="year" header="Jahr" sortable></Column>
        <Column field="price" header="Preis" sortable>
            <template #body="slotProps">
                <span v-if="slotProps.data.price == 0" class="text-red-500">{{ format_currency(slotProps.data.price) }}</span>
                <span v-if="slotProps.data.price != 0" class="text-green-500">{{ format_currency(slotProps.data.price) }}</span>
            </template>
        </Column>
        <Column field="count" header="Abonniert" sortable>
            <template #body="slotProps">
                <span class="font-bold">{{ slotProps.data.count }}</span>
            </template>
        </Column>
        <Column sortable>
            <template #body="slotProps">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-text" @click="showWineDetails(slotProps.data.sku)" />
            </template>
        </Column>
    </DataTable>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="weinalarmDetailsDialog" :style="{width: '1200px'}" :header="'Abonnierte Kunden:' + dialog_title" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <Toolbar>
                    <template #start>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filter_weinalarm_details['global'].value" placeholder="Suche" style="width: 400px" class="mr-2" />
                        </span>
                        <Button icon="pi pi-file-excel" class="bg-green-50 text-green-500 border-green-500 pi-button" label="Export" @click="exportCSV()" />
                    </template>
                </Toolbar>
                <DataTable :exportFilename="dialog_title+'.csv'" ref="dt_wein_alarm_details" :value="weinalarm_details" v-model:filters="filter_weinalarm_details" :rows="50" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[50,100,200]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                    <Column field="honorific" header="Anrede"></Column>
                    <Column field="firstName" header="Vornamen" sortable></Column>
                    <Column field="lastName" header="Nachnamen" sortable></Column>
                    <Column field="email" header="E-Mail"></Column>
                </DataTable>
            </div>
        </div>
    </Dialog>
</template>