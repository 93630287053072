<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import PageEditor from './Gerstl-Filter-Editor.vue'
import { useAuthStore } from '@/core/store/AuthStore';

//////////////////
// Data Sources //
//////////////////
const pages = ref([])
const page_categories = ref([])
const page_tags = ref([])
const changes = ref(0)
const save_text = ref("Speichern")
const page_status = ref([])
const editing = ref([])
const users = ref([])
const auth = useAuthStore()
const random_number = ref(String(Math.floor(Math.random() * 100000000)))

const get_base_data = async () => {
    await axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/get-filter-pages")
    .then(response => {
        pages.value = response.data
    })
    await axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/get-system")
    .then(response => {
        page_categories.value = response.data.filter_page_categories
        page_tags.value = response.data.page_tags
        page_status.value = response.data.page_status
    })
    await axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/get-users")
    .then(response => {
        users.value = response.data
    })
}

onMounted(() => {
    get_base_data()
})


/////////////////////
// Interface Props //
/////////////////////

const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
const filter_pages = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
const filter_page_category = ref()
const filter_page_status = ref()
const activeTabIndex = ref(0)

const onPageRowEdit = (e) => {
    if(pages.value[e.index]["redis_key"] != "") {
        if (pages.value[e.index][e.field] != e.newValue){
            console.log("Page " + pages.value[e.index].redis_key + " changed: " + e.field + " from " + pages.value[e.index][e.field] + " to " + e.newValue)
            pages.value[e.index][e.field] = e.newValue
            changes.value = changes.value + 1
            save_text.value = "Speichern (" + changes.value + ")"
            toast.add({severity: 'success', summary: 'Änderungen gespeichert', detail: 'Die Änderungen wurden erfolgreich gespeichert', life: 3000})
        }
    }
    else {
        toast.add({severity: 'error', summary: 'Fehler', detail: 'Der Magazin Eintrag muss zuerst gespeichert werden!', life: 3000})
    }
}

const reset_changes = () => {
    confirm.require({
        message: 'Wollen Sie wirklich alle Änderungen verwerfen?',
        header: 'Änderungen verwerfen',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja, Änderungen verwerfen',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/get-filter-pages")
            .then(response => {
                pages.value = response.data
                changes.value = 0
                save_text.value = "Speichern"
            })
        }
    })
}

const save_changes = () => {
    confirm.require({
        message: 'Wollen Sie wirklich alle Änderungen speichern?',
        header: 'Änderungen speichern',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/save-pages", pages.value)
            .then(response => {
                pages.value = response.data
                console.log("pages", response.data)
                toast.add({severity: 'success', summary: 'Erfolgreich gespeichert', detail: 'Änderungen wurden erfolgreich gespeichert', life: 3000})
                changes.value = 0
                save_text.value = "Speichern"
            })
        }
    })
}


const publish = () => {
    confirm.require({
        message: 'Wollen Sie wirklich alle Änderungen publizieren?',
        header: 'Änderungen publizieren',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            let payload = {"type": "url"}
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/publish", payload)
            .then(response => {
                console.log("published", response.data)
                toast.add({severity: 'success', summary: 'Publiziert', detail: 'Erstellt: '+response.data[0].created+', Gelöscht: '+response.data[0].deleted+', Ignoriert: '+response.data[0].ignored+', Aktualisiert: '+response.data[0].updated+'', life: 9000})
            })
        }
    })
}

const preview_page = (slug) => {
    let cs = "fc7e3d6b-271c-41f7-bc50-97e3ed805459"
    window.open("https://www.gerstl.ch/"+slug+"/a?preview=" + cs)
}

const edit_page = (redis_key) => {
    for (let key in pages.value){
        if (pages.value[key].redis_key == redis_key) {
            let found = editing.value.some((el) => el.redis_key === redis_key);
            if (!found) {
                let name = ""
                if (pages.value[key].title.length > 25) {
                    name = pages.value[key].title.substring(0, 25) + "..."
                } else {
                    name = pages.value[key].title
                }
                editing.value.push({
                    "redis_key": pages.value[key].redis_key,
                    "name": name
                })
                activeTabIndex.value = editing.value.length
            }
        }
    }
}

const check_if_slug_exists = (title) => {
    let exists = false
    let slug = slugify(title)
    console.log(slug)
    pages.value.forEach(page => {
        if (page.slug == slug) {
            exists = true
        }
    })
    return exists
}

const editor_callback = (e) => {
    if (e.action == "close_tab") {
        for (let key in editing.value){
            if (editing.value[key]["redis_key"] == e.redis_key){
                editing.value.splice(key, 1)
                activeTabIndex.value = editing.value.length
            }
        }
    }
}

const select_status_filter = () => {
    filter_pages.value['global'].value = filter_page_status.value
}

const select_category_filter = () => {
    filter_pages.value['global'].value = filter_page_category.value
}

//////////////////////////
// Formatting Functions //
//////////////////////////

const slugify = (str) => {
  return str.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().trim().replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '-');
}

const get_category = (category) => {
    for (let i = 0; i < page_categories.value.length; i++) {
        if (page_categories.value[i].value == category) {
            return page_categories.value[i].name
        }
    }
}

const get_status = (status) => {
    for (let i = 0; i < page_status.value.length; i++) {
        if (page_status.value[i].value == status) {
            if (status == "published"){
                return "Publiziert"
            }
            if (status == "draft"){
                return "Entwurf"
            }
        }
    }
}

const swiss_date = (date) => {
    return new Date(date).toLocaleDateString('de-CH', { year: 'numeric', month: '2-digit', day: '2-digit' })
}

const swiss_date_time = (date) => {
    return new Date(date).toLocaleDateString('de-CH', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })
}

const characters_left = (field, limit) => {
    let chars = field.length
    return limit - chars
}

watch([activeTabIndex], () => {
    if (activeTabIndex.value == 0) {
        console.log("get base data")
        get_base_data()
    }
})

</script>


<!-- <style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style> -->

<template>
    <Toast />
    <ConfirmDialog />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :fullScreen="true" :blocked="loader">
        <TabView class="col-12" ref="tabview4" v-model:activeIndex="activeTabIndex">
            <TabPanel>
                <template #header>
                    <i class="pi pi-comment mr-2"></i>
                    <span class="mr-3">Filter Seite</span>
                </template>
                <Toolbar>
                    <template #start>
                        <Button v-if="changes != 0" v-tooltip.bottom="'Änderungen zurücksetzen'" @click="reset_changes()" class="mr-2 p-button-danger" icon="pi pi-refresh" />
                        <Button :disabled="changes != 0 ? false : true" @click="save_changes()" v-tooltip.bottom="'Änderungen speichern'" :label="save_text" class="w-auto mr-2 p-button-success" icon="pi pi-save" />
                        <Button :disabled="changes == 0 ? false : true" @click="publish()" v-tooltip.bottom="'Änderungen publizieren'" label="Publizieren" class="w-auto mr-2 p-button-warning" icon="pi pi-upload" />
                        <Divider layout="vertical" />
                    </template>
                </Toolbar>
                <Toolbar class="mt-2">
                    <template #start>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filter_pages['global'].value" placeholder="Suche" style="width: 400px" class="mr-2" />
                        </span>
                        <Dropdown :showClear="true" @change="select_status_filter()" placeholder="Status" id="status_filter" v-model="filter_page_status" :options="page_status" optionLabel="name" optionValue="value" class="w-auto mr-2" />
                        <Dropdown :filter="true" :showClear="true" @change="select_category_filter()" placeholder="Kategorie" id="category_filter" v-model="filter_page_category" :options="page_categories" optionLabel="name" optionValue="value" class="w-auto mr-2" />
                    </template>
                </Toolbar>
                <!--------------------------------------------------->
                <!------------------ DataTable ---------------------->
                <!--------------------------------------------------->
                <DataTable class="mt-3" v-model:filters="filter_pages" :value="pages" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" editMode="cell" @cell-edit-complete="onPageRowEdit" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                    <Column field="status" header="Status" style="width: 100px">
                        <template #editor="{ data, field }">
                            <Dropdown id="page_status" v-model="data[field]" :options="page_status" optionLabel="name" optionValue="value" class="w-auto mr-2" />
                        </template>
                        <template #body="{ data, field }">
                            <Chip v-if="data[field] == 'draft'" :label="get_status(data[field])" v-tooltip.top="'Beitrag ist Publiziert'" class="mr-1" style="background-color: #d32f2f; color: #FFF" />
                            <Chip v-if="data[field] == 'published'" :label="get_status(data[field])" v-tooltip.top="'Beitrag ist Publiziert'" class="mr-1" style="background-color: #689f38; color: #FFF" />
                        </template>
                    </Column>
                    <Column field="header_image_cropped" style="width: 130px">
                        <template #body="slotProps">
                            <img :src="slotProps.data.header_image + '?v=' + random_number" width="32" class="shadow-2 mr-2" style="vertical-align: middle; width: 128px; height: 64px; object-fit: cover; border-radius: 5%; box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)" />
                        </template>
                    </Column>
                    <Column field="title" header="Titel">
                        <template #body="slotProps">
                            <div class="font-bold">{{ slotProps.data.title }}</div>
                            <div class="text-gray-500">{{ slotProps.data.redis_key.substring(12) }}</div>
                        </template>
                    </Column>
                    <Column field="redis_key" hidden />
                    <Column field="category" header="Thema" style="width: 130px">
                        <template #editor="{ data, field }">
                            <Dropdown id="page_category" v-model="data[field]" :options="page_categories" optionLabel="name" optionValue="value" class="w-auto mr-2" />
                        </template>
                        <template #body="{ data, field }">
                            <Chip :label="get_category(data[field])" v-tooltip.top="'Kategorie / Thema'" icon="pi pi-comment" class="mr-1" style="background-color: #ffd8b2; color: #805b36" />
                        </template>
                    </Column>  
                    <Column field="dm" header="Modifikation" style="width: 180px" sortable>
                        <template #body="slotProps">
                            <span>{{ swiss_date_time(slotProps.data.dm) }}</span>
                        </template>
                    </Column>
                    <Column field="user" header="Bearbeiter" style="width: 350px">
                        <template #editor="{ data, field }">
                            <Dropdown :showClear="true" id="user" v-model="data[field]" :options="users" optionLabel="user_fullname" optionValue="user_email" class="w-auto mr-2" />
                        </template>
                        <template #body="{ data, field }">
                            <Chip :label="data[field]" v-tooltip.top="'Der aktuellen Bearbeiter vom Blog'" icon="pi pi-user" class="mr-1" style="background-color: #0288d1; color: #FFF" />
                        </template>
                    </Column>
                    <Column style="width: 250px;">
                        <template #body="slotProps">
                            <Button v-if="slotProps.data.workflow_id" @click="preview_page(slotProps.data.slug)" v-tooltip.top="'Seite Vorschau'" class="p-button-rounded sm p-button-text" icon="pi pi-eye" />
                            <Button v-if="slotProps.data.workflow_id" @click="edit_page(slotProps.data.redis_key)" v-tooltip.top="'Seite editieren'" class="p-button-rounded p-button-text" icon="pi pi-pencil" />
                        </template>
                    </Column>
                </DataTable>
            </TabPanel>
            <TabPanel v-for="(editor, key) in editing" :key="key">
                <template #header>
                    <i class="pi pi-pencil mr-2"></i>
                    <span class="mr-3">{{ editor.name }}</span>
                    <!--<a href="test"><i class="pi pi-times-circle mr-2"></i></a>-->
                </template>
                <PageEditor :redis_key="editor.redis_key"  @editor-callback="editor_callback" />
            </TabPanel>
        </TabView>
    </BlockUI>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
</template>