<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from "primevue/useconfirm"
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useAuthStore } from '@/core/store/AuthStore'
import { useCustomerStore } from '@/core/store/CustomerStore';
//import '@/core/assets/primevue/neuraxis.css';

const toast = useToast()

const apikey = ref()
const authed = ref(false)
const dataset = ref()
const offset = ref(0)
const limit = ref(50)
const dataset_json = ref()
const viewDataDialog = ref(false)
const row = ref()
const code_snippet_curl = ref()
const code_snippet_nodejs = ref()
const code_snippet_php = ref()
const code_snippet_python = ref()
const code_snippet_powershell = ref()
const templates = ref([])
const xml_standard_file_url = ref()
const json_standard_file_url = ref()
const xml_vivino_file_url = ref()
const json_the_cellar_file_url = ref()

const filter_dataviewer = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})

onMounted(() =>{
    apikey.value = localStorage.getItem('apikey')
})

const get_data = () => {
    authed.value = false
    localStorage.setItem('apikey', apikey.value)
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gerstl/api/v1/products?offset=' + offset.value + '&limit=' + limit.value + '',
    {headers: {'Authorization': apikey.value}})
    .then(response => {
        console.log(response.data)
        if(response.data.status == "error"){
            toast.add({severity:'error', summary: 'Error', detail: response.data.message, life: 5000});
            authed.value = false
        }else{
            axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gerstl/get-api-account',
            {headers: {'Authorization': apikey.value}})
            .then(response => {templates.value = response.data.templates})
            xml_standard_file_url.value = "https://cdn.gerstl.ch/exports/xml/products-api-export.xml"
            json_standard_file_url.value = "https://cdn.gerstl.ch/exports/json/products-api-export.json"
            xml_vivino_file_url.value = "https://cdn.gerstl.ch/exports/vivino/products-vivino-export.xml"
            json_the_cellar_file_url.value = "https://cdn.gerstl.ch/exports/the-cellar/products-the-cellar-export.json"
            authed.value = true
            dataset.value = response.data
            dataset_json.value = JSON.stringify(response.data, null, "\t")
            code_snippet_curl.value = `curl -X GET \
https://api.snpy.ch/gerstl/api/v1/products?offset=${offset.value}&limit=${limit.value} \\
-H 'Accept: application/json' \\
-H 'Authorization: Bearer ${apikey.value}'`
            code_snippet_php.value = `<?php
require 'vendor/autoload.php';

$headers = array(
    'Accept' =>; 'application/json',
    'Authorization' =>; 'Bearer ${apikey.value}',
);

$client = new \\GuzzleHttp\\Client();
$url = 'https://api.snpy.ch/gerstl/api/v1/products?offset=${offset.value}&limit=${limit.value}';

try {
    $response = $client->;request('GET', $url, array(
        'headers' =>; $headers,
    ));

    print_r($response->getBody()->getContents());
}
catch (\\GuzzleHttp\\Exception\\BadResponseException $e) {
    // handle exception or api errors.
    print_r($e->getMessage());
}`
            code_snippet_python.value = `url = "https://gerstl.snpy.ch/api/v1/products?offset=${offset.value}&limit=${limit.value}"

headers = {
    'Accept': "application/json",
    'Content-Type': "application/json",
    'Authorization': "Bearer ${apikey.value}",
}

response = requests.request("GET", url, headers=headers)
print(response.text)`
            code_snippet_nodejs.value = `var unirest = require("unirest");
var req = unirest("GET", "https://api.snpy.ch/gerstl/api/v1/products?offset=${offset.value}&limit=${limit.value}");

req.headers({
  "Accept": "application/json",
  "Content-Type": "application/json",
  "Authorization": "Bearer ${apikey.value}"
});

req.type("json");

req.send();

req.end(function (res) {
  if (res.error) throw new Error(res.error);
  console.log(res.body);
});`
            code_snippet_powershell.value = `$url = "https://api.snpy.ch/gerstl/api/v1/products?offset=${offset.value}&limit=${limit.value}"
$headers = @{Authorization = "${apikey.value}"}
$response = (Invoke-WebRequest -Uri $Url -Method Get -Headers $headers -UseBasicParsing -ContentType "application/json").Content
Write-Host $response`
}
})
}

const view_data = (e) => {
    viewDataDialog.value = true
    row.value = e
    console.log(row.value)
}

const param_validation = () => {
    if(offset.value < limit.value){
        return false
    }
    return true
}

const copy_to_clipboard = (text) => {
    navigator.clipboard.writeText(text)
    toast.add({severity:'success', summary: 'Success', detail: 'Copied to Clipboard', life: 3000});
}

const open_url = (url) => {
    window.open(url, '_blank')
}

</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<style lang="css">
    .p-panel-title {
        font-weight: bold!important;
    }
</style>

<template>
    <Toast />
    <div class="m-5">
        <h1 class="mt-3 font-bold">Gerstl API V1</h1>
        <small>powered by <a class="font-bold text-primary" href="https://snappy.works" target="_blank">Snappy</a> Elements: Custom APIxSDK</small>
        <Toolbar class="mt-2">
            <template #start>
                <InputText v-model="apikey" style="width: 300px" placeholder="Please enter your API-Key" class="mr-2" />
                <Button @click="get_data()" :disabled="!apikey ? true : false" label="Submit" icon="pi pi-sync" class="p-button" />
            </template>
        </Toolbar>
        <Toolbar v-if="authed" class="mt-2">
            <template #start>
                <span class="mr-2 font-bold">Offset</span>
                <InputNumber v-model="offset" showButtons :min="0" :max="100000" :inputStyle="{'width': '80px'}" locale="de-CH" class="mr-2 neuraxis_inputnumber" />
                <span class="mr-2 font-bold">Limit</span>
                <InputNumber v-model="limit" showButtons :min="1" :max="100000" :inputStyle="{'width': '80px'}" locale="de-CH" class="mr-2 neuraxis_inputnumber" />
                <Button @click="get_data()" :disabled="param_validation()" label="Refresh" icon="pi pi-refresh" class="p-button" />
            </template>
        </Toolbar>
        <TabView v-if="authed" class="col-12" ref="tabview4">
            <TabPanel>
                <template #header>
                    <i class="pi pi-table mr-2"></i>
                    <span class="mr-3">Data Viewer</span>
                    <Avatar v-if="dataset" :label="String(dataset.length)" class="mr-2 bg-gray-200" shape="circle"  style="width: 1.5rem; height: 1.5rem; font-size: 1rem; color: #000" />
                </template>
                <Toolbar v-if="dataset" class="mt-2">
                    <template #start>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filter_dataviewer['global'].value" placeholder="Suche" style="width: 400px" class="mr-2" />
                        </span>
                    </template>
                </Toolbar>
                <DataTable class="mt-3" v-model:filters="filter_dataviewer" :value="dataset" :rows="50" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[50,100,200]"  :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                    <Column field="sku" header="Artikelnr." sortable>
                        <template #body="slotProps">
                            <span class="font-bold">{{slotProps.data.sku}}</span>
                        </template>
                    </Column>
                    <Column field="title1" header="Titel1" sortable />
                    <Column field="title2" header="Titel2" sortable />
                    <Column field="title3" header="Titel3" sortable />
                    <Column field="year" header="Jahr" sortable />
                    <Column field="stock" header="Lager" sortable />
                    <Column>
                        <template #body="slotProps">
                            <Button icon="pi pi-eye" class="p-button-rounded p-button-text" @click="view_data(slotProps.data)" />
                        </template>
                    </Column>
                </DataTable>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <i class="pi pi-database mr-2"></i>
                    <span class="mr-3">Response</span>
                    <Avatar v-if="dataset" :label="String(dataset.length)" class="mr-2 bg-gray-200" shape="circle"  style="width: 1.5rem; height: 1.5rem; font-size: 1rem; color: #000" />
                </template>
                <Panel header="REST-API Response" toggleable class="mt-3">
                    <template #icons>
                        <button class="p-panel-header-icon p-link mr-2" @click="copy_to_clipboard(dataset_json)">
                            <span class="pi pi-copy"></span>
                        </button>
                    </template>
                    <div style="background-color: #1e1e1e;" class="w-full border-round p-3">
                        <pre class="text-white p-4 text-sm">{{ dataset_json }}</pre>
                    </div>
                </Panel>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <i class="pi pi-file mr-2"></i>
                    <span class="mr-3">Files</span>
                    <Avatar v-if="dataset" :label="String(templates.length - 1)" class="mr-2 bg-gray-200" shape="circle"  style="width: 1.5rem; height: 1.5rem; font-size: 1rem; color: #000" />
                </template>
                <template v-for="template in templates" :key="template">
                    <Panel v-if="template == 2" header="XML Standard" toggleable class="mt-3">
                        <template #icons>
                            <button class="p-panel-header-icon p-link mr-2" @click="open_url(xml_standard_file_url)">
                                <span class="pi pi-external-link"></span>
                            </button>
                            <button class="p-panel-header-icon p-link mr-2" @click="copy_to_clipboard(xml_standard_file_url)">
                                <span class="pi pi-copy"></span>
                            </button>
                        </template>
                        <div style="background-color: #1e1e1e;" class="w-full border-round p-3">
                            <span class="text-white text-sm">
                                {{ xml_standard_file_url }}
                            </span>
                        </div>
                    </Panel>
                    <Panel v-if="template == 3" header="JSON Standard" toggleable class="mt-3">
                        <template #icons>
                            <button class="p-panel-header-icon p-link mr-2" @click="open_url(json_standard_file_url)">
                                <span class="pi pi-external-link"></span>
                            </button>
                            <button class="p-panel-header-icon p-link mr-2" @click="copy_to_clipboard(json_standard_file_url)">
                                <span class="pi pi-copy"></span>
                            </button>
                        </template>
                        <div style="background-color: #1e1e1e;" class="w-full border-round p-3">
                            <span class="text-white text-sm">
                                {{ json_standard_file_url }}
                            </span>
                        </div>
                    </Panel>
                    <Panel v-if="template == 10" header="Vivino XML Export" toggleable class="mt-3">
                        <template #icons>
                            <button class="p-panel-header-icon p-link mr-2" @click="open_url(xml_vivino_file_url)">
                                <span class="pi pi-external-link"></span>
                            </button>
                            <button class="p-panel-header-icon p-link mr-2" @click="copy_to_clipboard(xml_vivino_file_url)">
                                <span class="pi pi-copy"></span>
                            </button>
                        </template>
                        <div style="background-color: #1e1e1e;" class="w-full border-round p-3">
                            <span class="text-white text-sm">
                                {{xml_vivino_file_url }}
                            </span>
                        </div>
                    </Panel>
                    <Panel v-if="template == 11" header="The Cellar JSON Export" toggleable class="mt-3">
                        <template #icons>
                            <button class="p-panel-header-icon p-link mr-2" @click="open_url(json_the_cellar_file_url)">
                                <span class="pi pi-external-link"></span>
                            </button>
                            <button class="p-panel-header-icon p-link mr-2" @click="copy_to_clipboard(json_the_cellar_file_url)">
                                <span class="pi pi-copy"></span>
                            </button>
                        </template>
                        <div style="background-color: #1e1e1e;" class="w-full border-round p-3">
                            <span class="text-white text-sm">
                                {{json_the_cellar_file_url }}
                            </span>
                        </div>
                    </Panel>
                </template>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <i class="pi pi-code mr-2"></i>
                    <span class="mr-3">Code samples</span>
                    <Avatar v-if="dataset" :label="String(5)" class="mr-2 bg-gray-200" shape="circle"  style="width: 1.5rem; height: 1.5rem; font-size: 1rem; color: #000" />
                </template>
                <Panel header="cURL" toggleable class="mt-3">
                    <template #icons>
                        <button class="p-panel-header-icon p-link mr-2" @click="copy_to_clipboard(code_snippet_curl)">
                            <span class="pi pi-copy"></span>
                        </button>
                    </template>
                    <div style="background-color: #1e1e1e;" class="w-full border-round p-3">
                        <pre class="text-white text-sm">{{ code_snippet_curl }}</pre>
                    </div>
                </Panel>
                <Panel header="Python" toggleable class="mt-3">
                    <template #icons>
                        <button class="p-panel-header-icon p-link mr-2" @click="copy_to_clipboard(code_snippet_python)">
                            <span class="pi pi-copy"></span>
                        </button>
                    </template>
                    <div style="background-color: #1e1e1e;" class="w-full border-round p-3">
                        <pre class="text-white text-sm">{{ code_snippet_python }}</pre>
                    </div>
                </Panel>
                <Panel header="PHP" toggleable class="mt-3">
                    <template #icons>
                        <button class="p-panel-header-icon p-link mr-2" @click="copy_to_clipboard(code_snippet_php)">
                            <span class="pi pi-copy"></span>
                        </button>
                    </template>
                    <div style="background-color: #1e1e1e;" class="w-full border-round p-3">
                        <pre class="text-white text-sm">{{ code_snippet_php }}</pre>
                    </div>
                </Panel>
                <Panel header="Node.js" toggleable class="mt-3">
                    <template #icons>
                        <button class="p-panel-header-icon p-link mr-2" @click="copy_to_clipboard(code_snippet_nodejs)">
                            <span class="pi pi-copy"></span>
                        </button>
                    </template>
                    <div style="background-color: #1e1e1e;" class="w-full border-round p-3">
                        <pre class="text-white text-sm">{{ code_snippet_nodejs }}</pre>
                    </div>
                </Panel>
                <Panel header="Powershell" toggleable class="mt-3">
                    <template #icons>
                        <button class="p-panel-header-icon p-link mr-2" @click="copy_to_clipboard(code_snippet_powershell)">
                            <span class="pi pi-copy"></span>
                        </button>
                    </template>
                    <div style="background-color: #1e1e1e;" class="w-full border-round p-3">
                        <pre class="text-white text-sm">{{ code_snippet_powershell }}</pre>
                    </div>
                </Panel>
            </TabPanel>
        </TabView>
        <div class="mt-5">
            <small>&copy; <a class="font-bold text-primary" href="https://www-impact-solutions.ch" target="_blank">Impact Solutions GmbH</a> - 2023</small>
        </div>
    </div>
    <!---------------------------------------------------->
    <!------------------ Data Viewer --------------------->
    <!---------------------------------------------------->
    <Dialog v-model:visible="viewDataDialog" :style="{width: '80%'}" header="API Daten" :modal="true" class="p-fluid z-5">
        <div class="col-12 formgrid grid">
            <template  v-for="(data, key) in row" :key="key">
                <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                    {{ key }}
                </div>
                <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                    {{ data }}
                </div>
            </template>
            <div class="field col-12 mt-3">
                <div>
                    <Button label="Schliessen" @click="viewDataDialog = false" class="w-auto mr-2 mb-1 p-button" />
                </div>
            </div>
        </div>
    </Dialog>
</template>