<script setup>
import { ref, onMounted } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { useConfirm } from "primevue/useconfirm"
import { Cropper, Preview } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';

const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

const products = ref([])
const vendors = ref([])
const blogs = ref([])
const pages = ref([])

const frontpage = ref(null)
const title_image = ref(null)
const title_overlay_image = ref(null)
const title_post_title = ref(null)
const title_post_teaser = ref(null)
const title_post_cta_label = ref(null)
const title_post_cta_url = ref(null)
const quick_links = ref([])
const top_post_1 = ref(null)
const top_post_1_image = ref(null)
const top_post_2 = ref(null)
const top_post_2_image = ref(null)
const top_post_3 = ref(null)
const top_post_3_image = ref(null)
const top_post_4 = ref(null)
const top_post_4_image = ref(null)
const top_post_5 = ref(null)
const top_post_5_image = ref(null)
const top_post_6 = ref(null)
const top_post_6_image = ref(null)
const top_post_7 = ref(null)
const top_post_7_image = ref(null)
const top_post_library = ref([])
const blog_roll_image = ref(null)
const top_product_1 = ref(null)
const top_product_2 = ref(null)
const top_product_3 = ref(null)
const top_product_4 = ref(null)
const top_product_5 = ref(null)
const top_product_6 = ref(null)
const promotions = ref([])
const aktuell = ref([])
const locations = ref([])
const blog_categories = ref([])
const event_categories = ref([])
const blog_category_links = ref([])
const event_category_links = ref([])
const promotion_blocks = ref([])

const editFrontPageImageDialog = ref(false)
const editFrontPageImageOverlayDialog = ref(false)
const title_image_editor = ref(null)
const title_overlay_image_editor = ref(null)
const title_image_cropper = ref(null)
const title_overlay_image_cropper = ref(null)
const blog_roll_image_cropper = ref(null)
const blog_post_id = ref(null)
const random_number = ref(String(Math.floor(Math.random() * 100000000)))
const editBlogRollImageDialog = ref(false)

const location_categories = ref([
    {value: "weinshop", name: "Gerstl Wein&Shop"},
    {value: "partner", name: "Gerstl Partner Shops"},
    {value: "restaurant", name: "Gerstl Partner Restaurants"}
])

onMounted( async () => {
    await axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/get-blogs")
    .then(response => {
        blogs.value = response.data
        axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/get-events")
        .then(response => {
            Array.prototype.push.apply(blogs.value, response.data)
        })
    })
    await axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/get-frontpage")
    .then(response => {
        frontpage.value = response.data
        title_image.value = response.data.title_image
        title_overlay_image.value = response.data.title_overlay_image
        title_image_editor.value = response.data.title_image
        quick_links.value = response.data.quick_links
        title_post_title.value = response.data.title_post_title
        title_post_teaser.value = response.data.title_post_teaser
        title_post_cta_label.value = response.data.title_post_cta_label
        title_post_cta_url.value = response.data.title_post_cta_url
        top_post_1.value = response.data.top_post_1
        top_post_2.value = response.data.top_post_2
        top_post_3.value = response.data.top_post_3
        top_post_4.value = response.data.top_post_4
        top_post_5.value = response.data.top_post_5
        top_post_6.value = response.data.top_post_6
        top_post_7.value = response.data.top_post_7
        top_product_1.value = response.data.top_product_1
        top_product_2.value = response.data.top_product_2
        top_product_3.value = response.data.top_product_3
        top_product_4.value = response.data.top_product_4
        top_product_5.value = response.data.top_product_5
        top_product_6.value = response.data.top_product_6
        promotions.value = response.data.promotions
        aktuell.value = response.data.aktuell
        locations.value = response.data.locations
        blog_category_links.value = response.data.blog_category_links
        event_category_links.value = response.data.event_category_links
        promotion_blocks.value = response.data.promotion_blocks
        get_blog_preview_image(top_post_1.value, 1)
        get_blog_preview_image(top_post_2.value, 2)
        get_blog_preview_image(top_post_3.value, 3)
        get_blog_preview_image(top_post_4.value, 4)
        get_blog_preview_image(top_post_5.value, 5)
        get_blog_preview_image(top_post_6.value, 6)
        get_blog_preview_image(top_post_7.value, 7)
    })
    await axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/get-products")
    .then(response => {
        products.value = response.data
    })
    await axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/get-vendor-pages")
    .then(response => {
        vendors.value = response.data
    })
    await axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/get-staticpages")
    .then(response => {
        pages.value = response.data
    })
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gerstl/get-system')
    .then(response => {
        blog_categories.value = response.data.blog_categories
        event_categories.value = response.data.event_categories
    })
})

const add_quick_link = () => {
    quick_links.value.push({
        "url": "https://",
        "label": ""
    })
}

const add_promotion = () => {
    promotions.value.push({
        "promotion": null,
        "label": "",
        "url": ""
    })
}

const add_aktuell = () => {
    aktuell.value.push({
        "aktuell": null,
        "label": "",
        "url": ""
    })
}


const add_location = () => {
    locations.value.push({
        "page": null,
        "label": "",
        "category": "",
        "address": ""
    })
}

const add_blog_category_link = () => {
    blog_category_links.value.push({
        "category": null,
        "page": null
    })
}

const add_event_category_link = () => {
    event_category_links.value.push({
        "category": null,
        "page": null
    })
}

const onQuickLinksRowEdit = (e) => {
    quick_links.value[e.index][e.field] = e.newValue
}

const onPromotionsRowEdit = (e) => {
    promotions.value[e.index][e.field] = e.newValue
}

const onAktuellRowEdit = (e) => {
    aktuell.value[e.index][e.field] = e.newValue
}

const onLocationsRowEdit = (e) => {
    locations.value[e.index][e.field] = e.newValue
}

const onQuickLinksRowReorder = (e) => {
    quick_links.value = e.value
}

const onPromotionsRowReorder = (e) => {
    promotions.value = e.value
}

const onAktuellRowReorder = (e) => {
    aktuell.value = e.value
}

const onLocationsRowReorder = (e) => {
    locations.value = e.value
}

const delete_quick_link = (e) => {
    quick_links.value.splice(e, 1)
}

const delete_promotion = (e) => {
    promotions.value.splice(e, 1)
}

const delete_aktuell = (e) => {
    aktuell.value.splice(e, 1)
}

const delete_location = (e) => {
    locations.value.splice(e, 1)
}

const onPromotionBlockEdit = (e) => {
    promotion_blocks.value[e.index][e.field] = e.newValue
}

const onPromotionBlockRowReorder = (e) => {
    promotion_blocks.value = e.value
}

const save = () => {
    confirm.require({
        message: 'Bist du sicher, dass du Anpassungen speichern möchtest?',
        header: 'Startseite speichern',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, speichern',
        acceptClass: 'p-button-success',
        rejectLabel: 'Nein',
        accept: () => {
            let data = {
                "title_post_title": title_post_title.value,
                "title_post_teaser": title_post_teaser.value,
                "title_post_cta_label": title_post_cta_label.value,
                "title_post_cta_url": title_post_cta_url.value,
                "title_image": title_image.value,
                "title_overlay_image": title_overlay_image.value,
                "quick_links": quick_links.value,
                "top_post_1": top_post_1.value,
                "top_post_2": top_post_2.value,
                "top_post_3": top_post_3.value,
                "top_post_4": top_post_4.value,
                "top_post_5": top_post_5.value,
                "top_post_6": top_post_6.value,
                "top_post_7": top_post_7.value,
                "top_product_1": top_product_1.value,
                "top_product_2": top_product_2.value,
                "top_product_3": top_product_3.value,
                "top_product_4": top_product_4.value,
                "top_product_5": top_product_5.value,
                "top_product_6": top_product_6.value,
                "promotions": promotions.value,
                "aktuell": aktuell.value,
                "locations": locations.value,
                "promotion_blocks": promotion_blocks.value
            }
            console.log("data", data)
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/save-frontpage", data)
            .then(response => {
                toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Startseite erfolgreich gespeichert', life: 3000})
            })
        }
    })
}

const uploadTitleImage = (e) => {
    if (title_image_editor.value) {
          URL.revokeObjectURL(title_image_editor.value);
        }
        title_image_editor.value = URL.createObjectURL(e["files"][0])
}

const promotion_block_image_editor = ref(null)
const uploadPromotionBlockImage = (e) => {
    if (promotion_block_image_editor.value) {
          URL.revokeObjectURL(promotion_block_image_editor.value);
        }
        promotion_block_image_editor.value = URL.createObjectURL(e["files"][0])
}

const uploadTitleOverlayImage = (e) => {
    if (title_overlay_image_editor.value) {
          URL.revokeObjectURL(title_overlay_image_editor.value);
        }
        title_overlay_image_editor.value = URL.createObjectURL(e["files"][0])
}

const upload_blog_roll_image = (e) => {
    console.log("e", e)
    if (blog_roll_image.value) {
          URL.revokeObjectURL(blog_roll_image.value);
        }
        blog_roll_image.value = URL.createObjectURL(e["files"][0])
}

const save_title_image = async () => {
    let { canvas } = title_image_cropper.value.getResult()
    await canvas.toBlob((blob) => {
        let reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = async () => {
            let payload = {"file": reader.result, "image_type": 'title_image_' + random_number.value, "workflow_id": "frontpage" }
            await axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/upload-image", payload)
            .then(response => {
                toast.add({severity:'success', summary: 'Erfolg', detail: 'Bild erfolgreich hochgeladen', life: 3000})
                title_image.value = response.data.image_url
            })
        }
    })
}
const promotion_block_image_cropper = ref(null)
const save_promo_block_image = async () => {
    let { canvas } = promotion_block_image_cropper.value.getResult()
    await canvas.toBlob((blob) => {
        let reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = async () => {
            let payload = {"file": reader.result, "image_type": 'promotion_block_'+String(promotion_block_id.value)+'_' + random_number.value, "workflow_id": "frontpage" }
            await axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/upload-image", payload)
            .then(response => {
                toast.add({severity:'success', summary: 'Erfolg', detail: 'Bild erfolgreich hochgeladen', life: 3000})
                promotion_blocks.value[promotion_block_id.value].preview_image = response.data.image_url
            })
        }
    })
}

const save_title_overlay_image = async () => {
    let { canvas } = title_overlay_image_cropper.value.getResult()
    await canvas.toBlob((blob) => {
        let reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = async () => {
            let payload = {"file": reader.result, "image_type": 'title_overlay_image_' + random_number.value, "workflow_id": "frontpage" }
            await axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/upload-image", payload)
            .then(response => {
                toast.add({severity:'success', summary: 'Erfolg', detail: 'Bild erfolgreich hochgeladen', life: 3000})
                title_overlay_image.value = response.data.image_url
            })
        }
    })
}


const publish = () => {
    confirm.require({
        message: 'Wollen Sie wirklich alle Änderungen publizieren?',
        header: 'Änderungen publizieren',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            let payload = {"type": "frontPage"}
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/publish", payload)
            .then(response => {
                toast.add({severity: 'success', summary: 'Publiziert', detail: 'die Veröffentlichung war erfolgreich (Teil 1)', life: 3000})
                let payload = {"type": "site"}
                axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/publish", payload)
                .then(response => {
                    toast.add({severity: 'success', summary: 'Publiziert', detail: 'die Veröffentlichung war erfolgreich (Teil 2)', life: 3000})
                })
            })
        }
    })
}

const edit_blog_roll_image = async (blog_roll_id) => {
    if(!blog_roll_id){
        toast.add({severity: 'warning', summary: 'Fehler', detail: 'Bitte einen Artikel auswählen'})
    }else{
        let payload = {"workflow_id": blog_roll_id}
        await axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/get-top-post-library", payload)
        .then(response => {
            top_post_library.value = response.data
            console.log("top_post_libary", top_post_library.value)
            editBlogRollImageDialog.value = true
        })
    }
}

const save_blog_roll_image = async (blog_post_id) => {
    let { canvas } = blog_roll_image_cropper.value.getResult()
    await canvas.toBlob((blob) => {
        let reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = async () => {
            let payload = {"file": reader.result, "image_type": 'blog_roll_' + random_number.value, "workflow_id": "frontpage" }
            await axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/upload-image", payload)
            .then(response => {
                toast.add({severity:'success', summary: 'Erfolg', detail: 'Bild erfolgreich hochgeladen', life: 3000})
                blog_roll_image.value = response.data.image_url
                switch (blog_post_id) {
                    case 1:
                        top_post_1_image.value = blog_roll_image.value
                        break;
                    case 2:
                        top_post_2_image.value = blog_roll_image.value
                        break;
                    case 3:
                        top_post_3_image.value = blog_roll_image.value
                        break;
                    case 4:
                        top_post_4_image.value = blog_roll_image.value
                        break;
                    case 5:
                        top_post_5_image.value = blog_roll_image.value
                        break;
                    case 6:
                        top_post_6_image.value = blog_roll_image.value
                        break;
                    case 7:
                        top_post_7_image.value = blog_roll_image.value
                        break;
                }
            })
        }
    })
}

const get_blog_name = (workflow_id) => {
    let blog_name = ""
    for (let key in blogs.value){
        if (blogs.value[key].workflow_id == workflow_id) {
            blog_name = blogs.value[key].title
        }
    }
    return blog_name
}

const get_page_name = (slug) => {
    let page_name = ""
    for (let key in pages.value){
        if (pages.value[key].slug == slug) {
            page_name = pages.value[key].title
        }
    }
    return page_name
}

const get_location_category_name = (value) => {
    let category_name = ""
    for (let key in location_categories.value){
        if (location_categories.value[key].value == value) {
            category_name = location_categories.value[key].name
        }
    }
    return category_name
}

const get_blog_preview_image = async (workflow_id, blog_roll_id) => {
    for (let key in blogs.value){
        if (blogs.value[key].workflow_id == workflow_id) {
            switch (blog_roll_id) {
                case 1:
                    top_post_1_image.value = blogs.value[key].header_image_preview
                    break
                case 2:
                    top_post_2_image.value = blogs.value[key].header_image_preview
                    break
                case 3:
                    top_post_3_image.value = blogs.value[key].header_image_preview
                    break
                case 4:
                    top_post_4_image.value = blogs.value[key].header_image_preview
                    break
                case 5:
                    top_post_5_image.value = blogs.value[key].header_image_preview
                    break
                case 6:
                    top_post_6_image.value = blogs.value[key].header_image_preview
                    break
                case 7:
                    top_post_7_image.value = blogs.value[key].header_image_preview
                    break
            }
        break
        }
    }
}
const promotion_block_id = ref(null)
const editPromotionBlockImageDialog = ref(false)
const edit_promo_block_image = async (block_index) => {
    console.log("block_index", block_index, promotion_blocks.value[block_index])
    promotion_block_image_editor.value = promotion_blocks.value[block_index].preview_image
    promotion_block_id.value = block_index
    editPromotionBlockImageDialog.value = true
}
</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Toast />
    <ConfirmDialog />
    <Card class="mt-4 shadow-4">
        <template #title> Titel Beitrag </template>
        <template #content>
            <div class="col-12 formgrid grid">
                <div class="field col-12 mt-2">
                    <span class="p-float-label">
                        <InputText id="title_post_title" v-model="title_post_title" class="w-full" />
                        <label for="title_post_title">Titel</label>
                    </span>
                </div>
                <div class="field col-12 mt-2">
                    <span class="p-float-label">
                        <InputText id="title_post_teaser" v-model="title_post_teaser" class="w-full" />
                        <label for="title_post_teaser">Teaser</label>
                    </span>
                </div>
                <div class="field col-6 mt-2">
                    <span class="p-float-label">
                        <InputText id="title_post_cta_label" v-model="title_post_cta_label" class="w-full" />
                        <label for="title_post_cta_label">CTA Button</label>
                    </span>
                </div>
                <div class="field col-6 mt-2">
                    <div class="p-inputgroup flex-1">
                        <span class="p-inputgroup-addon">https://gerstl.ch/</span>
                        <span class="p-float-label">
                            <InputText id="title_post_cta_url" v-model="title_post_cta_url" class="w-full" />
                            <label for="title_post_cta_url">CTA Button URL</label>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-12 formgrid grid">
                <div class="field col-12 mt-2">
                    <Button @click="editFrontPageImageDialog = true" v-tooltip.bottom="'Titel-Bild editieren'" label="Titel-Bild" class="w-auto mr-2 p-button" icon="pi pi-image" />
                    <Button @click="editFrontPageImageOverlayDialog = true" v-tooltip.bottom="'Flaschen-Bild editieren'" label="Flaschen-Bild" class="w-auto p-button" icon="pi pi-image" />
                </div>
            </div>
            <div class="field col-6 mt-2">
                <div style="width: 200px" class="mr-2">
                    <img v-if="title_image" :src="title_image + '?v=' + random_number" />
                </div>
            </div>
            <div class="field col-6 mt-2">
                <div style="width: 200px;">
                    <img v-if="title_overlay_image" :src="title_overlay_image + '?v=' + random_number" />
                </div>
            </div>
        </template>
    </Card>
    <Card class="mt-4 shadow-4">
        <template #title>Quick-Links</template>
        <template #content>
            <div class="col-12 formgrid grid">
                <div class="col-12 formgrid grid">
                    <div class="field col-12 mt-2">
                        <DataTable :value="quick_links" :rows="20" responsiveLayout="scroll" editMode="cell" @cell-edit-complete="onQuickLinksRowEdit" :rowHover="true" @rowReorder="onQuickLinksRowReorder">
                            <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                            <Column field="label" header="Titel" style="">
                                <template #editor="{ data, field }">
                                    <InputText v-model="data[field]" />
                                </template>
                                <template #body="{ data, field }">
                                    {{data[field]}}
                                </template>
                            </Column>
                            <Column field="url" header="URL">
                                <template #editor="{ data, field }">
                                    <div class="p-inputgroup flex-1">
                                        <span class="p-inputgroup-addon">https://gerstl.ch/</span>
                                        <InputText v-model="data[field]" />
                                    </div>
                                </template>
                                <template #body="{ data, field }">
                                    https://gerstl.ch/{{data[field]}}
                                </template>
                            </Column>
                            <Column>
                                <template #body="slotProps">
                                    <Button @click="delete_quick_link(slotProps.index)" icon="pi pi-trash" class="p-button-rounded p-button-danger" v-tooltip.bottom="'Link löschen'" />
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                </div>
                <div class="col-12 formgrid grid">
                    <div class="field col-12 mt-2">
                        <Button icon="pi pi-plus" @click="add_quick_link()" label="Quick-Link hinzufügen" class="p-button p-button w-auto mr-4" />
                        <span class="mr-1 font-bold">{{ String(quick_links.length) }}</span><span>Quick-Links</span>
                    </div>
                </div>
            </div>
        </template>
    </Card>

    <Card class="mt-4 shadow-4">
        <template #title>Top Artikel 1 (Quadrat)</template>
        <template #content>
            <div class="col-12 formgrid grid">
                <div class="field col-12 mt-3">
                    <div class="flex align-items-center flex-wrap card-container green-container">
                        <img v-if="top_post_1_image" :src="top_post_1_image + '?v=' + random_number" style="height: 100px" class="shadow-2 m-2" />
                        <span class="p-float-label mr-2">
                            <Dropdown @change="get_blog_preview_image(top_post_1, 1)" :filter="true" id="top_post1" v-model="top_post_1" :options="blogs" optionLabel="title" optionValue="workflow_id" class="w-auto" />
                            <label for="top_post1">Artikel</label>
                        </span>
                        <!-- <Button @click="edit_blog_roll_image(top_post_1, blog_post_id = 1)" v-tooltip.bottom="'Vorschlau-Bild editieren'" label="Vorschau-Bild" class="w-auto p-button" icon="pi pi-image" /> -->
                    </div>
                </div>
            </div>
        </template>
    </Card>

    <Card class="mt-4 shadow-4">
        <template #title>Top Artikel 2 (Quadrat)</template>
        <template #content>
            <div class="col-12 formgrid grid">
                <div class="field col-12 mt-3">
                    <div class="flex align-items-center flex-wrap card-container green-container">
                        <img v-if="top_post_2_image" :src="top_post_2_image + '?v=' + random_number" style="height: 100px" class="shadow-2 m-2" />
                        <span class="p-float-label mr-2">
                            <Dropdown @change="get_blog_preview_image(top_post_2, 2)" :filter="true" id="top_post_2" v-model="top_post_2" :options="blogs" optionLabel="title" optionValue="workflow_id" class="w-auto" />
                            <label for="top_post_2">Artikel</label>
                        </span>
                        <!-- <Button @click="edit_blog_roll_image(top_post_2, blog_post_id = 2)" v-tooltip.bottom="'Vorschlau-Bild editieren'" label="Vorschau-Bild" class="w-auto p-button" icon="pi pi-image" /> -->
                    </div>
                </div>
            </div>
        </template>
    </Card>

    <Card class="mt-4 shadow-4">
        <template #title>Top Artikel 3 (Quadrat)</template>
        <template #content>
            <div class="col-12 formgrid grid">
                <div class="field col-12 mt-3">
                    <div class="flex align-items-center flex-wrap card-container green-container">
                        <img v-if="top_post_3_image" :src="top_post_3_image + '?v=' + random_number" style="height: 100px" class="shadow-2 m-2" />
                        <span class="p-float-label mr-2">
                            <Dropdown @change="get_blog_preview_image(top_post_3, 3)" :filter="true" id="top_post_3" v-model="top_post_3" :options="blogs" optionLabel="title" optionValue="workflow_id" class="w-auto" />
                            <label for="top_post_3">Artikel</label>
                        </span>
                        <!-- <Button @click="edit_blog_roll_image(top_post_3, blog_post_id = 3)" v-tooltip.bottom="'Vorschlau-Bild editieren'" label="Vorschau-Bild" class="w-auto p-button" icon="pi pi-image" /> -->
                    </div>
                </div>
            </div>
        </template>
    </Card>

    <Card class="mt-4 shadow-4">
        <template #title>Top Artikel 4 (Breit Format)</template>
        <template #content>
            <div class="col-12 formgrid grid">
                <div class="field col-12 mt-3">
                    <div class="flex align-items-center flex-wrap card-container green-container">
                        <span class="p-float-label mr-2">
                            <Dropdown :filter="true" id="top_post_4" v-model="top_post_4" :options="blogs" optionLabel="title" optionValue="workflow_id" class="w-auto" />
                            <label for="top_post_4">Artikel</label>
                        </span>
                    </div>
                </div>
            </div>
        </template>
    </Card>

    <Card class="mt-4 shadow-4">
        <template #title>Top Artikel 5 (Breit Format)</template>
        <template #content>
            <div class="col-12 formgrid grid">
                <div class="field col-12 mt-3">
                    <div class="flex align-items-center flex-wrap card-container green-container">
                        <span class="p-float-label mr-2">
                            <Dropdown :filter="true" id="top_post_5" v-model="top_post_5" :options="blogs" optionLabel="title" optionValue="workflow_id" class="w-auto" />
                            <label for="top_post_5">Artikel</label>
                        </span>
                    </div>
                </div>
            </div>
        </template>
    </Card>

    <Card class="mt-4 shadow-4">
        <template #title>Top Artikel 6 (Quadrat)</template>
        <template #content>
            <div class="col-12 formgrid grid">
                <div class="field col-12 mt-3">
                    <div class="flex align-items-center flex-wrap card-container green-container">
                        <img v-if="top_post_6_image" :src="top_post_6_image + '?v=' + random_number" style="height: 100px" class="shadow-2 m-2" />
                        <span class="p-float-label mr-2">
                            <Dropdown  @change="get_blog_preview_image(top_post_6, 6)" :filter="true" id="top_post_6" v-model="top_post_6" :options="blogs" optionLabel="title" optionValue="workflow_id" class="w-auto" />
                            <label for="top_post_6">Artikel</label>
                        </span>
                        <!-- <Button @click="edit_blog_roll_image(top_post_6, blog_post_id = 6)" v-tooltip.bottom="'Vorschlau-Bild editieren'" label="Vorschau-Bild" class="w-auto p-button" icon="pi pi-image" /> -->
                    </div>
                </div>
            </div>
        </template>
    </Card>

    <Card class="mt-4 shadow-4">
        <template #title>Top Artikel 7 (Quadrat)</template>
        <template #content>
            <div class="col-12 formgrid grid">
                <div class="field col-12 mt-3">
                    <div class="flex align-items-center flex-wrap card-container green-container">
                        <img v-if="top_post_7_image" :src="top_post_7_image + '?v=' + random_number" style="height: 100px" class="shadow-2 m-2" />
                        <span class="p-float-label mr-2">
                            <Dropdown  @change="get_blog_preview_image(top_post_7, 7)" :filter="true" id="top_post_7" v-model="top_post_7" :options="blogs" optionLabel="title" optionValue="workflow_id" class="w-auto" />
                            <label for="top_post_7">Artikel</label>
                        </span>
                        <!-- <Button @click="edit_blog_roll_image(top_post_7, blog_post_id = 7)" v-tooltip.bottom="'Vorschlau-Bild editieren'" label="Vorschau-Bild" class="w-auto p-button" icon="pi pi-image" /> -->
                    </div>
                </div>
            </div>
        </template>
    </Card>

    <Card class="mt-4 shadow-4">
        <template #title> Top 6 Produkte </template>
        <template #content>
            <div class="col-12 formgrid grid">
                <div class="field col-4 mt-2">
                    <span class="p-float-label">
                        <Dropdown id="top_product1" :filter="true" :virtualScrollerOptions="{ itemSize: 38 }" v-model="top_product_1" :options="products" optionLabel="name" optionValue="id" class="w-full" />
                        <label for="top_product1">Produkt 1</label>
                    </span>
                </div>
                <div class="field col-4 mt-2">
                    <span class="p-float-label">
                        <Dropdown id="top_product2" :filter="true" :virtualScrollerOptions="{ itemSize: 38 }" v-model="top_product_2" :options="products" optionLabel="name" optionValue="id" class="w-full" />
                        <label for="top_product2">Produkt 2</label>
                    </span>
                </div>
                <div class="field col-4 mt-2">
                    <span class="p-float-label">
                        <Dropdown id="top_product3" :filter="true" :virtualScrollerOptions="{ itemSize: 38 }" v-model="top_product_3" :options="products" optionLabel="name" optionValue="id" class="w-full" />
                        <label for="top_product3">Produkt 3</label>
                    </span>
                </div>
            </div>
            <div class="col-12 formgrid grid">
                <div class="field col-4 mt-4">
                    <span class="p-float-label">
                        <Dropdown id="top_product4" :filter="true" :virtualScrollerOptions="{ itemSize: 38 }" v-model="top_product_4" :options="products" optionLabel="name" optionValue="id" class="w-full" />
                        <label for="top_product4">Produkt 4</label>
                    </span>
                </div>
                <div class="field col-4 mt-4">
                    <span class="p-float-label">
                        <Dropdown id="top_product5" :filter="true" :virtualScrollerOptions="{ itemSize: 38 }" v-model="top_product_5" :options="products" optionLabel="name" optionValue="id" class="w-full" />
                        <label for="top_product5">Produkt 5</label>
                    </span>
                </div>
                <div class="field col-4 mt-4">
                    <span class="p-float-label">
                        <Dropdown id="top_product6" :filter="true" :virtualScrollerOptions="{ itemSize: 38 }" v-model="top_product_6" :options="products" optionLabel="name" optionValue="id" class="w-full" />
                        <label for="top_product6">Produkt 6</label>
                    </span>
                </div>
            </div>
        </template>
    </Card>

    <Card class="mt-4 shadow-4">
        <template #title> Promotion Blöcke </template>
        <template #content>
            <DataTable :value="promotion_blocks" :rows="20" responsiveLayout="scroll" editMode="cell" @cell-edit-complete="onPromotionBlockEdit" :rowHover="true" @rowReorder="onPromotionBlockRowReorder">
                <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                <Column field="preview_image" header="Vorschau">
                    <template #editor="{ data, field, index }">
                        <img v-if="data[field]" :src="data[field] + '?v=' + random_number" style="height: 50px" class="shadow-2 m-2" />
                        <Button @click="edit_promo_block_image(index)" icon="pi pi-pencil" class="p-button-rounded p-button-info" v-tooltip.bottom="'Bild editieren'" />
                    </template>
                    <template #body="{ data, field }">
                        <img v-if="data[field]" :src="data[field] + '?v=' + random_number" style="height: 100px" class="shadow-2 m-2" />
                    </template>
                </Column>
                <Column field="title" header="Titel">
                    <template #editor="{ data, field }">
                        <InputText v-model="data[field]" class="w-full" />
                    </template>
                    <template #body="{ data, field }">
                        {{data[field]}}
                    </template>
                </Column>
                <Column field="sub_title" header="Sub-Titel">
                    <template #editor="{ data, field }">
                        <InputText v-model="data[field]" class="w-full" />
                    </template>
                    <template #body="{ data, field }">
                        {{data[field]}}
                    </template>
                </Column>
                <Column field="cta_label" header="CTA Label">
                    <template #editor="{ data, field }">
                        <InputText v-model="data[field]" class="w-full" />
                    </template>
                    <template #body="{ data, field }">
                        {{data[field]}}
                    </template>
                </Column>
                <Column field="cta_url" header="CTA URL">
                    <template #editor="{ data, field }">
                        <div class="p-inputgroup flex-1">
                            <span class="p-inputgroup-addon">https://gerstl.ch/</span>
                            <InputText v-model="data[field]" class="w-full" />
                        </div>
                    </template>
                    <template #body="{ data, field }">
                        https://gerstl.ch/{{data[field]}}
                    </template>
                </Column>
            </DataTable>
        </template>
    </Card>
    
    <Card class="mt-4 shadow-4">
        <template #title>Promotionen</template>
        <template #content>
            <div class="col-12 formgrid grid">
                <div class="col-12 formgrid grid">
                    <div class="field col-12 mt-2">
                        <DataTable :value="promotions" :rows="20" responsiveLayout="scroll" editMode="cell" @cell-edit-complete="onPromotionsRowEdit" :rowHover="true" @rowReorder="onPromotionsRowReorder">
                            <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                            <Column field="label" header="Titel" style="">
                                <template #editor="{ data, field }">
                                    <InputText v-model="data[field]" />
                                </template>
                                <template #body="{ data, field }">
                                    {{data[field]}}
                                </template>
                            </Column>
                            <Column field="promotion" header="Magazin">
                                <template #editor="{ data, field }">
                                    <Dropdown :filter="true" :showClear="true" v-model="data[field]" :options="blogs" optionLabel="title" optionValue="workflow_id" class="w-auto" />
                                </template>
                                <template #body="{ data, field }">
                                    {{ get_blog_name(data[field]) }}
                                </template>
                            </Column>
                            <Column field="url" header="URL">
                                <template #editor="{ data, field }">
                                    <div class="p-inputgroup flex-1">
                                        <span class="p-inputgroup-addon">https://gerstl.ch</span>
                                        <InputText v-model="data[field]" />
                                    </div>
                                </template>
                                <template #body="{ data, field }">
                                    <span v-if="data[field]">https://gerstl.ch{{data[field]}}</span>
                                </template>
                            </Column>
                            <Column>
                                <template #body="slotProps">
                                    <Button @click="delete_promotion(slotProps.index)" icon="pi pi-trash" class="p-button-rounded p-button-danger" v-tooltip.bottom="'Link löschen'" />
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                </div>
                <div class="col-12 formgrid grid">
                    <div class="field col-12 mt-2">
                        <Button icon="pi pi-plus" @click="add_promotion()" label="Promotion hinzufügen" class="p-button p-button w-auto mr-4" />
                        <span class="mr-1 font-bold">{{ String(promotions.length) }}</span><span>Promotionen</span>
                    </div>
                </div>
            </div>
        </template>
    </Card>
    <Card class="mt-4 shadow-4">
    <template #title>Aktuell</template>
        <template #content>
            <div class="col-12 formgrid grid">
                <div class="col-12 formgrid grid">
                    <div class="field col-12 mt-2">
                        <DataTable :value="aktuell" :rows="20" responsiveLayout="scroll" editMode="cell" @cell-edit-complete="onAktuellRowEdit" :rowHover="true" @rowReorder="onAktuellRowReorder">
                            <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                            <Column field="label" header="Titel" style="">
                                <template #editor="{ data, field }">
                                    <InputText v-model="data[field]" />
                                </template>
                                <template #body="{ data, field }">
                                    {{data[field]}}
                                </template>
                            </Column>
                            <Column field="promotion" header="Magazin">
                                <template #editor="{ data, field }">
                                    <Dropdown :filter="true" :showClear="true" v-model="data[field]" :options="blogs" optionLabel="title" optionValue="workflow_id" class="w-auto" />
                                </template>
                                <template #body="{ data, field }">
                                    {{ get_blog_name(data[field]) }}
                                </template>
                            </Column>
                            <Column field="url" header="URL">
                                <template #editor="{ data, field }">
                                    <div class="p-inputgroup flex-1">
                                        <span class="p-inputgroup-addon">https://gerstl.ch</span>
                                        <InputText v-model="data[field]" />
                                    </div>
                                </template>
                                <template #body="{ data, field }">
                                    <span v-if="data[field]">https://gerstl.ch{{data[field]}}</span>
                                </template>
                            </Column>
                            <Column>
                                <template #body="slotProps">
                                    <Button @click="delete_aktuell(slotProps.index)" icon="pi pi-trash" class="p-button-rounded p-button-danger" v-tooltip.bottom="'Link löschen'" />
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                </div>
                <div class="col-12 formgrid grid">
                    <div class="field col-12 mt-2">
                        <Button icon="pi pi-plus" @click="add_aktuell()" label="Aktuell-Menu hinzufügen" class="p-button p-button w-auto mr-4" />
                        <span class="mr-1 font-bold">{{ String(aktuell.length) }}</span><span>Aktuell</span>
                    </div>
                </div>
            </div>
        </template>
    </Card>
    <Card class="mt-4 shadow-4">
        <template #title>Standorte</template>
        <template #content>
            <div class="col-12 formgrid grid">
                <div class="col-12 formgrid grid">
                    <div class="field col-12 mt-2">
                        <DataTable :value="locations" :rows="20" responsiveLayout="scroll" editMode="cell" @cell-edit-complete="onLocationsRowEdit" :rowHover="true" @rowReorder="onLocationsRowReorder">
                            <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                            <Column field="category" header="Kategorie">
                                <template #editor="{ data, field }">
                                    <Dropdown :filter="true" :showClear="true" v-model="data[field]" :options="location_categories" optionLabel="name" optionValue="value" class="w-auto" />
                                </template>
                                <template #body="{ data, field }">
                                    {{ get_location_category_name(data[field]) }}
                                </template>
                            </Column>
                            <Column field="label" header="Titel" style="">
                                <template #editor="{ data, field }">
                                    <InputText v-model="data[field]" />
                                </template>
                                <template #body="{ data, field }">
                                    {{data[field]}}
                                </template>
                            </Column>
                            <Column field="page" header="Seite">
                                <template #editor="{ data, field }">
                                    <Dropdown :filter="true" :showClear="true" v-model="data[field]" :options="pages" optionLabel="title" optionValue="slug" class="w-auto" />
                                </template>
                                <template #body="{ data, field }">
                                    {{ get_page_name(data[field]) }}
                                </template>
                            </Column>
                            <Column field="address" header="Adresse">
                                <template #editor="{ data, field }">
                                    <InputText v-model="data[field]" />
                                </template>
                                <template #body="{ data, field }">
                                    <span v-if="data[field]">{{data[field]}}</span>
                                </template>
                            </Column>
                            <Column>
                                <template #body="slotProps">
                                    <Button @click="delete_location(slotProps.index)" icon="pi pi-trash" class="p-button-rounded p-button-danger" v-tooltip.bottom="'Standort löschen'" />
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                </div>
                <div class="col-12 formgrid grid">
                    <div class="field col-12 mt-2">
                        <Button icon="pi pi-plus" @click="add_location()" label="Standort hinzufügen" class="p-button p-button w-auto mr-4" />
                        <span class="mr-1 font-bold">{{ String(locations.length) }}</span><span>Standorte</span>
                    </div>
                </div>
            </div>
        </template>
    </Card>
    <!--
    <Card class="mt-4 shadow-4">
        <template #title>Magazin Kategorien</template>
        <template #content>
            <div class="col-12 formgrid grid">
                <div class="col-12 formgrid grid">
                    <div class="field col-12 mt-2">
                        <DataTable :value="blog_category_links" :rows="20" responsiveLayout="scroll" editMode="cell" @cell-edit-complete="onBlogCategoryRowEdit" :rowHover="true" @rowReorder="onBlogCategoryRowReorder">
                            <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                            <Column field="category" header="Kategorie">
                                <template #editor="{ data, field }">
                                    <Dropdown :filter="true" :showClear="true" v-model="data[field]" :options="blog_categories" optionLabel="name" optionValue="value" class="w-auto" />
                                </template>
                                <template #body="{ data }">
                                    {{ data["value"] }}
                                </template>
                            </Column>
                            <Column field="page" header="Seite">
                                <template #editor="{ data, field }">
                                    <Dropdown :filter="true" :showClear="true" v-model="data[field]" :options="pages" optionLabel="title" optionValue="slug" class="w-auto" />
                                </template>
                                <template #body="{ data, field }">
                                    {{ get_page_name(data[field]) }}
                                </template>
                            </Column>
                            <Column>
                                <template #body="slotProps">
                                    <Button @click="delete_category(slotProps.index)" icon="pi pi-trash" class="p-button-rounded p-button-danger" v-tooltip.bottom="'Kategorie löschen'" />
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                </div>
                <div class="col-12 formgrid grid">
                    <div class="field col-12 mt-2">
                        <Button icon="pi pi-plus" @click="add_blog_category_link()" label="Kategorie hinzufügen" class="p-button p-button w-auto mr-4" />
                        <span class="mr-1 font-bold">{{ String(blog_category_links.length) }}</span><span>Kategorien</span>
                    </div>
                </div>
            </div>
        </template>
    </Card>
    <Card class="mt-4 shadow-4">
        <template #title>Event Kategorien</template>
        <template #content>
            <div class="col-12 formgrid grid">
                <div class="col-12 formgrid grid">
                    <div class="field col-12 mt-2">
                        <DataTable :value="event_category_links" :rows="20" responsiveLayout="scroll" editMode="cell" @cell-edit-complete="onEventCategoryRowEdit" :rowHover="true" @rowReorder="onEventCategoryRowReorder">
                            <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                            <Column field="category" header="Kategorie">
                                <template #editor="{ data, field }">
                                    <Dropdown :filter="true" :showClear="true" v-model="data[field]" :options="event_categories" optionLabel="name" optionValue="value" class="w-auto" />
                                </template>
                                <template #body="{ data }">
                                    {{ data["value"] }}
                                </template>
                            </Column>
                            <Column field="page" header="Seite">
                                <template #editor="{ data, field }">
                                    <Dropdown :filter="true" :showClear="true" v-model="data[field]" :options="pages" optionLabel="title" optionValue="slug" class="w-auto" />
                                </template>
                                <template #body="{ data, field }">
                                    {{ get_page_name(data[field]) }}
                                </template>
                            </Column>
                            <Column>
                                <template #body="slotProps">
                                    <Button @click="delete_category(slotProps.index)" icon="pi pi-trash" class="p-button-rounded p-button-danger" v-tooltip.bottom="'Kategorie löschen'" />
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                </div>
                <div class="col-12 formgrid grid">
                    <div class="field col-12 mt-2">
                        <Button icon="pi pi-plus" @click="add_event_category_link()" label="Kategorie hinzufügen" class="p-button p-button w-auto mr-4" />
                        <span class="mr-1 font-bold">{{ String(event_category_links.length) }}</span><span>Kategorien</span>
                    </div>
                </div>
            </div>
        </template>
    </Card>
    -->
    <Card class="mt-4 shadow-4">
        <template #content>
            <div class="col-12 formgrid grid">
                <div class="field col-12 mt-2">
                    <Button label="Speichern" icon="pi pi-save" class="p-button-success p-button w-auto mt-2 mr-3" @click="save" />
                    <Button @click="publish()" v-tooltip.bottom="'Änderungen publizieren'" label="Publizieren" class="w-auto mr-2 p-button-warning" icon="pi pi-upload" />
                </div>
            </div>
        </template>
    </Card>
    
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <!--------------------------------------------------->
    <!--------------- Edit Title Image ------------------>
    <!--------------------------------------------------->
    <Dialog v-model:visible="editFrontPageImageDialog" :style="{width: '80%'}" header="Titel Bild editieren" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-2">
                <cropper
                    ref="title_image_cropper"
                    :src="title_image_editor"
                    :auto-zoom="true"
                    :auto-center="true"
                />
            </div>
            <div class="field col-12 mt-2">
                <FileUpload mode="basic" customUpload url="" accept="image/*" :maxFileSize="10000000" @uploader="uploadTitleImage" :auto="true" chooseLabel="Bild hochladen" class="w-auto mr-2" />
            </div>
            <div class="field col-12 mt-2">
                <Button v-if="title_image_editor" label="Speichern" @click="editFrontPageImageDialog = false, save_title_image()" class="w-auto p-button-success" icon="pi pi-save" />
            </div>
        </div>
    </Dialog>
    <!--------------------------------------------------------->
    <!--------------- Edit Promo Block Image ------------------>
    <!--------------------------------------------------------->
    <Dialog v-model:visible="editPromotionBlockImageDialog" :style="{width: '80%'}" header="Promo Block Bild editieren" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-2">
                <cropper
                    ref="promotion_block_image_cropper"
                    :src="promotion_block_image_editor"
                    :auto-zoom="true"
                    :auto-center="true"
                    :stencil-props="{aspectRatio: 1 / 1}"
                />
            </div>
            <div class="field col-12 mt-2">
                <FileUpload mode="basic" customUpload url="" accept="image/*" :maxFileSize="10000000" @uploader="uploadPromotionBlockImage" :auto="true" chooseLabel="Bild hochladen" class="w-auto mr-2" />
            </div>
            <div class="field col-12 mt-2">
                <Button v-if="promotion_block_image_editor" label="Speichern" @click="editPromotionBlockImageDialog = false, save_promo_block_image()" class="w-auto p-button-success" icon="pi pi-save" />
            </div>
        </div>
    </Dialog>
    <!--------------------------------------------------->
    <!----------------- Preview Image ------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editFrontPageImageOverlayDialog" :style="{width: '80%'}" header="Titel Vorschau-Bild editieren" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-2">
                <cropper
                    ref="title_overlay_image_cropper"
                    :src="title_overlay_image_editor"
                    :auto-zoom="true"
                    :auto-center="true"
                />
            </div>
            <div class="field col-12 mt-2">
                <FileUpload mode="basic" customUpload url="" accept="image/*" :maxFileSize="10000000" @uploader="uploadTitleOverlayImage" :auto="true" chooseLabel="Bild hochladen" class="w-auto mr-2" />
            </div>
            <div class="field col-12 mt-2">
                <Button v-if="title_overlay_image_editor" label="Speichern" @click="editFrontPageImageOverlayDialog = false, save_title_overlay_image()" class="w-auto p-button-success" icon="pi pi-save" />
            </div>
        </div>
    </Dialog>
    <!-------------------------------------------------------->
    <!----------------- Blog Preview Image ------------------->
    <!-------------------------------------------------------->
    <Dialog v-model:visible="editBlogRollImageDialog" :style="{width: '80%'}" header="Titel Bild editieren" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-2">
                <cropper
                    ref="blog_roll_image_cropper"
                    :src="blog_roll_image"
                    :auto-zoom="true"
                    :auto-center="true"
                    :stencil-props="{aspectRatio: 1 / 1}"
                />
            </div>
            <div class="field col-12 mt-3">
                <template v-for="(img, index) of top_post_library" :key="index">
                    <img @click="blog_roll_image = img.url" :src="img.url" class="shadow-2 m-2" style="cursor: pointer; height: 100px; width: 100px; object-fit: cover; display:inline;" />
                </template>
            </div>
            <div class="field col-12 mt-2">
                <FileUpload mode="basic" customUpload url="" accept="image/*" :maxFileSize="10000000" @uploader="upload_blog_roll_image" :auto="true" chooseLabel="Bild hochladen" cursor="pointer" class="w-auto mr-2" />
            </div>
            <div class="field col-12 mt-2">
                <Button v-if="blog_roll_image" label="Speichern" @click="editBlogRollImageDialog = false, save_blog_roll_image(blog_post_id)" class="w-auto p-button-success" icon="pi pi-save" />
            </div>
        </div>
    </Dialog>
</template>