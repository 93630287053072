<script setup>
import { ref, onMounted, watch, defineProps, defineEmits } from 'vue'
import { Cropper, Preview } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import axios from 'axios'

// local variables
const editImageDialog = ref(false)
// define props
const props = defineProps({
    template_type: { type: String, required: true },
    index: { type: Number, required: false },
    data: { type: Object, required: true },
    workflow_id: { type: String, required: true}
})
// define emits
const emits = defineEmits(['blockCallback'])

// fields
const quote= ref(props.data.quote)
const quote_author = ref(props.data.quote_author)
const template = ref("quote_block")
const image = ref(props.data.image)
const image_upload_url = ref()
const image_library = ref()
const croppy_avatar = ref(null)
const random_number = ref(String(Math.floor(Math.random() * 100000000)))
const imageUploadDialog = ref(false)

const edit = ref(false)

onMounted( async () => {
    await axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/get-team-image-library")
    .then(response => {
        image_library.value = response.data
    })
})

// functions
const edit_block = () => {
    editImageDialog.value = true
    edit.value = false
    image.value = props.data.image
}

const save_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "quote": quote.value,
                "quote_author": quote_author.value,
                "template": template.value,
                "image": image_upload_url.value
            },
            "action": "save",
            "index": props.index
        }
    )
    editImageDialog.value = false
}

const delete_block = () => {
    emits('blockCallback',
        {
            "action": "delete",
            "index": props.index
        }
    )
    editImageDialog.value = false
}

const add_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "quote": quote.value,
                "quote_author": quote_author.value,
                "template": template.value,
                "image": image.value
            },
            "action": "add",
            "index": props.index
        }
    )
}

const crop_avatar = () => {
    image.value = croppy_avatar.value.getResult().canvas.toDataURL()
    upload_avatar()
    edit.value = false
}

const upload_library_image = async (e) => {
    for (let i = 0; i < e.files.length; i++) {
        let reader = new FileReader()
        reader.readAsDataURL(e.files[i])
        reader.onload = () => {
            let payload = {"filename": e.files[i].name, "file": reader.result }
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/upload-library-team", payload)
            .then(response => {
                image_library.value = response.data
                toast.add({severity:'success', summary: 'Erfolg', detail: 'Bild '+ e.files[i].name +' erfolgreich hochgeladen', life: 3000})
            })
        }
    }
}

const upload_avatar = async () => {
    let { canvas } = croppy_avatar.value.getResult()
    await canvas.toBlob((blob) => {
        let reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = async () => {
            let payload = {"file": reader.result, "image_type": "avatar", "workflow_id": props.workflow_id }
            await axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/upload-image", payload)
            .then(response => {
                image_upload_url.value = response.data.image_url
            })
        }
    })
}


</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <!--------------------------------------------------->
    <!------------------ Menu View ---------------------->
    <!--------------------------------------------------->
    <div v-if="props.template_type == 'menu'" class="card shadow-2 border-round-md py-3 px-3 mt-2">
        <div class="flex flex-row flex-wrap card-container">
            <div class="flex align-items-center justify-content-center mr-3">
                <Button v-tooltip.bottom="'Block hinzufügen'" class="p-button-success" icon="pi pi-plus" @click="add_block()" />
            </div>
            <div class="flex align-items-center justify-content-center mr-3 w-5rem border-gray-400 border-solid border-round-md border-1">
                <i class="pi pi-comment" style="color:#2196F3;"></i>
            </div>
            <div class="flex align-items-center justify-content-center font-bold">
                Zitat Block
            </div>
        </div>
    </div>
    <!--------------------------------------------------->
    <!------------------ Data View ---------------------->
    <!--------------------------------------------------->
    <div v-if="props.template_type == 'data'" class="shadow-2 border-round-md px-3 py-1" @click="edit_block()" v-bind:style="{cursor: 'pointer'}">
        <div class="mt-3">
            <i class="pi pi-box mr-2 ml-1"></i>
            <span class="mt-1 font-bold" style="font-size: 1em; color: #9e9e9e">Zitat</span>
        </div>
        <Divider />
        <div class="col-12 formgrid grid">
            <div class="field col-2">
                <img :src="props.data.image" style="width: 150px;" />
            </div>
            <div class="field col-10">
                {{ props.data.quote }} - {{ props.data.quote_author }}
            </div>
        </div>
    </div>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->

    <!--------------------------------------------------->
    <!------------------- Edit Image -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editImageDialog" :style="{width: '80%'}" header="Block bearbeiten" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-1">
                <Button label="Speichern" @click="save_block()" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                <Button label="Abbrechen" @click="editImageDialog = false" class="w-auto mr-3 mb-1 p-button-danger" icon="pi pi-times" />
                <Button label="Löschen" @click="delete_block()" class="w-auto mr-3 mb-1 p-button-danger" icon="pi pi-trash" />
            </div>
            <div v-if="edit == false" class="field col-12 mt-3">
                <img :src="image" class="shadow-2" style="width: 300px;" />
            </div>
            <Button v-if="edit == false" label="Bild Bearbeiten" @click="edit = true" class="w-auto mr-3 mb-1 p-button" icon="pi pi-pencil" />
            <div v-if="edit == true" class="field col-12 mt-3">
                <cropper
                    ref="croppy_avatar"
                    :src="image"
                    :auto-zoom="true"
                    :auto-center="true"
                    :stencil-props="{aspectRatio: 1 / 1}"
                />
            </div>
            <div v-if="edit == true" class="field col-12 mt-3">
                <template v-for="(img, index) of image_library" :key="index">
                    <img @click="image = img.image" :src="img.image" class="shadow-2 m-2" style="cursor: pointer; height: 100px; width: 100px; object-fit: cover; display:inline;" />
                </template>
            </div>
            <div v-if="edit == true" class="field col-12 mt-3">
                <Button label="Bild Speichern" @click="crop_avatar()" class="w-auto mr-3 mb-1 p-success" icon="pi pi-save" />
                <Button @click="imageUploadDialog = true" label="Bilder hochladen" class="w-auto mr-2 p-button" v-tooltip.top="'Bilder in die Mitarbeiter Bibliothek hochladen'" icon="pi pi-upload" />
            </div>
            <div class="field col-12 mt-3">
                <label for="quote">Zitat</label>
                <Textarea v-model="quote" id="quote" type="text" :autoResize="true" />
            </div>
            <div class="field col-12 mt-3">
                <label for="quote_author">Autor</label>
                <InputText v-model="quote_author" id="quote_author" />
            </div>
        </div>
    </Dialog>
    <!--------------------------------------------------->
    <!---------------- Image Library -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="imageUploadDialog" :style="{width: '800px'}" header="Bilder hochladen" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <FileUpload name="demo[]" :customUpload="true" @uploader="upload_library_image" :multiple="true" accept="image/*" :maxFileSize="10000000" chooseLabel="Bilder auswählen" uploadLabel="Hochladen" cancelLabel="Abbrechen">
                    <template #empty>
                        <div>
                            <div class="flex justify-content-center"><i class="pi pi-upload" style="font-size: 4rem; color: #dee2e6;"></i></div>
                            <div class="flex justify-content-center mt-2">Hochladen per Drag & Drop</div>
                        </div>
                    </template>
                </FileUpload>
            </div>
        </div>
    </Dialog>
</template>