<script setup>
import { ref, onMounted, watch, defineProps, defineEmits } from 'vue'
import MenuButton from './Components/Menu-Button.vue'
import DataView from './Components/Data-View.vue'
import { useCustomerStore } from '@/core/store/CustomerStore'
import ModalButtons from './Components/Modal-Menue.vue'
import { Cropper, Preview } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import axios from 'axios'
import { useConfirm } from "primevue/useconfirm"


const BLOCK_NAME = "gallery_block";
const NAME = 'Bilder Galerie';
const channel = ref(useCustomerStore().getCustomer.customer);

const confirm = useConfirm()

// local variables
const editDialog = ref(false)
// define props
const props = defineProps({
    template_type: { type: String, required: true },
    index: { type: Number, required: false },
    data: { type: Object, required: true },
    image_library: { type: Object, required: true },
    workflow_id: { type: String, required: true }
})
// define emits
const emits = defineEmits(['blockCallback'])


// fields
const template = ref(BLOCK_NAME)
const gallery = ref(props.data.gallery)
const croppy = ref(null)
const random_number = ref(String(Math.floor(Math.random() * 100000000)))
const image = ref()

const edit = ref(false)

// functions
const edit_block = () => {
    editDialog.value = true
    edit.value = false
    gallery.value = props.data.gallery
}

const save_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "template": template.value,
                "gallery": gallery.value
            },
            "action": "save",
            "index": props.index
        }
    )
    editDialog.value = false
}

const delete_block = () => {
    emits('blockCallback',
        {
            "action": "delete",
            "index": props.index
        }
    )
    editDialog.value = false
}

const add_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "template": template.value,
                "gallery": []
            },
            "action": "add",
            "index": props.index
        }
    )
}

const crop_image = () => {
    image.value = croppy.value.getResult().canvas.toDataURL()
    upload_image()
    edit.value = false
}

const on_row_reorder = (e) => {
    gallery.value = e.value
}

const on_row_edit = (e) => {
    if (gallery.value[e.index][e.field] != e.newValue) {
        gallery.value[e.index][e.field] = e.newValue
    }
}

const delete_row = (e) => {
    confirm.require({
        message: 'Wollen Sie wirklich das Bild löschen?',
        header: 'Bild löschen',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Bild löschen',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            gallery.value.splice(e.index, 1)
        }
    })
}

const upload_image = async () => {
    let { canvas } = croppy.value.getResult()
    await canvas.toBlob((blob) => {
        let reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = async () => {
            random_number.value = String(Math.floor(Math.random() * 100000000))
            let payload = { "file": reader.result, "image_type": String(props.index) + "_g_" + random_number.value, "workflow_id": props.workflow_id }
            await axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/upload-image`, payload)
                .then(response => {
                    gallery.value.push({ "image": response.data.image_url, "image_caption": "" })
                    image.value = null
                })
        }
    })
}

</script>

<template>
    <!--------------------------------------------------->
    <!------------------ Menu View ---------------------->
    <!--------------------------------------------------->
    <MenuButton v-if="props.template_type === 'menu'" :add_block="add_block" button="pi-image" :name="NAME" />

    <!--------------------------------------------------->
    <!------------------ Data View ---------------------->
    <!--------------------------------------------------->
    <DataView v-else-if="props.template_type === 'data'" :edit_block="edit_block" :name="NAME">
        <div v-if="gallery.length > 0" class="grid grid-cols-3 gap-4 p-3">
            <div v-for="(image, index) of gallery" :key="index">
                <img :src="image.image" class="shadow-md w-full h-20 object-cover border border-gray-200 rounded" />
            </div>
        </div>
        <div v-else class="grid my-6 mx-2 text-sm">
            <p>Keine Bilder vorhanden.<br /> Klicken Sie hier um Bilder hinzuzufügen</p>
        </div>
    </DataView>


    <!--------------------------------------------------->
    <!------------------- Edit Image -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editDialog" :style="{ width: '80%' }" header="Block bearbeiten" :modal="true" class="p-fluid">

        <div>
            <ModalButtons :saveBlock="save_block" :deleteBlock="delete_block" :toggleDialog="toggleDialog" />
        </div>

        <div class="w-full mt-1" v-if="edit == false">
            <DataTable :value="gallery" :rows="20" responsiveLayout="scroll" :rowHover="true" editMode="cell" @cell-edit-complete="on_row_edit" @rowReorder="on_row_reorder">
                <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                <Column field="image" header="Bild" style="width: 200px">
                    <template #body="slotProps">
                        <img :src="slotProps.data.image" style="vertical-align: middle; width: 150px; height: 100px; object-fit: cover; border-radius: 5%; box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)" />
                    </template>
                </Column>
                <Column field="image_caption" header="Bildunterschrift">
                    <template #editor="{ data, field }">
                        <InputText v-model="data[field]" class="w-full" />
                    </template>
                    <template #body="{ data, field }">
                        <span>{{ data[field] }}</span>
                    </template>
                </Column>
                <Column>
                    <template #body="slotProps">
                        <Button @click="delete_row(slotProps)" v-tooltip.top="'Bild löschen'" class="p-button-rounded p-button-text" icon="pi pi-trash" />
                    </template>
                </Column>
            </DataTable>
        </div>
        <div class="w-full mt-1">
            <Button v-if="edit == false" label="Gallerie Bild hinzufügen" @click="edit = true" class="w-auto mr-3 mb-1 p-button" icon="pi pi-plus" />
        </div>
        <div v-if="edit == true" class="w-full mt-3">
            <Button label="Bild Speichern" @click="crop_image()" class="mr-3 mb-1 p-success" icon="pi pi-save" />
        </div>
        <div v-if="edit == true" class="w-full mt-3">
            <cropper ref="croppy" :src="image" :stencil-props="{ aspectRatio: 1.5 / 1 }" :auto-zoom="true" :auto-center="true" />
        </div>
        <div v-if="edit == true" class="w-full mt-3">
            <div v-if="image_library" class="flex">
                <template v-for="(img, index) of image_library" :key="index">
                    <img @click="image = img.image" :src="img.image" class="shadow-2 m-2 cursor-pointer h-24 w-24 object-cover inline-block border border-gray-200 rounded" />
                </template>
            </div>
        </div>
    </Dialog>
</template>