<script setup>
import { ref, defineProps, defineEmits } from 'vue'
import { uploadSingleImage } from '@/core/var/crud'
import MenuButton from '@/core/components/Components/Builder-Blocks/Components/Menu-Button.vue'
import DataView from '@/core/components/Components/Builder-Blocks/Components/Data-View.vue'
import 'vue-advanced-cropper/dist/style.css'
import { useCustomerStore } from '@/core/store/CustomerStore'
import ModalButtons from '@/core/components/Components/Builder-Blocks/Components/Modal-Menue.vue'
import ImageEditor from '@/core/components/Components/Builder-Blocks/Components/Image-Editor.vue'
import crud from './Portraits-CRUD.vue'

const BLOCK_NAME = "image_portrait_crud";
const NAME = 'Portrait Liste';
const channel = ref(useCustomerStore().getCustomer.customer);
const template = ref(BLOCK_NAME)

// local variables
const editDialog = ref(false)
// define props
const props = defineProps({
    template_type: { type: String, required: true },
    index: { type: Number, required: false },
    data: { type: Object, required: true },
    image_library: { type: Object, required: true },
    workflow_id: { type: String, required: true }
})


// define emits
const emits = defineEmits(['blockCallback'])

// fields
const text_name = ref(props.data.text_name)

const obj_crud = ref(props.data.obj_crud)
const image = ref(props.data.image)
const image_upload_url = ref()
const croppy = ref(null)
const edit = ref(false)

//console.log(obj_crud.value);

// functions
const edit_block = () => {
    editDialog.value = true
    edit.value = false
    image.value = props.data.image
}

const save_block = () => {

    const img = image_upload_url.value ? image_upload_url.value : image_upload_url.value;

    emits('blockCallback',
        {
            "template_data": {
                "template": template.value,
                "obj_crud": obj_crud.value,
            
            },
            "action": "save",
            "index": props.index
        }
    )
    editDialog.value = false
}

const delete_block = () => {
    emits('blockCallback',
        {
            "action": "delete",
            "index": props.index
        }
    )
    editDialog.value = false
}

const add_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "template": template.value,
                "obj_crud": "",
            },
            "action": "add",
            "index": props.index
        }
    )
}

const crop_image = res => {
    croppy.value = res;
    image.value = res.getResult().canvas.toDataURL();
    upload_image()
    edit.value = false
}

const upload_image = async () => {
    await uploadSingleImage(croppy.value, props.index, props.workflow_id, channel.value, 'upload-image', true).then(res => {
        image_upload_url.value = res.data.image_url
    });
}
const toggleDialog = () => {
    editDialog.value = false
}

const get_data = (data) => {
    obj_crud.value = data
}

</script>
<style lang="scss" scoped>
@import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <!--------------------------------------------------->
    <!------------------ Menu View ---------------------->
    <!--------------------------------------------------->
    <!-- <MenuButton v-if="props.template_type == 'menu'" :add_block="add_block" button="pi-image" :name="NAME" /> -->
    <div v-if="props.template_type == 'menu'" class="card shadow-2 border-round-md py-3 px-3 mt-2">
        <div class="flex flex-row flex-wrap card-container">
            <div class="flex align-items-center justify-content-center mr-3">
                <Button v-tooltip.bottom="'Block hinzufügen'" class="p-button-success" icon="pi pi-plus" @click="add_block()" />
            </div>
            <div class="flex items-center justify-center mr-3 w-5rem border-gray-400 border-solid border-round-md border-1">
                <i class="pi pi-image" style="color:#2196F3;"></i>
            </div>
            <div class="flex align-items-center justify-content-center font-bold">
                Portrait Liste
            </div>
        </div>
    </div>

    <!--------------------------------------------------->
    <!------------------ Data View ---------------------->
    <!--------------------------------------------------->
    <DataView v-if="props.template_type == 'data'" :edit_block="edit_block" :name="NAME">
              <div class="flex flex-wrap -m-2">
    <div v-for="person in obj_crud" :key="person.id" class="p-2 w-1/2 sm:w-1/3 lg:w-1/4">
      <div class="border p-4 flex flex-col items-center">
        <img :src="person.image" alt="Portrait" class="h-24 w-24 object-cover">
        <div class="mt-2 text-center">
          <div class="font-bold text-sm">{{ person.name }}</div>
        </div>
      </div>
    </div>
  </div>
    </DataView>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->

    <!--------------------------------------------------->
    <!------------------- Edit Image -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editDialog" :style="{ width: '80%' }" header="Block bearbeiten" :modal="true" class="p-fluid">
        <div>
            <ModalButtons :saveBlock="save_block" :deleteBlock="delete_block" :toggleDialog="toggleDialog" />
        </div>
        <div class=" p-3">
            <crud :data="obj_crud" @get="get_data" :image_library="image_library" :workflow_id="workflow_id" />
        </div>
    </Dialog>
</template>