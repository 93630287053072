<script setup>
import { ref, onMounted, watch, defineProps, defineEmits } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import { Cropper, Preview } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import LoadingSpinner from '@/core/components/UI/Loading-Spinner.vue'
import { useCustomerStore } from '@/core/store/CustomerStore'

//////////////////////////////
// Content block components //
//////////////////////////////

import StandardText from './Editor-Blocks/standard_text.vue'
import StandardImage from './Editor-Blocks/standard_image.vue'
import StandardImageLarge from './Editor-Blocks/standard_image_large.vue'
import StandardTitle from './Editor-Blocks/standard_title.vue'
import StandardProducts from './Editor-Blocks/standard_products.vue'
import CTAButton from './Editor-Blocks/cta_button.vue'
import HtmlBlock from './Editor-Blocks/html_block.vue'
import QuoteBlock from './Editor-Blocks/quote_block.vue'
import GalleryBlock from './Editor-Blocks/gallery_block.vue'
import TextToggle from './Editor-Blocks/Text-Toggle.vue'
//import TextToggle from '@/core/components/Components/Builder-Blocks/Text-Toggle.vue'
import PortraitBlock from './Editor-Blocks/Image-Portrait-CRUD.vue'

const content_blocks = ref(['standard_text', 'standard_title', 'standard_image', 'standard_image_large', 'standard_products', 'cta_button', 'html_block', 'quote_block', 'gallery_block', 'text_toggle', 'image_portrait_crud'])
const channel = ref('gerstl')

/////////////////////
// Interface Props //
/////////////////////

const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
const imageUploadDialog = ref(false)
const editTitleImageDialog = ref(false)
const editTitleImagePreviewDialog = ref(false)
const edit_header_image_preview = ref(false)
const edit_header_image = ref(false)

const props = defineProps({
  redis_key: { type: String}
})

const workflow_id = ref("")
const blogs = ref([])
const blog = ref([])
const blog_categories = ref([])
const blog_tags = ref([])
const blog_sort = ref([])
const blog_status = ref([])
const users = ref([])
const blog_content = ref([])
const image_library = ref([])
const activeImageIndex = ref(0)
const displayGallery = ref(false)
const blog_changes = ref([])
const sage_groups = ref([])
const products = ref([])
const events = ref([])
const matching_products = ref([])
const matching_events = ref([])
const matching_sage_groups = ref([])
const og_title = ref("")
const team = ref([])

const random_number = ref(String(Math.floor(Math.random() * 100000000)))

onMounted(() => {
    let payload = { "redis_key": props.redis_key }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/"+channel.value+"/get-staticpage", payload)
    .then(response => {
        blog.value = response.data
        og_title.value = response.data.title
        workflow_id.value = response.data.workflow_id
        blog_content.value = response.data.content
        blog_changes.value = response.data.changes
        image_library.value = response.data.image_library
        matching_products.value = response.data.matching_products_neuraxis
        matching_events.value = response.data.events
        if (response.data.sage_groups) {
            matching_sage_groups.value = response.data.sage_groups
        }
    })
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/"+channel.value+"/get-system")
    .then(response => {
        blog_categories.value = response.data.blog_categories
        blog_tags.value = response.data.blog_tags
        blog_sort.value = response.data.blog_sort
        blog_status.value = response.data.blog_status
    })
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/"+channel.value+"/get-users")
    .then(response => {
        users.value = response.data
    })
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/"+channel.value+"/get-team")
    .then(response => {
        team.value = response.data
    })
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/"+channel.value+"/get-sage-groups")
    .then(response => {
        sage_groups.value = response.data
    })
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/"+channel.value+"/get-products")
    .then(response => {
        products.value = response.data
    })
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/"+channel.value+"/get-event-ids")
    .then(response => {
        events.value = response.data
    })
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/"+channel.value+"/get-staticpages")
    .then(response => {
        blogs.value = response.data
    })
})

const emits = defineEmits(['editorCallback'])

const close_tab = () => {
    confirm.require({
        message: 'Sind Sie sicher, dass Sie diesen Artikel schliessen wollen? Alle nicht gespeicherten Änderungen gehen verloren.',
        header: 'Artikel schliessen',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja, Artikel schliessen',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            emits('editorCallback',
                {
                    "action": "close_tab",
                    "redis_key": props.redis_key
                }
            )
        }
    })
}

const showGallery = (index) => {
    activeImageIndex.value = index
    displayGallery.value = true
}

const get_team_image = (team_name) => {
    let image = ""
    team.value.forEach(team_member => {
        if (team_member.name == team_name) {
            image = team_member.image
        }
    })
    return image
}

const get_team_text = (team_name) => {
    let text = ""
    team.value.forEach(team_member => {
        if (team_member.name == team_name) {
            text = team_member.author_text
        }
    })
    return text
}

const get_team_role = (team_name) => {
    let role = ""
    team.value.forEach(team_member => {
        if (team_member.name == team_name) {
            role = team_member.role
        }
    })
    return role
}

const update_template_data = (e) => {
    if (e.action == "save") {
        blog_content.value[e.index] = e.template_data
        toast.add({severity:'success', summary: 'Erfolg', detail: 'Inhalt gespeichert', life: 3000})
    } else if (e.action == "delete") {
        blog_content.value.splice(e.index, 1)
        toast.add({severity:'success', summary: 'Erfolg', detail: 'Inhalt gelöscht', life: 3000})
    } else if (e.action == "add") {
        blog_content.value.push(e.template_data)
        toast.add({severity:'success', summary: 'Erfolg', detail: 'Inhalt hinzugefügt', life: 3000})
    }
}

const save_changes = () => {
    if (blog.value["title"] != og_title.value){
        if(check_if_slug_exists(blog.value["title"])){
            toast.add({severity: 'error', summary: 'Fehler', detail: 'Der Titel existiert bereits', life: 3000})
            return
        }
    }
    blog.value["content"] = blog_content.value
    blog.value["changes"] = blog_changes.value
    blog.value["image_library"] = image_library.value
    blog.value["matching_products_neuraxis"] = matching_products.value
    blog.value["sage_groups"] = matching_sage_groups.value
    blog.value["author_image"] = get_team_image(blog.value["author"])
    blog.value["author_text"] = get_team_text(blog.value["author"])
    blog.value["author_role"] = get_team_role(blog.value["author"])
    console.log("submitted")
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/"+channel.value+"/save-staticpage", blog.value)
    .then(response => {
        if (response.data.status == "success"){
            toast.add({severity: 'success', summary: blog.value.title + ' gespeichert', detail: 'Änderungen wurden erfolgreich gespeichert', life: 3000})
        }
        if (response.data.status == "titlechanged"){
            toast.add({severity: 'success', summary: blog.value.title + ' gespeichert', detail: 'Änderungen wurden erfolgreich gespeichert', life: 3000})
            emits('editorCallback',
                {
                    "action": "close_tab",
                    "redis_key": props.redis_key
                }
            )
        }
    })
}

const reset_changes = () => {
    confirm.require({
        message: 'Wollen Sie wirklich alle Änderungen verwerfen?',
        header: 'Änderungen verwerfen',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja, Änderungen verwerfen',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            let payload = { "redis_key": props.redis_key }
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/"+channel.value+"/get-staticpage", payload)
            .then(response => {
                blog.value = response.data
            })
        }
    })
}

const onRowReorder = (e) => {
    if (e.dragIndex != e.dropIndex){
        blog_content.value = e.value
    }
}

const title_image_cropper = ref()
const crop_header_image = async (image, image_type) => {
    let { canvas } = image.getResult()
    await canvas.toBlob((blob) => {
        let reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = async () => {
            let payload = {"file": reader.result, "image_type": image_type, "workflow_id": blog.value.workflow_id }
            await axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/"+channel.value+"/upload-image", payload)
            .then(response => {
                toast.add({severity:'success', summary: 'Erfolg', detail: 'Bild erfolgreich hochgeladen', life: 3000})
                blog.value.header_image = response.data.image_url
                edit_header_image.value = false
            })
        }
    })
}

const title_image_cropper_preview = ref()
const crop_header_image_preview = async (image, image_type) => {
    let { canvas } = image.getResult()
    await canvas.toBlob((blob) => {
        let reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = async () => {
            let payload = {"file": reader.result, "image_type": image_type, "workflow_id": blog.value.workflow_id }
            await axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/"+channel.value+"/upload-image", payload)
            .then(response => {
                toast.add({severity:'success', summary: 'Erfolg', detail: 'Bild erfolgreich hochgeladen', life: 3000})
                blog.value.header_image_preview = response.data.image_url
                edit_header_image_preview.value = false
            })
        }
    })
}

const upload_library_image = async (e) => {
    for (let i = 0; i < e.files.length; i++) {
        let reader = new FileReader()
        reader.readAsDataURL(e.files[i])
        reader.onload = () => {
            let payload = {"filename": e.files[i].name, "file": reader.result, "type": e.files[i].type, "size": e.files[i].size, "redis_key": props.redis_key, "workflow_id": blog.value.workflow_id }
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/"+channel.value+"/upload-library-image", payload)
            .then(response => {
                image_library.value = response.data
                toast.add({severity:'success', summary: 'Erfolg', detail: 'Bild '+ e.files[i].name +' erfolgreich hochgeladen', life: 3000})
            })
        }
    }
}

const preview_blog = (slug) => {
    let cs = "fc7e3d6b-271c-41f7-bc50-97e3ed805459"
    window.open("https://www.gerstl.ch/de/"+slug+"/?preview=" + cs)
}

const swiss_date_time = (date) => {
    let parsed_date = new Date(date)
    return parsed_date.toLocaleDateString('de-CH') + " @ " + parsed_date.toLocaleTimeString('de-CH')
}

const check_if_slug_exists = (title) => {
    let exists = false
    let slug = slugify(title)
    console.log(slug)
    blogs.value.forEach(blog => {
        if (blog.slug == slug) {
            exists = true
        }
    })
    return exists
}

const slugify = (str) => {
  return str.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().trim().replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '-').toLowerCase();
}

const shift_up = (index) => {
    if (index != 0){
        let temp = blog_content.value[index]
        blog_content.value[index] = blog_content.value[index - 1]
        blog_content.value[index - 1] = temp
        console.log("temp", temp)
        console.log("index", index)
        console.log("blog_content", blog_content.value)
    }
}

const shift_down = (index) => {
    let temp = blog_content.value[index]
    blog_content.value[index] = blog_content.value[index + 1]
    blog_content.value[index + 1] = temp
}

</script>

<style>
.spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}
</style>

<template>
    <Toast />
    <LoadingSpinner v-if="loader" size="5" color="fill-primary-600" classs="m-5" />
    <Toolbar>
        <template #start>
            <Button v-tooltip.bottom="'Änderungen zurücksetzen'" @click="reset_changes()" class="mr-2 p-button-danger" icon="pi pi-refresh" />
            <Button @click="save_changes()" v-tooltip.bottom="'Änderungen Speichern'" label="Speichern" class="w-auto mr-4 p-button-success" icon="pi pi-save" />
            <Divider layout="vertical" />
            <Button v-tooltip.bottom="'Preview Artikel'" @click="preview_blog(blog.slug)" label="Vorschlau" class="mr-2 p-button w-auto" icon="pi pi-eye" />
            <Button v-tooltip.bottom="'Artikel schliessen'" @click="close_tab()" label="Schliessen" class="mr-2 p-button-danger w-auto" icon="pi pi-times-circle" />
        </template>
        <template #end>
            <Button type="button" icon="pi pi-refresh" aria-controls="overlay_menu" @click="reset_changes()" />
        </template>
    </Toolbar>
    <TabView class="col-12 mt-2" ref="tabview4">
        <TabPanel>
            <template #header>
                <i class="pi pi-file mr-2"></i>
                <span class="mr-3">Meta</span>
            </template>
            <div class="md:flex flex-row md:my-5">
                <div class="w-auto md:w-1/2 m-2">
                    <Editor editorStyle="width: 100%; min-height: 850px;" v-model="blog.text_content">
                        <template v-slot:toolbar>
                            <span class="ql-formats">
                                <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                                <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                                <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                                <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                                <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                            </span>
                        </template>
                    </Editor>
                </div>
                <div class="w-auto md:w-1/2 ">
                    <div class="">
                        <Accordion class="accordion-custom mt-2" :activeIndex="0">
                            <AccordionTab>
                                <template #header>
                                    <span class="mr-2" style="font-size: 130%;">Metadaten</span>
                                </template>
                                <div class=" my-6">
                                    <span class="p-float-label">
                                        <Dropdown id="blog_status" v-model="blog.status" :options="blog_status" optionLabel="name" optionValue="value" class="w-auto" />
                                        <label for="blog_status">Status</label>
                                    </span>
                                </div>
                                <div class=" my-6">
                                    <span class="p-float-label">
                                        <Textarea id="title" style="width:100%; min-height: 50px;" :autoResize="true" v-model="blog.title" />
                                        <label for="title">Titel</label>
                                    </span>
                                </div>
                                <div class=" my-6">
                                    <span class="p-float-label">
                                        <Textarea id="teaser" style="width:100%; min-height: 100px;" :autoResize="true" v-model="blog.teaser" />
                                        <label for="teaser">Teaser</label>
                                    </span>
                                </div>

                                <div class=" my-6">
                                    <span class="p-float-label">
                                        <Textarea id="preview_text" style="width:100%; min-height: 100px;" :autoResize="true" v-model="blog.preview_text" />
                                        <label for="preview_text">Preview Text</label>
                                    </span>
                                </div>

                                <div class=" my-6">
                                    <span class="p-2" v-if="blog.author"><img class="shadow-2 m-2" style="width: 80px; height: 80px; object-fit: cover;" :src="get_team_image(blog.author)" /></span>
                                    <span class="p-float-label">
                                        <Dropdown id="author" v-model="blog.author" showClear :options="team" optionLabel="name" optionValue="name" class="w-full" />
                                        <label for="author">Autor</label>
                                    </span>
                                </div>
                                <div class=" my-6">
                                    <span class="p-float-label">
                                        <Dropdown :filter="true" :showClear="true" id="user" v-model="blog.user" :options="users" optionLabel="user_email" optionValue="user_email" class="w-full" />
                                        <label for="user">Bearbeiter</label>
                                    </span>
                                </div>
                                <div class=" my-6">
                                    <span class="p-float-label">
                                        <InputText id="matching_products_title" placeholder="Passende Produkte" v-model="blog.matching_products_title" class="w-full" />
                                        <label for="matching_products_title">Passende Produkte (Titel)</label>
                                    </span>
                                </div>
                                <div class=" my-6">
                                    <span class="p-float-label">
                                        <MultiSelect :showToggleAll="false" display="chip" :filter="true" :showClear="true" id="sage_groups" v-model="matching_sage_groups" :options="sage_groups" optionLabel="name" optionValue="id" class="w-full" />
                                        <label for="sage_groups">Sage Gruppen</label>
                                    </span>
                                </div>
                                <div class="">
                                    <div class="justify-content-center">
                                        <Checkbox v-model="blog.show_filters" inputId="show_filters" id="show_filters" :binary="true" class="mb-1" />
                                        <label for="show_filters" class="ml-2">Produkten Filter anzeigen</label>
                                    </div>
                                </div>
                                <div class=" mt-6">
                                    <span class="p-float-label">
                                        <MultiSelect :showToggleAll="false" display="chip" :filter="true" :showClear="true" :virtualScrollerOptions="{ itemSize: 38 }" id="products" v-model="matching_products" :options="products" optionLabel="name" optionValue="id" class="w-full" />
                                        <label for="products">Produkte</label>
                                    </span>
                                    <small class="ml-2" v-if="matching_products!=undefined">Ausgewählte Produkte: {{ String(matching_products.length) }}</small>
                                </div>
                                <div class=" my-6">
                                    <span class="p-float-label">
                                        <InputText id="matching_products_cta_label" v-model="blog.matching_products_cta_label" class="w-full" />
                                        <label for="matching_products_cta_label">Passende Produkte (Button Titel)</label>
                                    </span>
                                </div>
                                <div class=" my-6">
                                    <div class="p-inputgroup flex-1">
                                        <span class="p-inputgroup-addon">https://gerstl.ch/</span>
                                        <span class="p-float-label">
                                            <InputText id="matching_products_cta_url" v-model="blog.matching_products_cta_url" class="w-full" />
                                            <label for="matching_products_cta_url">Passende Produkte (URL)</label>
                                        </span>
                                    </div>
                                </div>
                                <div class=" my-6">
                                    <span class="p-float-label">
                                        <InputNumber mode="decimal" showButtons :min="0" :max="24" id="matching_products_count" v-model="blog.matching_products_count" class="w-full neuraxis_inputnumber" />
                                        <label for="matching_products_count">Passende Produkte (Anzahl)</label>
                                    </span>
                                </div>
                                <div class=" my-6">
                                    <span class="p-float-label">
                                        <MultiSelect :showToggleAll="false" display="chip" :filter="true" :showClear="true" id="Produkte" v-model="blog.events" :options="events" optionLabel="title" optionValue="id" class="w-full" />
                                        <label for="user">Events</label>
                                    </span>
                                </div>
                                <div class=" my-6">
                                    <div class="flex align-items-center justify-content-start">
                                        <InputSwitch v-model="blog.disable_header_image" />
                                        <span v-if="!blog.disable_header_image" class="ml-2">Titelbild deaktivieren</span>
                                        <span v-if="blog.disable_header_image" class="ml-2 font-bold text-red-600">Titelbild ist deaktiviert</span>
                                    </div>
                                </div>
                                <div class=" mt-2">
                                    <img v-if="blog.header_image" :src="blog.header_image + '?v=' + random_number" style="height: 100px" class="shadow-2 m-2" />
                                    <Button @click="editTitleImageDialog = true" v-tooltip.bottom="'Titel-Bild editieren'" label="Titel-Bild" class="w-auto mr-2 p-button" icon="pi pi-image" />
                                    <Button v-if="blog.header_image" @click="blog.header_image = ''" v-tooltip.bottom="'Titel-Bild löschen'" class="p-button bg-red-600 text-white border-red-700" icon="pi pi-trash" />
                                </div>
                                <div class=" mt-2">
                                    <img v-if="blog.header_image_preview" :src="blog.header_image_preview + '?v=' + random_number" style="height: 100px" class="shadow-2 m-2" />
                                    <Button @click="editTitleImagePreviewDialog = true" v-tooltip.bottom="'Vorschau-Bild editieren'" label="Vorschau-Bild" class="w-auto p-button mr-2" icon="pi pi-image" />
                                    <Button v-if="blog.header_image_preview" @click="blog.header_image_preview = ''" v-tooltip.bottom="'Vorschau-Bild löschen'" class="p-button bg-red-600 text-white border-red-700" icon="pi pi-trash" />
                                </div>
                                <div class=" my-6">
                                    <span class="p-float-label">
                                        <Textarea id="metatitle" style="width:100%; min-height: 100px;" :autoResize="true" v-model="blog.meta_title" />
                                        <label for="metatitle">Meta Title</label>
                                    </span>
                                </div>
                                <div class=" my-6">
                                    <span class="p-float-label">
                                        <Textarea id="metadescription" style="width:100%; min-height: 100px;" :autoResize="true" v-model="blog.meta_description" />
                                        <label for="metadescription">Meta Description</label>
                                    </span>
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                    <div class="">
                        <Accordion class="accordion-custom mt-3">
                            <AccordionTab>
                                <template #header>
                                    <i class="pi pi-image mr-2"></i>
                                    <span class="mr-2" style="font-size: 130%;">Bilder Bibliotek</span>
                                    <Avatar v-if="image_library!=undefined" :label="String(image_library.length)" class="mr-2" shape="circle" />
                                </template>
                                <Galleria :value="image_library" v-model:activeIndex="activeImageIndex" :numVisible="7" containerStyle="max-width: 850px" :circular="true" :fullScreen="true" :showItemNavigators="true" :showThumbnails="false" v-model:visible="displayGallery">
                                    <template #item="slotProps">
                                        <img :src="slotProps.item.url" style="width: 100%; display: block;" />
                                    </template>
                                </Galleria>
                                <div v-if="image_library" class="grid">
                                    <div v-for="(image, index) of image_library" class="flex" :key="index">
                                        <img :src="image.url" class="shadow-2 m-2" style="cursor: pointer; width: 80px; height: 80px; object-fit: cover;" @click="showGallery(index)" />
                                    </div>
                                </div>
                                <div>
                                    <Button @click="imageUploadDialog = true" v-tooltip.bottom="'Bilder in die Bibliotek hochladen'" label="Bilder hochladen" class="mr-2 p-button w-auto" icon="pi pi-upload" />
                                    <Button @click="imageUploadDialog = true" v-tooltip.bottom="'Bilder aus der Bibliotek wählen'" label="Bibliotek" class="mr-2 p-button-success w-auto" icon="pi pi-folder" />
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                    <div class="">
                        <Accordion class="accordion-custom mt-3">
                            <AccordionTab>
                                <template #header>
                                    <i class="pi pi-history mr-2"></i>
                                    <span class="mr-2" style="font-size: 130%;">Änderungsverlauf</span>
                                    <Avatar v-if="blog_changes!=undefined" :label="String(blog_changes.length)" class="mr-2" shape="circle" />
                                </template>
                                <div v-if="blog_changes">
                                    <div v-for="(change, index) of blog_changes" :key="index">
                                        <Card style="width: 100%" class="mt-3 shadow-3">
                                            <template #content>
                                                <div class="flex">
                                                    <Chip :label="change.user" icon="pi pi-user" class="mr-3" />
                                                    <Chip :label="swiss_date_time(change.date)" icon="pi pi-clock" />
                                                </div>
                                                <div class="flex">
                                                    <span class="m-2 p-2 w-full" style="background-color: #b3e5fc; color: #23547b; border-radius: 3px;">{{ change.message }}</span>
                                                </div>
                                            </template>
                                        </Card>
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                </div>
            </div>
        </TabPanel>
        <TabPanel>
            <template #header>
                <i class="pi pi-box mr-2"></i>
                <span class="mr-3">Builder</span>
            </template>
            <div class="md:flex flex-row md:my-4">
                <div class="w-auto md:w-2/3 " style="border: 2px solid #e9ecef;">
                    <!--------------------------------------------------->
                    <!------------------ DataTable ---------------------->
                    <!--------------------------------------------------->
                    <DataTable :value="blog_content" :rows="20" responsiveLayout="scroll" :rowHover="true" @rowReorder="onRowReorder">
                        <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                        <Column field="template">
                            <template #header>
                                <i class="pi pi-box mr-2"></i>
                                <span class="mr-3">Blocks</span>
                                <Avatar v-if="blog_content!=undefined" :label="String(blog_content.length)" shape="circle" class="mr-2" style="background-color:#2196F3; color: #ffffff" />
                            </template>
                            <template #body="slotProps">
                                <StandardText v-if="slotProps.data.template == 'standard_text'" :template_type="'data'" :index="slotProps.index" :data="slotProps.data" @block-callback="update_template_data" :key="Math.floor(Math.random() * 101) " />
                                <StandardTitle v-if="slotProps.data.template == 'standard_title'" :template_type="'data'" :index="slotProps.index" :data="slotProps.data" @block-callback="update_template_data" :key="Math.floor(Math.random() * 101) " />
                                <StandardImage v-if="slotProps.data.template == 'standard_image' && image_library!=undefined" :template_type="'data'" :index="slotProps.index" :data="slotProps.data" @block-callback="update_template_data" :image_library="image_library" :workflow_id="workflow_id" :key="Math.floor(Math.random() * 101) " />
                                <StandardImageLarge v-if="slotProps.data.template == 'standard_image_large' && image_library!=undefined" :template_type="'data'" :index="slotProps.index" :data="slotProps.data" @block-callback="update_template_data" :image_library="image_library" :workflow_id="workflow_id" :key="Math.floor(Math.random() * 101) " />
                                <StandardProducts v-if="slotProps.data.template == 'standard_products'" :template_type="'data'" :index="slotProps.index" :data="slotProps.data" @block-callback="update_template_data" :key="Math.floor(Math.random() * 101) " />
                                <CTAButton v-if="slotProps.data.template == 'cta_button'" :template_type="'data'" :index="slotProps.index" :data="slotProps.data" @block-callback="update_template_data" :key="Math.floor(Math.random() * 101) " />
                                <HtmlBlock v-if="slotProps.data.template == 'html_block'" :template_type="'data'" :index="slotProps.index" :data="slotProps.data" @block-callback="update_template_data" :key="Math.floor(Math.random() * 101) " />
                                <QuoteBlock v-if="slotProps.data.template == 'quote_block'" :template_type="'data'" :index="slotProps.index" :data="slotProps.data" @block-callback="update_template_data" :workflow_id="workflow_id" :key="Math.floor(Math.random() * 101) " />
                                <GalleryBlock v-if="slotProps.data.template == 'gallery_block' && image_library!=undefined" :template_type="'data'" :index="slotProps.index" :data="slotProps.data" @block-callback="update_template_data" :image_library="image_library" :workflow_id="workflow_id" :key="Math.floor(Math.random() * 101) " />
                                <TextToggle v-if="slotProps.data.template == 'text_toggle'" :template_type="'data'" :index="slotProps.index" :data="slotProps.data" @block-callback="update_template_data" :key="Math.floor(Math.random() * 101) "  />
                                <PortraitBlock v-if="slotProps.data.template == 'image_portrait_crud' && image_library != undefined" :template_type="'data'" :index="slotProps.index" :data="slotProps.data" @block-callback="update_template_data" :image_library="image_library.map(item => ({ image: item.url }))" :workflow_id="workflow_id" :key="Math.floor(Math.random() * 101) " />
                            </template>
                        </Column>
                        <Column headerStyle="width: 2rem" style="padding: 0.5rem">
                            <template #body="slotProps">
                                <div>
                                    <Button v-if="slotProps.index != 0" size="small" icon="pi pi-sort-up" class="p-1 mb-1 w-2rem" @click="shift_up(slotProps.index)" />
                                </div>
                                <div>
                                    <Button v-if="slotProps.index != blog_content.length - 1" size="small" icon="pi pi-sort-down" class="p-1 w-2rem" @click="shift_down(slotProps.index)" />
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                </div>
                <div class="w-auto md:w-1/3 ml-1">
                    <div class="">
                        <div v-for="key in content_blocks" :key="key">
                            <StandardText v-if="key == 'standard_text'" :template_type="'menu'" :data="content_blocks" @block-callback="update_template_data" />
                            <StandardTitle v-if="key == 'standard_title'" :template_type="'menu'" :data="content_blocks" @block-callback="update_template_data" />
                            <StandardImage v-if="key == 'standard_image' && image_library!=undefined" :template_type="'menu'" :data="content_blocks" @block-callback="update_template_data" :image_library="image_library" :workflow_id="workflow_id" />
                            <StandardImageLarge v-if="key == 'standard_image_large' && image_library!=undefined" :template_type="'menu'" :data="content_blocks" @block-callback="update_template_data" :image_library="image_library" :workflow_id="workflow_id" />
                            <StandardProducts v-if="key == 'standard_products'" :template_type="'menu'" :data="content_blocks" @block-callback="update_template_data" />
                            <CTAButton v-if="key == 'cta_button'" :template_type="'menu'" :data="content_blocks" @block-callback="update_template_data" />
                            <HtmlBlock v-if="key == 'html_block'" :template_type="'menu'" :data="content_blocks" @block-callback="update_template_data" />
                            <QuoteBlock v-if="key == 'quote_block'" :template_type="'menu'" :data="content_blocks" @block-callback="update_template_data" :workflow_id="workflow_id" />
                            <GalleryBlock v-if="key == 'gallery_block' && image_library!=undefined" :template_type="'menu'" :data="content_blocks" @block-callback="update_template_data" :image_library="image_library" :workflow_id="workflow_id" />
                            <TextToggle v-if="key == 'text_toggle'" :template_type="'menu'" :data="content_blocks" @block-callback="update_template_data" />
                            <PortraitBlock v-if="key == 'image_portrait_crud' && image_library != undefined" :template_type="'menu'" :data="content_blocks" @block-callback="update_template_data" :image_library="image_library.map(item => ({ image: item.url }))" :workflow_id="workflow_id" />
                        </div>
                    </div>
                </div>
            </div>
        </TabPanel>
    </TabView>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="imageUploadDialog" :style="{width: '800px'}" header="Bilder hochladen" :modal="true" class="p-fluid">
        <div class="w-auto">
            <div class=" mt-3">
                <FileUpload name="demo[]" :customUpload="true" @uploader="upload_library_image" :multiple="true" accept="image/*" :maxFileSize="10000000" chooseLabel="Bilder auswählen" uploadLabel="Hochladen" cancelLabel="Abbrechen">
                    <template #empty>
                        <div>
                            <div class="flex justify-content-center"><i class="pi pi-upload" style="font-size: 4rem; color: #dee2e6;"></i></div>
                            <div class="flex justify-content-center mt-2">Hochladen per Drag & Drop</div>
                        </div>
                    </template>
                </FileUpload>
            </div>
        </div>
    </Dialog>
    <!--------------------------------------------------->
    <!--------------- Edit Title Image ------------------>
    <!--------------------------------------------------->
    <Dialog v-model:visible="editTitleImageDialog" :style="{width: '80%'}" header="Titel Bild editieren" :modal="true" class="p-fluid">
        <div class="w-auto">
            <div v-if="edit_header_image == false" class="mt-3">
                <img :src="blog.header_image  + '?v=' + random_number" class="shadow-2" style="width: 100%; height: 100%; object-fit: cover;" />
            </div>
            <Button v-if="edit_header_image == false" label="Bild Bearbeiten" @click="edit_header_image = true" class="w-auto mr-3 mb-1 p-button" icon="pi pi-pencil" />
            <div v-if="edit_header_image == true" class="mt-3">
                <cropper ref="title_image_cropper" :stencil-props="{aspectRatio: 6 / 1}" :src="blog.header_image" :auto-zoom="true" :auto-center="true" />
            </div>
            <div v-if="edit_header_image == true" class=" mt-3">
                <div v-if="image_library" class="flex">
                    <div v-for="(img, index) of image_library" class="flex" :key="index">
                        <img @click="blog.header_image = img.url" :src="img.url" class="shadow-2 m-2" style="cursor: pointer; width: 80px; height: 80px; object-fit: cover;" />
                    </div>
                </div>
            </div>
            <div v-if="edit_header_image == true" class=" mt-3">
                <Button label="Bild Speichern" @click="crop_header_image(title_image_cropper, 'header_image')" class="w-auto mr-3 mb-1 p-success" icon="pi pi-save" />
                <Button @click="imageUploadDialog = true" v-tooltip.bottom="'Bilder in die Bibliotek hochladen'" label="Bilder hochladen" class="mr-2 p-button w-auto" icon="pi pi-upload" />
            </div>
        </div>
    </Dialog>
    <!--------------------------------------------------->
    <!----------------- Preview Image ------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editTitleImagePreviewDialog" :style="{width: '80%'}" header="Titel Vorschau-Bild editieren" :modal="true" class="p-fluid">
        <div class="w-full">
            <div v-if="edit_header_image_preview == false" class=" mt-3">
                <img :src="blog.header_image_preview + '?v=' + random_number" class="shadow-2" style="width: 50%" />
            </div>
            <Button v-if="edit_header_image_preview == false" label="Bild Bearbeiten" @click="edit_header_image_preview = true, blog.header_image_preview = blog.header_image" class="w-auto mr-3 mb-1 p-button" icon="pi pi-pencil" />
            <div v-if="edit_header_image_preview == true" class=" mt-3">
                <cropper ref="title_image_cropper_preview" :stencil-props="{aspectRatio: 1 / 0.75}" :src="blog.header_image_preview" :auto-zoom="true" :auto-center="true" />
            </div>
            <div v-if="edit_header_image_preview == true" class=" mt-3">
                <div v-if="image_library" class="flex">
                    <div v-for="(img, index) of image_library" class="flex" :key="index">
                        <img @click="blog.header_image_preview = img.url" :src="img.url" class="shadow-2 m-2" style="cursor: pointer; width: 80px; height: 80px; object-fit: cover;" />
                    </div>
                </div>
            </div>
            <div v-if="edit_header_image_preview == true" class=" mt-3">
                <Button label="Bild Speichern" @click="crop_header_image_preview(title_image_cropper_preview, 'header_image_preview')" class="w-auto mr-3 mb-1 p-success" icon="pi pi-save" />
                <Button @click="imageUploadDialog = true" v-tooltip.bottom="'Bilder in die Bibliotek hochladen'" label="Bilder hochladen" class="mr-2 p-button w-auto" icon="pi pi-upload" />
            </div>
        </div>
    </Dialog>
</template>