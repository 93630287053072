
<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from "primevue/useconfirm"
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useAuthStore } from '@/core/store/AuthStore'

////////////
// System //
////////////

const channel = ref("krediflow")
const auth = useAuthStore()

/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

// filters

const filter_accounts = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})

//////////////////
// Data Sources //
//////////////////
const account_id = ref()
const accounts = ref()
const email = ref()
const account = ref()
const template = ref()
const description = ref()
const apikey = ref()
const pw = ref()
const templates = ref([{
    id: 1,
    name: "Standard Rest API"
},
{
    id: 2,
    name: "Standard XML Export"
},
{
    id: 3,
    name: "Standard JSON Export"
},
{
    id: 10,
    name: "Vivino XML Export"
},
{
    id: 11,
    name: "The Cellar JSON Export"
}
])
const createEditAccountDialog = ref(false)

onMounted( async () => {
    init_data()
})

const init_data = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gerstl/get-api-accounts')
    .then(response => {
        accounts.value = response.data
        loader.value = false
    })
}

const save_account = () => {
    let payload = {
        id: account_id.value,
        email: email.value,
        account: account.value,
        templates: template.value,
        description: description.value,
        apikey: apikey.value,
        pw: pw.value
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gerstl/save-api-account', payload)
    .then(response => {
        accounts.value = response.data
        createEditAccountDialog.value = false
        toast.add({severity:'success', summary: 'Erfolg', detail: 'API Account gespeichert', life: 3000})
        loader.value = false
    })
}

const copy_login = (row) => {
    console.log(row)
    let text = "Hier ist Ihr persönliches Zugangstoken für die Gerstl API (V1).\n\nAPI-Schlüssel: " + row.data.apikey + "\n\nMit diesem Schlüssel können Sie auf verschiedene Standard- und benutzerdefinierte Daten aus unserer Shop-API zugreifen.\n\nSie können den Schlüssel testen, indem Sie ihn hier in unser Testing-Tool einfügen: https://gerstl.snpy.ch/API-Documentation-V1\n\nDort finden Sie Codeschnipsel, Export-URLs, einen Datenviewer und einen Test-REST-API-Client.\n\nBei Fragen zur Schnittstelle stehen wir Ihnen gerne zur Verfügung."
    navigator.clipboard.writeText(text);
    toast.add({ severity: 'success', summary: 'Copied to clipboard', detail: 'Login Infos wurden in den Zwischenspeicher kopiert', life: 3000 });
}

const uuidv4 = () => {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )
}

const genpw = () => {
    return Math.random().toString(36).slice(-8);
}

const get_template_names = (template_id) => {
    console.log(template_id)
    return templates.value.find(template => template.id == template_id).name
}

const format_account = () => {
    account.value = slugify(account.value)
}

const slugify = (str) => {
  return str.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().trim().replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '-');
}

const get_random_id = () => {
    return Math.random().toString(16).slice(2)
}

</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <div>
        <Toast />
        <Toolbar>
            <template #start>
                <span class="p-input-icon-left mr-3">
                    <i class="pi pi-search" />
                    <InputText v-model="filter_accounts['global'].value" placeholder="Suche" style="width: 300px" />
                </span>
                <Button label="Neues Konto" icon="pi pi-plus" class="p-button mr-3" @click="createEditAccountDialog = true, account_id = get_random_id(), account = '', description = '', apikey = '', template = [], email = '', pw = ''" />
                <span class="font-bold text-blue-500"><a href="https://gerstl.snpy.ch/API-Documentation-V1" target="_blank">Documentation</a></span>
            </template>
        </Toolbar>
        <DataTable class="mt-3 inbox-datatable" v-model:filters="filter_accounts" :value="accounts" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
            <Column field="account" header="Konto">
                <template #body="slotProps">
                    <div >
                        <Avatar icon="pi pi-code" class="mr-3 bg-primary text-white" shape="circle" />
                        <span class="font-bold">{{ slotProps.data.account }}</span>
                    </div>
                </template>
            </Column>
            <Column field="templates" header="Templates">
                <template #body="slotProps">
                    <template v-for="item in slotProps.data.templates" :key="item">
                        <Chip :label="get_template_names(item)" icon='pi pi-file' class="mr-2 mb-1" />
                    </template>
                </template>
            </Column>
            <Column>
                <template #body="slotProps">
                   <Button @click="copy_login(slotProps)" label="Login Infos kopieren" class="pi-button bg-orange-50 text-orange-500 border-orange-500" icon="pi pi-copy" /> 
                </template>
            </Column>
            <Column field="email" hidden />
            <Column field="pw" hidden />
            <Column field="apikey" hidden />
            <Column field="description" hidden />
            <Column>
                <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-text" @click="createEditAccountDialog = true, account_id = slotProps.data.id, account = slotProps.data.account, description = slotProps.data.description, apikey = slotProps.data.apikey, template = slotProps.data.templates, email = slotProps.data.email, pw = slotProps.data.pw" />
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-text" @click="delete_account(slotProps.data.account_id)" />
                </template>
            </Column>
        </DataTable>
        <!------------------------------------------------------->
        <!------------------ Create Account --------------------->
        <!------------------------------------------------------->
        <Dialog v-model:visible="createEditAccountDialog" :style="{width: '700px'}" header="API Konto" :modal="true" class="p-fluid z-5">
            <div class="col-12 formgrid grid">
                <div class="field col-12 mt-3">
                    <span class="p-float-label">
                        <InputText @change="format_account()" id="name" type="text" v-model="account" />
                        <label for="name">Konto</label>
                    </span>
                </div>
                <div class="field col-12 mt-3">
                    <span class="p-float-label">
                        <InputText id="email" type="text" v-model="email" />
                        <label for="email">E-Mail</label>
                    </span>
                </div>
                <div class="field col-8 mt-3">
                    <span class="p-float-label">
                        <InputText id="pw" type="text" v-model="pw" />
                        <label for="pw">Passwort</label>
                    </span>
                </div>
                <div class="field col-4 mt-3">
                    <Button @click="pw = genpw()" class="mr-2 mb-1 p-button" icon="pi pi-refresh" />
                </div>
                <div class="field col-8 mt-3">
                    <span class="p-float-label">
                        <InputText id="apikey" type="text" v-model="apikey" />
                        <label for="apikey">API-Key</label>
                    </span>
                </div>
                <div class="field col-4 mt-3">
                    <Button @click="apikey = uuidv4()" class="mr-2 mb-1 p-button" icon="pi pi-refresh" />
                </div>
                <div class="field col-12 mt-3">
                    <MultiSelect v-model="template" :options="templates" optionLabel="name" optionValue="id" placeholder="Templates" />
                </div>
                <div class="field col-12 mt-3">
                    <span class="p-float-label">
                        <Textarea id="description" type="text" v-model="description" />
                        <label for="description">Beschreibung</label>
                    </span>
                </div>
                <div class="field col-12 mt-3">
                    <div>
                        <Button :disabled="email && account ? false : true" label="Konto speichern" @click="save_account()" class="w-auto mr-2 mb-1 p-button" icon="pi pi-save" />
                        <Button label="Abbrechen" @click="createEditAccountDialog = false" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-times" />
                    </div>
                </div>
            </div>
        </Dialog>
    </div>
</template>