<script setup>
import { ref, defineProps, defineEmits } from 'vue'
//import MenuButton from '@/core/components/Components/Builder-Blocks/Components/Menu-Button.vue'
import DataView from '@/core/components/Components/Builder-Blocks/Components/Data-View.vue'
import { useCustomerStore } from '@/core/store/CustomerStore'
import ModalButtons from '@/core/components/Components/Builder-Blocks/Components/Modal-Menue.vue'



const BLOCK_NAME = "text_toggle";
const NAME = 'Toggle Text';
const channel = ref(useCustomerStore().getCustomer.customer);


// local variables
const editImageDialog = ref(false)
// define props
const props = defineProps({
    template_type: { type: String, required: true },
    index: { type: Number, required: false },
    data: { type: Object, required: true },
    image_library: { type: Object, required: true },
    workflow_id: { type: String, required: true }
})
// define emits
const emits = defineEmits(['blockCallback'])

// fields
const teaser_text = ref(props.data.teaser_text)
const full_text = ref(props.data.full_text)
const height = ref(props.data.height)
const template = ref(BLOCK_NAME)
const image = ref(props.data.image)
const edit = ref(false)

// functions
const edit_block = () => {
    editImageDialog.value = true
    edit.value = false
    image.value = props.data.image
}

const save_block = () => {


    emits('blockCallback',
        {
            "template_data": {
                "teaser_text": teaser_text.value,
                //"full_text": full_text.value,
                "height": height.value,
                "template": template.value,
            },
            "action": "save",
            "index": props.index
        }
    )
    editImageDialog.value = false
}

const delete_block = () => {
    emits('blockCallback',
        {
            "action": "delete",
            "index": props.index
        }
    )
    editImageDialog.value = false
}

const add_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "teaser_text": "",
                //"full_text": "",
                "height": 300,
                "template": template.value,
            },
            "action": "add",
            "index": props.index
        }
    )
}

</script>
<style lang="scss" scoped>
@import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <!--------------------------------------------------->
    <!------------------ Menu View ---------------------->
    <!--------------------------------------------------->
    <div v-if="props.template_type == 'menu'" class="card shadow-2 border-round-md py-3 px-3 mt-2">
        <div class="flex flex-row flex-wrap card-container">
            <div class="flex align-items-center justify-content-center mr-3">
                <Button v-tooltip.bottom="'Block hinzufügen'" class="p-button-success" icon="pi pi-plus" @click="add_block()" />
            </div>
            <div class="flex items-center justify-center mr-3 w-5rem border-gray-400 border-solid border-round-md border-1">
                <i class="pi pi-file-edit" style="color:#2196F3;"></i>
            </div>
            <div class="flex align-items-center justify-content-center font-bold">
                Text-Toggle
            </div>
        </div>
    </div>

    <!--------------------------------------------------->
    <!------------------ Data View ---------------------->
    <!--------------------------------------------------->
    <DataView v-else-if="props.template_type === 'data'" :edit_block="edit_block" :name="NAME">
        <div v-if="teaser_text">
            <div class="text-lg  mb-4 text-gradient">
                <h2 class=" text-xl font-semibold">Text</h2>
                <div v-html="teaser_text.replace(/\n/g, '<br>')"></div>
            </div>
            <div class=" text-center relative -top-4 font-semibold">Weiterlesen</div>
        </div>
    </DataView>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->

    <!--------------------------------------------------->
    <!------------------- Edit Image -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editImageDialog" :style="{ width: '80%' }" header="Block bearbeiten" :modal="true" class="p-fluid">

        <div>
            <ModalButtons :saveBlock="save_block" :deleteBlock="delete_block" :toggleDialog="toggleDialog" />
        </div>

        <div class="my-6">
            <label for="height">Höhe</label>
            <input v-model="height" inputId="height" type="number" class="rounded border-gray-200 w-full" />
        </div>
        <div class="">
            <div class="my-12">
                <label for="teaser_text">Text</label>

                <Editor editorStyle="" class="w-full h-48" v-model="teaser_text" id="teaser_text">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                            <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                            <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                            <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                            <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                        </span>
                    </template>
                </Editor>
            </div>

        </div>
    </Dialog>
</template>

<style>
.text-gradient {
    background: linear-gradient(to bottom, #000, rgba(0, 0, 0, 0));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
</style>