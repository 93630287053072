<script setup>
import { ref, onMounted, watch, defineProps, defineEmits } from 'vue'
import { Cropper, Preview } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import axios from 'axios'
import { useConfirm } from "primevue/useconfirm"

const confirm = useConfirm()

// local variables
const editProductsDialog = ref(false)
const product_id = ref(null)
const products_data = ref([])
// define props
const props = defineProps({
    template_type: { type: String, required: true },
    index: { type: Number, required: false },
    data: { type: Object, required: true }
})

onMounted( async () => {
    await axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/get-products-dropdown")
    .then(response => {
        products_data.value = response.data
    })
})

// define emits
const emits = defineEmits(['blockCallback'])

// fields
const template = ref("standard_products")
const products = ref(props.data.products)
const edit = ref(false)

// functions
const edit_block = () => {
    editProductsDialog.value = true
    edit.value = false
    products.value = props.data.products
}

const save_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "template": template.value,
                "products": products.value
            },
            "action": "save",
            "index": props.index
        }
    )
    editProductsDialog.value = false
}

const delete_block = () => {
    emits('blockCallback',
        {
            "action": "delete",
            "index": props.index
        }
    )
    editProductsDialog.value = false
}

const add_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "template": template.value,
                "products": []
            },
            "action": "add",
            "index": props.index
        }
    )
}

const on_row_reorder = (e) => {
    products.value = e.value
}

const delete_row = (e) => {
    confirm.require({
        message: 'Wollen Sie wirklich das Bild löschen?',
        header: 'Bild löschen',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Bild löschen',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            products.value.splice(e.index, 1)
        }
    })
}

const add_product = () => {
    let product = products_data.value.find(product => product.id == product_id.value)
    products.value.push(product)
}

</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <!--------------------------------------------------->
    <!------------------ Menu View ---------------------->
    <!--------------------------------------------------->
    <div v-if="props.template_type == 'menu'" class="card shadow-2 border-round-md py-3 px-3 mt-2">
        <div class="flex flex-row flex-wrap card-container">
            <div class="flex align-items-center justify-content-center mr-3">
                <Button v-tooltip.bottom="'Block hinzufügen'" class="p-button-success" icon="pi pi-plus" @click="add_block()" />
            </div>
            <div class="flex align-items-center justify-content-center mr-3 w-5rem border-gray-400 border-solid border-round-md border-1">
                <i class="pi pi-shopping-cart" style="color:#2196F3;"></i>
            </div>
            <div class="flex align-items-center justify-content-center font-bold">
                Standard Produkte
            </div>
        </div>
    </div>
    <!--------------------------------------------------->
    <!------------------ Data View ---------------------->
    <!--------------------------------------------------->
    <div v-if="props.template_type == 'data'" class="shadow-2 border-round-md px-3 py-1" @click="edit_block()" v-bind:style="{cursor: 'pointer'}">
        <div class="mt-3">
            <i class="pi pi-box mr-2 ml-1"></i>
            <span class="mt-1 font-bold" style="font-size: 1em; color: #9e9e9e">Standard Produkte</span>
        </div>
        <Divider />
        <div v-if="products" class="grid">
            <div v-for="(image, index) of products" class="flex" :key="index">
                <img :src="image.image" class="shadow-2 m-2" style="width: 80px; height: 80px; object-fit: cover;"/>
            </div>
        </div>
    </div>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->

    <!--------------------------------------------------->
    <!------------------- Edit Image -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editProductsDialog" :style="{width: '80%'}" header="Block bearbeiten" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-1">
                <Button v-if="edit == false" label="Speichern" @click="save_block()" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                <Button label="Abbrechen" @click="editImageDialog = false" class="w-auto mr-3 mb-1 p-button-danger" icon="pi pi-times" />
                <Button label="Löschen" @click="delete_block()" class="w-auto mr-3 mb-1 p-button-danger" icon="pi pi-trash" />
            </div>
            <div class="field col-12 mt-1">
                <DataTable :value="products" :rows="20" responsiveLayout="scroll" :rowHover="true" @rowReorder="on_row_reorder">
                    <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                    <Column field="image" header="Bild" style="width: 100px">
                        <template #body="slotProps">
                            <img :src="slotProps.data.image" style="vertical-align: middle; width: 70px; height: 120px; object-fit: cover; border-radius: 5%; box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)" />
                        </template>
                    </Column>
                    <Column field="id" header="SKU">
                        <template #body="slotProps">
                            <span class="font-bold">{{ slotProps.data.id }}</span>
                        </template>
                    </Column>
                    <Column field="name" header="Namen">
                        <template #body="slotProps">
                            {{ slotProps.data.name }}
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <Button @click="delete_row(slotProps)" v-tooltip.top="'Bild löschen'" class="p-button-rounded p-button-text" icon="pi pi-trash" />
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="field col-10 mt-1">
                <Dropdown :virtualScrollerOptions="{ itemSize: 38 }" :filter="true" :showClear="true" v-model="product_id" :options="products_data" optionLabel="name" optionValue="id" class="w-full" />
            </div>
            <div class="field col-2 mt-1">
                <Button v-tooltip.bottom="'Produkt hinzufügen'" label="Hinzufügen" class="p-button-success" icon="pi pi-plus" @click="add_product()" />
            </div>
        </div>
    </Dialog>
</template>