# working Mini CRUD with emitter for template purposes
# v2.0.0

<template>
    <div>
        <div class="flex gap-2 my-2">
            <Button icon="pi pi-plus" @click="showDialog('add')" />
        </div>

        <DataTable :value="items" paginator :rows="10" tableStyle="min-width: 100%" rowReorder @rowReorder="onRowReorder">
            <Column rowReorder style="width:3em"></Column>
            <Column field="portrait" header="Portrait">
                <template #body="slotProps">
                    <img :src="slotProps.data.image" alt="portrait" class=" w-24" />
                </template>
            </Column>
            <Column field="name" header="Name"></Column>
            <Column field="funktion" header="Position"></Column>
            <Column field="email" header="E-Mail"></Column>
            <Column field="phone" header="Telefon"></Column>

            <Column>
                <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="showDialog('edit', slotProps.data)" />
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="deleteItem(slotProps.data)" />
                </template>
            </Column>
        </DataTable>


        <Dialog v-model:visible="dialogVisible" :header="dialogHeader" class=" w-2/3">
            <div class=" w-3/4">
                <div class="my-3">
                    <label for="portrait">Portrait</label>
                    <ImageEditor :edit="true" :image_library="image_library" :image="item.image" @update="change_image" @delete="remove_image" :stencil-props="{ aspectRatio: 1 / 1 }" />
                </div>
                <div class="my-3">
                    <label for="name">Name</label>
                    <InputText id="name" v-model="item.name" class=" w-full" />
                </div>
                <div class="my-3">
                    <label for="funktion">Funktion</label>
                    <InputText id="funktion" v-model="item.funktion" class=" w-full" />
                </div>
                <div class="my-3">
                    <label for="email">Email</label>
                    <InputText id="email" v-model="item.email" class=" w-full" />
                </div>
                <div class="my-3">
                    <label for="phone">Telefon</label>
                    <InputText id="phone" v-model="item.phone" class=" w-full" />
                </div>
            </div>
            <Button label="Speichern" @click="saveItem" />
        </Dialog>
    </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, onMounted, watchEffect } from 'vue';
import ImageEditor from '@/core/components/Components/Builder-Blocks/Components/Image-Editor.vue'
import { uploadSingleImage } from '@/core/var/crud'


const props = defineProps({
    data: Array,
    image_library: {
        default: '',
    },
    workflow_id: {
        default: '',
    }
});



const item = ref({ name: '', funktion: '', email: '', phone: '', portrait: '' });
const dialogVisible = ref(false);
const dialogHeader = ref('');
const emit = defineEmits(['get']);

const items = ref(props.data);

const showDialog = (type, data = { name: '', funktion: '', email: '', phone: '', portrait: '' }) => {
    item.value = { ...data };
    dialogVisible.value = true;
    dialogHeader.value = type === 'add' ? 'Portrait hinzufügen' : 'Portrait editieren';
};


const saveItem = () => {
    if (!Array.isArray(items.value)) {
        items.value = [];
    }

    if (item.value.id) {

        const index = items.value.findIndex(i => i.id === item.value.id);
        if (index !== -1) {
            items.value[index] = { ...item.value };
        }
    } else {
 
        item.value.id = Date.now();
        items.value.push({ ...item.value });
    }
    dialogVisible.value = false;
    emitItems();
};

const deleteItem = (data) => {
    const index = items.value.findIndex(i => i.id === data.id);
    if (index !== -1) {
        items.value.splice(index, 1);
    }
    emitItems();
};

const onRowReorder = (event) => {
    const { value } = items;
    const draggedItem = value[event.dragIndex];
    value.splice(event.dragIndex, 1);
    value.splice(event.dropIndex, 0, draggedItem);
    items.value = [...value];
    emitItems();
};

const emitItems = () => {
    emit('get', items.value);
};


const change_image = async (res) => {
    const result = await cropImage(res, 'portrait_crud');
    item.value.image = result;
}

const UploadImage = (image, image_type) => {
    return new Promise((resolve, reject) => {
        uploadSingleImage(image, image_type, props.workflow_id, 'dashboard', 'upload-image', true).then((res) => {
            resolve(res.data.image_url);
            items.value.portrait = res.data.image_url;
            emitItems();
            console.log(res.data.image_url);
        }).catch(err => {
            reject(err);
        });
    });
}

const cropImage = async (image, image_type) => {
    const res = await UploadImage(image, image_type);
    return res;
}
</script>
