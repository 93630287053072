<script setup>
import { ref, onMounted, useSSRContext } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import { EnumStringBody } from '@babel/types'
/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
// filters
const filter_orders = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
//////////////////
// Data Sources //
//////////////////
const orders = ref([])
const details = ref()
const search_term = ref()
const result_count = ref(200)
const orderDetailsDialog = ref(false)

const result_counts = [
    {label: '200', value: 200},
    {label: '1000', value: 1000},
    {label: '2000', value: 2000},
    {label: '5000', value: 5000},
    {label: '10000', value: 10000},
]

onMounted( async () => {
    search_orders()
})

const search_orders = () => {
    console.log("search_orders", search_term.value, result_count.value)
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gerstl/webshop-admin-search-orders', {search: search_term.value, limit: result_count.value})
    .then(response => {
        orders.value = response.data
        loader.value = false
    })
}

const order_details = (e) => {
    console.log(e.data)
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gerstl/webshop-admin-order-details', {order_id: e.data.id})
    .then(response => {
        console.log("response.data", response.data)
        details.value = response.data
        orderDetailsDialog.value = true
        loader.value = false
    })
}

const transfer_order = (order_id) => {
    confirm.require({
        message: 'Möchten Sie die Bestellung wirklich nochmals übermitteln?',
        header: 'Bestellung übermitteln',
        icon: 'pi pi-exclamation-triangle',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja',
        accept: () => {
            loader.value = true
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gerstl/webshop-admin-transfer-order', {order_id: order_id})
            .then(response => {
                console.log("response.data", response.data)
                loader.value = false
                toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Bestellung wurde übermittelt', life: 3000});
            })
        }
    })
}

const new_order = () => {
    console.log("new_order")
}

const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}

const format_currency_without_prefix = (value) => {
    return Number(value).toLocaleString('de-CH', {minimumFractionDigits: 2});
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const format_swiss_date_time = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4) + ' ' + String(Number(value.substring(11,13)) + 1).padStart(2,0) + value.substring(13,16)
}

const format_swiss_date_time_plus_hour = (datetime) => {
    let d = new Date(datetime)
    d.setHours(d.getHours() + 1)
    let month = '' + d.getMonth()
    let day = '' + d.getDate()
    let year = '' + d.getFullYear()
    let hour = '' + d.getHours()
    let minute = '' + d.getMinutes()
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    if (hour.length < 2)
        hour = '0' + hour;
    if (minute.length < 2)
        minute = '0' + minute;
    return [day, month, year].join('.') + ' ' + [hour, minute].join(':')
}

const copy_to_clipboard = (value) => {
    navigator.clipboard.writeText(value)
    toast.add({severity:'success', summary: 'Erfolgreich', detail: 'In Zwischenablage kopiert', life: 3000});
}

</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>


<template>
    <Toast />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :fullScreen="true" :blocked="loader">
        <div class="col-12 mb-2">
            <Toolbar>
                <template #start>
                    <span class="p-input-icon-left mr-2">
                        <i class="pi pi-search" />
                        <InputText v-model="search_term" v-on:keyup.enter="search_orders()" placeholder="Suche" style="width: 300px"  />
                    </span>
                    <Button label="Suchen" icon="pi pi-search" class="mr-2" @click="search_orders()" />
                    <Dropdown @change="search_orders()" v-model="result_count" :options="result_counts" optionLabel="label" optionValue="value" placeholder="Resultate" style="width: 150px" />
                </template>
                <template #end>
                    <Button label="Neue Bestellung" icon="pi pi-plus" class="p-button bg-green-600 text-white border-green-700" @click="new_order" />
                </template>
            </Toolbar>
        </div>
        <div rounded class="overflow-hidden shadow-3 border-200">
            <DataTable :value="orders" @row-click="order_details" :rows="50" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                <Column field="id" header="Bestellung" sortable>
                    <template #body="slotProps">
                        <Chip class="pl-0 pr-3 mr-2">
                            <span class="bg-primary border-circle w-2rem h-2rem flex align-items-center justify-content-center"><i class="pi pi-shopping-cart" /></span>
                            <span class="ml-2 font-medium">{{slotProps.data.id}}</span>
                        </Chip>
                        <span class="font-bold mr-2">{{slotProps.data.name}}</span>
                        <span v-if="slotProps.data.isGuest">(Gast)</span>
                    </template>
                </Column>
                <Column field="status" header="Status">
                    <template #body="slotProps">
                        <Badge v-if="slotProps.data.status == 'Bestellung'" value="Abgeschlossen" class="mr-2 bg-green-600 text-white" />
                        <Badge v-if="slotProps.data.status == 'Pending'" value="Offen" class="mr-2 bg-yellow-500 text-black" />
                        <Badge v-if="slotProps.data.status == 'Rechnung'" value="Rechnung" class="mr-2 bg-primary text-white" />
                    </template>
                </Column>
                <Column field="date" header="Datum" sortable>
                    <template #body="slotProps">
                        {{format_swiss_date_time(slotProps.data.date)}}
                    </template>
                </Column>
                <Column field="billing.firstName" hidden></Column>
                <Column field="billing.lastName" hidden></Column>
                <Column field="promoCode" header="Promo Code" sortable>
                    <template #body="slotProps">
                        <span>{{slotProps.data.promoCode}}</span>
                    </template>
                </Column>
                <Column header="Sage">
                    <template #body="slotProps">
                        <Avatar v-if="slotProps.data.transferred" icon="pi pi-check" class="mr-2 bg-green-600 text-white" shape="circle" v-tooltip.top="format_swiss_date_time(slotProps.data.transferred)" />
                        <Avatar v-if="!slotProps.data.transferred" icon="pi pi-times" class="mr-2 bg-red-500 text-white" shape="circle" v-tooltip.top="'Bestellung noch nicht übermittelt'" />
                    </template>
                </Column>
                <Column field="amount" header="Total" sortable>
                    <template #body="slotProps">
                        <span class="font-bold">{{format_currency(slotProps.data.amount)}}</span>
                    </template>
                </Column>
            </DataTable>
        </div>
    </BlockUI>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <Sidebar v-model:visible="orderDetailsDialog" position="right" style="width: 60rem">
        <div class="col-12 formgrid grid">
            <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Bestellungs-Nr.
            </div>
            <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <span class="font-bold">{{details.number}}</span>
                <i @click="copy_to_clipboard(details.number)" v-tooltip.top="'In die Zwischenablage kopieren'" class="pi pi-copy ml-2 text-primary cursor-pointer" />
            </div>
            <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Webshop-ID
            </div>
            <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                {{details.id}}
            </div>
            <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Bestellungs-Datum
            </div>
            <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                {{ format_swiss_date_time(details.date) }}
            </div>
            <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Betrag
            </div>
            <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                {{ format_currency(details.amount) }}
            </div>
            <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Status
            </div>
            <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <Badge v-if="details.status == 'Bestellung'" value="Abgeschlossen" class="mr-2 bg-green-600 text-white" />
                <Badge v-if="details.status == 'Pending'" value="Offen" class="mr-2 bg-yellow-500 text-black" />
                <Badge v-if="details.status == 'Rechnung'" value="Rechnung" class="mr-2 bg-primary text-white" />
            </div>
            <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Versandart
            </div>
            <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                {{ details.deliveryMethod }}
            </div>
            <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Zahlungsart
            </div>
            <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                {{ details.paymentMethod }}
            </div>
            <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Promo-Code
            </div>
            <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                {{ details.promoCode }}
                <i v-if="details.promoCode" @click="copy_to_clipboard(details.promoCode)" v-tooltip.top="'In die Zwischenablage kopieren'" class="pi pi-copy ml-2 text-primary cursor-pointer" />
            </div>
            <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Bemerkungen
            </div>
            <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <span class="text-red-600 italic">{{ details.comments }}</span>
            </div>
            <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Sage Übermittlung
            </div>
            <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <Avatar v-if="details.transferred" icon="pi pi-check" class="mr-2 bg-green-600 text-white" shape="circle" v-tooltip.top="format_swiss_date_time(details.transferred)" />
                <span v-if="details.transferred">{{ format_swiss_date_time(details.transferred) }}</span>
                <Avatar v-if="!details.transferred" icon="pi pi-times" class="mr-2 bg-red-500 text-white" shape="circle" v-tooltip.top="'Bestellung (noch) nicht übermittelt'" />
                <Button @click="transfer_order(details.id)" icon="pi pi-replay" label="Nochmals übermitteln" class="p-button ml-2 p-2" size="small" />
            </div>
            <div v-if="details.customer" class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Kunde
            </div>
            <div v-if="details.customer" class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <div class="mt-1">
                    <span v-if="details.customer.salute" class="mr-1">{{ details.customer.salute }}</span>
                    <span class="mr-1">{{ details.customer.firstName }}</span>
                    <span class="mr-1">{{ details.customer.lastName }}</span>
                </div>                                                                                                                                                  
                <div class="mt-1">
                    <span class="mr-2 font-bold">E-Mail:</span>
                    <span class="mr-2"><a class="font-bold text-primary" :href="'mailto:' + details.customer.email">{{ details.customer.email }}</a></span>
                    <i @click="copy_to_clipboard(details.customer.email)" v-tooltip.top="'In die Zwischenablage kopieren'" class="pi pi-copy ml-2 text-primary cursor-pointer" />
                </div>
                <div class="mt-1">
                    <span class="mr-2 font-bold">Geburtstag:</span>
                    <span class="mr-2" v-if="details.customer.birthday">{{ format_swiss_date(details.customer.birthday) }}</span>
                </div>
                <div class="mt-1">
                    <span class="mr-2 font-bold">Registriert:</span>
                    <span class="mr-2" v-if="details.customer.created">{{ format_swiss_date_time_plus_hour(details.customer.created) }}</span>
                </div>
            </div>
            <div v-if="details.billing" class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Rechnungsadresse
            </div>
            <div v-if="details.billing" class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <div class="mt-1" v-if="details.billing.company">
                    <span class="mr-1">{{ details.billing.company }}</span>
                </div>
                <div class="mt-1">
                    <span v-if="details.billing.honorific" class="mr-1">{{ details.billing.honorific }}</span>
                    <span class="mr-1">{{ details.billing.firstName }}</span>
                    <span class="mr-1">{{ details.billing.lastName }}</span>
                </div>
                <div class="mt-1">
                    <span class="mr-1">{{ details.billing.address1 }}</span>
                    <span class="mr-1">{{ details.billing.streetNumber }}</span>
                </div>
                <div class="mt-1" v-if="details.billing.address2">
                    <span class="mr-1">{{ details.billing.address2 }}</span>
                </div>
                <div class="mt-1">
                    <span class="mr-1">{{ details.billing.postcode }}</span>
                    <span class="mr-1">{{ details.billing.city }}</span>
                </div>
                <div class="mt-1">
                    <span class="mr-2 font-bold">Tel:</span>
                    <span class="mr-2">{{ details.billing.phone }}</span>
                    <i @click="copy_to_clipboard(details.billing.phone)" v-tooltip.top="'In die Zwischenablage kopieren'" class="pi pi-copy text-primary cursor-pointer" />
                </div>
                <div class="mt-1" v-if="details.billing.birthDate">
                    <span class="mr-2 font-bold">Geburtstag:</span>
                    <span class="mr-2">{{ details.billing.birthDate }}</span>
                </div>
                <div class="mt-1" v-if="details.billing.email">
                    <span class="mr-2 font-bold">E-Mail:</span>
                    <span class="mr-2"><a class="font-bold text-primary" :href="'mailto:' + details.billing.email">{{ details.billing.email }}</a></span>
                    <i @click="copy_to_clipboard(details.billing.email)" v-tooltip.top="'In die Zwischenablage kopieren'" class="pi pi-copy text-primary cursor-pointer" />
                </div>
            </div>
            <div v-if="details.shipping" class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Lieferadresse
            </div>
            <div v-if="details.shipping" class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <div class="mt-1" v-if="details.shipping.company">
                    <span class="mr-1">{{ details.shipping.company }}</span>
                </div>
                <div class="mt-1">
                    <span v-if="details.shipping.honorific" class="mr-1">{{ details.shipping.honorific }}</span>
                    <span class="mr-1">{{ details.shipping.firstName }}</span>
                    <span class="mr-1">{{ details.shipping.lastName }}</span>
                </div>
                <div class="mt-1">
                    <span class="mr-1">{{ details.shipping.address1 }}</span>
                    <span class="mr-1">{{ details.shipping.streetNumber }}</span>
                </div>
                <div class="mt-1" v-if="details.shipping.address2">
                    <span class="mr-1">{{ details.shipping.address2 }}</span>
                </div>
                <div class="mt-1">
                    <span class="mr-1">{{ details.shipping.postcode }}</span>
                    <span class="mr-1">{{ details.shipping.city }}</span>
                </div>
                <div class="mt-1">
                    <span class="mr-2 font-bold">Tel:</span>
                    <span class="mr-2">{{ details.shipping.phone }}</span>
                    <i @click="copy_to_clipboard(details.shipping.phone)" v-tooltip.top="'In die Zwischenablage kopieren'" class="pi pi-copy text-primary cursor-pointer" />
                </div>
                <div class="mt-1" v-if="details.shipping.birthDate">
                    <span class="mr-2 font-bold">Geburtstag:</span>
                    <span class="mr-2">{{ details.shipping.birthDate }}</span>
                </div>
                <div class="mt-1" v-if="details.shipping.email">
                    <span class="mr-2 font-bold">E-Mail:</span>
                    <span class="mr-2"><a class="font-bold text-primary" :href="'mailto:' + details.shipping.email">{{ details.shipping.email }}</a></span>
                    <i @click="copy_to_clipboard(details.email)" v-tooltip.top="'In die Zwischenablage kopieren'" class="pi pi-copy text-primary cursor-pointer" />
                </div>
            </div>
            <div class="field col-12 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Bestellte Artikel
            </div>
            <div class="field col-12 p-2 border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                <div rounded class="overflow-hidden shadow-3 border-200">
                    <DataTable :value="details.lines" responsiveLayout="scroll" :rowHover="true">
                        <Column field="sku" header="SKU" :sortable="true">
                            <template #body="slotProps">
                                <span class="font-bold mr-2">{{ slotProps.data.sku }}</span>
                                <i @click="copy_to_clipboard(slotProps.data.sku)" v-tooltip.top="'In die Zwischenablage kopieren'" class="pi pi-copy text-primary cursor-pointer" />
                            </template>
                        </Column>
                        <Column field="name" header="Name" :sortable="true" />
                        <Column field="quantity" header="Menge" :sortable="true" />
                        <Column field="price" header="Preis" :sortable="true">
                            <template #body="slotProps">
                                <span>{{ format_currency_without_prefix(slotProps.data.price) }}</span>
                            </template>
                        </Column>
                        <Column field="total" header="Total" :sortable="true">
                            <template #body="slotProps">
                                <span>{{ format_currency_without_prefix(slotProps.data.total) }}</span>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
        <template #header>
            <div class="flex">
                <button class="p-sidebar-icon p-link mr-2">
                    <span class="pi pi-print" />
                </button>
            </div>
        </template>
    </Sidebar>
</template>