<template>
    <Galleria :value="image_library" v-model:activeIndex="activeImageIndex" :numVisible="7" containerStyle="max-width: 850px" :circular="true" :fullScreen="true" :showItemNavigators="true" :showThumbnails="false" v-model:visible="displayGallery">
        <template #item="slotProps">
            <div v-if="image_library.length > 0">
                <div>
                    <img :src="slotProps.item.image" alt="Gallery image" class="border border-gray-100 rounded w-auto" />
                    <Button icon="pi pi-trash" class="p-button-danger my-2 float-right absolute bottom-2 md:bottom-8 right-4" @click="deleteImage(slotProps.item.image)" :label="``" />
                </div>
                <div class=" hidden md:block text-white text-sm">{{ getFilenameFromUrl(slotProps.item.image) }}</div>
            </div>

        </template>
    </Galleria>

    <div class="flex flex-wrap">
        <div v-for="(image, index) in image_library" :key="index">
            <img :src="image.image" alt="Thumbnail" class="shadow-2 m-2 w-24 h-24 cursor-pointer object-cover rounded-sm shadow-md" @click="showGallery(index)" />
        </div>
    </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue';

const props = defineProps({
    imageLibrary: {
        type: Array,
        default: () => []
    }
});

const emit = defineEmits(['update:imageLibrary', 'update:visible']);

const activeImageIndex = ref(0);
const displayGallery = ref(false);
const image_library = ref([]);


const closeGallery = () => {
    displayGallery.value = false;
    activeImageIndex.value = 0;
    emit('update:visible', false);
};

watchEffect(() => {
    image_library.value = props.imageLibrary || [];
    if (image_library.value.length === 0) {
        closeGallery();
    }
});

const showGallery = (index) => {
    activeImageIndex.value = index;
    displayGallery.value = true;
    emit('update:visible', true);
};

const deleteImage = (imageToDelete) => {
    if (imageToDelete) {
        const updatedLibrary = image_library.value.filter(image => image.image !== imageToDelete);
        emit('update:imageLibrary', updatedLibrary);
        if (updatedLibrary.length === 0) {
            closeGallery();
        } else if (activeImageIndex.value >= updatedLibrary.length) {
            activeImageIndex.value = updatedLibrary.length - 1;
        }
    }
};

function getFilenameFromUrl(url) {
    const urlObj = new URL(url);
    const pathname = urlObj.pathname;
    const filename = pathname.split('/').pop();
    return filename;
}
</script>
