<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import StaticEditor from './Gerstl-Static-Editor.vue'
import { useAuthStore } from '@/core/store/AuthStore';
import { useCustomerStore } from '@/core/store/CustomerStore';

const cust = useCustomerStore().getCustomer.customer;
//console.log(cust); // gerstl

// getCustomer refers to its getter and must be called without braces
const CustomerStore = useCustomerStore();

console.log(CustomerStore.getCustomer.customer, CustomerStore.getCustomer.fullname ); // gerstl Gerstl Weinselektionen


const channel = ref('gerstl');

//////////////////
// Data Sources //
//////////////////
const staticpages = ref([])
const changes = ref(0)
const save_text = ref("Speichern")
const static_status = ref([])
const editing = ref([])
const users = ref([])
const new_static_title = ref("")
const auth = useAuthStore();

const get_base_data = async () => {
    try {
        const [staticpagesResponse, systemResponse, usersResponse] = await Promise.all([
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/get-staticpages`),
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/get-system`),
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/get-users`)
        ]);
        staticpages.value = staticpagesResponse.data
        static_status.value = systemResponse.data.static_status
        users.value = usersResponse.data
    } catch (error) {
        console.error(error);
    }
};

onMounted(async () => {
    await get_base_data()
})

/////////////////////
// Interface Props //
/////////////////////

const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
const filter_staticpages = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
const filter_static_status = ref()
const newStaticDialog = ref(false)
const activeTabIndex = ref(0)

const onTextFieldRowReorder = (e) => {
    staticpages.value = e.value
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
    toast.add({severity: 'success', summary: 'Reihenfolge geändert', detail: 'Die Reihenfolge wurde erfolgreich geändert', life: 3000})
}

const onStaticRowEdit = (e) => {
    if(staticpages.value[e.index]["redis_key"] != "") {
        if (staticpages.value[e.index][e.field] != e.newValue){
            console.log("Static " + staticpages.value[e.index].redis_key + " changed: " + e.field + " from " + staticpages.value[e.index][e.field] + " to " + e.newValue)
            staticpages.value[e.index][e.field] = e.newValue
            changes.value = changes.value + 1
            save_text.value = "Speichern (" + changes.value + ")"
            toast.add({severity: 'success', summary: 'Änderungen gespeichert', detail: 'Die Änderungen wurden erfolgreich gespeichert', life: 3000})
        }
    }
    else {
        toast.add({severity: 'error', summary: 'Fehler', detail: 'Der Seite Eintrag muss zuerst gespeichert werden!', life: 3000})
    }
}

const reset_changes = () => {
    confirm.require({
        message: 'Wollen Sie wirklich alle Änderungen verwerfen?',
        header: 'Änderungen verwerfen',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja, Änderungen verwerfen',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/"+channel.value+"/get-staticpages")
            .then(response => {
                staticpages.value = response.data
                changes.value = 0
                save_text.value = "Speichern"
            })
        }
    })
}

const save_changes = () => {
    confirm.require({
        message: 'Wollen Sie wirklich alle Änderungen speichern?',
        header: 'Änderungen speichern',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            loader.value = true
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/"+channel.value+"/save-staticpages", staticpages.value)
            .then(response => {
                console.log("response", response.data)
                staticpages.value = response.data
                toast.add({severity: 'success', summary: 'Erfolgreich gespeichert', detail: 'Änderungen wurden erfolgreich gespeichert', life: 3000})
                changes.value = 0
                save_text.value = "Speichern"
                loader.value = false
            })
        }
    })
}

const publish = () => {
    confirm.require({
        message: 'Wollen Sie wirklich alle Änderungen publizieren?',
        header: 'Änderungen publizieren',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            let payload = {"type": "page"}
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/publish", payload)
            .then(response => {
                console.log("published", response.data)
                toast.add({severity: 'success', summary: 'Publiziert', detail: 'Erstellt: '+response.data[0].created+', Gelöscht: '+response.data[0].deleted+', Ignoriert: '+response.data[0].ignored+', Aktualisiert: '+response.data[0].updated+'', life: 9000})
            })
        }
    })
}



const create_static = () => {
    if (!new_static_title.value) {
        toast.add({severity: 'error', summary: 'Fehler', detail: 'Bitte geben Sie einen Titel ein', life: 3000})
        return
    }
    if (check_if_slug_exists(new_static_title.value)) {
        toast.add({severity: 'error', summary: 'Fehler', detail: 'Ein Artikel mit diesem Titel existiert bereits', life: 3000})
        return
    }
    let new_static = {
        "workflow_id": "",
        "title": new_static_title.value,
        "title2": "",
        "teaser": "",
        "slug": slugify(new_static_title.value),
        "redis_key": channel.value+":staticpage:de:" + slugify(new_static_title.value),
        "category": "static",
        "status": "draft",
        "status_props": {
            "key": "published",
            "value": "Publiziert"
        },
        "presence": "",
        "user": auth.userdata.user_email,
        "header_image": "",
        "header_image_preview": "",
        "header_image_newsletter": "",
        "author": "",
        "dc": Date.now(),
        "dm": Date.now(),
        "publish_date": swiss_date(Date.now()),
        "runtime_from": "",
        "runtime_to": "",
        "tags": [],
        "matching_products_neuraxis": [],
        "matching_products_title": "",
        "events": [],
        "event_type": "",
        "sage_groups": [],
        "changes": [
            {
                "user": auth.userdata.user_email,
                "date": Date.now(),
                "message": "Seite wurde erstellt"
            }
        ],
        "content": [],
        "text_content": "",
        "image_library": []
    }
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
    staticpages.value.unshift(new_static)
    toast.add({severity: 'success', summary: 'Neuer Artikel', detail: 'Der neue Artikel wurde erfolgreich erstellt', life: 3000})
    newStaticDialog.value = false
}

const get_random_id = () => {
    return Math.random().toString(16).slice(2)
}

const preview_static = (slug) => {
    let cs = "fc7e3d6b-271c-41f7-bc50-97e3ed805459"
    window.open("https://www.gerstl.ch/de/"+slug+"/?preview=" + cs)
}

const delete_static = (redis_key) => {
    confirm.require({
        message: 'Bist du sicher, dass du den Artikel löschen möchtest?',
        header: 'Artikel löschen',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Artikel löschen',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            for (let key in staticpages.value){
                if (staticpages.value[key].redis_key == redis_key) {
                    staticpages.value.splice(key, 1)
                    changes.value = changes.value + 1
                    save_text.value = "Speichern (" + changes.value + ")"
                    toast.add({ severity: 'success', summary: 'Gelöscht', life: 3000})
                }
            }
        }
    })
}

const edit_static = (redis_key) => {
    for (let key in staticpages.value){
        if (staticpages.value[key].redis_key == redis_key) {
            let found = editing.value.some((el) => el.redis_key === redis_key);
            if (!found) {
                let name = ""
                if (staticpages.value[key].title.length > 25) {
                    name = staticpages.value[key].title.substring(0, 25) + "..."
                } else {
                    name = staticpages.value[key].title
                }
                editing.value.push({
                    "redis_key": staticpages.value[key].redis_key,
                    "name": name
                })
                activeTabIndex.value = editing.value.length
            }
        }
    }
}

const copy_static = (redis_key) => {
    for (let key in staticpages.value){
        if (staticpages.value[key].redis_key == redis_key) {
            console.log("copy", redis_key, staticpages.value[key].title)
            let new_static = JSON.parse(JSON.stringify(staticpages.value[key]))
            new_static.slug = new_static.slug + "-copy"
            new_static.title = new_static.title + " (Kopie)"
            new_static.redis_key = channel.value+":static:de:" + new_static.slug
            new_static.dc = swiss_date(Date.now())
            new_static.dm = swiss_date(Date.now())
            new_static.publish_date = swiss_date(Date.now())
            new_static.user = auth.userdata.user_email
            new_static.changes = [
                {
                    "user": auth.userdata.user_email,
                    "date": swiss_date(Date.now()),
                    "message": "Seite wurde kopiert"
                }
            ]
            new_static.status = "draft"
            staticpages.value.unshift(new_static)
            changes.value = changes.value + 1
            save_text.value = "Speichern (" + changes.value + ")"
            toast.add({severity: 'success', summary: 'Artikel kopiert', detail: 'Der Artikel wurde erfolgreich kopiert', life: 3000})
            break
        }
    }
}

const check_if_slug_exists = (title) => {
    let exists = false
    let slug = slugify(title)
    console.log(slug)
    staticpages.value.forEach(Static => {
        if (Static.slug == slug) {
            exists = true
        }
    })
    return exists
}

const editor_callback = (e) => {
    if (e.action == "close_tab") {
        for (let key in editing.value){
            if (editing.value[key]["redis_key"] == e.redis_key){
                editing.value.splice(key, 1)
                activeTabIndex.value = editing.value.length
            }
        }
    }
}

const select_status_filter = () => {
    filter_staticpages.value['global'].value = filter_static_status.value
}

watch([activeTabIndex], () => {
    if (activeTabIndex.value == 0) {
        console.log("get base data")
        get_base_data()
    }
})

//////////////////////////
// Formatting Functions //
//////////////////////////

const slugify = (str) => {
  return str.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().trim().replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '-');
}
const get_status = (status) => {
    for (let i = 0; i < static_status.value.length; i++) {
        if (static_status.value[i].value == status) {
            if (status == "published"){
                return "Publiziert"
            }
            if (status == "draft"){
                return "Entwurf"
            }
        }
    }
}

const swiss_date = (date) => {
    return new Date(date).toLocaleDateString('de-CH', { year: 'numeric', month: '2-digit', day: '2-digit' })
}

const swiss_date_time = (date) => {
    return new Date(date).toLocaleDateString('de-CH', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })
}

const characters_left = (field, limit) => {
    let chars = field.length
    return limit - chars
}
</script>

<style>
.spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}
</style>

<!-- <style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style> -->

<template>
    <Toast />
    <ConfirmDialog />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px;" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :fullScreen="true" :blocked="loader">
        <TabView class="col-12" ref="tabview4" v-model:activeIndex="activeTabIndex">
            <TabPanel>
                <template #header>
                    <i class="pi pi-comment mr-2"></i>
                    <span class="mr-3">Statische Seiten</span>
                </template>
                <Toolbar>
                    <template #start>
                        <Button v-if="changes != 0" v-tooltip.bottom="'Änderungen zurücksetzen'" @click="reset_changes()" class="mr-2 p-button-danger" icon="pi pi-refresh" />
                        <Button :disabled="changes != 0 ? false : true" @click="save_changes()" v-tooltip.bottom="'Änderungen speichern'" :label="save_text" class="w-auto mr-2 p-button-success" icon="pi pi-save" />
                        <Button :disabled="changes == 0 ? false : true" @click="publish()" v-tooltip.bottom="'Änderungen publizieren'" label="Publizieren" class="w-auto mr-2 p-button-warning" icon="pi pi-upload" />
                        <Divider layout="vertical" />
                        <Button @click="newStaticDialog = true, new_static_title = ''" label="Seite hinzufügen" class="w-auto mr-2 p-button" v-tooltip.top="'Eine neue Seite erstellen'" icon="pi pi-plus" />
                    </template>
                    <template #end>
                        <Button type="button" icon="pi pi-refresh" aria-controls="overlay_menu" @click="reset_changes()" />
                    </template>
                </Toolbar>
                <Toolbar class="mt-2">
                    <template #start>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filter_staticpages['global'].value" placeholder="Suche" style="width: 400px" class="mr-2" />
                        </span>
                        <Dropdown :showClear="true" @change="select_status_filter()" placeholder="Status" id="status_filter" v-model="filter_static_status" :options="static_status" optionLabel="name" optionValue="value" class="w-auto mr-2" />
                    </template>
                </Toolbar>
                <!--------------------------------------------------->
                <!------------------ DataTable ---------------------->
                <!--------------------------------------------------->
                <DataTable class="mt-3" v-model:filters="filter_staticpages" :value="staticpages" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" editMode="cell" @cell-edit-complete="onStaticRowEdit" @rowReorder="onTextFieldRowReorder" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                    <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                    <Column field="status" header="Status" style="width: 100px">
                        <template #editor="{ data, field }">
                            <Dropdown id="static_status" v-model="data[field]" :options="static_status" optionLabel="name" optionValue="value" class="w-auto mr-2" />
                        </template>
                        <template #body="{ data, field }">
                            <Chip v-if="data[field] == 'draft'" :label="get_status(data[field])" v-tooltip.top="'Beitrag ist Publiziert'" class="mr-1" style="background-color: #d32f2f; color: #FFF" />
                            <Chip v-if="data[field] == 'published'" :label="get_status(data[field])" v-tooltip.top="'Beitrag ist Publiziert'" class="mr-1" style="background-color: #689f38; color: #FFF" />
                        </template>
                    </Column>
                    <Column field="header_image" style="width: 130px">
                        <template #body="slotProps">
                            <img :src="slotProps.data.header_image" width="32" class="shadow-2 mr-2" style="vertical-align: middle; width: 128px; height: 64px; object-fit: cover; border-radius: 5%; box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)" />
                        </template>
                    </Column>
                    <Column field="title" header="Titel">
                        <template #body="{ data, field }">
                            <span>{{ data[field] }}</span>
                        </template>
                    </Column> 
                    <Column field="dm" header="Modifikation" style="width: 180px" sortable>
                        <template #body="slotProps">
                            <span>{{ swiss_date_time(slotProps.data.dm) }}</span>
                        </template>
                    </Column>
                    <Column field="user" header="Bearbeiter" style="width: 350px">
                        <template #editor="{ data, field }">
                            <Dropdown :showClear="true" id="user" v-model="data[field]" :options="users" optionLabel="user_fullname" optionValue="user_email" class="w-auto mr-2" />
                        </template>
                        <template #body="{ data, field }">
                            <Chip :label="data[field]" v-tooltip.top="'Der aktuellen Bearbeiter von der Seite'" icon="pi pi-user" class="mr-1" style="background-color: #0288d1; color: #FFF" />
                        </template>
                    </Column>
                    <Column style="width: 250px;">
                        <template #body="slotProps">
                            <Button v-if="slotProps.data.workflow_id" @click="preview_static(slotProps.data.slug)" v-tooltip.top="'Artikel Vorschau'" class="p-button-rounded sm p-button-text" icon="pi pi-eye" />
                            <Button v-if="slotProps.data.workflow_id && changes == 0" @click="edit_static(slotProps.data.redis_key)" v-tooltip.top="'Artikel editieren'" class="p-button-rounded p-button-text" icon="pi pi-pencil" />
                            <Button v-if="slotProps.data.workflow_id && changes == 0" @click="copy_static(slotProps.data.redis_key)" v-tooltip.top="'Artikel kopieren'" class="p-button-rounded p-button-text" icon="pi pi-copy" />
                            <Button v-if="slotProps.data.workflow_id && changes == 0" @click="delete_static(slotProps.data.redis_key)" v-tooltip.top="'Artikel löschen'" class="p-button-rounded p-button-text" icon="pi pi-trash" />
                        </template>
                    </Column>
                </DataTable>
            </TabPanel>
            <TabPanel v-for="(editor, key) in editing" :key="key">
                <template #header>
                    <i class="pi pi-pencil mr-2"></i>
                    <span class="mr-3">{{ editor.name }}</span>
                    <!--<a href="test"><i class="pi pi-times-circle mr-2"></i></a>-->
                </template>
                <StaticEditor :redis_key="editor.redis_key"  @editor-callback="editor_callback" />
            </TabPanel>
        </TabView>
    </BlockUI>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="newStaticDialog" :style="{width: '800px'}" header="Neuen Seite" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="new_static_title" v-model="new_static_title" class="w-full" />
                    <label for="new_static_title">Titel</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <Button @click="create_static()" label="Seite erstellen" class="p-button-success" />
            </div>
        </div>
    </Dialog>
</template>