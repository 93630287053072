<template>
    <Toast />
    <LibraryUpload v-model:visible="imageUploadDialog" :path="endpoints.uploadlibrary" :slug="channel" @data="libraryUploadGetData" @finished="libraryUploadidFinished" />

    <Toolbar>
        <template #start>

            <div class="inline sm:hidden">
                <SplitButton label="Speichern" icon="pi pi-save" :model="items" />
            </div>
            <div class="hidden sm:flex sm:justify-items-start">
                <Button @click="save_changes" v-tooltip.bottom="'Änderungen Speichern'" label="Speichern" class="w-auto mr-4 p-button-success" icon="pi pi-save" :loading="loader" size="small" />
                <Divider layout="vertical" />
                <!-- <Button v-tooltip.bottom="'Preview Artikel'" @click="preview" label="Vorschlau" class="mr-2 p-button w-auto" icon="pi pi-eye" size="small" :disabled="disabled" /> -->
                <Button v-tooltip.bottom="'Artikel schliessen'" @click="close_tab" label="Schliessen" class="mr-2 p-button-danger w-auto" icon="pi pi-times-circle" size="small" :disabled="disabled" />
            </div>

        </template>
        <template #end>
            <div class="hidden sm:inline-block">
                <Button type="button" icon="pi pi-refresh" aria-controls="overlay_menu" @click="reset_changes" />
            </div>
        </template>
    </Toolbar>

    <TabView ref="tabview4">
        <TabPanel>
            <template #header>
                <i class="pi pi-file mr-2"></i>
                <span class="mr-3">Meta</span>
            </template>
            <div class="md:flex flex-row md:my-5">
                <div class="w-auto md:w-2/4 m-2">

                    <Accordion class="accordion-custom" :activeIndex="0">
                        <AccordionTab>
                            <template #header>
                                <span class="mr-2 font-bold">Metadaten</span>
                            </template>

                            <div class="my-7">
                                <span class="p-float-label">
                                    <InputText id="title" class="w-full" v-model="blog.title" />
                                    <label for="title">Titel</label>
                                </span>
                            </div>
                            <div class="mb-5">
                                <h3 class="mb-2 font-semibold">Kategorie</h3>
                                <MultiSelect v-model="blog.cagtegory" :options="blog_categories" optionLabel="name" optionValue="value" placeholder="Kategorie wählen" :maxSelectedLabels="0" class="w-full" selectedItemsLabel="{0} Kategorien" filter>
                                    <template #header>
                                        <div class="py-2 px-3">
                                            <b>{{ blog.cagtegory ? blog.cagtegory.length : 0 }}</b> Kategorien ausgewählt.
                                        </div>
                                    </template>
                                </MultiSelect>
                            </div>
                            <div class="my-5">
                                <h3 class="mb-2 font-semibold">Ort</h3>
                                <Editor editorStyle="width: 100%; min-height: 200px;" v-model="blog.place">
                                    <template v-slot:toolbar>
                                        <span class="ql-formats">
                                            <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                                            <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                                            <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                                            <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                                            <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                                            <!-- <button v-tooltip.bottom="'chatGPT'" @click="chatGptDialog = true">
                                                <CloudIcon class="h-10 w-10" />
                                            </button> -->
                                        </span>
                                    </template>
                                </Editor>
                            </div>
                        </AccordionTab>
                    </Accordion>
                </div>


                <div class="w-auto md:w-2/4 m-2">
                    <div class="">
                        <Accordion class="accordion-custom" :activeIndex="0">
                            <AccordionTab>
                                <template #header>
                                    <span class="mr-2 font-bold">Metadaten</span>
                                </template>
                                <div class="my-7">
                                    <span class="p-float-label">
                                        <Dropdown id="blog_status" v-model="blog.status" :options="blog_status" optionLabel="name" optionValue="value" class="w-full" />
                                        <label for="blog_status">Status</label>
                                    </span>
                                </div>

                                <div class="my-7">
                                    <span class="p-float-label">
                                        <InputNumber id="price" class="w-full" v-model="blog.price" inputId="currency-switzerland" mode="currency" currency="CHF" locale="de-CH" />
                                        <label for="price">Preis</label>
                                    </span>
                                </div>
                                <div class=" my-7">
                                    <span class="p-float-label">
                                        <InputText id="entrytext" placeholder="Eintritt Bemerkung" v-model="blog.entrytext" class="w-full" />
                                        <label for="entrytext">Eintritt Bemerkung</label>
                                    </span>
                                </div>


                                <div class="my-7">
                                    <span class="p-float-label">
                                        <Dropdown id="blog_canton" v-model="blog.canton" :options="cantons" optionLabel="name" optionValue="value" class="w-full" />
                                        <label for="blog_status">Kanton</label>
                                    </span>
                                </div>
                                <div class=" my-7">
                                    <span class="p-float-label">
                                        <InputText id="mail_cc" placeholder="E-Mail CC" v-model="blog.mail_cc" class="w-full" />
                                        <label for="mail_cc">E-Mail CC Adresse</label>
                                    </span>
                                </div>

                                <div class="my-7">
                                    <label for="event_adress">Event-Adresse</label>
                                    <InputGroup>
                                        <InputText id="event_adress" placeholder="Event-Adresse" v-model="blog.event_adress" class="w-full" />
                                        <Button icon="pi pi-flag" @click="openAdressPrewiewDialog" :disabled="event_adress_disabled" />
                                    </InputGroup>

                                </div>

                                <div class="my-7">
                                    <label class="block text-gray-700 text-sm font-bold mb-2" for="startDate">Start Datum </label>
                                    <Calendar v-model="blog.startDate" id="startDate" :showIcon="true" dateFormat="dd.mm.yy" class="w-full" />
                                </div>
                                <div class="my-7">
                                    <label class="block text-gray-700 text-sm font-bold mb-2" for="endDate">Schluss Datum </label>
                                    <Calendar v-model="blog.endDate" id="endDate" :showIcon="true" dateFormat="dd.mm.yy" class="w-full" />
                                </div>


                                <div class=" my-7">
                                    <div class="flex align-items-center justify-content-start">
                                        <InputSwitch v-model="blog.registerform" />
                                        <span class="ml-2">Anmeldeformular</span>
                                    </div>
                                </div>

                                <div class=" bg-gray-100 p-2 rounded-md" v-if="blog.registerform">
                                    <div class=" my-7">
                                        <span class="p-float-label">
                                            <InputText type="number" mode="decimal" showButtons :min="0" id="availability" v-model="blog.availability" class="w-full" />
                                            <label for="availability">Verfügbarkeit</label>
                                        </span>
                                    </div>

                                    <div class=" my-7">
                                        <div class="flex align-items-center justify-content-start">
                                            <InputSwitch v-model="blog.ticketbuy" />
                                            <span class="ml-2">Ticketeinkauf</span>
                                        </div>
                                    </div>

                                    <div class=" my-7">
                                        <div class="flex align-items-center justify-content-start">
                                            <InputSwitch v-model="blog.waitlist" />
                                            <span class="ml-2">Warteliste</span>
                                        </div>
                                    </div>
                                    <div class=" my-7">
                                        <div class="flex align-items-center justify-content-start">
                                            <Button :label="`Gästeliste (${guestlist.length})`" icon="pi pi-user-edit" size="small" class="" @click="guestlistDialog = true" />
                                        </div>
                                    </div>
                                </div>


                                <!--     <div class="my-7">
                                    <span class="p-float-label">
                                        <Dropdown id="team" v-model="blog.users" :options="users" optionLabel="user_email" optionValue="user_email" class="w-full" />
                                        <label for="team">Bearbeiter</label>
                                    </span>
                                </div> -->
                                <!-- <div class="my-7">
                                    <Button label="Preispanne generieren" :loading="pricespan_loading" icon="pi pi-calculator" size="small" class="" @click="generate_pricespan" />
                                </div> -->


                                <!-- <div class="my-7">
                                    <span class="p-float-label">
                                        <InputNumber id="min_price" class="w-full" v-model="blog.min_price" inputId="currency-switzerland" mode="currency" currency="CHF" locale="de-CH" />
                                        <label for="min_price">Mindestpreis</label>
                                    </span>
                                </div>
                                <div class="my-7">
                                    <span class="p-float-label">
                                        <InputNumber id="average_price" class="w-full" v-model="blog.average_price" inputId="currency-switzerland" mode="currency" currency="CHF" locale="de-CH" />
                                        <label for="average_price">Durchschnittpreis</label>
                                    </span>
                                </div> -->


                                <!-- <div class="my-7">
                                    <span class="p-float-label">
                                        <MultiSelect id="blog_categories" v-model="new_blog_categories" :options="blog_categories" optionLabel="name" optionValue="value" class="w-full" filter :maxSelectedLabels="0" selectedItemsLabel="{0} Kategorien" />
                                        <label for="blog_categories">Kategorien</label>
                                    </span>
                                </div> -->

                                <!-- <div class=" bg-gray-100 p-2 rounded-md">

                                    <div class=" my-7">
                                        <span class="p-float-label">
                                            <InputText id="matching_products_title" placeholder="Passende Produkte" v-model="blog.matching_products_title" class="w-full" />
                                            <label for="matching_products_title">Passende Produkte (Titel)</label>
                                        </span>
                                    </div>
                                    <div class="my-7">
                                        <span class="p-float-label">
                                            <InputText id="matching_products_cta_label" v-model="blog.matching_products_cta_label" class="w-full" />
                                            <label for="matching_products_cta_label">Passende Produkte (Button Titel)</label>
                                        </span>
                                    </div>
                                    <div class="my-7">
                                        <div class="p-inputgroup">
                                            <span class="p-inputgroup-addon text-xs py-2">https://gerstl.ch/</span>
                                            <span class="p-float-label">
                                                <InputText id="matching_products_cta_url" v-model="blog.matching_products_cta_url" class="w-full" />
                                                <label for="matching_products_cta_url" class=" -ml-24">Passende Produkte (URL)</label>
                                            </span>
                                        </div>
                                    </div>
                                    <div class=" my-7">
                                        <span class="p-float-label">
                                            <InputText type="number" mode="decimal" showButtons :min="0" :max="24" id="matching_products_count" v-model="blog.matching_products_count" class="w-full" />
                                            <label for="matching_products_count">Passende Produkte (Anzahl)</label>
                                        </span>
                                    </div>
                                </div> -->

                                <!-- <div class="my-7">
                                    <label class="block text-gray-700 text-sm font-bold mb-2" for="selectedSage">Sage Gruppen</label>
                                    <MultiSelect v-model="blog.sagegroup" :options="sageGroup" id="selectedSage" optionLabel="name" optionValue="id" placeholder="Sage Gruppe wählen" :maxSelectedLabels="0" class="w-full" selectedItemsLabel="{0} Sage Gruppen" filter>
                                        <template #header>
                                            <div class="py-2 px-3">
                                                <b>{{ blog.sagegroup ? blog.sagegroup.length : 0 }}</b> Sage Gruppen ausgewählt.
                                            </div>
                                        </template>
                                    </MultiSelect>
                                </div>
                                <div class="my-7">
                                    <label class="block text-gray-700 text-sm font-bold mb-2" for="selectedProducts">Ausgewählte Produkte</label>
                                    <MultiSelect v-model="blog.products" :options="products" id="selectedProducts" optionLabel="name" optionValue="id" placeholder="Artikel wählen" :maxSelectedLabels="0" class="w-full" selectedItemsLabel="{0} Artikel" filter :loading="loader" @selectall-change="onSelect($event)" @change="onChange($event)" :virtualScrollerOptions="{ itemSize: 25 }">
                                        <template #header>
                                            <div class="py-2 px-3">
                                                <b>{{ blog.products ? blog.products.length : 0 }}</b> Artikel ausgewählt.
                                            </div>
                                        </template>
                                    </MultiSelect>
                                </div>
                                <div class=" my-7">
                                    <div class="flex align-items-center justify-content-start">
                                        <InputSwitch v-model="blog.disable_header_image" />
                                        <span v-if="!blog.disable_header_image" class="ml-2">Titelbild deaktivieren</span>
                                        <span v-if="blog.disable_header_image" class="ml-2 font-bold text-red-600">Titelbild ist deaktiviert</span>
                                    </div>
                                </div> -->


                                <div class="flex flex-wrap" v-if="image_library.length > 0 && !blog.disable_header_image">
                                    <UploadImageButton :imagelibrary="image_library" @delete="delete_image" label="Hauptbild" :image="blog.header_image" :workflow_id="workflow_id" @uploaded-image="imageUploaded" image-type="header_image" />
                                </div>
                                <div class="flex flex-wrap" v-if="image_library.length > 0">
                                    <UploadImageButton :imagelibrary="image_library" @delete="delete_image" label="Vorschaubild" :image="blog.header_image_preview" :workflow_id="workflow_id" @uploaded-image="imageUploaded" image-type="header_image_preview" />
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                    <!-- <div class="mt-4">
                        <Accordion class="accordion-custom mt-3">
                            <AccordionTab>
                                <template #header>
                                    <i class="pi pi-image mr-2"></i>
                                    <span class="mr-2 font-bold">Bilder Bibliotek</span>
                                    <span class="inline-flex items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{{ String(image_library.length) }}</span>
                                </template>
                                <ImageGallery :image-library="image_library" @update:imageLibrary="image_library = $event" />
                                <div>
                                    <Button @click="imageUploadDialog = true" v-tooltip.bottom="'Bilder in die Bibliotek hochladen'" label="Bilder hochladen" class="mr-2 p-button w-auto" icon="pi pi-upload" />
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div> -->
                    <div class="mt-4">
                        <Accordion class="accordion-custom mt-3">
                            <AccordionTab>
                                <template #header>
                                    <i class="pi pi-history mr-2"></i>
                                    <span class="mr-2 font-bold">Änderungsverlauf</span>

                                    <span class="inline-flex items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{{ String(blog_changes.length) }}</span>
                                </template>
                                <div v-if="blog_changes">
                                    <div v-for="(change, index) of blog_changes.slice().reverse()" :key="index" class="my-8 bg-yellow-700/20 p-6 rounded-lg">

                                        <div class="text-xs">
                                            <Chip :label="change.user" icon="pi pi-user" class="m-1" />
                                            <Chip :label="swiss_date_time(change.date)" icon="pi pi-clock" class="m-2" />
                                        </div>
                                        <div class="bg-primary-100 text-white m-2 p-2 rounded-md">
                                            <span class=""> {{ change.message }}</span>
                                        </div>
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                </div>
            </div>
        </TabPanel>
        <!-- <TabPanel>
            <template #header>
                <i class="pi pi-box mr-2"></i>
                <span class="mr-3">Builder</span>
            </template>
            <div class="md:flex flex-row my-5">
                <div class="w-auto md:w-2/3 m-2 border-solid border border-gray-200">
                    <DataTable :value="blog_content" :rows="20" responsiveLayout="scroll" :rowHover="true" @rowReorder="onRowReorder">
                        <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                        <Column field="template">
                            <template #header>
                                <i class="pi pi-box mr-2"></i>
                                <span class="mr-3">Blocks</span>
                                <span className="inline-flex items-center rounded-full bg-primary-500 px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-primary-700/10">
                                    {{ blog_content.length }}
                                </span>
                            </template>
                            <template #body="slotProps">
                                <component :is="content_blocks.find(block => block.name === slotProps.data.template).type" template_type="data" :index="slotProps.index" :data="slotProps.data" @block-callback="update_template_data" :image_library="image_library" :workflow_id="workflow_id" />
                            </template>
                        </Column>
                        <Column class="w-10">
                            <template #body="slotProps">
                                <div>
                                    <ChevronUpIcon v-if="slotProps.index != 0" class="h-10 w-10 text-gray-400" @click="shift_col('up', slotProps.index)" />
                                </div>
                                <div>
                                    <ChevronDownIcon v-if="slotProps.index != blog_content.length - 1" class="h-10 w-10 text-gray-400" @click="shift_col('down', slotProps.index)" />
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                </div>
                <div class="w-auto md:w-1/3 m-5 border-solid border border-gray-200">
                    <div v-for="key in content_blocks" :key="key">
                        <component :is="key.type" template_type="menu" :data="content_blocks" @block-callback="update_template_data" :image_library="image_library" :workflow_id="workflow_id" />
                    </div>
                </div>
            </div>
        </TabPanel> -->
    </TabView>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->

    <Dialog v-model:visible="blog_text_vorschlag_dialog" :style="{ width: '800px' }" header="Teaser Vorschlag" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            {{ blog_text_vorschlag }}
        </div>
    </Dialog>
    <!--------------------------------------------------->
    <!--------------- Edit Title Image ------------------>
    <!--------------------------------------------------->
    <Dialog v-model:visible="editTitleImageDialog" :style="{ width: '80%' }" header="Titel Bild editieren" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">

            <div v-if="edit_header_image == false" class="m-3">
                <img :src="blog.header_image" class="shadow-2" style="width: 100%; height: 100%; object-fit: cover;" />
            </div>

            <span class="inline-block h-48 w-48 overflow-hidden rounded-full bg-gray-100 my-10" v-if="edit_header_image == false && !blog.header_image">
                <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
            </span>
            <Button v-if="edit_header_image == false" label="Bild Bearbeiten" @click="edit_header_image = true" class="w-auto mr-3 mb-1 p-button" icon="pi pi-pencil" />

            <div v-if="edit_header_image == true" class="mt-3">
                <cropper ref="title_image_cropper" :src="blog.header_image" :auto-zoom="true" :auto-center="true" />
            </div>

            <div v-if="edit_header_image == true" class="mt-3">
                <div v-if="image_library" class="flex">
                    <div v-for="(img, index) of image_library" class="flex" :key="index">
                        <img @click="blog.header_image = img.image" :src="img.image" class="shadow-2 m-2" style="cursor: pointer; width: 80px; height: 80px; object-fit: cover;" />
                    </div>
                </div>
            </div>

            <div v-if="edit_header_image == true" class="mt-3">
                <Button label="Bild Speichern" @click="cropImage(title_image_cropper, 'header_image', 'image123')" class="w-auto mr-3 mb-1 p-success" icon="pi pi-save" />
            </div>
        </div>

    </Dialog>
    <!--------------------------------------------------->
    <!----------------- Preview Image ------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editTitleImagePreviewDialog" :style="{ width: '80%' }" header="Titel Vorschau-Bild editieren" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div v-if="edit_header_image_preview == false" class="mt-3">
                <img :src="blog.header_image_preview" class="shadow-2" style="width: 100%; height: 100%; object-fit: cover;" />
            </div>
            <Button v-if="edit_header_image_preview == false" label="Bild Bearbeiten" @click="edit_header_image_preview = true, blog.header_image_preview = blog.header_image" class="w-auto mr-3 mb-1 p-button" icon="pi pi-pencil" />
            <div v-if="edit_header_image_preview == true" class="mt-3">
                <cropper ref="title_image_cropper_preview" :src="blog.header_image_preview" :auto-zoom="true" :auto-center="true" />
            </div>
            <div v-if="edit_header_image_preview == true" class="mt-3">
                <div v-if="image_library" class="flex">
                    <div v-for="(img, index) of image_library" class="flex" :key="index">
                        <img @click="blog.header_image_preview = img.image" :src="img.image" class="shadow-2 m-2" style="cursor: pointer; width: 80px; height: 80px; object-fit: cover;" />
                    </div>
                </div>
            </div>
            <div v-if="edit_header_image_preview == true" class="mt-3">
                <Button label="Bild Speichern" @click="crop_header_image(title_image_cropper_preview, 'header_image_preview')" class="w-auto mr-3 mb-1 p-success" icon="pi pi-save" />
            </div>
        </div>
    </Dialog>

    <Dialog v-model:visible="guestlistDialog" :style="{ width: '80%' }" header="Gästeliste" :modal="true" class="p-fluid" maximizable>
        <EventsList :list="guestlist" @save="saveGuestlist" @title="getListTitle" :title="blog.guestlist_title" @guestlist_id="getListID" :id="blog.guestlist_id" />
    </Dialog>

    <Dialog v-model:visible="adressPreviewDialog" :style="{ width: '80%' }" header="Adresse anzeigen" :modal="true" class="p-fluid">
        <div v-html="google_geo.adress"></div>
          <GMapMap
          :center="{ lat: google_geo.lat, lng: google_geo.lng }"
          :zoom="12"
          map-type-id="terrain"
          style="width: 100%; height: 500px"
      >
      </GMapMap>
    </Dialog>
    <!--------------------------------------------------->
    <!------------------- GPT Dialog -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="chatGptDialog" :style="{ width: '80%' }" header="Inhalte mit AI erstellen" :modal="true" class="p-fluid">
        <ChatGPT :questions="chatGptQuestions" @chat-callback="update_text_content" />
    </Dialog>
    <div id="map" ref="map"></div>
</template>

<script setup>
import { ref, onMounted, watch, defineProps, defineEmits, inject, provide, shallowRef, reactive, computed } from 'vue'

import InputGroup from 'primevue/inputgroup';
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import EventsList from '../Events/Events-List-Inline.vue'
import { useConfirm } from "primevue/useconfirm"
import { Cropper, Preview } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import { useCustomerStore } from '@/core/store/CustomerStore'
import ChatGPT from '@/core/components/Components/Main-GPT.vue';
import StandardText from '@/core/components/Components/Builder-Blocks/Standard-Text.vue'
import StandardImage from '@/core/components/Components/Builder-Blocks/Standard-Image.vue'
import StandardTitle from '@/core/components/Components/Builder-Blocks/Standard-Title.vue'
import CTAButton from '@/core/components/Components/Builder-Blocks/Cta-Button.vue'
import HtmlBlock from '@/core/components/Components/Builder-Blocks/Html-Block.vue'
import QuoteBlock from '@/core/components/Components/Builder-Blocks/Quote-Block.vue'
import ImageText from '@/core/components/Components/Builder-Blocks/Image-Text.vue'
import ComparsionBlock from '@/core/components/Components/Builder-Blocks/Comparsion-Block.vue'
import QuotingLogo from '@/core/components/Components/Builder-Blocks/Quoting-Logo-Block.vue'
import ImageCols from '@/core/components/Components/Builder-Blocks/Image-Columns.vue'
import Markdown from '@/core/components/Components/Builder-Blocks/Markdown-Block.vue'
import FAQBlock from '@/core/components/Components/Builder-Blocks/FAQ-Block.vue'
import GalleryBlock from '@/core/components/Components/Builder-Blocks/Gallery-Block.vue'

import StandardProducts from '../Editor-Blocks/standard_products.vue'
import ImageBig from '@/core/components/Components/Builder-Blocks/Image-Big.vue'

import Simplestore from '@/core/var/Simplestore';
import { swiss_date_time, preview_dataset, checkDuplicate } from '@/core/var/tools'
import { uploadSingleImage } from '@/core/var/crud'
import LibraryUpload from '@/core/components/Components/Main-Library-Upload.vue'
import VideoURL from '@/core/components/Components/Builder-Blocks/Video-URL.vue'
import YouTubeURL from '@/core/components/Components/Builder-Blocks/Youtube-URL.vue'
//import MainButton from '@/core/components/UI/Main-Button.vue'
import UploadImageButton from '@/core/components/Components/Main-UploadImage-Button2.vue';
//import InputNumber from 'primevue/inputnumber';
import ImageGallery from '@/core/components/Components/Main-Image-Library.vue'

//import ImageEditor from '@/core/components/Components/Builder-Blocks/Components/Image-Editor.vue'
import { useAuthStore } from '@/core/store/AuthStore';
const auth = useAuthStore();

const props = defineProps({
    redis_key: { type: String }
})

const MODULE = 'event';

const content_blocks = ref([
    { name: 'standard_text', type: shallowRef(StandardText) },
    { name: 'standard_title', type: shallowRef(StandardTitle) },
    { name: 'standard_image', type: shallowRef(StandardImage) },
    { name: 'cta_button', type: shallowRef(CTAButton) },
    { name: 'html_block', type: shallowRef(HtmlBlock) },
    { name: 'quote_block', type: shallowRef(QuoteBlock) },
    { name: 'image_text', type: shallowRef(ImageText) },
    { name: 'comparsion_block', type: shallowRef(ComparsionBlock) },
    { name: 'quoting_logo', type: shallowRef(QuotingLogo) },
    { name: 'image_cols', type: shallowRef(ImageCols) },
    { name: 'video_url', type: shallowRef(VideoURL) },
    { name: 'youtube_url', type: shallowRef(YouTubeURL) },
    { name: 'markdown', type: shallowRef(Markdown) },
    { name: 'faq_block', type: shallowRef(FAQBlock) },
    { name: 'standard_products', type: shallowRef(StandardProducts) },
    { name: 'gallery_block', type: shallowRef(GalleryBlock) },
    { name: 'image_big', type: shallowRef(ImageBig) },
]);


const channel = ref(useCustomerStore().getCustomer.customer)
provide('channel', channel)
const SERVICE_URL = inject('SERVICE_URL')


const loader = ref(false)
const toast = useToast()
const confirm2 = useConfirm()
const imageUploadDialog = ref(false)
const editTitleImageDialog = ref(false)
const editTitleImagePreviewDialog = ref(false)
const edit_header_image_preview = ref(false)
const edit_header_image = ref(false)
const workflow_id = ref("")
const new_blog_categories = ref();
const blog = ref([])
const blog_categories = ref([])
const guestlistDialog = ref(false)
const blog_sort = ref([])
const blog_status = ref([])
const users = ref([])
const blog_content = ref([])
const image_library = ref([])
const activeImageIndex = ref(0)
const displayGallery = ref(false)
const blog_changes = ref([])
const chatGptQuestions = ref([])
const chatGptDialog = ref(false)
const blog_title = ref(null)
const sageGroup = ref(false)
const products = ref(false)
const new_articles = ref()
const blog_text_vorschlag = ref(null);
const blog_text_vorschlag_dialog = ref(false);
const title_image_cropper = ref();
const emits = defineEmits(['editorCallback'])
const title_image_cropper_preview = ref()
const disabled = ref(false);
const pricespan_loading = ref(false);
const adressPreviewDialog = ref(false);
const google_geo = ref(false);
const data_guestlistTitle = ref(false);


const team = ref([]);
const cantons = ref([]);
const guestlist = ref([
    {
        "guestlist_event_id": '98765432',
        "guestlist_list_id": '87654321',
        "guestlist_firstname": "John",
        "guestlist_lastname": "Doe",
        "guestlist_company": "Demo Company GmbH",
        "guestlist_address": "Demo Street",
        "guestlist_housenumber": "1",
        "guestlist_city": "Berlin",
        "guestlist_zip": "10115",
        "guestlist_country": "DE",
        "guestlist_registered": new Date().toISOString(),
        "guestlist_total_entry": 3,
        "guestlist_email": "john.doe@demo-company.de",
        "guestlist_phone": "0123456789",
        "guestlist_price": 100.00,
        "guestlist_status": true,
        "guestlist_payment_type": "PayPal",
        "guestlist_paid": new Date().toISOString(),
        "guestlist_barcode": '87654321',
        "guestlist_notifications": ["0123456789", "9876543210"]
    },
    {
        "guestlist_event_id": '12345678',
        "guestlist_list_id": '23456789',
        "guestlist_firstname": "Alice",
        "guestlist_lastname": "Smith",
        "guestlist_company": "Alice Co.",
        "guestlist_address": "Wonderland Ave",
        "guestlist_housenumber": "42",
        "guestlist_city": "Wonder City",
        "guestlist_zip": "42424",
        "guestlist_country": "Wonderland",
        "guestlist_registered": new Date().toISOString(),
        "guestlist_total_entry": 2,
        "guestlist_email": "alice.smith@aliceco.com",
        "guestlist_phone": "9876543210",
        "guestlist_price": 150.00,
        "guestlist_status": "paid",
        "guestlist_payment_type": "Credit Card",
        "guestlist_paid": new Date().toISOString(),
        "guestlist_barcode": '12345678',
        "guestlist_notifications": ["9876543210", "1234567890"]
    },
    {
        "guestlist_event_id": '11223344',
        "guestlist_list_id": '22113344',
        "guestlist_firstname": "Bob",
        "guestlist_lastname": "Brown",
        "guestlist_company": "Bobby's World",
        "guestlist_address": "Imagination Road",
        "guestlist_housenumber": "7",
        "guestlist_city": "Fantasy Town",
        "guestlist_zip": "22444",
        "guestlist_country": "Dreamland",
        "guestlist_registered": new Date().toISOString(),
        "guestlist_total_entry": 1,
        "guestlist_email": "bob.brown@bobbysworld.com",
        "guestlist_phone": "2233445566",
        "guestlist_price": 75.00,
        "guestlist_status": true,
        "guestlist_payment_type": "Bank Transfer",
        "guestlist_paid": new Date().toISOString(),
        "guestlist_barcode": '33221144',
        "guestlist_notifications": ["2233445566", "6655443322"]
    },
    {
        "guestlist_event_id": '55667788',
        "guestlist_list_id": '66557788',
        "guestlist_firstname": "Charlie",
        "guestlist_lastname": "Chaplin",
        "guestlist_company": "Chaplin Films",
        "guestlist_address": "Silent Street",
        "guestlist_housenumber": "12",
        "guestlist_city": "Classic City",
        "guestlist_zip": "55668",
        "guestlist_country": "Oldland",
        "guestlist_registered": new Date().toISOString(),
        "guestlist_total_entry": 4,
        "guestlist_email": "charlie.chaplin@silentfilms.com",
        "guestlist_phone": "5566778899",
        "guestlist_price": 200.00,
        "guestlist_status": false,
        "guestlist_payment_type": "Cash",
        "guestlist_paid": false,
        "guestlist_barcode": '88776655',
        "guestlist_notifications": ["5566778899", "9988776655"]
    },
    {
        "guestlist_event_id": '99887766',
        "guestlist_list_id": '88997766',
        "guestlist_firstname": "Diana",
        "guestlist_lastname": "Prince",
        "guestlist_company": "Themyscira Inc.",
        "guestlist_address": "Amazon Lane",
        "guestlist_housenumber": "5",
        "guestlist_city": "Paradise Island",
        "guestlist_zip": "55677",
        "guestlist_country": "Mythland",
        "guestlist_registered": new Date().toISOString(),
        "guestlist_total_entry": 5,
        "guestlist_email": "diana.prince@themyscira.com",
        "guestlist_phone": "4455667788",
        "guestlist_price": 250.00,
        "guestlist_status": true,
        "guestlist_payment_type": "Debit Card",
        "guestlist_paid": new Date().toISOString(),
        "guestlist_barcode": '66778899',
        "guestlist_notifications": ["4455667788", "8877665544"]
    },
    {
        "guestlist_event_id": '44332211',
        "guestlist_list_id": '33224411',
        "guestlist_firstname": "Edward",
        "guestlist_lastname": "Scissorhands",
        "guestlist_company": "Edward's Garden",
        "guestlist_address": "Sharp Lane",
        "guestlist_housenumber": "9",
        "guestlist_city": "Cutting Edge City",
        "guestlist_zip": "44332",
        "guestlist_country": "Sharpville",
        "guestlist_registered": new Date().toISOString(),
        "guestlist_total_entry": 2,
        "guestlist_email": "edward.scissorhands@edwardsgarden.com",
        "guestlist_phone": "1122334455",
        "guestlist_price": 120.00,
        "guestlist_status": false,
        "guestlist_payment_type": "Google Pay",
        "guestlist_paid": false,
        "guestlist_barcode": '11332244',
        "guestlist_notifications": ["1122334455", "5544332211"]
    }
]);


const getListTitle = (listname) => {
    blog.value.guestlist_title = listname
}

const getListID = (listid) => {
    blog.value.guestlist_id = listid
}

const items = ref([
    {
        label: 'Änderungen zurücksetzen',
        icon: 'pi pi-refresh',
        command: () => {
            reset_changes()
        }
    },
    // {
    //     label: 'Vorschau',
    //     icon: 'pi pi-eye',
    //     command: () => {
    //         preview()
    //     }
    // },
    {
        label: 'Schließen',
        icon: 'pi pi-times',
        command: () => {
            close_tab()
        }
    }

]);


const endpoints = {
    get: `${MODULE}/get`,
    getsingle: `${MODULE}/get-single`,
    savesingle: `${MODULE}/save-single`,
    getsystem: `get-system`,
    getusers: `get-users`,
    getcategories: `events/categories/get`,
    getsubcategories: `${MODULE}/subcategories/get`,
    getqualities: `${MODULE}/qualities/get`,
    getcolors: `${MODULE}/colors/get`,
    gettags: `${MODULE}/tags`,
    save: `${MODULE}/save`,
    uploadsingle: `upload-image`,
    uploadlibrary: `${MODULE}/upload-library-image`,
    preview: `${MODULE}/preview/save`,
    getarticles: `articles/get`,
    checktitle: `${MODULE}/check-title`,
    get_pricespan: `${MODULE}/get-pricespan`,
}

provide('upload_endpoint', endpoints.uploadsingle);

onMounted(async () => {
    loader.value = true;
    await getBlogs()
    await fetchSystemAndUsers();
    loader.value = false;
})
const event_adress_disabled = computed(() => {
    return !blog.value.event_adress || blog.value.event_adress === '';
});
watch(loader, res => {
    Simplestore.spinner = res;
    disabled.value = res;
});
watch(workflow_id, res => {
    provide('workflow_id', res)
})

const fetchSystemAndUsers = async () => {
    try {
        const systemRequest = axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getsystem}`);
        const usersRequest = axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/dashboard/${endpoints.getusers}`);
        const categoriesRequest = axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getcategories}`);
        const teamRequest = axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/get-team`);
        const sagegroupRequest = axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/get-sage-groups`);
        const productsRequest = axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/get-products`);

        const [systemResponse, usersResponse, categoriesResponse, teamResponse, sageGroupResponse, productsResponse] = await Promise.all([systemRequest, usersRequest, categoriesRequest, teamRequest, sagegroupRequest, productsRequest]);

        team.value = teamResponse.data;
        sageGroup.value = sageGroupResponse.data.filter((value, index, self) =>
            self.findIndex(v => v.id === value.id) === index
        );
        blog_categories.value = categoriesResponse.data;
        blog_sort.value = systemResponse.data.blog_sort;
        blog_status.value = systemResponse.data.blog_status;
        users.value = usersResponse.data
        products.value = productsResponse.data;
        blog.value.users = auth.userdata.user_email
        cantons.value = systemResponse.data.cantons;
        blog.value.guestlist_title = blog.value.guestlist_title == undefined ? `${blog.value.title} - ${swiss_date_time(new Date())}` : blog.value.guestlist_title
    } catch (error) {
        console.error("Error fetching system and users data:", error);
    }
};


const preview = () => {
    const workload = {
        redis_key: blog.value.redis_key,
        content: blog.value.content,
        title: blog.value.title,
        teaser: blog.value.teaser,
        header_image: blog.value.header_image,
    };

    axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.preview}`, workload)
        .then(response => {
            preview_dataset(blog.value.slug, SERVICE_URL, MODULE)
        })
}

const generate_pricespan = async () => {

    const workload = new_articles.value
    pricespan_loading.value = true
    const response = await axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.get_pricespan}`, { articles: workload });
    blog.value.price_range = response.data.average_price
    blog.value.average_price = response.data.average_price
    blog.value.min_price = response.data.minimum_price
    pricespan_loading.value = false
}


const getBlogs = async () => {
    const payload = { redis_key: props.redis_key };
    try {
        const response = await axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getsingle}`, payload);
        const { data } = response;

        blog.value = data;
        new_blog_categories.value = data.category;
        blog.value.availability = data.availability ? data.availability : 0;

        new_articles.value = data.articles;
        blog.value.category = data.category[0];
        workflow_id.value = data.workflow_id;
        blog_content.value = data.content;
        blog_title.value = data.title;
        blog_changes.value = data.changes;
        image_library.value = data.image_library;
        GPT_Primer(blog_title.value);
        guestlist.value = data.guestlist != null ? data.guestlist : [];
    } catch (error) {
        console.error("Error fetching products data:", error);
    }
};

const GPT_Primer = (blog_title) => {
    chatGptQuestions.value = [
        {
            question: `Erstelle einen Text zum Thema ${blog_title}`,
            button_name: `Text automatsch erstellen`,
        },
        {
            question: `Erstelle einen Text zum Thema ${blog_title} und fasse ihn mit 100 Wörtern zusammen`,
            button_name: "Text-Zusammenfassung (100 Wörter)",
        },
    ];
}

const update_text_content = res => {
    blog.value.text_content = blog.value.text_content + res.chatGptAnswers;
    chatGptDialog.value = false;
}

const close_tab = () => {
    confirm2.require({
        message: 'Sind Sie sicher, dass Sie diesen Artikel schliessen wollen? Alle nicht gespeicherten Änderungen gehen verloren.',
        header: 'Artikel schliessen',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja, Artikel schliessen',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            emits('editorCallback',
                {
                    "action": "close_tab",
                    "redis_key": props.redis_key
                }
            )
        }
    })
}

// const showGallery = (index) => {
//     activeImageIndex.value = index
//     displayGallery.value = true
// }

// const update_template_data = (e) => {
//     if (e.action == "save") {
//         blog_content.value[e.index] = e.template_data
//         toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Inhalt gespeichert', life: 3000 })
//     } else if (e.action == "delete") {
//         blog_content.value.splice(e.index, 1)
//         toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Inhalt gelöscht', life: 3000 })
//     } else if (e.action == "add") {
//         blog_content.value.push(e.template_data)
//         toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Inhalt hinzugefügt', life: 3000 })
//     }
// }

const save_changes = async () => {
    loader.value = true;
    const { value } = blog;

    // const checkTitle = await checkDuplicate(blog.value.title, blog.value.workflow_id, `${channel.value}/${endpoints.checktitle}`)

    // if (checkTitle.msg == true) {
    //     toast.add({ severity: 'error', summary: 'Fehler', detail: 'Der Titel existiert bereits', life: 3000 })
    //     loader.value = false;
    //     return;
    // }

    value["content"] = blog_content.value;
    value["changes"] = blog_changes.value;
    value["image_library"] = image_library.value;
    value["category"] = new_blog_categories.value;
    value["guestlist"] = blog.value.guestlist

    try {
        const response = await axios.post(
            `${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.savesingle}`,
            value
        );

        const { status } = response.data;

        if (status === "success" || status === "titlechanged") {
            toast.add({
                severity: "success",
                summary: `${value.title} gespeichert`,
                detail: "Änderungen wurden erfolgreich gespeichert",
                life: 3000,
            });
            loader.value = false;
        }

    } catch (error) {
        console.error(error);
    }
};

const reset_changes = async () => {
    confirm2.require({
        message: 'Wollen Sie wirklich alle Änderungen verwerfen?',
        header: 'Änderungen verwerfen',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja, Änderungen verwerfen',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
            try {
                const payload = { redis_key: props.redis_key };
                const response = await axios.post(
                    `${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getsingle}`,
                    payload
                );
                blog.value = response.data;
            } catch (error) {
                console.error(error);
            }
        },
    });
};

const onRowReorder = (e) => {
    if (e.dragIndex != e.dropIndex) {
        blog_content.value = e.value
    }
}

const crop_header_image = async (image, image_type) => {

    await uploadSingleImage(image, image_type, blog.value.workflow_id, channel.value, endpoints.uploadsingle, true).then((res) => {
        toast.add({
            severity: "success",
            summary: "Erfolg",
            detail: "Bild erfolgreich hochgeladen",
            life: 3000,
        });

        blog.value.header_image = res.data.image_url;
        edit_header_image.value = false;
    });
}

// const crop_header_image_preview = async (image, image_type) => {

//     await uploadSingleImage(image, image_type, blog.value.workflow_id, channel.value, endpoints.uploadsingle, true).then((res) => {
//         toast.add({
//             severity: "success",
//             summary: "Erfolg",
//             detail: "Bild erfolgreich hochgeladen",
//             life: 3000,
//         });

//         blog.value.header_image_preview = res.data.image_url
//         edit_header_image_preview.value = false
//     });
// }

const UploadImage = (image, image_type) => {
    return new Promise((resolve, reject) => {
        uploadSingleImage(image, image_type, blog.value.workflow_id, channel.value, endpoints.uploadsingle, true).then((res) => {
            resolve(res.data.image_url);
        }).catch(err => {
            reject(err);
        });
    });
}

const cropImage = async (image, image_type, reference) => {
    loader.value = true;
    UploadImage(image, image_type).then(res => {
        toast.add({
            severity: "success",
            summary: "Erfolg",
            detail: "Bild erfolgreich hochgeladen",
            life: 2000,
        });
        [reference].value = res;
        loader.value = false;
    });
}

const libraryUploadGetData = (res) => {
    blog.value['image_library'].push({ image: res });
}

const libraryUploadidFinished = (res) => {
    if (res === true) {
        imageUploadDialog.value = false;
    }
}

const openEditDialog = res => {
    editTitleImageDialog.value = res;
}

const imageUploaded = res => {
    blog.value[res.type] = res.image;
}
const delete_image = res => {
    blog.value[res.type] = null;
    disabled.value = false;
    imageUploadDialog.value = false;
}
//const selectAll = ref(false);
// const onChange = (event) => {
//     selectAll.value = event.value.length === items.value.length;
// }
// const onSelect = (event) => {
//     blog.value.products = event.checked ? items.value.map((item) => item.value) : [];
//     selectAll.value = event.checked;
// };

const shift_col = (direction, index) => {

    if (direction == 'up') {
        if (index != 0) {
            let temp = blog_content.value[index]
            blog_content.value[index] = blog_content.value[index - 1]
            blog_content.value[index - 1] = temp
        }
    } else {
        if (index != blog_content.value.length - 1) {
            let temp = blog_content.value[index]
            blog_content.value[index] = blog_content.value[index + 1]
            blog_content.value[index + 1] = temp
        }
    }

}
const saveGuestlist = (data) => {
    console.log(data);
    guestlist.value = data;
    blog.value.guestlist = data;
}

const openAdressPrewiewDialog = async () => {

    const response = await axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/gerstl/event/adress/preview`, { adress: blog.value.event_adress });

    if(response.data.message.length == 0){
        toast.add({
            severity: "error",
            summary: "Fehler",
            detail: "Adresse konnte nicht gefunden werden",
            life: 3000,
        });
        return;
    }else{
        google_geo.value = {
            adress: response.data.message[0].formatted_address,
            lat: response.data.message[0].geometry.location.lat,
            lng: response.data.message[0].geometry.location.lng
        };

        adressPreviewDialog.value = true;
    }

}
</script>
