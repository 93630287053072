<script setup>
import { ref, onMounted, watch, defineProps, defineEmits } from 'vue'
//import { Cropper, Preview } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
//import axios from 'axios'
import MenuButton from './Components/Menu-Button.vue'
import { useConfirm } from "primevue/useconfirm"
import DataView from './Components/Data-View.vue'
import 'vue-advanced-cropper/dist/style.css'
//import { useCustomerStore } from '@/core/store/CustomerStore'
import ModalButtons from './Components/Modal-Menue.vue'
//import ImageEditor from './Components/Image-Editor.vue'
//import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
import { getRandomId } from '@/core/var/tools'

const confirm = useConfirm()

// local variables
const editDialog = ref(false)
const question = ref(null)
const answer = ref(null)
const BLOCK_NAME = "faq_block";
const NAME = 'FAQ';


const props = defineProps({
    template_type: { type: String, required: true },
    index: { type: Number, required: false },
    data: { type: Object, required: true },
    image_library: { type: Object, required: true },
    workflow_id: { type: String, required: true }
})

const template = ref(BLOCK_NAME)
const edit = ref(false)
const faqs = ref(props.data.faqs)
const disabled = ref(true)


const emits = defineEmits(['blockCallback'])

const toggleDialog = () => {
    editDialog.value = false
}


const edit_block = () => {
    editDialog.value = true
    edit.value = false
    faqs.value = props.data.faqs
}


const isValidValue = (val) => ![null, false, ''].includes(val);
watch([question, answer], ([newQuestionValue, newAnswerValue]) => {
    disabled.value = !(isValidValue(newQuestionValue) && isValidValue(newAnswerValue));
});


const save_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "template": template.value,
                "faqs": faqs.value
            },
            "action": "save",
            "index": props.index
        }
    )
    editDialog.value = false
    question.value = ''
    answer.value = ''
}

const delete_block = () => {
    emits('blockCallback',
        {
            "action": "delete",
            "index": props.index
        }
    )
    editDialog.value = false
}

const add_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "template": template.value,
                "faqs": []
            },
            "action": "add",
            "index": props.index
        }
    )
}

const on_row_reorder = (e) => {
    faqs.value = e.value
}

const delete_row = (e) => {
    confirm.require({
        message: 'Wollen Sie den Datensatz wirklich löschen?',
        header: 'Löschen',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, löschen',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            faqs.value.splice(e.index, 1)
        }
    })
}

const add_faq = () => {
    const workload = { workflow_id: getRandomId(5), question: question.value, answer: answer.value }
    faqs.value.push(workload)
}


const onCellEditComplete = (event) => {
    let { data, newValue, field } = event;
    data[field] = newValue;
};


</script>

<template>
    <!--------------------------------------------------->
    <!------------------ Menu View ---------------------->
    <!--------------------------------------------------->
    <MenuButton v-if="props.template_type == 'menu'" :add_block="add_block" button="pi-question-circle" :name="NAME" />
    <!--------------------------------------------------->
    <!------------------ Data View ---------------------->
    <!--------------------------------------------------->
    <DataView v-if="props.template_type == 'data'" :edit_block="edit_block" :name="NAME">

        <div class="px-4 sm:px-6 lg:px-8 w-full max-w-3xl">
            <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle">
                        <table class="min-w-full border-separate border-spacing-0">
                            <thead>
                                <tr>
                                    <th scope="col" class="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-bold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">Frage</th>
                                    <th scope="col" class="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter hidden lg:table-cell">Antwort</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(faq, faqIdx) in faqs" :key="faq.workflow_id">
                                    <td :class="[faqIdx !== faqs.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-pre-wrap hidden px-3 py-4 text-base md:text-sm text-gray-500 sm:table-cell font-semibold']">
                                        {{ faq.question }}
                                        <dl class="font-normal lg:hidden">
                                            <dt class="sr-only">Antwort</dt>
                                            <dd class="mt-1 truncate text-gray-700 whitespace-pre-wrap">{{ faq.answer }}</dd>
                                        </dl>
                                    </td>
                                    <td :class="[faqIdx !== faqs.length - 1 ? 'border-b border-gray-200' : '', ' whitespace-pre-wrap px-3 py-4 text-sm text-gray-500 hidden lg:table-cell']">{{ faq.answer }}</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    </DataView>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->

    <!--------------------------------------------------->
    <!------------------- Edit  -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editDialog" :style="{ width: '80%' }" header="Block bearbeiten" :modal="true" class="">

        <div>
            <ModalButtons :saveBlock="save_block" :deleteBlock="delete_block" :toggleDialog="toggleDialog" />
        </div>

        <div class="w-full">
            <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="question">
                        Frage:
                    </label>
                    <InputText v-model="question" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="question" type="text" placeholder="Ihre Frage eingeben" />
                </div>

                <div class="mb-6">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="answer">
                        Antwort:
                    </label>
                    <Textarea v-model="answer" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-24" id="answer" placeholder="Die Antwort eingeben"></Textarea>
                </div>

                <div class="flex items-center justify-between">
                    <Button v-tooltip.bottom="'Produkt hinzufügen'" label="Hinzufügen" class="p-button-success" icon="pi pi-plus" @click="add_faq" :disabled="disabled" />
                </div>
            </div>
        </div>



        <div class="mt-1">
            <DataTable :value="faqs" :rows="20" responsiveLayout="scroll" :rowHover="true" @rowReorder="on_row_reorder" editMode="cell" @cell-edit-complete="onCellEditComplete">
                <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                <Column field="question" header="FAQ-Frage">
                    <template #body="{ data }">
                        <span class="">{{ data.question }}</span>
                    </template>
                    <template #editor="{ data, field }">
                        <Textarea v-model="data[field]" rows="5" cols="30" class="w-full" />
                    </template>
                </Column>
                <Column field="answer" header="Antwort">
                    <template #body="{ data }">
                        {{ data.answer }}
                    </template>
                    <template #editor="{ data, field }">
                        <Textarea v-model="data[field]" rows="5" cols="30" class="w-full" />
                    </template>
                </Column>
                <Column>
                    <template #body="slotProps">
                        <Button @click="delete_row(slotProps)" v-tooltip.top="'Löschen'" class="p-button-rounded p-button-text" icon="pi pi-trash" />
                    </template>
                </Column>
            </DataTable>
        </div>
    </Dialog>
</template>