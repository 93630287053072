<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"

const toast = useToast()
const confirm = useConfirm()
// filters
const filter_library = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
const filter_history = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
const loader = ref(false)

const recipe = ref()
const library = ref()
const editRecipeDialog = ref(false)

const props = defineProps({
    redis_key: { type: String, required: true }
})

const component_callback = (e) => {
    get_library(props.redis_key)
}

onMounted( async () => {
    loader.value = true
    await get_library(props.redis_key)
    loader.value = false
})

const get_library = (redis_key) => {
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/get-library", {"redis_key": redis_key})
        .then((response) => {
            library.value = response.data
            resolve()
        })
        .catch((error) => {
            toast.add({severity:'error', summary: 'Fehler', detail: 'Fehler beim Laden der Bibliotheken'})
            reject()
        })
    })
}

const update_library = () => {
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/update-library", {"redis_key": props.redis_key, "library": library.value})
        .then((response) => {
            library.value = response.data
            toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Bibliothek aktualisiert', life: 3000})
            resolve()
        })
        .catch((error) => {
            toast.add({severity:'error', summary: 'Fehler', detail: 'Fehler beim Aktualisieren der Bibliothek'})
            reject()
        })
    })
}

const new_recipe = () => {
    recipe.value = {
        name: "",
        recipe: "Beispiel Frage an GPT mit Platzhalter {0}, {1}, {2}",
        parameters: ["Parameter1", "Parameter2", "Parameter3"]
    }
    editRecipeDialog.value = true
}

const edit_recipe = (e) => {
    recipe.value = e.data
    recipe.value.index = e.index
    editRecipeDialog.value = true
}

const save_recipe = () => {
    if (recipe.value.index != undefined){
        library.value[recipe.value.index] = recipe.value
    } else {
        library.value.push(recipe.value)
    }
    update_library()
    editRecipeDialog.value = false
}

const on_pattern_row_edit = (e) => {
    console.log(e)
    if (recipe.value.patterns[e.index]['gpt'] != e.newData['gpt']){
        recipe.value.patterns[e.index]['gpt'] = e.newData['gpt']
    }
    if (recipe.value.patterns[e.index][e.field] != e.newValue){
        recipe.value.patterns[e.index][e.field] = e.newValue
    }
}

const on_rule_row_edit = (e) => {
    if (recipe.value.replace_rules[e.index][e.field] != e.newValue){
        recipe.value.replace_rules[e.index][e.field] = e.newValue
    }
}

const copy_to_clipboard = (value) => {
    navigator.clipboard.writeText(value)
    toast.add({severity:'success', summary: 'Erfolgreich', detail: 'In Zwischenablage kopiert', life: 3000})
}

const format_swiss_date_time = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4) + ' ' + value.substring(11,16)
}

const on_row_reorder = (e) => {
    library.value = e.value
    update_library()
}

const on_row_edit = (e) => {
    if (library.value[e.index][e.field] != e.newValue){
        library.value[e.index][e.field] = e.newValue
        update_library()
    }
}

const delete_recipe = (e) => {
    confirm.require({
        message: 'Wollen Sie wirklich das Schema '+e.data.name+' löschen?',
        header: 'Schema löschen',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Schema löschen',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            library.value.splice(e.index, 1)
            update_library()
        }
    })
}

const delete_rule = (e) => {
    confirm.require({
        message: 'Wollen Sie wirklich die Regel '+e.data.find+' löschen?',
        header: 'Regel löschen',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Regel löschen',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            recipe.value.replace_rules.splice(e.index, 1)
            update_library()
        }
    })
}

const add_rule = () => {
    recipe.value.replace_rules.push({find: "", replace: ""})
}

const exerpt_string = (str) => {
    return str.length > 150 ? str.substring(0, 150) + '...' : str
}

const test_parameters = ref([])
const format_pattern = (str, arr) => arr.length > 1 
	? format_pattern(str.replace('{}', arr[0]), arr.slice(1)) 
	: (arr[0] && str.replace('{}', arr[0])) || str

//replace handlebars with array values
const replace_handlebars = (str, arr, parameter_names, replace_rules) => {
    let new_str = str
    for (let i = 0; i < arr.length; i++) {
        new_str = new_str.replace("{" + parameter_names[i] + "}", arr[i])
    }
    for (let i = 0; i < replace_rules.length; i++) {
        new_str = new_str.replace(replace_rules[i].find, replace_rules[i].replace)
    }
    //highlight matching values
    new_str = new_str.replace(new RegExp(arr.join("|"), "gi"), function(matched){
        return "<span class='font-bold text-green-500'>" + matched + "</span>"
    })
    //highlight handlebars
    new_str = new_str.replace(new RegExp("{.*?}", "gi"), function(matched){
        return "<span class='font-bold text-yellow-500'>" + matched + "</span>"
    })
    return new_str
}

const replace_handlebars_for_gpt = (str, arr, parameter_names) => {
    let new_str = str
    for (let i = 0; i < arr.length; i++) {
        new_str = new_str.replace("{" + parameter_names[i] + "}", arr[i])
    }
    return new_str
}

const generate_gpt = () => {
    for (let i = 0; i < recipe.value["patterns"].length; i++) {
        let gpt_input = recipe.value["patterns"][i]["gpt"].replace("{pattern}", recipe.value["patterns"][i]["pattern"])
        gpt_input = replace_handlebars_for_gpt(gpt_input, test_parameters.value, recipe.value["parameters"])
        gpt_input = replace_handlebars_for_gpt(gpt_input, test_parameters.value, recipe.value["parameters"])
        console.log(gpt_input)
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/chatgpt4-question", {"question": gpt_input})
        .then((response) => {
            recipe.value["patterns"][i]["gpt_output"] = response.data.message
        })
    }
}

</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <Toast />
    <ConfirmDialog />
    <div class="col-12 formgrid grid">
        <div class="col-12 mb-2">
            <Toolbar>
                <template #start>
                    <span class="p-input-icon-left mr-2">
                        <i class="pi pi-search" />
                        <InputText v-model="filter_library['global'].value" placeholder="Suche" style="width: 300px"  />
                    </span>
                    <Button icon="pi pi-refresh" @click="get_library(props.redis_key)" class="mr-2 bg-primary text-white" v-tooltip.top="'Liste aktualisieren'"></Button>
                </template>
                <template #end>
                    <Button icon="pi pi-plus" @click="new_recipe()" class="mr-2 bg-green-600 text-white border-green-700" label="Schema erstellen"></Button>
                    <Button icon="pi pi-cloud-upload" @click="publish_recipe()" class="mr-2 bg-orange-600 text-white border-orange-700" label="Schema publizieren"></Button>
                    <Button icon="pi pi-history" @click="version_history()" class="mr-2" label="Versionen"></Button>
                </template>
            </Toolbar>
        </div>
        <div class="col-12 mb-2">
            <DataTable v-model:filters="filter_library" :value="library" :rows="50" editMode="cell" @cell-edit-complete="on_row_edit" @rowReorder="on_row_reorder"  responsiveLayout="scroll" class="w-full" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                <Column field="name" header="Name" sortable>
                    <template #editor="{ data, field }">
                        <InputText v-model="data[field]" class="w-full" />
                    </template>
                    <template #body="{ data, field }">
                        <Avatar icon="pi pi-google" class="mr-2 bg-primary text-white" shape="circle" />
                        <span class="font-bold mr-2">{{data[field]}}</span>
                        (Level: {{ data["level"] + 1 }})
                        <Avatar :label="String(data['patterns'].length)" shape="circle" />
                    </template>
                </Column>
                <Column>
                    <template #body="slotProps">
                        <Button @click="edit_recipe(slotProps)" v-tooltip.top="'Rezept editieren'" label="Bearbeiten" icon="pi pi-pencil" class="mr-2" />
                        <Button @click="delete_recipe(slotProps)" v-tooltip.top="'Rezept löschen'" class="bg-red-600 text-white border-red-700" rounded icon="pi pi-trash" />
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
    <!---------------------------------------------->
    <!--------------- Edit Schema ------------------>
    <!---------------------------------------------->
    <Dialog v-model:visible="editRecipeDialog" :style="{width: '1200px'}" header="Schema editieren" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="name" v-model="recipe.name" class="w-full" />
                    <label for="name">Namen</label>
                </span>
                <small class="text-gray-500">Der Name identifiziert das Schema</small>
            </div>
            <div class="field col-12 mt-2">
                <h2 class="font-bold">Schema Muster</h2>
                <small class="text-gray-500">Diese Muster werden auf die verschiedenen Inhaltsseiten angewendet. Die Muster können mit den verfügbaren Seitenvariablen parametrisiert werden.</small>
            </div>
            <div class="field col-12">
                <DataTable :value="recipe.patterns" editMode="cell" @cell-edit-complete="on_pattern_row_edit" responsiveLayout="scroll" class="w-full" :rowHover="true">
                    <Column field="pattern" header="SEO Schema">
                        <template #editor="{ data, field }">
                            <label class="font-bold">{{data["field"]}}:</label>
                            <div><InputText v-model="data[field]" class="w-full" /></div>
                            <div class="mt-1"><InputText v-model="data['gpt']" class="w-full" /></div>
                        </template>
                        <template #body="{ data, field }">
                            <label class="font-bold">{{data["field"]}}:</label>
                            <div class="bg-gray-900 text-white border-round p-3 mt-1" v-html="replace_handlebars(data[field], test_parameters, recipe.parameters, recipe.replace_rules)"></div>
                            <div class="bg-blue-900 text-white border-round p-3 mt-1" v-html="replace_handlebars(data['gpt'], test_parameters, recipe.parameters, recipe.replace_rules)"></div>
                            <div v-if="data['gpt_output']" class="bg-yellow-800 text-black-alpha-90 border-round p-3 mt-1 font-bold">{{ data['gpt_output'] }}</div>
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="field col-12 mt-2">
                <h2 class="font-bold">Schema testen</h2>
                <small class="text-gray-500">Geben Sie Werte in die Felder ein, um das Schema zu testen.</small>
            </div>
            <template v-for="key, index in recipe.parameters" :key="key">
                <div class="field col-4 mt-1">
                    <label class="font-bold" :for="key">{{key}}</label>
                    <InputText :id="key" v-model="test_parameters[index]" class="w-full mt-1" />
                </div>
            </template>
            <div class="field col-12 mt-1">
                <Button @click="generate_gpt()" v-tooltip.top="'GPT Rezepte generieren'" label="GPT-Rezepte generieren" icon="pi pi-android" class="mr-2 w-auto bg-blue-900 text-white border-blue-900" />
            </div>
            <div class="field col-12 mt-3">
                <h2 class="font-bold">Ausnahme Regeln</h2>
                <small class="text-gray-500">Diese Regeln ersetzen Werte in den Mustern, um Ausnahmen zu behandeln</small>
            </div>
            <div class="field col-12">
                <DataTable :value="recipe.replace_rules" editMode="cell" @cell-edit-complete="on_rule_row_edit" responsiveLayout="scroll" class="w-full" :rowHover="true">
                    <Column field="find" header="Such Wert">
                        <template #editor="{ data, field }">
                            <InputText v-model="data[field]" class="w-full" />
                        </template>
                        <template #body="{ data, field }">
                            <span>{{ data[field] }}</span>
                        </template>
                    </Column>
                    <Column field="replace" header="Ersetzen mit">
                        <template #editor="{ data, field }">
                            <InputText v-model="data[field]" class="w-full" />
                        </template>
                        <template #body="{ data, field }">
                            <span>{{ data[field] }}</span>
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <Button @click="delete_rule(slotProps)" v-tooltip.top="'Regel löschen'" rounded class="bg-red-600 text-white border-red-700" icon="pi pi-trash" />
                        </template>
                    </Column>
                </DataTable>
                <Button @click="add_rule" v-tooltip.top="'Regel hinzufügen'" label="Regel hinzufügen" icon="pi pi-plus" class="mt-2 w-auto" />
            </div>
            <div class="field col-12 mt-3">
                <Button @click="save_recipe()" label="Schema speichern" icon="pi pi-save" class="mr-2 bg-green-600 text-white border-green-700 w-auto" />
                <Button @click="editRecipeDialog = false" label="Abbrechen" icon="pi pi-times" class="mr-2 w-auto" />
            </div>
        </div>
    </Dialog>
</template>