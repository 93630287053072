<script setup>
import { ref, onMounted, watch, defineProps, defineEmits } from 'vue'
import { Cropper, Preview } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import axios from 'axios'

// local variables
const editImageDialog = ref(false)
// define props
const props = defineProps({
    template_type: { type: String, required: true },
    index: { type: Number, required: false },
    data: { type: Object, required: true },
    image_library: { type: Object, required: true},
    workflow_id: { type: String, required: true}
})
// define emits
const emits = defineEmits(['blockCallback'])

// fields
const image_caption = ref(props.data.image_caption)
const template = ref("standard_image")
const image = ref(props.data.image)
const croppy = ref(null)
const random_number = ref(String(Math.floor(Math.random() * 100000000)))

const edit = ref(false)

// functions
const edit_block = () => {
    editImageDialog.value = true
    edit.value = false
    image.value = props.data.image
}

const save_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "image_caption": image_caption.value,
                "template": template.value,
                "image": image.value
            },
            "action": "save",
            "index": props.index
        }
    )
    editImageDialog.value = false
}

const delete_block = () => {
    emits('blockCallback',
        {
            "action": "delete",
            "index": props.index
        }
    )
    editImageDialog.value = false
}

const add_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "image_caption": image_caption.value,
                "template": template.value,
                "image": image.value
            },
            "action": "add",
            "index": props.index
        }
    )
}

const crop_image = () => {
    //image.value = croppy.value.getResult().canvas.toDataURL()
    upload_image()
    edit.value = false
}

const upload_image = async () => {
    let { canvas } = croppy.value.getResult()
    await canvas.toBlob((blob) => {
        let reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = async () => {
            let payload = {"file": reader.result, "image_type": String(props.index) + "_" + random_number.value, "workflow_id": props.workflow_id }
            await axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/upload-image", payload)
            .then(response => {
                image.value = response.data.image_url
            })
        }
    })
}

</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <!--------------------------------------------------->
    <!------------------ Menu View ---------------------->
    <!--------------------------------------------------->
    <div v-if="props.template_type == 'menu'" class="card shadow-2 border-round-md py-3 px-3 mt-2">
        <div class="flex flex-row flex-wrap card-container">
            <div class="flex align-items-center justify-content-center mr-3">
                <Button v-tooltip.bottom="'Block hinzufügen'" class="p-button-success" icon="pi pi-plus" @click="add_block()" />
            </div>
            <div class="flex align-items-center justify-content-center mr-3 w-5rem border-gray-400 border-solid border-round-md border-1">
                <i class="pi pi-image" style="color:#2196F3;"></i>
            </div>
            <div class="flex align-items-center justify-content-center font-bold">
                Standard Bild
            </div>
        </div>
    </div>
    <!--------------------------------------------------->
    <!------------------ Data View ---------------------->
    <!--------------------------------------------------->
    <div v-if="props.template_type == 'data'" class="shadow-2 border-round-md px-3 py-1" @click="edit_block()" v-bind:style="{cursor: 'pointer'}">
        <div class="mt-3">
            <i class="pi pi-box mr-2 ml-1"></i>
            <span class="mt-1 font-bold" style="font-size: 1em; color: #9e9e9e">Standard Bild</span>
        </div>
        <Divider />
        <img :src="props.data.image" class="w-full" />
        <div class="text-center p-2">{{ props.data.image_caption }}</div>
    </div>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->

    <!--------------------------------------------------->
    <!------------------- Edit Image -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editImageDialog" :style="{width: '80%'}" header="Block bearbeiten" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-1">
                <Button v-if="edit == false" label="Speichern" @click="save_block()" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                <Button label="Abbrechen" @click="editImageDialog = false" class="w-auto mr-3 mb-1 p-button-danger" icon="pi pi-times" />
                <Button label="Löschen" @click="delete_block()" class="w-auto mr-3 mb-1 p-button-danger" icon="pi pi-trash" />
            </div>
            <div v-if="edit == false" class="field col-12 mt-3">
                <img :src="image" class="shadow-2" style="width: 100%; height: 100%; object-fit: cover;" />
            </div>
            <Button v-if="edit == false" label="Bild Bearbeiten" @click="edit = true" class="w-auto mr-3 mb-1 p-button" icon="pi pi-pencil" />
            <div v-if="edit == true" class="field col-12 mt-3">
                <Button label="Bild Speichern" @click="crop_image()" class="w-auto mr-3 mb-1 p-success" icon="pi pi-save" />
            </div>
            <div v-if="edit == true" class="field col-12 mt-3">
                <cropper
                    ref="croppy"
                    :src="image"
                    :stencil-props="{aspectRatio: 1.7 / 1}"
                    :auto-zoom="true"
                    :auto-center="true"
                />
            </div>
            <div v-if="edit == true" class="field col-12 mt-3">
                <div v-if="image_library" class="flex">
                    <template v-for="(img, index) of image_library" :key="index">
                        <img @click="image = img.url" :src="img.url" class="shadow-2 m-2" style="cursor: pointer; height: 100px; width: 100px; object-fit: cover; display:inline;" />
                    </template>
                </div>
            </div>
            <div class="field col-12 mt-3">
                <label for="image_caption">Bildunterschrift</label>
                <Textarea v-model="image_caption" id="image_caption" type="text" :autoResize="true" />
            </div>
        </div>
    </Dialog>
</template>