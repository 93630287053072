<template>
    <Toast />
    <ConfirmDialog />

    <Toolbar>

        <template #start>
            <div class="inline sm:hidden">
                <div class="">
                    <SplitButton label="Neu" icon="pi pi-plus" @click="setNew" :model="items" />
                </div>
            </div>
            <div class="hidden md:flex">
                <div v-if="changes > 0"><Button v-tooltip.bottom="'Änderungen zurücksetzen'" @click="reset_changes" class="mr-2 p-button-danger" icon="pi pi-refresh" /></div>
                <div v-if="changes > 0"> <Button @click="save_changes" v-tooltip.bottom="'Änderungen speichern'" :label="save_text" class="w-auto  p-button-success mx-2 mr-3" icon="pi pi-save" /></div>
                <div v-if="changes == 0"> <Button @click="setNew" label="Erstellen" class="w-auto mr-2 p-button" v-tooltip.top="'Ein neuer Datensatz erfassen'" icon="pi pi-plus" /></div>
            </div>
        </template>


    </Toolbar>
    <Toolbar class="mt-2">
        <template #start>
            <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filter_dataset['name'].value" placeholder="Suche" class="mr-2 w-52" />
            </span>
            <Dropdown v-model="filter_dataset['status'].value" :options="status" optionLabel="label" optionValue="value" placeholder="Status" class="p-column-filter ml-2 w-36" showClear />

            <Dropdown v-model="filter_dataset['category'].value" filter  :options="categories" optionLabel="name" optionValue="value" placeholder="Kategorie" class="p-column-filter ml-2 w-44" showClear />
        </template>
    </Toolbar>
    <!--------------------------------------------------->
    <!------------------ DataTable ---------------------->
    <!--------------------------------------------------->
    <DataTable class="mt-3" v-model:filters="filter_dataset" :value="dataset" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20, 50, 100]" @rowReorder="onRowReorder" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}"
        @row-dblclick="handleRowDoubleClick">
        <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
        <Column field="status" header="Status">
            <template #body="{ data, field }">
                <span class="inline-flex items-center rounded-full bg-yellow-200 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/10" v-if="data[field] == 'draft'" :label="get_status(data[field])" v-tooltip.top="'Beitrag ist Publiziert'">{{ get_status(data[field]) }}</span>

                <span class="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-900 ring-1 ring-inset ring-green-600/20" v-if="data[field] == 'published'" v-tooltip.top="'Beitrag ist Publiziert'">{{ get_status(data[field]) }}</span>
            </template>
        </Column>
        <Column field="name" header="Name">
            <template #body="{ data }">
                <div class="truncate max-w-xs" v-tooltip.top="data['name']">{{ data['name'] }} </div>
            </template>
        </Column>
        <Column field="source" header="Source">
            <template #body="{ data }">
                <span v-if="data['source']" class=" text-sm">gerstl.ch/<span class="font-semibold">{{ `${data['source']}` }}</span></span>
                <span v-else>-</span>
            </template>
        </Column>
        <Column field="target" header="Target">
            <template #body="{ data }">
                <div v-if="data['target']" class=" text-sm truncate max-w-xs" v-tooltip.top="data['target']">
                    <a :href="`${data['target']}`" target="_blank">{{ `${data['target']}` }}</a>
                </div>
                <span v-else>-</span>
            </template>
        </Column>
        <Column field="category" header="Kategorie">
            <template #body="{ data }">
                {{ getCategoryName(data.category) }}
            </template>
        </Column>
        <Column>
            <template #body="slotProps">
                <Button @click="handleEditClick(slotProps)" class="p-button-rounded p-button-text" v-tooltip.top="'Datensatz bearbeiten'" icon="pi pi-pencil" />
                <Button @click="delete_dataset(slotProps.data.workflow_id)" class="p-button-rounded p-button-text" v-tooltip.top="'Datensatz löschen'" icon="pi pi-trash" />
            </template>
        </Column>
    </DataTable>


    <div class="flex items-center justify-center m-16" v-show="loader">
        <LoadingSpinner size="12"></LoadingSpinner>
    </div>


    <!------------------- Dialogs ----------------------->
    <!-- Shortlink Edit Dialog -->
    <Dialog v-model:visible="editDialog" header="Shortlink bearbeiten" :modal="true" class="w-auto md:w-3/4">
        <div class="w-full mx-auto">
            <div class="flex flex-wrap">
                <!-- Status Field -->
                <div class="w-full px-3 mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="status">Status</label>
                    <Dropdown v-model="data_status" id="status" :options="status" optionLabel="label" optionValue="value" class="w-full" />
                </div>

                <!-- Title Field -->
                <div class="w-full px-3 mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="title">Titel</label>
                    <InputText v-model="data_name" id="title" class="w-full" />
                </div>

                <!-- Source Field -->
                <div class="w-full px-3 mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="source">Source</label>
                    <div class="flex">
                        <span class="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md">
                            gerstl.ch/
                        </span>
                        <InputText v-model="data_source" id="source" class="flex-1 block w-full rounded-none rounded-r-md" :disabled="true" />
                    </div>
                </div>

                <!-- Target Field -->
                <div class="w-full px-3 mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="target">Target</label>
                    <InputText v-model="data_target" id="target" class="w-full" />
                </div>

                <!-- Category Dropdown -->
                <div class="w-full px-3 mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="category">Kategorie</label>
                    <Dropdown v-model="data_category" :options="categories" id="category" optionLabel="name" optionValue="value" class="w-full" />
                </div>

                <!-- Buttons -->
                <div class="flex items-center justify-between mt-4">
                    <Button label="Speichern" :disabled="!formValid_big" @click="save_dataset(data_index), editDialog = false" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                    <Button label="Abbrechen" @click="editDialog = false" class="w-auto mr-3 mb-1 p-button-danger" icon="pi pi-times" />
                </div>

            </div>
        </div>
    </Dialog>

    <!------------------ New ---------------------->
    <Dialog v-model:visible="newDialog" header="Shortlink hinzufügen" :modal="true" class=" w-full mx-3 md:w-3/4">

        <div class="w-full mx-auto">
            <div class="flex flex-wrap">

                <!-- Column 1 -->
                <div class="w-full px-3">

                    <!-- Name -->
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="name">Name *</label>
                        <InputText v-model="data_name" id="name" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight " required />
                    </div>

                    <!-- Source -->
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="source">Source</label>
                        <InputText v-model="data_source" id="source" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight" />
                    </div>
                </div>
            </div>

            <!-- Buttons -->
            <div class="flex items-center justify-between mt-4">
                <Button :disabled="!formValid_entry" label="Speichern" @click="new_dataset()" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                <Button label="Abbrechen" @click="newDialog = false" class="w-auto mr-3 mb-1 p-button-danger" icon="pi pi-times" />
            </div>
        </div>



    </Dialog>
</template>


<script setup>
import { ref, onMounted, watch, inject, computed } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import Simplestore from '@/core/var/Simplestore';
import LoadingSpinner from '@/core/components/UI/Loading-Spinner.vue';
import { FilterMatchMode } from 'primevue/api'

import { useCustomerStore } from '@/core/store/CustomerStore'
import 'vue-advanced-cropper/dist/style.css';
import { slugify, getRandomId } from '@/core/var/tools'

// Data

const data_code = ref('');
const data_rabattTyp = ref('CHF');

const data_endDate = ref(null);

const toast = useToast()
const SERVICE_URL = inject('SERVICE_URL');
const dataset = ref([])
const data_name = ref(null)
const data_status = ref(null)
const data_index = ref();

const data_workflow_id = ref(null)

const changes = ref(0)
const save_text = ref("Speichern")
const editDialog = ref(false)
const newDialog = ref(false)
const force_save = ref(false)
const loader = ref(false)
const disabled = ref()
const data_sageGroupExcluded = ref([])
const data_source = ref('');
const data_target = ref('https://foo.bar/baz');
const data_category = ref('');


const formValid_entry = computed(() => data_name.value && data_name.value.length > 2 && data_source.value.length > 1);
const formValid_big = computed(() => {
    let res = data_name.value && data_name.value.length > 2 && data_category.value && data_source.value && data_target.value
    return res
});

const status = ref([
    { label: 'Aktiv', value: 'published' },
    { label: 'Inaktiv', value: 'draft' }
])

const filter_dataset = ref({ 'global': { value: null, matchMode: FilterMatchMode.CONTAINS } })
filter_dataset.value['name'] = { value: null, matchMode: FilterMatchMode.CONTAINS };
filter_dataset.value['status'] = { value: null, matchMode: FilterMatchMode.EQUALS };
filter_dataset.value['category'] = { value: null, matchMode: 'equals' };

const slug = ref(useCustomerStore().getCustomer.customer);

const module = 'shortlinks';

const endpoints = {
    get: `${module}/get`,
    getImagelib: `${module}/get-image-library`,
    uploadImage: `${module}/upload-image`,
    uploadLibImage: `${module}/upload-library-image`,
    getsystem: "get-system",
    getcategories: `${module}/categories/get`,
    gettags: `${module}/tags`,
    getusers: "get-users",
    save: `${module}/save`
}
const categories = ref([]);

onMounted(async () => {
    loader.value = true;
    await bootstrap();
    loader.value = false;
})


const bootstrap = async () => {
    try {
        const [dataResponse, categoriesResponse] = await Promise.all([
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/${endpoints.get}`),
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/${endpoints.getcategories}`),
        ])
        dataset.value = dataResponse.data;
        categories.value = categoriesResponse.data
    } catch (error) {
        console.error(error);
    }
}


const items = ref([
    {
        label: save_text,
        icon: 'pi pi-refresh',
        disabled: changes.value > 0 ? false : true,
        command: () => {
            save_changes();
        }
    },
    {
        label: 'Zurücksetzen',
        icon: 'pi pi-times',
        disabled: changes.value != 0 ? false : true,
        command: () => {
            reset_changes();
        }
    },
]);

watch(changes, res => {
    items.value[0].disabled = res > 0 ? false : true;
    items.value[1].disabled = res != 0 ? false : true;
});

watch(loader, res => {
    Simplestore.spinner = res;
    disabled.value = res;
});


const reset_changes = async () => {
    try {
        const response = await axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/${endpoints.get}`);
        dataset.value = response.data;
        changes.value = 0;
        save_text.value = "Speichern";
        force_save.value = false;
    } catch (error) {
        console.error(error);
    }
};

const onTeamRowReorder = (e) => {
    dataset.value = e.value
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
    force_save.value = true
    toast.add({ severity: 'success', summary: 'Reihenfolge geändert', detail: 'Die Reihenfolge wurde erfolgreich geändert', life: 3000 })
}

const get_status = (status_value) => {
    if (status_value == "published") {
        return "Aktiv"
    } else if (status_value == "draft") {
        return "Inaktiv"
    }
}

const setNew = () => {
    newDialog.value = true;
    data_name.value = ''
    data_source.value = ''
}

const save_dataset = async (index) => {

    const updatedShortlink = {
        workflow_id: data_workflow_id.value,
        status: data_status.value,
        name: data_name.value,
        source: data_source.value,
        target: data_target.value,
        category: data_category.value,

    };

    if (index >= 0 && index < dataset.value.length) {
        dataset.value[index] = updatedShortlink;
    } else {
        dataset.value.push(updatedShortlink);
    }

    incrementSaves();
}

const new_dataset = async () => {


    const res = await checkSource(slugify(data_source.value));

    if (res === false) {

        const slug = slugify(data_name.value);

        const append = {
            "workflow_id": getRandomId(6),
            "slug": slug,
            "name": data_name.value,
            "source": slugify(data_source.value),
            "status": "draft"
        }

        dataset.value.unshift(append)
        force_save.value = true
        incrementSaves();
        newDialog.value = false
    } else {
        toast.add({ severity: 'error', summary: 'Source', detail: 'Source bereits in Verwendung', life: 5000 });
    }
}

const checkSource = async source => {

    try {
        const payload = {
            source: source
        };

        const response = await axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/shortlinks/check-source`, payload);

        return response.data;

    } catch (error) {
        console.error('Error checking duplicate:', error);
        throw error;
    }
}

const delete_dataset = (id) => {
    const newt = dataset.value.filter(dataset => dataset.workflow_id !== id);
    dataset.value = newt;
    incrementSaves();
}

const incrementSaves = () => {
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
}

const save_changes = async () => {
    try {
        await axios.post(
            `${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/${endpoints.save}`,
            dataset.value);

        toast.add({
            severity: "success",
            summary: "Änderungen gespeichert",
            detail: "Die Änderungen wurden erfolgreich gespeichert",
            life: 3000
        });

        changes.value = 0;
        save_text.value = "Speichern";
        force_save.value = false;

    } catch (error) {
        console.error(error);
    }
};


function handleEditClick(slotProps) {
    editDialog.value = true;
    data_name.value = slotProps.data.name;
    data_code.value = slotProps.data.code;
    data_rabattTyp.value = slotProps.data.type;
    data_index.value = slotProps.index;
    data_status.value = slotProps.data.status;
    data_sageGroupExcluded.value = slotProps.data.sage_group_excluded;
    data_endDate.value = slotProps.data.end;
    data_workflow_id.value = slotProps.data.workflow_id;
    data_source.value = slotProps.data.source;
    data_target.value = slotProps.data.target;
    data_category.value = slotProps.data.category;
}

function handleRowDoubleClick(event) {
    handleEditClick(event);
}

const onRowReorder = (event) => {

};
function getCategoryName(value) {
    const category = categories.value.find((cat) => cat.value === value);
    return category ? category.name : 'Unkategorisiert';
}
</script>
