<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"

const toast = useToast()
const confirm = useConfirm()
// filters
const filter_library = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
const filter_history = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
const loader = ref(false)

const library = ref()
const editRecipeDialog = ref(false)
const runRecipeDialog = ref(false)
const testGPTDialog = ref(false)
const historyDialog = ref(false)
const historyQuestionDialog = ref(false)
const historyAnswerDialog = ref(false)
const gpt_loading = ref(false)

const recipe = ref({})
const gpt_answer = ref()
const gpt_question = ref()
const gpt_parameters = ref([])
const history = ref([])
const history_question = ref()
const history_answer = ref()

const props = defineProps({
    redis_key: { type: String, required: true }
})

const component_callback = (e) => {
    get_library(props.redis_key)
}

onMounted( async () => {
    loader.value = true
    await get_library(props.redis_key)
    loader.value = false
})

const get_library = (redis_key) => {
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/get-library", {"redis_key": redis_key})
        .then((response) => {
            library.value = response.data
            console.log(library.value)
            resolve()
        })
        .catch((error) => {
            toast.add({severity:'error', summary: 'Fehler', detail: 'Fehler beim Laden der Bibliotheken'})
            reject()
        })
    })
}

const update_library = () => {
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/update-library", {"redis_key": props.redis_key, "library": library.value})
        .then((response) => {
            library.value = response.data
            toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Bibliothek aktualisiert', life: 3000})
            resolve()
        })
        .catch((error) => {
            toast.add({severity:'error', summary: 'Fehler', detail: 'Fehler beim Aktualisieren der Bibliothek'})
            reject()
        })
    })
}

const send_recipe = () => {
    gpt_loading.value = true
    let question = format_question(gpt_question.value, gpt_parameters.value)
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/chatgpt-question", {"question": question})
    .then((response) => {
        gpt_answer.value = response.data.message
        gpt_loading.value = false
    })
    .catch((error) => {
        toast.add({severity:'error', summary: 'Fehler', detail: 'Fehler beim Senden der Frage an GPT'})
        gpt_loading.value = false
    })
}

const new_recipe = () => {
    recipe.value = {
        name: "",
        recipe: "Beispiel Frage an GPT mit Platzhalter {0}, {1}, {2}",
        parameters: ["Parameter1", "Parameter2", "Parameter3"]
    }
    editRecipeDialog.value = true
}

const edit_recipe = (e) => {
    recipe.value = e.data
    recipe.value.index = e.index
    editRecipeDialog.value = true
}

const run_recipe = (e) => {
    recipe.value = e.data
    recipe.value.index = e.index
    gpt_question.value = recipe.value.recipe
    gpt_parameters.value = []
    runRecipeDialog.value = true
}

const history_gpt = () => {
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/get-library-history", {"redis_key": props.redis_key})
        .then((response) => {
            console.log("history", response.data)
            history.value = response.data
            historyDialog.value = true
            resolve()
        })
        .catch((error) => {
            toast.add({severity:'error', summary: 'Fehler', detail: 'Fehler beim Aktualisieren der Bibliothek'})
            reject()
        })
    })
}

const test_gpt = () => {
    testGPTDialog.value = true
}

const save_recipe = () => {
    if (recipe.value.index != undefined){
        library.value[recipe.value.index] = recipe.value
    } else {
        library.value.push(recipe.value)
    }
    update_library()
    editRecipeDialog.value = false
}

const copy_to_clipboard_html = (text, html) => {
    let blobHtml = new Blob([html], { type: "text/html" });
    let blobText = new Blob([text], { type: "text/plain" });
    let data = [new ClipboardItem({["text/plain"]: blobText, ["text/html"]: blobHtml})]
    navigator.clipboard.write(data)
    toast.add({severity:'success', summary: 'Erfolgreich', detail: 'In Zwischenablage kopiert', life: 3000})
}

const copy_to_clipboard = (value) => {
    navigator.clipboard.writeText(value)
    toast.add({severity:'success', summary: 'Erfolgreich', detail: 'In Zwischenablage kopiert', life: 3000})
}

const format_swiss_date_time = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4) + ' ' + value.substring(11,16)
}

const on_row_reorder = (e) => {
    library.value = e.value
    update_library()
}

const on_row_edit = (e) => {
    if (library.value[e.index][e.field] != e.newValue){
        library.value[e.index][e.field] = e.newValue
        update_library()
    }
}

const delete_recipe = (e) => {
    confirm.require({
        message: 'Wollen Sie wirklich die Datei '+e.data.name+' löschen?',
        header: 'Datei löschen',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Datei löschen',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            library.value.splice(e.index, 1)
            update_library()
        }
    })
}

const exerpt_string = (str) => {
    return str.length > 150 ? str.substring(0, 150) + '...' : str
}

const format_question = (str, arr) => arr.length > 1 
	? format_question(str.replace('{}', arr[0]), arr.slice(1)) 
	: (arr[0] && str.replace('{}', arr[0])) || str

</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <Toast />
    <ConfirmDialog />
    <div class="col-12 formgrid grid">
        <div class="col-12 mb-2">
            <Toolbar>
                <template #start>
                    <span class="p-input-icon-left mr-2">
                        <i class="pi pi-search" />
                        <InputText v-model="filter_library['global'].value" placeholder="Suche" style="width: 300px"  />
                    </span>
                    <Button icon="pi pi-refresh" @click="get_library(props.redis_key)" class="mr-2 bg-primary text-white" v-tooltip.top="'Liste aktualisieren'"></Button>
                </template>
                <template #end>
                    <Button icon="pi pi-plus" @click="new_recipe()" class="mr-2 bg-green-600 text-white border-green-700" label="Rezept erstellen"></Button>
                    <Button icon="pi pi-comments" @click="test_gpt()" class="mr-2 bg-blue-600 text-white border-blue-700" label="GPT testen"></Button>
                    <Button icon="pi pi-history" @click="history_gpt()" class="mr-2" label="Verlauf"></Button>
                </template>
            </Toolbar>
        </div>
        <div class="col-12 mb-2">
            <DataTable v-model:filters="filter_library" :value="library" :rows="50" editMode="cell" @cell-edit-complete="on_row_edit" @rowReorder="on_row_reorder"  responsiveLayout="scroll" class="w-full" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                <Column field="name" header="Name" sortable>
                    <template #editor="{ data, field }">
                        <InputText v-model="data[field]" class="w-full" />
                    </template>
                    <template #body="{ data, field }">
                        <Avatar icon="pi pi-comment" class="mr-2 bg-primary text-white" shape="circle" />
                        <span class="font-bold">{{data[field]}}</span>
                    </template>
                </Column>
                <Column>
                    <template #body="slotProps">
                        <Button @click="run_recipe(slotProps)" label="Rezept ausführen" icon="pi pi-play" class="mr-2 bg-green-600 text-white border-green-700" />
                        <Button @click="edit_recipe(slotProps)" v-tooltip.top="'Rezept editieren'" label="Bearbeiten" icon="pi pi-pencil" class="mr-2" />
                        <Button @click="delete_recipe(slotProps)" v-tooltip.top="'Rezept löschen'" class="bg-red-600 text-white border-red-700" icon="pi pi-trash" />
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
    <!------------------------------------------->
    <!--------------- Edit GPT ------------------>
    <!------------------------------------------->
    <Dialog v-model:visible="editRecipeDialog" :style="{width: '850px'}" header="GPT Rezept" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="name" v-model="recipe.name" class="w-full" />
                    <label for="name">Namen</label>
                </span>
                <small class="text-gray-500">Der Name identifiziert dein Rezept</small>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="name" v-model="recipe.recipe" class="w-full" />
                    <label for="name">Rezept</label>
                </span>
                <small class="text-gray-500">Ein Rezept ist die Frage, die bei OpenAI GPT gestellt wird. Die Frage kann Parameter enthalten (mit geschweifte Klammern: {}), die dann beim Ausführen eines Rezepts gefüllt werden. Achtet bitte auf die Reihenfolge der Parameter.</small>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Chips v-model="recipe.parameters" class="w-full" separator="," />
                    <label for="parameters">Parametern</label>
                </span>
                <small class="text-gray-500">Gib den Parametern, die in deinem GPT-Rezept verwendet werden, einen Namen. Bitte beachte, dass die Anzahl und Reihenfolge der Parameter wichtig ist (von links nach rechts)</small>
            </div>
            <div class="field col-12 mt-3">
                <Button @click="save_recipe()" label="Rezept speichern" icon="pi pi-save" class="mr-2 bg-green-600 text-white border-green-700 w-auto" />
                <Button @click="editRecipeDialog = false" label="Abbrechen" icon="pi pi-times" class="mr-2 w-auto" />
            </div>
        </div>
    </Dialog>
    <!------------------------------------------>
    <!--------------- Run GPT ------------------>
    <!------------------------------------------>
    <Dialog v-model:visible="runRecipeDialog" :style="{width: '850px'}" header="GPT Rezept" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <template v-if="recipe.parameters">
                <template v-for="(parameter, index) in recipe.parameters" :key="index">
                    <div class="field col-12 mt-3">
                        <span class="p-float-label">
                            <InputText v-model="gpt_parameters[index]" class="w-full" />
                            <label>{{parameter}}</label>
                        </span>
                    </div>
                </template>
            </template>
            <div class="field col-12 mt-3">
                <div class="bg-blue-800 text-white border-round p-3"><span class="font-bold mr-2">Frage:</span>{{ format_question(gpt_question, gpt_parameters) }}</div>
            </div>
            <div class="field col-12 mt-3">
                <Button @click="send_recipe()" label="Rezept ausführen" icon="pi pi-play" class="mr-2 bg-green-600 text-white border-green-700 w-auto" />
            </div>
            <div class="field col-12 mt-3">
                <BlockUI :blocked="gpt_loading">
                    <Textarea v-model="gpt_answer" class="w-full" autoResize rows="6" />
                </BlockUI>
            </div>
            <div class="field col-12 mt-3">
                <Button label="Kopieren" icon="pi pi-copy" class="mr-2 w-auto" @click="copy_to_clipboard(gpt_answer)" />
            </div>
        </div>
    </Dialog>
    <!------------------------------------------->
    <!--------------- Test GPT ------------------>
    <!------------------------------------------->
    <Dialog v-model:visible="testGPTDialog" :style="{width: '850px'}" header="GPT Testen" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-11 mt-3">
                <span class="p-float-label">
                    <InputText id="question" v-model="gpt_question" class="w-full" />
                    <label for="question">Frage an GPT</label>
                </span>
            </div>
            <div class="field col-1 mt-3">
                <Button icon="pi pi-copy" class="mr-2" @click="copy_to_clipboard(gpt_question)" />
            </div>
            <div class="field col-12 mt-3">
                <Button @click="send_recipe()" label="GPT-3" icon="pi pi-play" class="mr-2 bg-green-600 text-white border-green-700 w-auto" />
                <Button :disabled="true" @click="send_recipe()" label="GPT-4" icon="pi pi-play" class="mr-2 bg-green-600 text-white border-green-700 w-auto" />
            </div>
            <div class="field col-12 mt-3">
                <BlockUI :blocked="gpt_loading">
                    <Textarea v-model="gpt_answer" class="w-full" autoResize rows="6" />
                </BlockUI>
            </div>
            <div class="field col-12 mt-3">
                <Button label="Kopieren" icon="pi pi-copy" class="mr-2 w-auto" @click="copy_to_clipboard(gpt_answer)" />
            </div>
        </div>
    </Dialog>
    <Dialog v-model:visible="historyDialog" :style="{width: '80%'}" header="Verlauf" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <Toolbar>
                    <template #start>
                        <span class="p-input-icon-left mr-2">
                            <i class="pi pi-search" />
                            <InputText v-model="filter_history['global'].value" placeholder="Suche" style="width: 300px"  />
                        </span>
                    </template>
                </Toolbar>
            </div>
            <div class="field col-12 mt-3">
                <DataTable v-model:filters="filter_history" :value="history" :rows="50"  responsiveLayout="scroll" class="w-full" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                    <Column field="user" header="Benutzer" style="width: 15%" />
                    <Column field="date" header="Erstellt" style="width: 15%">
                        <template #body="slotProps">
                            {{ format_swiss_date_time(slotProps.data.date) }}
                        </template>
                    </Column>
                    <Column field="question" header="Frage" style="width: 30%">
                        <template #body="slotProps">
                            <span class="font-cursive mr-2">{{ exerpt_string(slotProps.data.question) }}</span>
                            <Button @click="history_question = slotProps.data.question, historyQuestionDialog = true" icon="pi pi-eye" class="p-button-rounded p-button-text" />
                            <Button @click="copy_to_clipboard(slotProps.data.question)" icon="pi pi-copy" class="p-button-rounded p-button-text" />
                        </template>
                    </Column>
                    <Column field="answer" header="Antwort" style="width: 40%">
                        <template #body="slotProps">
                            <span class="font-cursive mr-2">{{ exerpt_string(slotProps.data.answer) }}</span>
                            <Button @click="history_answer = slotProps.data.answer, historyAnswerDialog = true" icon="pi pi-eye" class="p-button-rounded p-button-text" />
                            <Button @click="copy_to_clipboard(slotProps.data.answer)" icon="pi pi-copy" class="p-button-rounded p-button-text" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </Dialog>
    <Dialog v-model:visible="historyQuestionDialog" modal header="Frage" :style="{ width: '850px' }">
        <Textarea v-model="history_question" class="w-full" autoResize rows="6" />
    </Dialog>
    <Dialog v-model:visible="historyAnswerDialog" modal header="Antwort" :style="{ width: '850px' }">
        <Textarea v-model="history_answer" class="w-full" autoResize rows="6" />
    </Dialog>
</template>