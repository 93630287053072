<script setup>
import { ref, onMounted, watch, defineProps, defineEmits } from 'vue'
import { Cropper, Preview } from 'vue-advanced-cropper'
import { uploadSingleImage } from '@/core/var/crud'
import 'vue-advanced-cropper/dist/style.css'
import axios from 'axios'
import MenuButton from './Components/Menu-Button.vue'
import DataView from './Components/Data-View.vue'
import { useCustomerStore } from '@/core/store/CustomerStore'
import ModalButtons from './Components/Modal-Menue.vue'
import ImageEditor from './Components/Image-Editor.vue'

const BLOCK_NAME = "image_big";
const NAME = 'Bild groß';
const channel = ref(useCustomerStore().getCustomer.customer);
// local variables
const editImageDialog = ref(false)
// define props
const props = defineProps({
    template_type: { type: String, required: true },
    index: { type: Number, required: false },
    data: { type: Object, required: true },
    image_library: { type: Object, required: true },
    workflow_id: { type: String, required: true },
    aspectRatio: { type: Object, required: false, default: () => { } }
})
// define emits
const emits = defineEmits(['blockCallback'])

// fields
const image_caption = ref(props.data.image_caption)
const template = ref(BLOCK_NAME)
const image = ref(props.data.image)
const croppy = ref(null)
const random_number = ref(String(Math.floor(Math.random() * 100000000)))
const image_upload_url = ref()
const edit = ref(false)

// functions
const edit_block = () => {
    editImageDialog.value = true
    edit.value = false
    image.value = props.data.image
}

const save_block = () => {

    const img = image_upload_url.value ? image_upload_url.value : image_upload_url.value

    emits('blockCallback',
        {
            "template_data": {
                "image_caption": image_caption.value,
                "template": template.value,
                "image": img
            },
            "action": "save",
            "index": props.index
        }
    )
    editImageDialog.value = false
}

const delete_block = () => {
    emits('blockCallback',
        {
            "action": "delete",
            "index": props.index
        }
    )
    editImageDialog.value = false
}

const add_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "image_caption": image_caption.value,
                "template": template.value,
                "image": image.value
            },
            "action": "add",
            "index": props.index
        }
    )
}

const crop_image = res => {
    croppy.value = res;
    image.value = res.getResult().canvas.toDataURL();
    upload_image()
    edit.value = false
}


// const crop_image = () => {
//     upload_image()
//     edit.value = false
// }

// const upload_image = async () => {
//     let { canvas } = croppy.value.getResult()
//     await canvas.toBlob((blob) => {
//         let reader = new FileReader()
//         reader.readAsDataURL(blob)
//         reader.onload = async () => {
//             let payload = { "file": reader.result, "image_type": String(props.index) + "_" + random_number.value, "workflow_id": props.workflow_id }
//             await axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/upload-image`, payload)
//                 .then(response => {
//                     image.value = response.data.image_url
//                 })
//         }
//     })
// }

const upload_image = async () => {
    const res = await uploadSingleImage(croppy.value, props.index, props.workflow_id, channel.value, 'upload-image', true);
    image_upload_url.value = res.data.image_url
}
</script>

<template>
    <!--------------------------------------------------->
    <!------------------ Menu View ---------------------->
    <!--------------------------------------------------->
    <MenuButton v-if="props.template_type === 'menu'" :add_block="add_block" button="pi-image" :name="NAME" />

    <!--------------------------------------------------->
    <!------------------ Data View ---------------------->
    <!--------------------------------------------------->
    <DataView v-else-if="props.template_type === 'data'" :edit_block="edit_block" :name="NAME">
        <div v-if="props.data.image">
        <img :src="props.data.image" class="w-full shadow-2 border border-gray-200 rounded" />
        <div class="text-left p-1 text-xs ">{{ props.data.image_caption }}</div>
        </div>
        <div v-else>
            <div class="text-center p-2">Kein Bild ausgewählt</div>
        </div>
    </DataView>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->

    <!--------------------------------------------------->
    <!------------------- Edit Image -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editImageDialog" :style="{ width: '80%' }" header="Block bearbeiten" :modal="true" class="p-fluid">

        <div>
            <ModalButtons :saveBlock="save_block" :deleteBlock="delete_block" :toggleDialog="toggleDialog" />
        </div>


        <div class="">
            <!-- <div v-if="edit == false" class="field col-12 mt-3">
                <img :src="image" class="shadow-2" style="width: 100%; height: 100%; object-fit: cover;" />
            </div>
            <Button v-if="edit == false" label="Bild Bearbeiten" @click="edit = true" class="w-auto mr-3 mb-1 p-button" icon="pi pi-pencil" />
            <div v-if="edit == true" class="field col-12 mt-3">
                <Button label="Bild Speichern" @click="crop_image()" class="w-auto mr-3 mb-1 p-success" icon="pi pi-save" />
            </div>
            <div v-if="edit == true" class="field col-12 mt-3">
                <cropper ref="croppy" :src="image" :stencil-props="{ aspectRatio: 2 / 1 }" :auto-zoom="true" :auto-center="true" />
            </div>
            <div v-if="edit == true" class="field col-12 mt-3">
                <div v-if="image_library" class="flex">
                    <template v-for="(img, index) of image_library" :key="index">
                        <img @click="image = img.url" :src="img.url" class="shadow-2 m-2" style="cursor: pointer; height: 100px; width: 100px; object-fit: cover; display:inline;" />
                    </template>
                </div>
            </div> -->
            <div class="mt-3">
                <ImageEditor :edit="edit" :image_library="image_library" :image="image" @update="crop_image" :stencil-props="props.aspectRatio" />
                <label for="image_caption">Bildunterschrift</label>
                <Textarea v-model="image_caption" id="image_caption" type="text" :autoResize="true" />
            </div>
        </div>
    </Dialog>
</template>