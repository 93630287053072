<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import BlogEditor from './Gerstl-Event-Editor.vue'
import { useAuthStore } from '@/core/store/AuthStore';

const channel = ref('gerstl');

//////////////////
// Data Sources //
//////////////////
const blogs = ref([])
const blog_categories = ref([])
const blog_tags = ref([])
const changes = ref(0)
const save_text = ref("Speichern")
const blog_status = ref([])
const editing = ref([])
const users = ref([])
const new_blog_title = ref("")
const new_blog_category = ref("")
const auth = useAuthStore();

const get_base_data = async () => {
    try {
        const [blogsResponse, systemResponse, usersResponse] = await Promise.all([
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/get-events`),
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/get-system`),
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/get-users`)
        ]);
        blogs.value = blogsResponse.data;
        blog_categories.value = systemResponse.data.event_categories;
        blog_tags.value = systemResponse.data.blog_tags;
        blog_status.value = systemResponse.data.blog_status;
        users.value = usersResponse.data;
    } catch (error) {
        console.error(error);
    }
};

onMounted(async () => {
    await get_base_data()
})

/////////////////////
// Interface Props //
/////////////////////

const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
const filter_blogs = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
const filter_blog_category = ref()
const filter_blog_status = ref()
const newBlogDialog = ref(false)
const activeTabIndex = ref(0)

const onTextFieldRowReorder = (e) => {
    blogs.value = e.value
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
    toast.add({severity: 'success', summary: 'Reihenfolge geändert', detail: 'Die Reihenfolge wurde erfolgreich geändert', life: 3000})
}

const onBlogRowEdit = (e) => {
    if(blogs.value[e.index]["redis_key"] != "") {
        if (blogs.value[e.index][e.field] != e.newValue){
            console.log("Blog " + blogs.value[e.index].redis_key + " changed: " + e.field + " from " + blogs.value[e.index][e.field] + " to " + e.newValue)
            blogs.value[e.index][e.field] = e.newValue
            changes.value = changes.value + 1
            save_text.value = "Speichern (" + changes.value + ")"
            toast.add({severity: 'success', summary: 'Änderungen gespeichert', detail: 'Die Änderungen wurden erfolgreich gespeichert', life: 3000})
        }
    }
    else {
        toast.add({severity: 'error', summary: 'Fehler', detail: 'Der Magazin Eintrag muss zuerst gespeichert werden!', life: 3000})
    }
}

const reset_changes = () => {
    confirm.require({
        message: 'Wollen Sie wirklich alle Änderungen verwerfen?',
        header: 'Änderungen verwerfen',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja, Änderungen verwerfen',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/"+channel.value+"/get-events")
            .then(response => {
                blogs.value = response.data
                changes.value = 0
                save_text.value = "Speichern"
            })
        }
    })
}

const save_changes = () => {
    confirm.require({
        message: 'Wollen Sie wirklich alle Änderungen speichern?',
        header: 'Änderungen speichern',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            loader.value = true
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/"+channel.value+"/save-events", blogs.value)
            .then(response => {
                blogs.value = response.data
                toast.add({severity: 'success', summary: 'Erfolgreich gespeichert', detail: 'Änderungen wurden erfolgreich gespeichert', life: 3000})
                changes.value = 0
                save_text.value = "Speichern"
                loader.value = false
            })
        }
    })
}

const publish = () => {
    confirm.require({
        message: 'Wollen Sie wirklich alle Änderungen publizieren?',
        header: 'Änderungen publizieren',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            let payload = {"type": "blogPost"}
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/publish", payload)
            .then(response => {
                toast.add({severity: 'success', summary: 'Publiziert', detail: 'Erstellt: '+response.data[0].created+', Gelöscht: '+response.data[0].deleted+', Ignoriert: '+response.data[0].ignored+', Aktualisiert: '+response.data[0].updated+'', life: 9000})
            })
        }
    })
}

const create_blog = () => {
    if (!new_blog_title.value) {
        toast.add({severity: 'error', summary: 'Fehler', detail: 'Bitte geben Sie einen Titel ein', life: 3000})
        return
    }
    if (!new_blog_category.value) {
        toast.add({severity: 'error', summary: 'Fehler', detail: 'Bitte wählen Sie eine Kategorie aus', life: 3000})
        return
    }
    if (check_if_slug_exists(new_blog_title.value)) {
        toast.add({severity: 'error', summary: 'Fehler', detail: 'Ein Event mit diesem Titel existiert bereits', life: 3000})
        return
    }
    let new_blog = {
        "workflow_id": "",
        "title": new_blog_title.value,
        "teaser": "",
        "slug": slugify(new_blog_title.value),
        "redis_key": channel.value+":blog:" + slugify(new_blog_title.value),
        "category": "events",
        "status": "draft",
        "status_props": {
            "key": "published",
            "value": "Publiziert"
        },
        "category_props": category_hack(new_blog_category.value),
        "presence": "",
        "user": auth.userdata.user_email,
        "header_image": "",
        "header_image_preview": "",
        "header_image_newsletter": "",
        "author": "",
        "dc": Date.now(),
        "dm": Date.now(),
        "publish_date": swiss_date(Date.now()),
        "runtime_from": "",
        "runtime_to": "",
        "tags": [],
        "matching_products_neuraxis": [],
        "matching_products_title": "",
        "events": [],
        "event_category": new_blog_category.value,
        "sage_groups": [],
        "changes": [
            {
                "user": auth.userdata.user_email,
                "date": Date.now(),
                "message": "Magazin-Event wurde erstellt"
            }
        ],
        "content": [],
        "text_content": "",
        "image_library": []
    }
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
    blogs.value.unshift(new_blog)
    toast.add({severity: 'success', summary: 'Neuer Event', detail: 'Der neue Event wurde erfolgreich erstellt', life: 3000})
    newBlogDialog.value = false
}

const category_hack = (category) => {
    if (category == "magazin"){
        return {
            "key": "magazin",
            "value": "Magazin"
        }
    }
    if (category == "events"){
        return {
            "key": "events",
            "value": "Events"
        }
    }
    if (category == "promotionen"){
        return {
            "key": "magazin",
            "value": "Magazin"
        }
    }
    if (category == "news"){
        return {
            "key": "news",
            "value": "News"
        }
    }
    if (category == "weinwissen"){
        return {
            "key": "weinwissen",
            "value": "Weinwissen"
        }
    }
    if (category == "promotionen"){
        return {
            "key": "promotionen",
            "value": "Promotionen"
        }
    }
    if (category == "wettbewerbe"){
        return {
            "key": "wettbewerbe",
            "value": "Wettbewerbe"
        }
    }
    if (category == "subskriptionen"){
        return {
            "key": "subskriptionen",
            "value": "Subskriptionen"
        }
    }
}

const get_random_id = () => {
    return Math.random().toString(16).slice(2)
}

const preview_blog = (slug) => {
    let cs = "fc7e3d6b-271c-41f7-bc50-97e3ed805459"
    window.open("https://www.gerstl.ch/"+slug+"/a?preview=" + cs)
}

const delete_blog = (redis_key) => {
    confirm.require({
        message: 'Bist du sicher, dass du den Event löschen möchtest?',
        header: 'Event löschen',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Event löschen',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            for (let key in blogs.value){
                if (blogs.value[key].redis_key == redis_key) {
                    blogs.value.splice(key, 1)
                    changes.value = changes.value + 1
                    save_text.value = "Speichern (" + changes.value + ")"
                    toast.add({ severity: 'success', summary: 'Gelöscht', life: 3000})
                }
            }
        }
    })
}

const edit_blog = (redis_key) => {
    for (let key in blogs.value){
        if (blogs.value[key].redis_key == redis_key) {
            let found = editing.value.some((el) => el.redis_key === redis_key);
            if (!found) {
                let name = ""
                if (blogs.value[key].title.length > 25) {
                    name = blogs.value[key].title.substring(0, 25) + "..."
                } else {
                    name = blogs.value[key].title
                }
                editing.value.push({
                    "redis_key": blogs.value[key].redis_key,
                    "name": name
                })
                activeTabIndex.value = editing.value.length
            }
        }
    }
}

const copy_blog = (redis_key) => {
    for (let key in blogs.value){
        if (blogs.value[key].redis_key == redis_key) {
            let new_blog = JSON.parse(JSON.stringify(blogs.value[key]))
            new_blog.slug = new_blog.slug + "-copy"
            new_blog.title = new_blog.title + " (Kopie)"
            new_blog.redis_key = channel.value+":blog:" + new_blog.slug
            new_blog.dc = swiss_date(Date.now())
            new_blog.dm = swiss_date(Date.now())
            new_blog.publish_date = swiss_date(Date.now())
            new_blog.user = auth.userdata.user_email
            new_blog.changes = [
                {
                    "user": auth.userdata.user_email,
                    "date": swiss_date(Date.now()),
                    "message": "Magazin-Event wurde kopiert"
                }
            ]
            new_blog.status = "draft"
            blogs.value.unshift(new_blog)
            changes.value = changes.value + 1
            save_text.value = "Speichern (" + changes.value + ")"
            toast.add({severity: 'success', summary: 'Event kopiert', detail: 'Der Event wurde erfolgreich kopiert', life: 3000})
            break
        }
    }
}

const check_if_slug_exists = (title) => {
    let exists = false
    let slug = slugify(title)
    console.log(slug)
    blogs.value.forEach(blog => {
        if (blog.slug == slug) {
            exists = true
        }
    })
    return exists
}

const editor_callback = (e) => {
    if (e.action == "close_tab") {
        for (let key in editing.value){
            if (editing.value[key]["redis_key"] == e.redis_key){
                editing.value.splice(key, 1)
                activeTabIndex.value = editing.value.length
            }
        }
    }
}

const select_status_filter = () => {
    filter_blogs.value['global'].value = filter_blog_status.value
}

const select_category_filter = () => {
    filter_blogs.value['global'].value = filter_blog_category.value
}

watch([activeTabIndex], () => {
    if (activeTabIndex.value == 0) {
        console.log("get base data")
        get_base_data()
    }
})

//////////////////////////
// Formatting Functions //
//////////////////////////

const slugify = (str) => {
  return str.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().trim().replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '-').toLowerCase();
}

const get_category = (category) => {
    for (let i = 0; i < blog_categories.value.length; i++) {
        if (blog_categories.value[i].value == category) {
            return blog_categories.value[i].name
        }
    }
}

const get_status = (status) => {
    for (let i = 0; i < blog_status.value.length; i++) {
        if (blog_status.value[i].value == status) {
            if (status == "published"){
                return "Publiziert"
            }
            if (status == "draft"){
                return "Entwurf"
            }
        }
    }
}

const swiss_date = (date) => {
    return new Date(date).toLocaleDateString('de-CH', { year: 'numeric', month: '2-digit', day: '2-digit' })
}

const swiss_date_time = (date) => {
    return new Date(date).toLocaleDateString('de-CH', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })
}

const characters_left = (field, limit) => {
    let chars = field.length
    return limit - chars
}
</script>

<style>
.spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}
</style>

<!-- <style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>  -->

<template>
    <Toast />
    <ConfirmDialog />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px;" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :fullScreen="true" :blocked="loader">
        <TabView class="col-12" ref="tabview4" v-model:activeIndex="activeTabIndex">
            <TabPanel>
                <template #header>
                    <i class="pi pi-comment mr-2"></i>
                    <span class="mr-3">Magazin Event</span>
                </template>
                <Toolbar>
                    <template #start>
                        <Button v-if="changes != 0" v-tooltip.bottom="'Änderungen zurücksetzen'" @click="reset_changes()" class="mr-2 p-button-danger" icon="pi pi-refresh" />
                        <Button :disabled="changes != 0 ? false : true" @click="save_changes()" v-tooltip.bottom="'Änderungen speichern'" :label="save_text" class="w-auto mr-2 p-button-success" icon="pi pi-save" />
                        <Button :disabled="changes == 0 ? false : true" @click="publish()" v-tooltip.bottom="'Änderungen publizieren'" label="Publizieren" class="w-auto mr-2 p-button-warning" icon="pi pi-upload" />
                        <Divider layout="vertical" />
                        <Button @click="newBlogDialog = true, new_blog_title = '', new_blog_category = ''" label="Event hinzufügen" class="w-auto mr-2 p-button" v-tooltip.top="'Ein neuer Magazin Beitrag erstellen'" icon="pi pi-plus" />
                    </template>
                    <template #end>
                        <Button type="button" icon="pi pi-refresh" aria-controls="overlay_menu" @click="reset_changes()" />
                    </template>
                </Toolbar>
                <Toolbar class="mt-2">
                    <template #start>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filter_blogs['global'].value" placeholder="Suche" style="width: 400px" class="mr-2" />
                        </span>
                        <Dropdown :showClear="true" @change="select_status_filter()" placeholder="Status" id="status_filter" v-model="filter_blog_status" :options="blog_status" optionLabel="name" optionValue="value" class="w-auto mr-2" />
                        <Dropdown :filter="true" :showClear="true" @change="select_category_filter()" placeholder="Kategorie" id="category_filter" v-model="filter_blog_category" :options="blog_categories" optionLabel="name" optionValue="value" class="w-auto mr-2" />
                    </template>
                </Toolbar>
                <!--------------------------------------------------->
                <!------------------ DataTable ---------------------->
                <!--------------------------------------------------->
                <DataTable class="mt-3" v-model:filters="filter_blogs" :value="blogs" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" editMode="cell" @cell-edit-complete="onBlogRowEdit" @rowReorder="onTextFieldRowReorder" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                    <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                    <Column field="status" header="Status" style="width: 100px">
                        <template #editor="{ data, field }">
                            <Dropdown id="blog_status" v-model="data[field]" :options="blog_status" optionLabel="name" optionValue="value" class="w-auto mr-2" />
                        </template>
                        <template #body="{ data, field }">
                            <Chip v-if="data[field] == 'draft'" :label="get_status(data[field])" v-tooltip.top="'Beitrag ist Publiziert'" class="mr-1" style="background-color: #d32f2f; color: #FFF" />
                            <Chip v-if="data[field] == 'published'" :label="get_status(data[field])" v-tooltip.top="'Beitrag ist Publiziert'" class="mr-1" style="background-color: #689f38; color: #FFF" />
                        </template>
                    </Column>
                    <Column field="header_image" style="width: 130px">
                        <template #body="slotProps">
                            <img :src="slotProps.data.header_image" width="32" class="shadow-2 mr-2" style="vertical-align: middle; width: 128px; height: 64px; object-fit: cover; border-radius: 5%; box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)" />
                        </template>
                    </Column>
                    <Column field="title" header="Titel" sortable>
                        <template #body="{ data, field }">
                            <span>{{ data[field] }}</span>
                        </template>
                    </Column> 
                    <Column field="event_category" header="Kategorie" style="width: 130px">
                        <template #editor="{ data, field }">
                            <Dropdown id="blog_category" v-model="data[field]" :options="blog_categories" optionLabel="name" optionValue="value" class="w-auto mr-2" />
                        </template>
                        <template #body="{ data, field }">
                            <Chip :label="get_category(data[field])" v-tooltip.top="'Kategorie / Thema'" icon="pi pi-comment" class="mr-1" style="background-color: #ffd8b2; color: #805b36" />
                        </template>
                    </Column>  
                    <Column field="dm" header="Modifikation" style="width: 180px" sortable>
                        <template #body="slotProps">
                            <span>{{ swiss_date_time(slotProps.data.dm) }}</span>
                        </template>
                    </Column>
                    <Column field="user" header="Bearbeiter" style="width: 350px">
                        <template #editor="{ data, field }">
                            <Dropdown :showClear="true" id="user" v-model="data[field]" :options="users" optionLabel="user_fullname" optionValue="user_email" class="w-auto mr-2" />
                        </template>
                        <template #body="{ data, field }">
                            <Chip :label="data[field]" v-tooltip.top="'Der aktuellen Bearbeiter vom Blog'" icon="pi pi-user" class="mr-1" style="background-color: #0288d1; color: #FFF" />
                        </template>
                    </Column>
                    <Column style="width: 250px;">
                        <template #body="slotProps">
                            <Button v-if="slotProps.data.workflow_id" @click="preview_blog(slotProps.data.slug)" v-tooltip.top="'Event Vorschau'" class="p-button-rounded sm p-button-text" icon="pi pi-eye" />
                            <Button v-if="slotProps.data.workflow_id && changes == 0" @click="edit_blog(slotProps.data.redis_key)" v-tooltip.top="'Event editieren'" class="p-button-rounded p-button-text" icon="pi pi-pencil" />
                            <Button hidden v-if="slotProps.data.workflow_id && changes == 0" @click="copy_blog(slotProps.data.redis_key)" v-tooltip.top="'Event kopieren'" class="p-button-rounded p-button-text" icon="pi pi-copy" />
                            <Button v-if="slotProps.data.workflow_id && changes == 0" @click="delete_blog(slotProps.data.redis_key)" v-tooltip.top="'Event löschen'" class="p-button-rounded p-button-text" icon="pi pi-trash" />
                        </template>
                    </Column>
                </DataTable>
            </TabPanel>
            <TabPanel v-for="(editor, key) in editing" :key="key">
                <template #header>
                    <i class="pi pi-pencil mr-2"></i>
                    <span class="mr-3">{{ editor.name }}</span>
                </template>
                <BlogEditor :redis_key="editor.redis_key"  @editor-callback="editor_callback" />
            </TabPanel>
        </TabView>
    </BlockUI>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="newBlogDialog" :style="{width: '800px'}" header="Neuen Event" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="new_blog_title" v-model="new_blog_title" class="w-full" />
                    <label for="new_blog_title">Titel</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <Dropdown id="new_blog_category" v-model="new_blog_category" :options="blog_categories" optionLabel="name" optionValue="value" class="w-auto" />
                    <label for="new_blog_category">Kategorie</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <Button @click="create_blog()" label="Event erstellen" class="p-button-success" />
            </div>
        </div>
    </Dialog>
</template>