<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'

const toast = useToast()

const props = defineProps({
    redis_key: { type: String, required: true }
})

const emits = defineEmits(['componentCallback'])

const upload_pdf = async (e) => {
    for (let i = 0; i < e.files.length; i++) {
        let reader = new FileReader()
        reader.readAsDataURL(e.files[i])
        reader.onload = () => {
            let payload = {"filename": e.files[i].name, "file": reader.result, "type": e.files[i].type, "size": e.files[i].size, "redis_key": props.redis_key }
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/upload-library-file", payload)
            .then(response => {
                emits('componentCallback',
                {
                    "redis_key": props.redis_key
                })
                toast.add({severity:'success', summary: 'Erfolg', detail: 'PDF Files '+ e.files[i].name +' erfolgreich hochgeladen', life: 3000})
            })
        }
    }
}

</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <Toast />
    <div class="col-12 formgrid grid">
        <div class="field col-12 mt-3">
            <FileUpload name="pdfs[]" :customUpload="true" @uploader="upload_pdf" :multiple="true" accept="application/pdf" :maxFileSize="10000000" chooseLabel="PDFs auswählen" uploadLabel="Hochladen" cancelLabel="Abbrechen" previewWidth="0">
                <template #empty>
                    <div>
                        <div class="flex justify-content-center"><i class="pi pi-upload" style="font-size: 4rem; color: #dee2e6;"></i></div>
                        <div class="flex justify-content-center mt-2">Hochladen per Drag & Drop</div>
                    </div>
                </template>
            </FileUpload>
        </div>
    </div>
</template>