<template>
    <Toast />
    <LibraryUpload v-model:visible="imageUploadDialog" :path="endpoints.uploadlibrary" :slug="channel" @data="libraryUploadGetData" @finished="libraryUploadidFinished" />

    <Toolbar>
        <template #start>

            <div class="inline sm:hidden">
                <SplitButton label="Speichern" icon="pi pi-save" :model="items" />
            </div>
            <div class="hidden sm:flex sm:justify-items-start">
                <Button @click="save_changes" v-tooltip.bottom="'Änderungen Speichern'" label="Speichern" class="w-auto mr-4 p-button-success" icon="pi pi-save" :loading="loader" size="small" />
                <Divider layout="vertical" />
                <!-- <Button v-tooltip.bottom="'Preview Artikel'" @click="preview" label="Vorschlau" class="mr-2 p-button w-auto" icon="pi pi-eye" size="small" :disabled="disabled" /> -->
                <Button v-tooltip.bottom="'Artikel schliessen'" @click="close_tab" label="Schliessen" class="mr-2 p-button-danger w-auto" icon="pi pi-times-circle" size="small" :disabled="disabled" />
            </div>

        </template>
        <template #end>
            <div class="hidden sm:inline-block">
                <Button type="button" icon="pi pi-refresh" aria-controls="overlay_menu" @click="reset_changes" :loading="loader" />
            </div>
        </template>
    </Toolbar>

    <TabView ref="tabview4">
        <TabPanel>
            <template #header>
                <i class="pi pi-file mr-2"></i>
                <span class="mr-3">Meta</span>
            </template>
            <div class="md:flex flex-row md:my-5">
                <div class="w-auto md:w-2/4 m-2">

                    <Accordion class="accordion-custom" :activeIndex="0">
                        <AccordionTab>
                            <template #header>
                                <span class="mr-2 font-bold">Metadaten</span>
                            </template>

                            <div class="my-7">
                                <span class="p-float-label">
                                    <InputText id="title" class="w-full" v-model="blog.title" />
                                    <label for="title">Titel</label>
                                </span>
                            </div>
                                         <div class="my-7">
                                    <span class="p-float-label">
                                        <InputText id="subtitle" class="w-full" v-model="blog.subtitle" />
                                        <label for="subtitle">Teaser</label>
                                    </span>
                                </div>               
                            <div class="my-7" v-if="pdf_preview_image">
                                <div><Image :src="pdf_preview_image" alt="Image" width="250" preview class=" border-grey-100 border-2" /></div>
                                <div><i class="pi pi-file-pdf"></i> <a :href="pdf_download" download="pdf_download" target="_blank">Download PDF</a>
                                </div>
                            </div>

                            <div v-if="loader" class="my-7">
                            <LoadingSpinner size="6" color="fill-primary-600" background-dark="dark:text-gray-100"></LoadingSpinner> 
                            </div>
                            <div class="my-7" v-else>
                                <FileUpload mode="basic" name="pdf[]" accept="application/pdf" :maxFileSize="10000000" @upload="onUpload" :auto="true" chooseLabel="PDF hochladen" customUpload @uploader="upload_pdf" />
                            </div>
                        </AccordionTab>
                    </Accordion>
                </div>


                <div class="w-auto md:w-2/4 m-2">
                    <div class="">
                        <Accordion class="accordion-custom" :activeIndex="0">
                            <AccordionTab>
                                <template #header>
                                    <span class="mr-2 font-bold">Metadaten</span>
                                </template>
                                <div class="my-7">
                                    <span class="p-float-label">
                                        <Dropdown id="blog_status" v-model="blog.status" :options="blog_status" optionLabel="name" optionValue="value" class="w-full" />
                                        <label for="blog_status">Status</label>
                                    </span>
                                </div>

                                <div class="my-7">
                                    <span class="p-float-label">
                                        <Calendar id="year" v-model="blog_year" class="w-full" showIcon view="year" dateFormat="yy" />
                                        <label for="team">Jahr</label>
                                    </span>
                                </div>
                                <!-- <div class="my-7">
                                    <span class="p-float-label">
                                        <Calendar id="month" v-model="blog.month" class="w-full" view="month" showIcon dateFormat="mm" />
                                        <label for="month">Monat</label>
                                    </span>
                                </div> -->


                                <div class="flex flex-wrap" v-if="image_library.length > 0 && !blog.disable_header_image">
                                    <UploadImageButton :imagelibrary="image_library" @delete="delete_image" label="Hauptbild" :image="blog.header_image" :workflow_id="workflow_id" @uploaded-image="imageUploaded" image-type="header_image" />
                                </div>
                                <div class="flex flex-wrap" v-if="image_library.length > 0">
                                    <UploadImageButton :imagelibrary="image_library" @delete="delete_image" label="Vorschaubild" :image="blog.header_image_preview" :workflow_id="workflow_id" @uploaded-image="imageUploaded" image-type="header_image_preview" />
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                    <!-- <div class="mt-4">
                        <Accordion class="accordion-custom mt-3">
                            <AccordionTab>
                                <template #header>
                                    <i class="pi pi-image mr-2"></i>
                                    <span class="mr-2 font-bold">Bilder Bibliotek</span>
                                    <span class="inline-flex items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{{ String(image_library.length) }}</span>
                                </template>
                                <ImageGallery :image-library="image_library" @update:imageLibrary="image_library = $event" />
                                <div>
                                    <Button @click="imageUploadDialog = true" v-tooltip.bottom="'Bilder in die Bibliotek hochladen'" label="Bilder hochladen" class="mr-2 p-button w-auto" icon="pi pi-upload" />
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div> 
                    <div class="mt-4">
                        <Accordion class="accordion-custom mt-3">
                            <AccordionTab>
                                <template #header>
                                    <i class="pi pi-history mr-2"></i>
                                    <span class="mr-2 font-bold">Änderungsverlauf</span>

                                    <span class="inline-flex items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{{ String(blog_changes.length) }}</span>
                                </template>
                                <div v-if="blog_changes">
                                    <div v-for="(change, index) of blog_changes.slice().reverse()" :key="index" class="my-8 bg-yellow-700/20 p-6 rounded-lg">

                                        <div class="text-xs">
                                            <Chip :label="change.user" icon="pi pi-user" class="m-1" />
                                            <Chip :label="swiss_date_time(change.date)" icon="pi pi-clock" class="m-2" />
                                        </div>
                                        <div class="bg-primary-100 text-white m-2 p-2 rounded-md">
                                            <span class=""> {{ change.message }}</span>
                                        </div>
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div> -->
                </div>
            </div>
        </TabPanel>
        <!-- <TabPanel>
            <template #header>
                <i class="pi pi-box mr-2"></i>
                <span class="mr-3">Builder</span>
            </template>
            <div class="md:flex flex-row my-5">
                <div class="w-auto md:w-2/3 m-2 border-solid border border-gray-200">
                    <DataTable :value="blog_content" :rows="20" responsiveLayout="scroll" :rowHover="true" @rowReorder="onRowReorder">
                        <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                        <Column field="template">
                            <template #header>
                                <i class="pi pi-box mr-2"></i>
                                <span class="mr-3">Blocks</span>
                                <span className="inline-flex items-center rounded-full bg-primary-500 px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-primary-700/10">
                                    {{ blog_content.length }}
                                </span>
                            </template>
                            <template #body="slotProps">
                                <component :is="content_blocks.find(block => block.name === slotProps.data.template).type" template_type="data" :index="slotProps.index" :data="slotProps.data" @block-callback="update_template_data" :image_library="image_library" :workflow_id="workflow_id" />
                            </template>
                        </Column>
                        <Column class="w-10">
                            <template #body="slotProps">
                                <div>
                                    <ChevronUpIcon v-if="slotProps.index != 0" class="h-10 w-10 text-gray-400" @click="shift_col('up', slotProps.index)" />
                                </div>
                                <div>
                                    <ChevronDownIcon v-if="slotProps.index != blog_content.length - 1" class="h-10 w-10 text-gray-400" @click="shift_col('down', slotProps.index)" />
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                </div>
                <div class="w-auto md:w-1/3 m-5 border-solid border border-gray-200">
                    <div v-for="key in content_blocks" :key="key">
                        <component :is="key.type" template_type="menu" :data="content_blocks" @block-callback="update_template_data" :image_library="image_library" :workflow_id="workflow_id" />
                    </div>
                </div>
            </div>
        </TabPanel> -->
    </TabView>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->

    <Dialog v-model:visible="blog_text_vorschlag_dialog" :style="{ width: '800px' }" header="Teaser Vorschlag" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            {{ blog_text_vorschlag }}
        </div>
    </Dialog>
    <!--------------------------------------------------->
    <!--------------- Edit Title Image ------------------>
    <!--------------------------------------------------->
    <Dialog v-model:visible="editTitleImageDialog" :style="{ width: '80%' }" header="Titel Bild editieren" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">

            <div v-if="edit_header_image == false" class="m-3">
                <img :src="blog.header_image" class="shadow-2" style="width: 100%; height: 100%; object-fit: cover;" />
            </div>

            <span class="inline-block h-48 w-48 overflow-hidden rounded-full bg-gray-100 my-10" v-if="edit_header_image == false && !blog.header_image">
                <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
            </span>
            <Button v-if="edit_header_image == false" label="Bild Bearbeiten" @click="edit_header_image = true" class="w-auto mr-3 mb-1 p-button" icon="pi pi-pencil" />

            <div v-if="edit_header_image == true" class="mt-3">
                <cropper ref="title_image_cropper" :src="blog.header_image" :auto-zoom="true" :auto-center="true" />
            </div>

            <div v-if="edit_header_image == true" class="mt-3">
                <div v-if="image_library" class="flex">
                    <div v-for="(img, index) of image_library" class="flex" :key="index">
                        <img @click="blog.header_image = img.image" :src="img.image" class="shadow-2 m-2" style="cursor: pointer; width: 80px; height: 80px; object-fit: cover;" />
                    </div>
                </div>
            </div>

            <div v-if="edit_header_image == true" class="mt-3">
                <Button label="Bild Speichern" @click="cropImage(title_image_cropper, 'header_image', 'image123')" class="w-auto mr-3 mb-1 p-success" icon="pi pi-save" />
            </div>
        </div>

    </Dialog>
    <!--------------------------------------------------->
    <!----------------- Preview Image ------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editTitleImagePreviewDialog" :style="{ width: '80%' }" header="Titel Vorschau-Bild editieren" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div v-if="edit_header_image_preview == false" class="mt-3">
                <img :src="blog.header_image_preview" class="shadow-2" style="width: 100%; height: 100%; object-fit: cover;" />
            </div>
            <Button v-if="edit_header_image_preview == false" label="Bild Bearbeiten" @click="edit_header_image_preview = true, blog.header_image_preview = blog.header_image" class="w-auto mr-3 mb-1 p-button" icon="pi pi-pencil" />
            <div v-if="edit_header_image_preview == true" class="mt-3">
                <cropper ref="title_image_cropper_preview" :src="blog.header_image_preview" :auto-zoom="true" :auto-center="true" />
            </div>
            <div v-if="edit_header_image_preview == true" class="mt-3">
                <div v-if="image_library" class="flex">
                    <div v-for="(img, index) of image_library" class="flex" :key="index">
                        <img @click="blog.header_image_preview = img.image" :src="img.image" class="shadow-2 m-2" style="cursor: pointer; width: 80px; height: 80px; object-fit: cover;" />
                    </div>
                </div>
            </div>
            <div v-if="edit_header_image_preview == true" class="mt-3">
                <Button label="Bild Speichern" @click="crop_header_image(title_image_cropper_preview, 'header_image_preview')" class="w-auto mr-3 mb-1 p-success" icon="pi pi-save" />
            </div>
        </div>
    </Dialog>
    <!--------------------------------------------------->
    <!------------------- GPT Dialog -------------------->
    <!--------------------------------------------------->
    <!-- <Dialog v-model:visible="chatGptDialog" :style="{ width: '80%' }" header="Inhalte mit AI erstellen" :modal="true" class="p-fluid">
        <ChatGPT :questions="chatGptQuestions" @chat-callback="update_text_content" />
    </Dialog> -->
</template>

<script setup>
import { ref, onMounted, watch, defineProps, defineEmits, inject, provide, shallowRef, computed } from 'vue'
//import { CloudIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/vue/20/solid';
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import Image from 'primevue/image';
import { useConfirm } from "primevue/useconfirm"
import { Cropper, Preview } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import LoadingSpinner from '@/core/components/UI/Loading-Spinner.vue'
import { useCustomerStore } from '@/core/store/CustomerStore'
//import ChatGPT from '@/core/components/Components/Main-GPT.vue';
import StandardText from '@/core/components/Components/Builder-Blocks/Standard-Text.vue'
import StandardImage from '@/core/components/Components/Builder-Blocks/Standard-Image.vue'
import StandardTitle from '@/core/components/Components/Builder-Blocks/Standard-Title.vue'
import CTAButton from '@/core/components/Components/Builder-Blocks/Cta-Button.vue'
import HtmlBlock from '@/core/components/Components/Builder-Blocks/Html-Block.vue'
import QuoteBlock from '@/core/components/Components/Builder-Blocks/Quote-Block.vue'
import ImageText from '@/core/components/Components/Builder-Blocks/Image-Text.vue'
import ComparsionBlock from '@/core/components/Components/Builder-Blocks/Comparsion-Block.vue'
import QuotingLogo from '@/core/components/Components/Builder-Blocks/Quoting-Logo-Block.vue'
import ImageCols from '@/core/components/Components/Builder-Blocks/Image-Columns.vue'
import Markdown from '@/core/components/Components/Builder-Blocks/Markdown-Block.vue'
import FAQBlock from '@/core/components/Components/Builder-Blocks/FAQ-Block.vue'
import GalleryBlock from '@/core/components/Components/Builder-Blocks/Gallery-Block.vue'
import StandardProducts from '../Editor-Blocks/standard_products.vue'
import ImageBig from '@/core/components/Components/Builder-Blocks/Image-Big.vue'

import Simplestore from '@/core/var/Simplestore';
import { swiss_date_time, preview_dataset, slugify, getRandomId } from '@/core/var/tools'
import { uploadSingleImage } from '@/core/var/crud'
import LibraryUpload from '@/core/components/Components/Main-Library-Upload.vue'
import VideoURL from '@/core/components/Components/Builder-Blocks/Video-URL.vue'
import YouTubeURL from '@/core/components/Components/Builder-Blocks/Youtube-URL.vue'
import UploadImageButton from '@/core/components/Components/Main-UploadImage-Button2.vue';

import { useAuthStore } from '@/core/store/AuthStore';

const auth = useAuthStore();

const props = defineProps({
    redis_key: { type: String }
})

const MODULE = 'weinbroschueren';

const content_blocks = ref([
    { name: 'standard_text', type: shallowRef(StandardText) },
    { name: 'standard_title', type: shallowRef(StandardTitle) },
    { name: 'standard_image', type: shallowRef(StandardImage) },
    { name: 'cta_button', type: shallowRef(CTAButton) },
    { name: 'html_block', type: shallowRef(HtmlBlock) },
    { name: 'quote_block', type: shallowRef(QuoteBlock) },
    { name: 'image_text', type: shallowRef(ImageText) },
    { name: 'comparsion_block', type: shallowRef(ComparsionBlock) },
    { name: 'quoting_logo', type: shallowRef(QuotingLogo) },
    { name: 'image_cols', type: shallowRef(ImageCols) },
    { name: 'video_url', type: shallowRef(VideoURL) },
    { name: 'youtube_url', type: shallowRef(YouTubeURL) },
    { name: 'markdown', type: shallowRef(Markdown) },
    { name: 'faq_block', type: shallowRef(FAQBlock) },
    { name: 'standard_products', type: shallowRef(StandardProducts) },
    { name: 'gallery_block', type: shallowRef(GalleryBlock) },
    { name: 'image_big', type: shallowRef(ImageBig) },
]);


const channel = ref(useCustomerStore().getCustomer.customer)
provide('channel', channel)
const SERVICE_URL = inject('SERVICE_URL')


const loader = ref(false)
const toast = useToast()
const confirm2 = useConfirm()
const imageUploadDialog = ref(false)
const editTitleImageDialog = ref(false)
const editTitleImagePreviewDialog = ref(false)
const edit_header_image_preview = ref(false)
const edit_header_image = ref(false)
const workflow_id = ref("")
const new_blog_categories = ref();
const blog = ref([])
const blog_categories = ref([])
const new_blog_subcategories = ref(false)
const blog_sort = ref([])
const blog_status = ref([])
const users = ref([])
const blog_content = ref([])
const image_library = ref([])
const activeImageIndex = ref(0)
const displayGallery = ref(false)
const blog_changes = ref([])
const chatGptQuestions = ref([])
const chatGptDialog = ref(false)
const blog_title = ref(null)

const blog_text_vorschlag = ref(null);
const blog_text_vorschlag_dialog = ref(false);
const title_image_cropper = ref();
const emits = defineEmits(['editorCallback'])
const title_image_cropper_preview = ref()
const disabled = ref(false);
const pdf_preview_image = ref(false);
const pdf_download = ref(false);

const blog_year = ref();

const items = ref([
    {
        label: 'Änderungen zurücksetzen',
        icon: 'pi pi-refresh',
        command: () => {
            reset_changes()
        }
    },
    // {
    //     label: 'Vorschau',
    //     icon: 'pi pi-eye',
    //     command: () => {
    //         preview()
    //     }
    // },
    {
        label: 'Schließen',
        icon: 'pi pi-times',
        command: () => {
            close_tab()
        }
    }

]);


const endpoints = {
    get: `${MODULE}/get`,
    getsingle: `${MODULE}/get-single`,
    savesingle: `${MODULE}/save-single`,
    pdfupload: `${MODULE}/upload-pdf`,
    getsystem: `get-system`,
    getusers: `get-users`,
    getcategories: `events/categories/get`,
    getsubcategories: `${MODULE}/subcategories/get`,
    getqualities: `${MODULE}/qualities/get`,
    getcolors: `${MODULE}/colors/get`,
    gettags: `${MODULE}/tags`,
    save: `${MODULE}/save`,
    uploadsingle: `upload-image`,
    uploadlibrary: `${MODULE}/upload-library-image`,
    preview: `${MODULE}/preview/save`,
    getarticles: `articles/get`,
    checktitle: `${MODULE}/check-title`,
    get_pricespan: `${MODULE}/get-pricespan`,
}

const upload_url = `${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.pdfupload}`

provide('upload_endpoint', endpoints.uploadsingle);

onMounted(async () => {
    loader.value = true;
    await getBlogs()
    await fetchAdditionals();
    loader.value = false;
})

watch(loader, res => {
    Simplestore.spinner = res;
    disabled.value = res;
});

watch(workflow_id, res => {
    provide('workflow_id', res)
})

const fetchAdditionals = async () => {
    try {
        const [systemResponse] = await Promise.all([
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getsystem}`)
        ]);
        blog_status.value = systemResponse.data.blog_status;
    } catch (error) {
        console.error(error);
    }
}

const onUpload = e => {
    toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 });
};

const upload_pdf = async e => {
    loader.value = true;
    const formData = new FormData();
    formData.append('file', e.files[0]);
    formData.append('slug', slugify(blog.value.title)+getRandomId(5));
    const response = await axios.post(upload_url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

    pdf_download.value = response.data.file;
    pdf_preview_image.value = response.data.preview_image;
    toast.add({ severity: 'info', summary: 'PDF Upload', detail: 'PDF wurde hochgeladen und verarbeitet, bitte speichern Sie den Datensatz', life: 5000 });
    loader.value = false;
    return response.data;
};


const preview = () => {
    const workload = {
        redis_key: blog.value.redis_key,
        content: blog.value.content,
        title: blog.value.title,
        teaser: blog.value.teaser,
        header_image: blog.value.header_image,
    };

    axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.preview}`, workload)
        .then(response => {
            preview_dataset(blog.value.slug, SERVICE_URL, MODULE)
        })
}

const getBlogs = async () => {
    const payload = { redis_key: props.redis_key };
    try {
        const response = await axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getsingle}`, payload);
        const { data } = response;

        blog.value = data;

        pdf_download.value = data.pdf_download !== null ? data.pdf_download : false;
        pdf_preview_image.value = data.pdf_preview_image !== null ? data.pdf_preview_image : false;

        workflow_id.value = data.workflow_id;
        blog_title.value = data.title;
        blog_changes.value = data.changes;
        blog_year.value = data.year;
        // const yearString = data.year.toString();
        // const date = new Date(yearString);
        // const isoDate = date.toISOString();
        // console.log(isoDate);
        //blog.value.year = new Date(data.year, 0, 1);
        //blog.value.month = new Date(data.year, data.month - 1);
    } catch (error) {
        console.error("Error fetching products data:", error);
    }
};

const GPT_Primer = (blog_title) => {
    chatGptQuestions.value = [
        {
            question: `Erstelle einen Text zum Thema ${blog_title}`,
            button_name: `Text automatsch erstellen`,
        },
        {
            question: `Erstelle einen Text zum Thema ${blog_title} und fasse ihn mit 100 Wörtern zusammen`,
            button_name: "Text-Zusammenfassung (100 Wörter)",
        },
    ];
}



const close_tab = () => {
    confirm2.require({
        message: 'Sind Sie sicher, dass Sie diesen Artikel schliessen wollen? Alle nicht gespeicherten Änderungen gehen verloren.',
        header: 'Artikel schliessen',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja, Artikel schliessen',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            emits('editorCallback',
                {
                    "action": "close_tab",
                    "redis_key": props.redis_key
                }
            )
        }
    })
}


const save_changes = async () => {
    loader.value = true;
    const { value } = blog;

    // const checkTitle = await checkDuplicate(blog.value.title, blog.value.workflow_id, `${channel.value}/${endpoints.checktitle}`)

    // if (checkTitle.msg == true) {
    //     toast.add({ severity: 'error', summary: 'Fehler', detail: 'Der Titel existiert bereits', life: 3000 })
    //     loader.value = false;
    //     return;
    // }
    if (!blog_year.value) {
        toast.add({ severity: 'error', summary: 'Fehler', detail: 'Bitte geben Sie ein Jahr ein', life: 3000 })
        loader.value = false;
        return;
    }
    if (!blog.value.title) {
        toast.add({ severity: 'error', summary: 'Fehler', detail: 'Bitte geben Sie einen Titel ein', life: 3000 })
        loader.value = false;
        return;
    }
    if (!blog.value.subtitle) {
        toast.add({ severity: 'error', summary: 'Fehler', detail: 'Bitte geben Sie einen Teaser ein', life: 3000 })
        loader.value = false;
        return;
    }
    if(!pdf_download.value){
        toast.add({ severity: 'error', summary: 'Fehler', detail: 'Bitte laden Sie ein PDF hoch', life: 3000 })
        loader.value = false;
        return;
    }

    value["content"] = blog_content.value;
    value["changes"] = blog_changes.value;
    value["image_library"] = image_library.value;
    value["pdf_download"] = pdf_download.value;
    value["pdf_preview_image"] = pdf_preview_image.value;
    //console.log(blog_year.value);
    // Bugfix of Primevue Calandar, sometimes (5/100) it returns a wrong date, reason unknown
    if (blog_year.value instanceof Date) {
        const yearString = blog_year.value.getFullYear().toString();
        value["year"] = yearString;
    } else {
        value["year"] = new Date().getFullYear().toString();
    }



    try {
        const response = await axios.post(
            `${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.savesingle}`,
            value
        );

        const { status } = response.data;

        if (status === "success" || status === "titlechanged") {
            toast.add({
                severity: "success",
                summary: `${value.title} gespeichert`,
                detail: "Änderungen wurden erfolgreich gespeichert",
                life: 3000,
            });
            loader.value = false;
        }

    } catch (error) {
        console.error(error);
    }
};

const reset_changes = async () => {
    confirm2.require({
        message: 'Wollen Sie wirklich alle Änderungen verwerfen?',
        header: 'Änderungen verwerfen',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja, Änderungen verwerfen',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
            try {
                const payload = { redis_key: props.redis_key };
                const response = await axios.post(
                    `${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getsingle}`,
                    payload
                );
                blog.value = response.data;
            } catch (error) {
                console.error(error);
            }
        },
    });
};

const onRowReorder = (e) => {
    if (e.dragIndex != e.dropIndex) {
        blog_content.value = e.value
    }
}

const crop_header_image = async (image, image_type) => {

    await uploadSingleImage(image, image_type, blog.value.workflow_id, channel.value, endpoints.uploadsingle, true).then((res) => {
        toast.add({
            severity: "success",
            summary: "Erfolg",
            detail: "Bild erfolgreich hochgeladen",
            life: 3000,
        });

        blog.value.header_image = res.data.image_url;
        edit_header_image.value = false;
    });
}



const UploadImage = (image, image_type) => {
    return new Promise((resolve, reject) => {
        uploadSingleImage(image, image_type, blog.value.workflow_id, channel.value, endpoints.uploadsingle, true).then((res) => {
            resolve(res.data.image_url);
        }).catch(err => {
            reject(err);
        });
    });
}

const cropImage = async (image, image_type, reference) => {
    loader.value = true;
    UploadImage(image, image_type).then(res => {
        toast.add({
            severity: "success",
            summary: "Erfolg",
            detail: "Bild erfolgreich hochgeladen",
            life: 2000,
        });
        [reference].value = res;
        loader.value = false;
    });
}

const libraryUploadGetData = (res) => {
    blog.value['image_library'].push({ image: res });
}

const libraryUploadidFinished = (res) => {
    if (res === true) {
        imageUploadDialog.value = false;
    }
}

const openEditDialog = res => {
    editTitleImageDialog.value = res;
}

const imageUploaded = res => {
    blog.value[res.type] = res.image;
}
const delete_image = res => {
    blog.value[res.type] = null;
    disabled.value = false;
    imageUploadDialog.value = false;
}

</script>
