<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import { Cropper, Preview } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';

const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

const filter_customers = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})

const newCustomerDialog = ref(false)
const customers = ref([])
const crm_owners = ref([])
const webshop_contacts = ref([])
const active = ref("active")

const customer = ref({
    company_name: null,
    company_type: null,
    company_industry: null,
    company_street: null,
    company_zip: null,
    company_city: null,
    company_phone: null,
    company_email: null,
    company_website: null,
    company_tax_number: null,
    company_registration_number: null,
    company_sales_tax_number: null,
    contact_firstname: null,
    contact_lastname: null,
    contact_phone: null,
    contact_email: null,
    webshop_contact: null,
    crm_status: "Nicht kontaktiert",
    crm_introduction: null,
    crm_notes: null,
    crm_material: null,
    crm_owner: null,
    crm_welcome: null,
})

onMounted( async () => {
    await axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/get-crm-contacts")
    .then(response => {
        customers.value = response.data
        console.log(customers.value)
    })
    await axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/get-crm-owners")
    .then(response => {
        crm_owners.value = response.data
    })
    await axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/get-webshop-contacts")
    .then(response => {
        webshop_contacts.value = response.data
    })
})

const company_types = ref([
    {type: 'Kunde', label: 'Kunde'},
    {type: 'Interessent', label: 'Interessent'},
    {type: 'Lieferant', label: 'Lieferant'},
    {type: 'Partner', label: 'Partner'},
    {type: 'Sonstiges', label: 'Sonstiges'},
])
const company_industries = ref([
    {type: 'Weinhandel', label: 'Weinhandel'},
    {type: 'Grosshandel', label: 'Grosshandel'},
    {type: 'Gastronomie', label: 'Gastronomie'},
    {type: 'Restaurant', label: 'Restaurant'},
    {type: 'Hotel', label: 'Hotel'},
    {type: 'Veranstaltungen', label: 'Veranstaltungen'},
    {type: 'Sonstiges', label: 'Sonstiges'},
])
const crm_statuses = ref([
    {type: 'Nicht kontaktiert', label: 'Nicht kontaktiert'},
    {type: 'Kontaktiert', label: 'Kontaktiert'},
    {type: 'Termin', label: 'Termin'},
    {type: 'Intresse', label: 'Intresse'},
    {type: 'Keine intresse', label: 'Keine intresse'},
])

const new_customer = () => {
    confirm.require({
        message: 'Möchten Sie wirklich einen neuen Kunden/Intressent erfassen?',
        header: 'Neuen Kunden/Intressent erfassen',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja, erfassen',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/crm-create-contact", customer.value)
            .then(response => {
                toast.add({severity:'success', summary: 'Erfolg', detail: 'Kontakt erfolgreich erstellt', life: 3000})
            })
        }
    })
}

</script>

<style scoped>
.p-toolbar {
    width: 100%!important;
}
</style>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Toast />
    <ConfirmDialog />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px;" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <Toolbar>
        <template #start>
            <span class="p-input-icon-left mr-2">
                <i class="pi pi-search" />
                <InputText v-model="filter_customers['global'].value" placeholder="Suche" style="width: 400px" class="mr-2" />
            </span>
            <Button @click="newCustomerDialog = true" label="Kunden hinzufügen" class="w-auto mr-2 p-button" v-tooltip.top="'Ein neuer Kunden/Intressent erfassen'" icon="pi pi-plus" style="white-space:nowrap;" />
        </template>
        <template #end>
            <div class="flex align-items-center mt-2 mr-2">
                <Checkbox v-model="active" name="active" value="active" class="mr-3" />
                <label for="active" @click="active = '0'" no-wrap>Meine Kontakte</label>
            </div>
            <Dropdown v-if="active != 'active'" placeholder="Alle Kontakten anzeigen" id="crm_owner" :options="crm_owners" optionLabel="name" optionValue="id" class="w-auto"  />
        </template>
    </Toolbar>
    <!--------------------------------------------------->
    <!------------------ DataTable ---------------------->
    <!--------------------------------------------------->

    <DataTable class="mt-4" v-model:filters="filter_customers" :value="customers" :rows="50" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[50,100,200]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
        <Column header="B2B Kunden" style="width: 25%">
            <template #body="slotProps">
                <div @click="newCustomerDialog = true" v-if="slotProps.data.company_type == 'Kunde'" class="flex align-items-center justify-content-center border-round bg-blue-500 text-white p-3 font-bold">{{slotProps.data.company_name}}</div>
            </template>
        </Column>
        <Column header="Nicht kontaktiert" style="width: 15%">
            <template #body="slotProps">
                <div @click="newCustomerDialog = true" v-if="slotProps.data.crm_status == 'Nicht kontaktiert' && slotProps.data.company_type != 'Kunde'" class="flex align-items-center justify-content-center border-round bg-red-700 text-white p-3 font-bold">
                    {{slotProps.data.company_name}}
                </div>
            </template>
        </Column>
        <Column header="Kontaktiert" style="width: 15%">
            <template #body="slotProps">
                <div @click="newCustomerDialog = true" v-if="slotProps.data.crm_status == 'Kontaktiert' && slotProps.data.company_type != 'Kunde'" class="flex align-items-center justify-content-center border-round bg-orange-500 text-white p-3 font-bold">{{slotProps.data.company_name}}</div>
            </template>
        </Column>
        <Column header="Termin" style="width: 15%">
            <template #body="slotProps">
                <div @click="newCustomerDialog = true" v-if="slotProps.data.crm_status == 'Termin' && slotProps.data.company_type != 'Kunde'" class="flex align-items-center justify-content-center border-round bg-indigo-500 text-white p-3 font-bold">{{slotProps.data.company_name}}</div>
            </template>
        </Column>
        <Column header="Interessiert" style="width: 15%">
            <template #body="slotProps">
                <div @click="newCustomerDialog = true" v-if="slotProps.data.crm_status == 'Intresse' && slotProps.data.company_type != 'Kunde'" class="flex align-items-center justify-content-center border-round bg-green-600 text-white p-3 font-bold">{{slotProps.data.company_name}}</div>
            </template>
        </Column>
        <Column header="Keine intresse" style="width: 15%">
            <template #body="slotProps">
                <div @click="newCustomerDialog = true" v-if="slotProps.data.crm_status == 'Keine intresse' && slotProps.data.company_type != 'Kunde'" class="flex align-items-center justify-content-center border-round bg-bluegray-500 text-white p-3 font-bold">{{slotProps.data.company_name}}</div>
            </template>
        </Column>
        <Column field="company_name" hidden></Column>
        <Column field="company_city" hidden></Column>
    </DataTable>
    <!------------------------------------------------->
    <!------------------ Dialogs ---------------------->
    <!------------------------------------------------->
    <Dialog v-model:visible="newCustomerDialog" :style="{width: '80%'}" header="Firma hinzufügen" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-5 mt-1">
                <label for="company_name">Firmennamen</label>
                <InputText v-model="customer.company_name" id="company_name" />
            </div>
            <div class="field col-3 mt-1">
                <label for="company_type">Typ</label>
                <Dropdown v-model="customer.company_type" id="company_type" :options="company_types" optionLabel="label" optionValue="type" class="w-auto"  />
            </div>
            <div class="field col-4 mt-1">
                <label for="company_type">Industrie</label>
                <MultiSelect display="chip" v-model="customer.company_industry" id="company_type" :options="company_industries" optionLabel="label" optionValue="type" class="w-auto"  />
            </div>
            <div class="field col-5 mt-1">
                <label for="company_street">Adresse</label>
                <InputText v-model="customer.company_street" id="company_street" />
            </div>
            <div class="field col-3 mt-1">
                <label for="company_zip">PLZ</label>
                <InputNumber v-model="customer.company_zip" id="company_zip" />
            </div>
            <div class="field col-4 mt-1">
                <label for="company_city">Ort</label>
                <InputText v-model="customer.company_city" id="company_city" />
            </div>
            <div class="field col-4 mt-1">
                <label for="company_phone">Tel-Nr.</label>
                <InputText v-model="customer.company_phone" id="company_phone" />
            </div>
            <div class="field col-4 mt-1">
                <label for="company_email">E-Mail</label>
                <InputText v-model="customer.company_email" id="company_email" />
            </div>
            <div class="field col-4 mt-1">
                <label for="company_website">Webseite</label>
                <InputText v-model="customer.company_website" id="company_website" />
            </div>
            <div class="field col-3 mt-1">
                <label for="company_employees">Anzahl Mitarbeitende</label>
                <InputNumber v-model="customer.company_employees" id="company_employees" />
            </div>
            <div class="field col-3 mt-1">
                <label for="company_registration_number">Handelsregister-Nummer</label>
                <InputText v-model="customer.company_registration_number" id="company_registration_number" />
            </div>
            <div class="field col-3 mt-1">
                <label for="company_tax_number">MWST-Nummer</label>
                <InputText v-model="customer.company_tax_number" id="company_tax_number" />
            </div>
            <div class="field col-3 mt-1">
                <label for="company_sales_tax_number">Umsatzsteuer-Identifikationsnummer</label>
                <InputText v-model="customer.company_sales_tax_number" id="company_sales_tax_number" />
            </div>
            <div class="field col-12 mt-1">
                <div class="font-bold">Kontaktdaten</div>
            </div>
            <div class="field col-3 mt-1">
                <label for="contact_firstname">Vorname</label>
                <InputText v-model="customer.contact_firstname" id="contact_firstname" />
            </div>
            <div class="field col-3 mt-1">
                <label for="contact_lastname">Nachname</label>
                <InputText v-model="customer.contact_lastname" id="contact_lastname" />
            </div>
            <div class="field col-3 mt-1">
                <label for="contact_phone">Tel-Nr.</label>
                <InputText v-model="customer.contact_phone" id="contact_phone" />
            </div>
            <div class="field col-3 mt-1">
                <label for="contact_email">E-Mail</label>
                <InputText v-model="customer.contact_email" id="contact_email" />
            </div>
            <div class="field col-8 mt-1">
                <label for="contact_webshop">Webshop Konto:</label>
                <Dropdown :showToggleAll="false" :filter="true" :showClear="true" :virtualScrollerOptions="{ itemSize: 38 }" id="contact_webshop" v-model="customer.webshop_contact" :options="webshop_contacts" optionLabel="name" optionValue="id" class="w-auto" />
            </div>
            <div class="field col-12 mt-1">
                <div class="font-bold">CRM</div>
            </div>
            <div class="field col-3 mt-1">
                <label for="crm_status">Status</label>
                <Dropdown v-model="customer.crm_status" id="crm_status" :options="crm_statuses" optionLabel="label" optionValue="type" class="w-auto"  />
            </div>
            <div class="field col-3 mt-1">
                <label for="crm_owner">Vertreter</label>
                <Dropdown v-model="customer.crm_owner" id="crm_owner" :options="crm_owners" optionLabel="name" optionValue="id" class="w-auto"  />
            </div>
            <div class="field col-6 mt-1"> </div>
            <div class="field col-3 mt-1">
                <div class="flex align-items-center mt-3">
                    <Checkbox v-model="customer.crm_introduction" name="crm_introduction" value="Intro" class="mr-2" />
                    <label for="crm_introduction">Vorstellungs E-Mail senden</label>
                </div>
            </div>
            <div class="field col-3 mt-1">
                <div class="flex align-items-center mt-3">
                    <Checkbox v-model="customer.crm_welcome" name="crm_welcome" value="Welcome" class="mr-2" />
                    <label for="crm_welcome">Wilkommens E-Mails senden</label>
                </div>
            </div>
            <div class="field col-3 mt-1">
                <div class="flex align-items-center mt-3">
                    <Checkbox v-model="customer.crm_material" name="crm_material" value="Material" class="mr-2" />
                    <label for="crm_material">Verkaufsunterlagen senden</label>
                </div>
            </div>
            <div class="field col-6 mt-1">
                <label for="crm_notes">Notizen</label>
                <Textarea v-model="customer.crm_notes" id="crm_notes" />
            </div>
            <div class="field col-12 mt-1">
                <Button :disabled="customer.company_name == ''" label="Speichern" @click="new_customer(), newCustomerDialog = false" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                <Button label="Abbrechen" @click="newCustomerDialog = false" class="w-auto mr-3 mb-1 p-button-danger" icon="pi pi-times" />
            </div>
        </div>
    </Dialog>
</template>

