<script setup>
import { ref, onMounted, useSSRContext } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import { EnumStringBody } from '@babel/types'
/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
// filters
const filter_customers = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
const filter_customer_orders = ref({global: {value: null, matchMode: FilterMatchMode.CONTAINS}, status: {value: 'Bestellung', matchMode: FilterMatchMode.EQUALS}})
//////////////////
// Data Sources //
//////////////////
const customers = ref([])
const details = ref()
const customer_orders = ref([])
const search_term = ref()
const result_count = ref(200)
const customerDetailsDialog = ref(false)
const expanded_customer_orders = ref([])
const only_completed = ref(false)

const result_counts = [
    {label: '200', value: 200},
    {label: '1000', value: 1000},
    {label: '2000', value: 2000},
    {label: '5000', value: 5000},
    {label: '10000', value: 10000},
]

onMounted( async () => {
    search_customers()
})

const search_customers = () => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gerstl/webshop-admin-search-customers', {search: search_term.value, limit: result_count.value})
    .then(response => {
        console.log(response.data)
        customers.value = response.data
        loader.value = false
    })
}

const customer_details = (e) => {
    loader.value = true
    console.log("get customer orders", e.data)
    details.value = e.data
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gerstl/webshop-admin-customer-details', {search: e.data.email})
    .then(response => {
        console.log("got customer orders", response.data)
        customer_orders.value = response.data
        customerDetailsDialog.value = true
        loader.value = false
    })
}

const new_customer = () => {
    console.log("new_customer")
}

const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}

const format_currency_without_prefix = (value) => {
    return Number(value).toLocaleString('de-CH', {minimumFractionDigits: 2});
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const format_swiss_date_time = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4) + ' ' + value.substring(11,16)
}

const format_swiss_date_time_plus_hour = (datetime) => {
    let d = new Date(datetime)
    d.setHours(d.getHours() + 2)
    let month = '' + d.getMonth()
    let day = '' + d.getDate()
    let year = '' + d.getFullYear()
    let hour = '' + d.getHours()
    let minute = '' + d.getMinutes()
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    if (hour.length < 2)
        hour = '0' + hour;
    if (minute.length < 2)
        minute = '0' + minute;
    return [day, month, year].join('.') + ' ' + [hour, minute].join(':')
}

const get_open_orders_count = () => {
    let count = 0
    customer_orders.value.forEach(order => {
        if (order.status == 'Pending') {
            count++
        }
    })
    return count
}

const toggle_check = () => {
    console.log("toggle_check")
    if (only_completed.value) {
        filter_customer_orders.value = {global: {value: null, matchMode: FilterMatchMode.CONTAINS}, status: {value: null, matchMode: FilterMatchMode.CONTAINS}}
    } else {
        filter_customer_orders.value = {global: {value: null, matchMode: FilterMatchMode.CONTAINS}, status: {value: 'Bestellung', matchMode: FilterMatchMode.EQUALS}}
    }
}

const copy_to_clipboard = (value) => {
    navigator.clipboard.writeText(value)
    toast.add({severity:'success', summary: 'Erfolgreich', detail: 'In Zwischenablage kopiert', life: 3000});
}

</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>


<template>
    <Toast />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :fullScreen="true" :blocked="loader">
        <div class="col-12 mb-2">
            <Toolbar>
                <template #start>
                    <span class="p-input-icon-left mr-2">
                        <i class="pi pi-search" />
                        <InputText v-model="search_term" v-on:keyup.enter="search_customers()" placeholder="Suche" style="width: 300px"  />
                    </span>
                    <Button label="Suchen" icon="pi pi-search" class="mr-2" @click="search_customers()" />
                    <Dropdown @change="search_customers()" v-model="result_count" :options="result_counts" optionLabel="label" optionValue="value" placeholder="Resultate" style="width: 150px" />
                </template>
                <template #end>
                    <Button label="Kunden erfassen" icon="pi pi-plus" class="p-button bg-green-600 text-white border-green-700" @click="new_customer" />
                </template>
            </Toolbar>
        </div>
        <div rounded class="overflow-hidden shadow-3 border-200">
            <DataTable :value="customers" @row-click="customer_details" :rows="50" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                <Column field="id" header="Kunde" sortable>
                    <template #body="slotProps">
                        <Chip class="pl-0 pr-3 mr-2">
                            <span class="bg-primary border-circle w-2rem h-2rem flex align-items-center justify-content-center"><i class="pi pi-user" /></span>
                            <span class="ml-2 font-medium">{{slotProps.data.email}}</span>
                        </Chip>
                        <span class="font-bold mr-2">{{slotProps.data.firstName}} {{slotProps.data.lastName}}</span>
                        <span>({{slotProps.data.id}})</span>
                    </template>
                </Column>
                <Column field="email" header="E-Mail" sortable hidden>
                    <template #body="slotProps">
                        <span>{{slotProps.data.email}}</span>
                        <i @click="copy_to_clipboard(slotProps.data.email)" v-tooltip.top="'In die Zwischenablage kopieren'" class="pi pi-copy ml-2 text-primary cursor-pointer" />
                    </template>
                </Column>
                <Column field="created" header="Registriert" sortable>
                    <template #body="slotProps">
                        <span v-if="slotProps.data.created">{{format_swiss_date_time(slotProps.data.created)}}</span>
                    </template>
                </Column>
                <Column field="firstName" hidden></Column>
                <Column field="lastName" hidden></Column>
                <Column header="Bestellungen">
                    <template #body="slotProps">
                        <Avatar :label="String(slotProps.data.orders)" class="mr-2 bg-primary text-white" shape="circle" v-tooltip.top="String(slotProps.data.orders) + '  Bestellungen'" />
                    </template>
                </Column>
                <!--
                <Column header="Artikel">
                    <template #body="slotProps">
                        <Avatar :label="String(slotProps.data.total_articles)" class="mr-2 bg-primary text-white" shape="circle" v-tooltip.top="String(slotProps.data.orders) + ' Produkte'" />
                    </template>
                </Column>
                <Column field="amount" header="Total" sortable>
                    <template #body="slotProps">
                        <span class="font-bold">{{format_currency(slotProps.data.total_purchase)}}</span>
                    </template>
                </Column>
                -->
            </DataTable>
        </div>
    </BlockUI>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <Sidebar v-model:visible="customerDetailsDialog" position="right" style="width: 60rem">
        <div class="col-12 formgrid grid">
            <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0" hidden>
                Kunden-Nr.
            </div>
            <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0" hidden>
                <span class="font-bold">{{details.id}}</span>
                <i @click="copy_to_clipboard(details.id)" v-tooltip.top="'In die Zwischenablage kopieren'" class="pi pi-copy ml-2 text-primary cursor-pointer" />
            </div>
            <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                E-Mail
            </div>
            <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <span class="font-bold">{{details.email}}</span>
                <i @click="copy_to_clipboard(details.email)" v-tooltip.top="'In die Zwischenablage kopieren'" class="pi pi-copy ml-2 text-primary cursor-pointer" />
            </div>
            <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Anrede
            </div>
            <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                {{details.salute}}
            </div>
            <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Vorname
            </div>
            <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                {{details.firstName}}
            </div>
            <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Nachname
            </div>
            <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                {{details.lastName}}
            </div>
            <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Geburtsdatum
            </div>
            <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <span v-if="details.birthday">{{ format_swiss_date(details.birthday) }}</span>
            </div>
            <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Registriert
            </div>
            <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <span v-if="details.created">{{ format_swiss_date_time_plus_hour(details.created) }}</span>
            </div>
            <div v-if="details.billing" class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Rechnungsadresse
            </div>
            <div v-if="details.billing" class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <div class="mt-1" v-if="details.billing.company">
                    <span class="mr-1">{{ details.billing.company }}</span>
                </div>
                <div class="mt-1">
                    <span v-if="details.billing.honorific" class="mr-1">{{ details.billing.honorific }}</span>
                    <span class="mr-1">{{ details.billing.firstName }}</span>
                    <span class="mr-1">{{ details.billing.lastName }}</span>
                </div>
                <div class="mt-1">
                    <span class="mr-1">{{ details.billing.address1 }}</span>
                    <span class="mr-1">{{ details.billing.streetNumber }}</span>
                </div>
                <div class="mt-1" v-if="details.billing.address2">
                    <span class="mr-1">{{ details.billing.address2 }}</span>
                </div>
                <div class="mt-1">
                    <span class="mr-1">{{ details.billing.postcode }}</span>
                    <span class="mr-1">{{ details.billing.city }}</span>
                </div>
                <div class="mt-1">
                    <span class="mr-2 font-bold">Tel:</span>
                    <span class="mr-2">{{ details.billing.phone }}</span>
                    <i @click="copy_to_clipboard(details.billing.phone)" v-tooltip.top="'In die Zwischenablage kopieren'" class="pi pi-copy text-primary cursor-pointer" />
                </div>
                <div class="mt-1" v-if="details.billing.birthDate">
                    <span class="mr-2 font-bold">Geburtstag:</span>
                    <span class="mr-2">{{ details.billing.birthDate }}</span>
                </div>
                <div class="mt-1" v-if="details.billing.email">
                    <span class="mr-2 font-bold">E-Mail:</span>
                    <span class="mr-2"><a class="font-bold text-primary" :href="'mailto:' + details.billing.email">{{ details.billing.email }}</a></span>
                    <i @click="copy_to_clipboard(details.billing.email)" v-tooltip.top="'In die Zwischenablage kopieren'" class="pi pi-copy text-primary cursor-pointer" />
                </div>
            </div>
            <div v-if="details.shipping" class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Lieferadresse
            </div>
            <div v-if="details.shipping" class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <div class="mt-1" v-if="details.shipping.company">
                    <span class="mr-1">{{ details.shipping.company }}</span>
                </div>
                <div class="mt-1">
                    <span v-if="details.shipping.honorific" class="mr-1">{{ details.shipping.honorific }}</span>
                    <span class="mr-1">{{ details.shipping.firstName }}</span>
                    <span class="mr-1">{{ details.shipping.lastName }}</span>
                </div>
                <div class="mt-1">
                    <span class="mr-1">{{ details.shipping.address1 }}</span>
                    <span class="mr-1">{{ details.shipping.streetNumber }}</span>
                </div>
                <div class="mt-1" v-if="details.shipping.address2">
                    <span class="mr-1">{{ details.shipping.address2 }}</span>
                </div>
                <div class="mt-1">
                    <span class="mr-1">{{ details.shipping.postcode }}</span>
                    <span class="mr-1">{{ details.shipping.city }}</span>
                </div>
                <div class="mt-1">
                    <span class="mr-2 font-bold">Tel:</span>
                    <span class="mr-2">{{ details.shipping.phone }}</span>
                    <i @click="copy_to_clipboard(details.shipping.phone)" v-tooltip.top="'In die Zwischenablage kopieren'" class="pi pi-copy text-primary cursor-pointer" />
                </div>
                <div class="mt-1" v-if="details.shipping.birthDate">
                    <span class="mr-2 font-bold">Geburtstag:</span>
                    <span class="mr-2">{{ details.shipping.birthDate }}</span>
                </div>
                <div class="mt-1" v-if="details.shipping.email">
                    <span class="mr-2 font-bold">E-Mail:</span>
                    <span class="mr-2"><a class="font-bold text-primary" :href="'mailto:' + details.shipping.email">{{ details.shipping.email }}</a></span>
                    <i @click="copy_to_clipboard(details.email)" v-tooltip.top="'In die Zwischenablage kopieren'" class="pi pi-copy text-primary cursor-pointer" />
                </div>
            </div>
            <div class="field col-12 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Bestellungen
            </div>
            <div v-if="get_open_orders_count() > 0" class="field col-12 pt-4 pb-4 pl-2 pr-2 border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                <div class="flex justify-content-start">
                    <Checkbox v-model="only_completed" binary class="mr-2" name="show_pending" @change="toggle_check()" />
                    <label for="show_pending" class="ml-2">Nicht abgeschlossene Bestellungen anzeigen ({{get_open_orders_count()}})</label>
                </div>
            </div>
            <div class="field col-12 p-2 border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                <div rounded class="overflow-hidden shadow-3 border-200">
                    <DataTable v-model:filters="filter_customer_orders" v-model:expandedRows="expanded_customer_orders" :value="customer_orders" responsiveLayout="scroll" :rowHover="true">
                        <Column expander style="width: 4rem" />
                        <Column field="number" header="Bestellung" :sortable="true">
                            <template #body="slotProps">
                                <Badge :value="String(slotProps.data.lines.length)" class="mr-2" severity="secondary"></Badge>
                                <span class="font-bold mr-2">{{ slotProps.data.number }}</span>
                                <i @click="copy_to_clipboard(slotProps.data.number)" v-tooltip.top="'In die Zwischenablage kopieren'" class="pi pi-copy text-primary cursor-pointer" />
                            </template>
                        </Column>
                        <Column field="date" header="Datum" :sortable="true">
                            <template #body="slotProps">
                                <span v-if="slotProps.data.date">{{ format_swiss_date(slotProps.data.date) }}</span>
                            </template>
                        </Column>
                        <Column field="status" header="Status" :sortable="true">
                            <template #body="slotProps">
                                <Badge v-if="slotProps.data.status == 'Bestellung'" value="Abgeschlossen" class="mr-2 bg-green-600 text-white" />
                                <Badge v-if="slotProps.data.status == 'Pending'" value="Offen" class="mr-2 bg-yellow-500 text-black" />
                                <Badge v-if="slotProps.data.status == 'Rechnung'" value="Rechnung" class="mr-2 bg-primary text-white" />
                            </template>
                        </Column>
                        <Column field="promoCode" header="Promo Code" sortable>
                            <template #body="slotProps">
                                <span>{{slotProps.data.promoCode}}</span>
                            </template>
                        </Column>
                        <Column field="amount" header="Total" :sortable="true">
                            <template #body="slotProps">
                                <span class="font-bold">{{format_currency(slotProps.data.amount)}}</span>
                            </template>
                        </Column>
                        <template #expansion="slotProps">
                            <div rounded class="overflow-hidden shadow-3 border-200">
                                <DataTable :value="slotProps.data.lines">
                                    <Column field="sku" header="SKU" />
                                    <Column field="name" header="Name" />
                                    <Column field="quantity" header="Menge" />
                                    <Column field="price" header="Preis">
                                        <template #body="slotProps">
                                            <span class="font-bold">{{format_currency(slotProps.data.price)}}</span>
                                        </template>
                                    </Column>
                                    <Column field="total" header="Total">
                                        <template #body="slotProps">
                                            <span class="font-bold">{{format_currency(slotProps.data.total)}}</span>
                                        </template>
                                    </Column>
                                </DataTable>
                            </div>
                        </template>
                    </DataTable>
                </div>
            </div>
        </div>
        <template #header>
            <div class="flex">
                <button class="p-sidebar-icon p-link mr-2">
                    <span class="pi pi-print" />
                </button>
            </div>
        </template>
    </Sidebar>
</template>