import {
    Bars3Icon,
    CalendarIcon,
    HomeIcon,
    ArrowUpIcon,
    ArrowUpOnSquareIcon,
    MagnifyingGlassCircleIcon,
    MapIcon,
    MegaphoneIcon,
    UserGroupIcon,
    XMarkIcon,
    InboxIcon,
    UsersIcon,
    CogIcon,
    DocumentIcon,
    QuestionMarkCircleIcon,
    ArrowLeftOnRectangleIcon,
    ArrowDownTrayIcon,
    EnvelopeIcon,
    BellIcon,
    AtSymbolIcon,
    EnvelopeOpenIcon, MagnifyingGlassIcon, Bars3BottomLeftIcon, ChartPieIcon, BookOpenIcon, GlobeAltIcon, DocumentMagnifyingGlassIcon, DocumentTextIcon, NewspaperIcon, ShoppingCartIcon, BeakerIcon
} from '@heroicons/vue/24/outline';

//https://vue-hero-icons.netlify.app/

export default [{ name: 'Dashboard', href: '/dashboard', icon: HomeIcon, current: false, order: 0 },
{ name: 'Startseite', href: '/dashboard/Startseite', icon: HomeIcon, current: false, order: 1 },
{ name: 'Webshop', href: '/dashboard/Webshop', icon: ShoppingCartIcon, current: false, order: 2 },
{ name: 'Promocodes', href: '/dashboard/Promocodes', icon: MegaphoneIcon, current: false, order: 2.1 },
{ name: 'Promotionen', href: '/dashboard/Promotionen', icon: MegaphoneIcon, current: false, order: 2.11 },
{ name: 'Shortlinks', href: '/dashboard/Shortlinks', icon: AtSymbolIcon, current: false, order: 2.2 },
{ name: 'Filter-Seiten', href: '/dashboard/Filter-Seiten', icon: DocumentMagnifyingGlassIcon, current: false, order: 3 },
{ name: 'Produzenten', href: '/dashboard/Produzenten', icon: DocumentTextIcon, current: false, order: 4 },
{ name: 'Magazin', href: '/dashboard/Magazin', icon: NewspaperIcon, current: false, order: 5 },
{ name: 'Events', href: '/dashboard/Events', icon: CalendarIcon, current: false, order: 6 },
{ name: 'Eventsv2', href: '/dashboard/Eventsv2', icon: CalendarIcon, current: false, order: 6.1 },
{ name: 'Weinbroschüren', href: '/dashboard/Weinbroschüren', icon: DocumentIcon, current: false, order: 6.2 },
{ name: 'Seiten', href: '/dashboard/Seiten', icon: BookOpenIcon, current: false, order: 7 },
{ name: 'Weinalarm', href: '/dashboard/Weinalarm', icon: BellIcon, current: false, order: 8 },
{ name: 'Team', href: '/dashboard/Team', icon: UserGroupIcon, current: false, order: 9 },
{ name: 'Statistiken', href: '/dashboard/Statistiken', icon: ChartPieIcon, current: false, order: 10 },
{ name: 'Tools', href: '/dashboard/Tools', icon: BeakerIcon, current: false, order: 11},
{ name: 'Einstellungen', href: '/dashboard/Einstellungen', icon: CogIcon, current: false, order: 12 },
{ name: 'B2B', href: '/dashboard/B2B', icon: UsersIcon, current: false, order: 13 },
{ name: 'Logout', href: '/logout', icon: ArrowLeftOnRectangleIcon, current: false, order: 14 }]