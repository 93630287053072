<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"

import UploadPDF from './Library-Upload-PDF.vue'

const toast = useToast()
const confirm = useConfirm()
// filters
const filter_library = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
const loader = ref(false)

const library = ref()
const uploadDialog = ref(false)


const props = defineProps({
    redis_key: { type: String, required: true }
})

const component_callback = (e) => {
    get_library(props.redis_key)
}

onMounted( async () => {
    loader.value = true
    await get_library(props.redis_key)
    loader.value = false
})

const get_library = (redis_key) => {
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/get-library", {"redis_key": redis_key})
        .then((response) => {
            library.value = response.data
            console.log(library.value)
            resolve()
        })
        .catch((error) => {
            toast.add({severity:'error', summary: 'Fehler', detail: 'Fehler beim Laden der Bibliotheken'})
            reject()
        })
    })
}

const update_library = () => {
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/update-library", {"redis_key": props.redis_key, "library": library.value})
        .then((response) => {
            library.value = response.data
            toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Bibliothek aktualisiert', life: 3000})
            resolve()
        })
        .catch((error) => {
            toast.add({severity:'error', summary: 'Fehler', detail: 'Fehler beim Aktualisieren der Bibliothek'})
            reject()
        })
    })
}

const copy_to_clipboard_html = (text, html) => {
    let blobHtml = new Blob([html], { type: "text/html" });
    let blobText = new Blob([text], { type: "text/plain" });
    let data = [new ClipboardItem({["text/plain"]: blobText, ["text/html"]: blobHtml})]
    navigator.clipboard.write(data)
    toast.add({severity:'success', summary: 'Erfolgreich', detail: 'In Zwischenablage kopiert', life: 3000})
}

const copy_to_clipboard = (value) => {
    navigator.clipboard.writeText(value)
    toast.add({severity:'success', summary: 'Erfolgreich', detail: 'In Zwischenablage kopiert', life: 3000})
}

function bytes_for_humans(bytes, decimals = 2) {
    let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']
    let i = 0
    for (i; bytes > 1024; i++) {
        bytes /= 1024;
    }
    return parseFloat(bytes.toFixed(decimals)) + ' ' + units[i]
}

const format_swiss_date_time = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4) + ' ' + value.substring(11,16)
}

const open_url = (value) => {
    window.open(value, '_blank')
}

const on_row_reorder = (e) => {
    library.value = e.value
    update_library()
}

const on_row_edit = (e) => {
    if (library.value[e.index][e.field] != e.newValue){
        library.value[e.index][e.field] = e.newValue
        update_library()
    }
}

const delete_file = (e) => {
    confirm.require({
        message: 'Wollen Sie wirklich die Datei '+e.data.name+' löschen?',
        header: 'Datei löschen',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Datei löschen',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            library.value.splice(e.index, 1)
            update_library()
        }
    })
}

</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <Toast />
    <ConfirmDialog />
    <div class="col-12 formgrid grid">
        <div class="col-12 mb-2">
            <Toolbar>
                <template #start>
                    <span class="p-input-icon-left mr-2">
                        <i class="pi pi-search" />
                        <InputText v-model="filter_library['global'].value" placeholder="Suche" style="width: 300px"  />
                    </span>
                    <Button icon="pi pi-refresh" @click="get_library(props.redis_key)" class="mr-2 bg-primary text-white" v-tooltip.top="'Liste aktualisieren'"></Button>
                </template>
                <template #end>
                    <Button icon="pi pi-upload" @click="uploadDialog = true" class="mr-2 bg-green-600 text-white border-green-700" label="PDF Hochladen"></Button>
                </template>
            </Toolbar>
        </div>
        <div class="col-12 mb-2">
            <DataTable v-model:filters="filter_library" :value="library" :rows="50" editMode="cell" @cell-edit-complete="on_row_edit" @rowReorder="on_row_reorder"  responsiveLayout="scroll" class="w-full" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                <Column field="name" header="Name" sortable>
                    <template #editor="{ data, field }">
                        <InputText v-model="data[field]" class="w-full" />
                    </template>
                    <template #body="{ data, field }">
                        <Avatar icon="pi pi-file-pdf" class="mr-2 bg-primary text-white" shape="circle" />
                        <span class="font-bold">{{data[field]}}</span>
                        <div style="padding-left: 40px"><small>{{data["filename"]}} ({{ bytes_for_humans(data["size"]) }})</small></div>
                    </template>
                </Column>
                <Column field="dc" header="Hochgeladen">
                    <template #body="slotProps">
                        <span>{{format_swiss_date_time(slotProps.data.dc)}}</span>
                    </template>
                </Column>
                <Column field="filename" hidden />
                <Column>
                    <template #body="slotProps">
                        <Button label="Namen" icon="pi pi-copy" class="p-2 mr-2" size="smal" @click="copy_to_clipboard(slotProps.data.name)" />
                        <Button label="URL" icon="pi pi-copy" class="p-2 mr-2" size="smal" @click="copy_to_clipboard(slotProps.data.url)" />
                        <Button label="HTML" icon="pi pi-copy" class="p-2 mr-2" size="smal" @click="copy_to_clipboard_html(slotProps.data.name, '<a class=\'rudy-dl\' href=\''+slotProps.data.url+'\'>'+slotProps.data.name+'</a>')" />
                        <Button @click="open_url(slotProps.data.url)" v-tooltip.top="'Artikel Vorschau'" class="p-button-rounded sm p-button-text" icon="pi pi-eye" />
                        <Button @click="delete_file(slotProps)" v-tooltip.top="'Artikel löschen'" class="p-button-rounded p-button-text" icon="pi pi-trash" />
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
    <Dialog v-model:visible="uploadDialog" :style="{width: '800px'}" header="PDFs hochladen" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <UploadPDF :redis_key="props.redis_key"  @component-callback="component_callback" />
            </div>
        </div>
    </Dialog>
</template>