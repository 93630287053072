<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from "primevue/useconfirm"
import { FilterMatchMode } from 'primevue/api'
import axios from 'axios'

/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

// filters
const filter_promo = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
const filter_sage_groups = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})

// data

const promo = ref({})
const promos = ref([])
const changes = ref(0)
const editing_mode = ref(0)
const products = ref([])
const sage_groups = ref([])
const sage_groups_product_count = ref(0)
const sage_groups_excluded_product_count = ref(0)

const add_mode = ref(true)
const product_search_value = ref('')

const edit_promo_slider = ref(false)
const add_product_slider = ref(false)
const add_sage_group_slider = ref(false)

onMounted(() => {
    get_promos()
})

const get_promos = () => {
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gerstlv2/get-promos')
    .then(response => {
        promos.value = response.data
        changes.value = 0
    })
}

const search_products = () => {
    if (product_search_value.value.length > 2) {
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gerstlv2/search-products', {search: product_search_value.value})
        .then(response => {
            console.log("search_results", response.data)
            products.value = response.data.data
        })
    }
}

const get_sage_groups = () => {
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gerstlv2/get-sage-groups')
    .then(response => {
        console.log("sage_groups", response.data)
        sage_groups.value = response.data.filters3.sageGroups
    })
}

const get_sage_groups_product_count = (groups = [], mode = "include") => {
    if (groups.length > 0) {
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gerstlv2/get-sage-groups-product-count', {sage_groups: groups})
        .then(response => {
            console.log("sage_group_count", response.data.count)
            if (mode == "include") {
                sage_groups_product_count.value = response.data.count
            } else {
                sage_groups_excluded_product_count.value = response.data.count
            }
        })
    }
}

const promo_types = ref([
    { label: 'CHF', value: 'CHF' },
    { label: 'Prozent', value: 'percent' },
    { label: 'Gratisversand', value: 'FreeShipping' },
    { label: '6 für 5', value: '6for5' },
    { label: 'FanPoints', value: 'FanPoints' }
])

const statuses = ref([
    { label: 'Entwurf', value: 'draft' },
    { label: 'Publiziert', value: 'published' }
])

const price_categories = ref([
    { label: 'Privat (PR)', value: 'PR' },
    { label: 'Hotel+Restaurant (HR)', value: 'HR' },
    { label: 'Hotel+Restaurant 2 (HR2)', value: 'HR2' },
    { label: 'Wholesale (WS)', value: 'WS' },
    { label: 'Sonderpreise', value: 'SP' }
])

const yes_no = ref([
    { label: 'Ja', value: true },
    { label: 'Nein', value: false }
])

const new_promo = ref({
    "workflow_id": "",
    "name": "",
    "code": "",
    "type": "CHF",
    "minimum": 0,
    "automated": false,
    "usage": 0,
    "min_bottles": 0,
    "used": [],
    "status": "draft",
    "discount": 0,
    "products": [],
    "price_categories": [{"label": "Privat (PR)", "value": "PR"}],
    "start": "",
    "end": "",
    "sage_group_excluded": [],
    "sage_group": [],
    "excluded": [],
    "single_positions": true
})

const edit_promos = (e) => {
    if (check_code_exists(e.newValue) && e.field == 'code' && e.newValue != e.value) {
        toast.add({severity:'error', summary:'Fehler', detail:'Promocode existiert bereits', life: 3000})
    } else if (e.field == 'status' && e.newValue == 'published' && e.data.sage_group_excluded.length == 0) {
        toast.add({severity:'error', summary:'Fehler', detail:'Keine Produkte oder Sage Gruppen wurden ausgewählt!', life: 3000})
    } else {
        for (let i = 0; i < promos.value.length; i++) {
            if (promos.value[i].workflow_id == e.data.workflow_id) {
                if (promos.value[i][e.field] != e.newValue) {
                    promos.value[i][e.field] = e.newValue
                    changes.value++
                }
            }
        }
    }
    editing_mode.value--
}

const add_promo = (slotProps) => {
    let promo = new_promo.value
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gerstlv2/get-ids')
    .then(response => {
        console.log(response.data)
        console.log(promo)
        promo["workflow_id"] = response.data[0]
        promo["code"] = response.data[1]
        promos.value.push(promo)
        changes.value++
    })
}

const delete_promo = (index) => {
    confirm.require({
        message: "Möchten Sie diese Promotion wirklich löschen?",
        header: "Bestätigen",
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: "Ja, löschen",
        rejectLabel: "Nein",
        accept: () => {
            promos.value.splice(index, 1)
            changes.value++
        }
    })
}

const edit_promo = (slotProps) => {
    edit_promo_slider.value = true
    promo.value = slotProps.data
    get_sage_groups_product_count(promo.value.sage_group, "include")
    get_sage_groups_product_count(promo.value.sage_group_excluded, "exclude")
}

const reorder_promos = (e) => {
    const moved = promos.value.splice(e.dragIndex, 1)[0]
    promos.value.splice(e.dropIndex, 0, moved)
    changes.value++
}

const init_cell = () => {
    editing_mode.value++
}

const save_changes = () => {
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gerstlv2/save-promos', promos.value)
    .then(response => {
        console.log("save response", response.data)
        if (response.data.status == 'success') {
            toast.add({severity:'success', summary:'Erfolg', detail:'Änderungen gespeichert', life: 3000})
            changes.value = 0
        } else {
            toast.add({severity:'error', summary:'Fehler', detail:'Änderungen konnten nicht gespeichert werden', life: 3000})
        }
    })
}

const reset_changes = () => {
    get_promos()
}

const check_code_exists = (code) => {
    return promos.value.find(p => p.code == code)
}

const get_status = (status) => {
    return statuses.value.find(s => s.value == status).label
}

const get_promo_type = (type) => {
    return promo_types.value.find(s => s.value == type).label
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Toast />
    <ConfirmDialog />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <Toolbar class="mt-2">
        <template #start>
            <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filter_promo['global'].value" placeholder="Suche" style="width: 400px" class="mr-2" />
            </span>
            <Button :disabled="editing_mode > 0 ? true : false" v-if="changes > 0" @click="save_changes" v-tooltip.bottom="'Änderungen speichern'" label="Änderungen speichern" class="w-auto p-button-success mx-2 mr-3" icon="pi pi-save" :badge="String(changes)" badgeSeverity="danger" />
            <Button v-if="changes > 0" v-tooltip.bottom="'Änderungen zurücksetzen'" @click="reset_changes" class="mr-2 p-button-danger" icon="pi pi-refresh" />
        </template>
    </Toolbar>
    <div rounded class="overflow-hidden shadow-4 rounded border-200 mt-4">
        <DataTable v-model:filters="filter_promo" :value="promos" :rows="50" responsiveLayout="scroll" :rowsPerPageOptions="[50, 100, 300]" editMode="cell" @cell-edit-complete="edit_promos" @cell-edit-init="init_cell" :rowHover="true" @rowReorder="reorder_promos" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
            <Column v-if="!filter_promo['global'].value" :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
            <Column field="name" header="Name">
                <template #body="slotProps">
                    <Avatar icon="pi pi-megaphone" class="mr-2 bg-primary text-white" />
                    <span class="font-bold">{{ slotProps.data.name }}</span>
                </template>
                <template #editor="slotProps">
                    <InputText v-model="slotProps.data.name" class="w-full" />
                </template>
            </Column>
            <Column field="type" header="Typ">
                <template #body="slotProps">
                    <Chip :label="get_promo_type(slotProps.data.type)" class="bg-primary text-white" />
                </template>
                <template #editor="slotProps">
                    <Dropdown v-model="slotProps.data.type" :options="promo_types" optionLabel="label" optionValue="value" style="width: 100%" />
                </template>
            </Column>
            <Column field="code" header="Code">
                <template #body="slotProps">
                    <span>{{ slotProps.data.code }}</span>
                </template>
                <template #editor="slotProps">
                    <InputText v-model="slotProps.data.code" class="w-full" />
                </template>
            </Column>
            <Column field="used" header="Angewandt">
                <template #body="slotProps">
                    <span>{{ slotProps.data.used.length }}</span>
                </template>
            </Column>
            <Column field="status" header="Status" style="width: 300px">
                <template #body="{ data, field }">
                    <Chip v-if="data[field] == 'draft'" :label="get_status(data[field])" v-tooltip.top="'Beitrag ist Publiziert'" />
                    <Chip v-if="data[field] == 'published'" :label="get_status(data[field])" v-tooltip.top="'Beitrag ist Publiziert'" class="bg-green-200" />
                </template>
                <template #editor="{ data, field }">
                    <Dropdown v-model="data[field]" :options="statuses" optionLabel="label" optionValue="value" style="width: 100%" />
                </template>
            </Column>
            <Column style="width: 200px">
                <template #body="slotProps">
                    <Button v-if="changes == 0" icon="pi pi-pencil" class="mr-2 p-button-rounded p-button-primary" @click="edit_promo(slotProps)" v-tooltip.top="'Promotion editieren'" />
                    <Button v-if="changes == 0" icon="pi pi-trash" class="mr-2 p-button-rounded p-button-danger" @click="delete_promo(slotProps.index)" v-tooltip.top="'Promotion löschen'" />
                </template>
                <template #header="slotProps">
                    <Button v-if="changes == 0" icon="pi pi-plus" class="mr-2 p-button-rounded" @click="add_promo(slotProps)" v-tooltip.top="'Promotion hinzufügen'" />
                </template>
            </Column>
        </DataTable>
    </div>
    <!---------------------------------------------------------------->
    <!-------------------------- Dialogs ----------------------------->
    <!---------------------------------------------------------------->
    <!---------------------------------------------------------------->
    <!------------------------- Edit Promo --------------------------->
    <!---------------------------------------------------------------->
    <Sidebar header="Promotion bearbeiten" v-model:visible="edit_promo_slider" position="left" style="width: 50%">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-4">
                <div class="font-bold p2">Konfiguration</div>
            </div>
            <div class="field col-6 mt-4">
                <span class="p-float-label">
                    <InputText id="name" v-model="promo.name" class="w-full" />
                    <label for="name">Name</label>
                </span>
            </div>
            <div class="field col-6 mt-4">
                <span class="p-float-label">
                    <InputText :disabled="promo.automated ? true : false" id="code" v-model="promo.code" class="w-full" />
                    <label for="code">Code</label>
                </span>
            </div>
            <div class="field col-6 mt-4">
                <span class="p-float-label">
                    <Dropdown id="type" v-model="promo.type" :options="promo_types" optionLabel="label" optionValue="value" style="width: 100%" />
                    <label for="type">Typ</label>
                </span>
            </div>
            <div class="field col-6 mt-4">
                <span class="p-float-label">
                    <Dropdown id="status" v-model="promo.status" :options="statuses" optionLabel="label" optionValue="value" style="width: 100%" />
                    <label for="status">Status</label>
                </span>
            </div>
            <div class="field col-6 mt-4">
                <span class="p-float-label">
                    <Dropdown id="automated" v-model="promo.automated" :options="yes_no" optionLabel="label" optionValue="value" style="width: 100%" />
                    <label for="automated">Automatische Anwendung</label>
                </span>
            </div>
            <div class="field col-6 mt-4">
                <span class="p-float-label">
                    <Dropdown id="automated" v-model="promo.single_positions" :options="yes_no" optionLabel="label" optionValue="value" style="width: 100%" />
                    <label for="automated">Einzelne Positonen</label>
                </span>
            </div>
            <div class="field col-6 mt-4">
                <span class="p-float-label">
                    <Calendar id="start" :showIcon="true" dateFormat="dd.mm.yy" v-model="promo.start" class="w-full" />
                    <label for="start">Start Datum</label>
                </span>
            </div>
            <div class="field col-6 mt-4">
                <span class="p-float-label">
                    <Calendar id="end" :showIcon="true" dateFormat="dd.mm.yy" v-model="promo.end" class="w-full" />
                    <label for="end">Schluss Datum</label>
                </span>
            </div>
            <div class="field col-12 mt-4">
                <span class="p-float-label">
                    <MultiSelect id="price_categories" v-model="promo.price_categories" :options="price_categories" optionLabel="label" style="width: 100%" display="chip" />
                    <label for="price_categories">Preis Kategorie</label>
                </span>
            </div>
            <div v-if="promo.type == 'FanPoints'" class="field col-6 mt-4">
                <span class="p-float-label">
                    <InputText id="fanpoints_loyalty_programm_id" v-model="promo.fanpoints_loyalty_programm_id" class="w-full" />
                    <label for="fanpoints_loyalty_programm_id">FanPoints Loyalty-Programm-ID</label>
                </span>
            </div>
            <div v-if="promo.type == 'FanPoints'" class="field col-6 mt-4">
                <span class="p-float-label">
                    <InputText id="fanpoints_secret" v-model="promo.fanpoints_secret" class="w-full" />
                    <label for="fanpoints_secret">FanPoints Secret</label>
                </span>
            </div>
            <div v-if="promo.type == 'FanPoints'" class="field col-6 mt-4">
                <span class="p-float-label">
                    <InputText id="fanpoints_client_id" v-model="promo.fanpoints_client_id" class="w-full" />
                    <label for="fanpoints_client_id">FanPoints Client-ID</label>
                </span>
            </div>
            <div class="field col-12 mt-4">
                <div class="font-bold p2">Bedingungen</div>
            </div>
            <div v-if="promo.type == 'CHF' || promo.type == 'percent' || promo.type == 'FanPoints'" class="field col-6 mt-4">
                <span class="p-float-label">
                    <InputNumber v-if="promo.type == 'CHF'" mode="currency" currency="CHF" locale="de-CH" id="discount" v-model="promo.discount" class="w-full" />
                    <InputNumber v-if="promo.type == 'percent' || promo.type == 'FanPoints'" suffix=" %" id="discount" v-model="promo.discount" class="w-full" />
                    <label v-if="promo.type == 'CHF'" for="discount">Rabatt CHF</label>
                    <label v-if="promo.type == 'percent' || promo.type == 'FanPoints'" for="discount">Rabatt %</label>
                </span>
            </div>
            <div class="field col-6 mt-4">
                <span class="p-float-label">
                    <InputNumber id="min_bottles" v-model="promo.min_bottles" class="w-full" />
                    <label for="min_bottles">Minimum Flaschen</label>
                </span>
            </div>
            <div class="field col-6 mt-4">
                <span class="p-float-label">
                    <InputNumber mode="currency" currency="CHF" locale="de-CH" id="minimum" v-model="promo.minimum" class="w-full" />
                    <label for="minimum">Minimum Bestellung</label>
                </span>
            </div>
            <div class="field col-6 mt-4">
                <span class="p-float-label">
                    <InputNumber id="usage" v-model="promo.usage" class="w-full" />
                    <label for="usage">Maximale Anwendung</label>
                </span>
            </div>
            <div class="field col-12 mt-4">
                <Fieldset legend="Ausgewählte Produkte" class="w-full pl-3">
                    <div>
                        <Chip v-for="(product) in promo.products" :key="product" :label="String(product)" removable @remove="promo.products = promo.products.filter(p => p != product)" class="mr-2 mt-1" />
                    </div>
                    <div class="pl-2 mt-2 flex justify-content-between flex-wrap">
                        <small v-if="promo.products.length != 1" class="mr-auto">{{String(promo.products.length)}} x Produkte</small>
                        <small v-if="promo.products.length == 1" class="mr-auto">{{String(promo.products.length)}} x Produkt</small>
                        <Button icon="pi pi-plus" class="p-button-rounded p-button-primary ml-auto" @click="add_product_slider = true, add_mode = true"  v-tooltip.top="'Produkt hinzufügen'"  />
                    </div>
                </Fieldset>
            </div>
            <div class="field col-12 mt-4">
                <Fieldset legend="Ausgeschlossene Produkte" class="w-full pl-3">
                    <div>
                        <Chip v-for="(exclude) in promo.excluded" :key="exclude" :label="String(exclude)" removable @remove="promo.excluded = promo.excluded.filter(p => p != exclude)" class="mr-2 mt-1 bg-red-200" />
                    </div>
                    <div class="pl-2 mt-2 flex justify-content-between flex-wrap">
                        <small v-if="promo.excluded.length != 1" class="mr-auto">{{String(promo.excluded.length)}} x Produkte</small>
                        <small v-if="promo.excluded.length == 1" class="mr-auto">{{String(promo.excluded.length)}} x Produkt</small>
                        <Button icon="pi pi-plus" class="p-button-rounded p-button-primary ml-auto" @click="add_product_slider = true, add_mode = false"  v-tooltip.top="'Ausgeschlossenes Produkt hinzufügen'"  />
                    </div>
                </Fieldset>
            </div>
            <div class="field col-12 mt-4">
                <Fieldset legend="Ausgewählte Sage Gruppen" class="w-full pl-3">
                    <div>
                        <Chip v-for="(group) in promo.sage_group" :key="group" :label="String(group)" removable @remove="promo.sage_group = promo.sage_group.filter(p => p != group)" class="mr-2 mt-1" />
                    </div>
                    <div class="pl-2 mt-2 flex justify-content-between flex-wrap">
                        <small v-if="promo.sage_group.length != 1" class="mr-auto">{{String(promo.sage_group.length)}} x Gruppen ({{ String(sage_groups_product_count) }} Produkte)</small>
                        <small v-if="promo.sage_group.length == 1" class="mr-auto">{{String(promo.sage_group.length)}} x Gruppe ({{ String(sage_groups_product_count) }} Produkte)</small>
                        <Button icon="pi pi-plus" class="p-button-rounded p-button-primary ml-auto" @click="add_sage_group_slider = true, add_mode = true, get_sage_groups()"  v-tooltip.top="'Gruppe hinzufügen'"  />
                    </div>
                </Fieldset>
            </div>
            <div class="field col-12 mt-4">
                <Fieldset legend="Ausgeschlossene Sage Gruppen" class="w-full pl-3">
                    <div>
                        <Chip v-for="(exclude) in promo.sage_group_excluded" :key="exclude" :label="String(exclude)" removable @remove="promo.sage_group_excluded = promo.sage_group_excluded.filter(p => p != exclude)" class="mr-2 mt-1 bg-red-200" />
                    </div>
                    <div class="pl-2 mt-2 flex justify-content-between flex-wrap">
                        <small v-if="promo.sage_group_excluded.length != 1" class="mr-auto">{{String(promo.sage_group_excluded.length)}} x Gruppen ({{ String(sage_groups_excluded_product_count) }} Produkte)</small>
                        <small v-if="promo.sage_group_excluded.length == 1" class="mr-auto">{{String(promo.sage_group_excluded.length)}} x Gruppe ({{ String(sage_groups_excluded_product_count) }} Produkte)</small>
                        <Button icon="pi pi-plus" class="p-button-rounded p-button-primary ml-auto" @click="add_sage_group_slider = true, add_mode = false, get_sage_groups()"  v-tooltip.top="'Ausgeschlossene Gruppe hinzufügen'"  />
                    </div>
                </Fieldset>
            </div>
            <div class="field col-12 mt-4">
                <Button label="Speichern" class="p-button-success mr-2" @click="edit_promo_slider = false, changes += 1" />
                <Button label="Abbrechen" class="p-button-danger" @click="edit_promo_slider = false" />
            </div>
        </div>
    </Sidebar>
    <!---------------------------------------------------------------->
    <!------------------------ Add Product --------------------------->
    <!---------------------------------------------------------------->
    <Sidebar header="Produkt suchen" v-model:visible="add_product_slider" position="right" style="width: 50%">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-4">
                <Toolbar class="mt-2">
                    <template #start>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="product_search_value" @input="search_products" @change="search_products" placeholder="Suche" style="width: 400px" class="mr-2"  />
                        </span>
                    </template>
                </Toolbar>
                <div rounded class="overflow-hidden shadow-4 rounded border-200 mt-4">
                    <DataTable :value="products" :rows="100" responsiveLayout="scroll" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                        <Column field="sku" header="SKU">
                            <template #body="slotProps">
                                <span class="font-bold">{{ slotProps.data.sku }}</span>
                            </template>
                        </Column>
                        <Column field="title3" header="Name">
                            <template #body="slotProps">
                                <span>{{ slotProps.data.title3 }} - {{ slotProps.data.title2 }}</span>
                            </template>
                        </Column>
                        <Column field="title1" header="Produzent" />
                        <Column field="year" header="Jahr">
                            <template #body="slotProps">
                                <span v-if="slotProps.data.year">{{ slotProps.data.year.name }}</span>
                            </template>
                        </Column>
                        <Column field="country" header="Land">
                            <template #body="slotProps">
                                <span v-if="slotProps.data.country">{{ slotProps.data.country.name }}</span>
                            </template>
                        </Column>
                        <Column field="region" header="Region">
                            <template #body="slotProps">
                                <span v-if="slotProps.data.region">{{ slotProps.data.region.name }}</span>
                            </template>
                        </Column>
                        <Column>
                            <template #body="slotProps">
                                <Button v-if="add_mode && promo.products.indexOf(slotProps.data.sku) == -1" icon="pi pi-plus" class="mr-2 p-button-rounded p-button-primary" @click="promo.products.push(slotProps.data.sku), toast.add({severity:'success', summary:'Erfolg', detail:'Produkt hinzugefügt', life: 3000})" v-tooltip.top="'Produkt hinzufügen'" />
                                <Button v-if="!add_mode  && promo.products.indexOf(slotProps.data.sku) == -1" icon="pi pi-plus" class="mr-2 p-button-rounded p-button-primary" @click="promo.excludes.push(slotProps.data.sku), toast.add({severity:'success', summary:'Erfolg', detail:'Produkt hinzugefügt', life: 3000})" v-tooltip.top="'Produkt hinzufügen'" />
                                <Button v-if="add_mode  && promo.products.indexOf(slotProps.data.sku) != -1" @click="promo.products.splice(promo.products.indexOf(slotProps.data.sku), 1), toast.add({severity:'success', summary:'Erfolg', detail:'Produkt entfernt', life: 3000})" icon="pi pi-times" class="mr-2 p-button-rounded p-button-danger" v-tooltip.top="'Produkt entfernen'" />
                                <Button v-if="!add_mode  && promo.products.indexOf(slotProps.data.sku) != -1" @click="promo.excludes.splice(promo.excludes.indexOf(slotProps.data.sku), 1), toast.add({severity:'success', summary:'Erfolg', detail:'Produkt entfernt', life: 3000})" icon="pi pi-times" class="mr-2 p-button-rounded p-button-danger" v-tooltip.top="'Produkt entfernen'" />
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </Sidebar>
    <!---------------------------------------------------------------->
    <!---------------------- Add Sage Group -------------------------->
    <!---------------------------------------------------------------->
    <Sidebar header="Sage Gruppen" v-model:visible="add_sage_group_slider" position="right" style="width: 50%">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-4">
                <Toolbar class="mt-2">
                    <template #start>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filter_sage_groups['global'].value" placeholder="Suche" style="width: 400px" class="mr-2"  />
                        </span>
                    </template>
                </Toolbar>
                <div rounded class="overflow-hidden shadow-4 rounded border-200 mt-4">
                    <DataTable v-model:filters="filter_sage_groups" :value="sage_groups" :rows="100" responsiveLayout="scroll" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                        <Column field="data.name" header="Name">
                            <template #body="slotProps">
                                <span class="font-bold">{{ slotProps.data.data.name }}</span>
                            </template>
                        </Column>
                        <Column field="data.key" header="Key">
                            <template #body="slotProps">
                                <span>{{ slotProps.data.data.key }}</span>
                            </template>
                        </Column>
                        <Column field="count" header="Produkte">
                            <template #body="slotProps">
                                <span>{{ slotProps.data.count }}</span>
                            </template>
                        </Column>   
                        <Column>
                            <template #body="slotProps">
                                <Button v-if="add_mode && promo.sage_group.indexOf(slotProps.data.data.key) == -1" icon="pi pi-plus" class="mr-2 p-button-rounded p-button-primary" @click="promo.sage_group.push(slotProps.data.data.key), toast.add({severity:'success', summary:'Erfolg', detail:'Gruppe hinzugefügt', life: 3000}), get_sage_groups_product_count(promo.sage_group, 'include')" v-tooltip.top="'Gruppe hinzufügen'" />
                                <Button v-if="!add_mode && promo.sage_group_excluded.indexOf(slotProps.data.data.key) == -1" icon="pi pi-plus" class="mr-2 p-button-rounded p-button-primary" @click="promo.sage_group_excluded.push(slotProps.data.data.key), toast.add({severity:'success', summary:'Erfolg', detail:'Gruppe hinzugefügt', life: 3000}), get_sage_groups_product_count(promo.sage_group_excluded, 'exclude')" v-tooltip.top="'Gruppe hinzufügen'" />
                                <Button v-if="add_mode  && promo.sage_group.indexOf(slotProps.data.data.key) != -1" @click="promo.sage_group.splice(promo.sage_group.indexOf(slotProps.data.data.key), 1), toast.add({severity:'success', summary:'Erfolg', detail:'Gruppe entfernt', life: 3000}), get_sage_groups_product_count(promo.sage_group, 'include')" icon="pi pi-times" class="mr-2 p-button-rounded p-button-danger" v-tooltip.top="'Gruppe entfernen'" />
                                <Button v-if="!add_mode  && promo.sage_group_excluded.indexOf(slotProps.data.data.key) != -1" @click="promo.sage_group_excluded.splice(promo.sage_group_excluded.indexOf(slotProps.data.data.key), 1), toast.add({severity:'success', summary:'Erfolg', detail:'Gruppe entfernt', life: 3000}), get_sage_groups_product_count(promo.sage_group_excluded, 'exclude')" icon="pi pi-times" class="mr-2 p-button-rounded p-button-danger" v-tooltip.top="'Gruppe entfernen'" />
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </Sidebar>
</template>