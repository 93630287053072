<template>
    <h2 v-if="!editMode" @click="editMode = true" class="text-2xl font-semibold text-gray-800 my-3 cursor-pointer hover:text-gray-500">
        {{ listName }}
    </h2>
    <InputText v-else v-model="listName" @blur="editTitle" @keypress.enter="editTitle" class="0 my-3" autofocus />
    <div class="flex justify-between my-2">
        <!-- <div> <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText placeholder="Suche" :disabled="guestlistForm" />
            </span>
        </div> -->

        <div v-if="guestlistForm === false" class="flex w-1/2">
            <div> <Button type="button" icon="pi pi-user-edit" label="Neu" @click="newGuestlist" /></div>
        </div>
        <div v-else class="flex w-1/2">

            <!-- <Button type="button" icon="pi pi-times" label="Formular Schließen" @click="guestlistForm = false" class="p-button-warning mx-2" />

            <Button type="button" icon="pi pi-save" label="Eintrag speichern" @click="addGuestlist" class="p-button-success mx-2" v-if="mode == 'new'" />
             <Button type="button" icon="pi pi-save" label="Eintrag editieren" @click="editGuestlist" class="p-button-success mx-2" v-if="mode == 'edit'" />  -->
        </div>
    </div>

    <div v-if="guestlistForm === false">
        <div v-if="guestlist.length > 0">
            <DataTable :value="guestlist" v-model:filters="filter_dataset" tableStyle="min-width: 50rem" scrollable scrollHeight="flex" paginator :rows="10" :rowsPerPageOptions="[5, 10, 20, 50]" @rowReorder="onRowReorder">
                <template #header>
                    <div class="flex justify-content-end">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filter_dataset['global'].value" placeholder="Suche" />
                        </span>
                    </div>
                </template>
                <Column rowReorder headerStyle="width: 3rem" :reorderableColumn="true" />
                <Column field="guestlist_firstname" header="Vorname" sortable></Column>
                <Column field="guestlist_lastname" header="Nachname" sortable></Column>
                <Column field="guestlist_company" header="Firma" sortable></Column>
                <Column field="guestlist_address" header="Adresse" sortable></Column>
                <Column field="guestlist_housenumber" header="Hausnummer" sortable></Column>
                <Column field="guestlist_city" header="Stadt" sortable></Column>
                <Column field="guestlist_zip" header="PLZ" sortable></Column>
                <Column field="guestlist_country" header="Land" sortable></Column>
                <Column field="guestlist_registered" header="Registriert" sortable></Column>
                <Column field="guestlist_total_entry" header="Gesamteintrag" sortable></Column>
                <Column field="guestlist_email" header="E-Mail" sortable></Column>
                <Column field="guestlist_phone" header="Telefon" sortable></Column>
                <Column field="guestlist_price" header="Preis" sortable></Column>
                <Column field="guestlist_status" header="Status" sortable>
                    <template #body="slotProps">
                        <div class="flex">
                            <span v-if="slotProps.data.guestlist_status === true" class="p-tag p-tag-success"><i class="pi pi-check"></i></span>
                            <span v-else class="p-tag p-tag-danger"><i class="pi pi-times"></i></span>
                        </div>
                    </template>
                </Column>
                <Column field="guestlist_payment_type" header="Zahlungsart" sortable></Column>
                <Column field="guestlist_paid" header="Bezahlt" sortable>
                    <template #body="slotProps">
                        <div class="flex">
                            <span v-if="slotProps.data.guestlist_paid === true" class="p-tag p-tag-success"><i class="pi pi-check"></i></span>
                            <span v-else class="p-tag p-tag-danger"><i class="pi pi-times"></i></span>
                        </div>
                    </template>
                </Column>
                <Column field="guestlist_notifications" header="Benachrichtigungen" sortable>
                    <template #body="slotProps">
                        <span v-for="(notification, index) in slotProps.data.guestlist_notifications" :key="index" class="m-1">
                            <Chip :label="notification" class="my-1" />
                        </span>
                    </template>
                </Column>
                <Column header="Aktionen" bodyStyle="width: 8rem" class="text-center">
                    <template #body="slotProps">
                        <div class="flex">
                            <Button type="button" icon="pi pi-pencil" class="p-button-rounded p-button-success mx-1" @click="editGuestlist(slotProps.data)" :disabled="slotProps.data.guestlist_paid === true" outlined />
                            <Button type="button" icon="pi pi-trash" class="p-button-rounded p-button-warning mx-1" @click="deleteGuestlist(slotProps.data)" outlined />
                        </div>
                    </template>
                </Column>
            </DataTable>
        </div>
        <Message severity="info" v-else>Keine Einträge vorhanden</Message>
    </div>
    <div v-else>

        <form @submit.prevent="saveGuestlist">
            <div class="grid grid-cols-2 gap-2">
                <div class="my-2">
                    <label for="guestlist_firstname">Vorname</label>
                    <InputText id="guestlist_firstname" v-model="form.guestlist_firstname" />
                </div>
                <div class="my-2">
                    <label for="guestlist_lastname">Nachname</label>
                    <InputText id="guestlist_lastname" v-model="form.guestlist_lastname" />
                </div>
                <div class="my-2">
                    <label for="guestlist_company">Firma</label>
                    <InputText id="guestlist_company" v-model="form.guestlist_company" />
                </div>
                <div class="my-2">
                    <label for="guestlist_address">Adresse</label>
                    <InputText id="guestlist_address" v-model="form.guestlist_address" />
                </div>
                <div class="my-2">
                    <label for="guestlist_housenumber">Hausnummer</label>
                    <InputText id="guestlist_housenumber" v-model="form.guestlist_housenumber" />
                </div>
                <div class="my-2">
                    <label for="guestlist_city">Stadt</label>
                    <InputText id="guestlist_city" v-model="form.guestlist_city" />
                </div>
                <div class="my-2">
                    <label for="guestlist_zip">PLZ</label>
                    <InputText id="guestlist_zip" v-model="form.guestlist_zip" />
                </div>
                <div class="my-2">
                    <label for="guestlist_country">Land</label>
                    <InputText id="guestlist_country" v-model="form.guestlist_country" />
                </div>
                <div class="my-2">
                    <label for="guestlist_registered">Registriert</label>
                    <Calendar id="guestlist_registered" v-model="form.guestlist_registered" :showIcon="true" dateFormat="dd.mm.yy" class="w-full" />
                </div>
                <div class="my-2">
                    <label for="guestlist_total_entry">Gesamteintrag</label>
                    <InputText id="guestlist_total_entry" v-model="form.guestlist_total_entry" />
                </div>
                <div class="my-2">
                    <label for="guestlist_email">E-Mail</label>
                    <InputText id="guestlist_email" v-model="form.guestlist_email" />
                </div>
                <div class="my-2">
                    <label for="guestlist_phone">Telefon</label>
                    <InputText id="guestlist_phone" v-model="form.guestlist_phone" />
                </div>
                <div class="my-2">
                    <label for="guestlist_price">Preis</label>
                    <InputNumber class="w-full" id="guestlist_price" v-model="form.guestlist_price" inputId="currency-switzerland" mode="currency" currency="CHF" locale="de-CH" />
                </div>
                <div class="my-2">
                    <label for="guestlist_status">Status</label>
                    <InputSwitch v-model="form.guestlist_status" id="guestlist_status" />
                </div>
                <div class="my-2">
                    <label for="guestlist_payment_type">Zahlungsart</label>
                    <InputText id="guestlist_payment_type" v-model="form.guestlist_payment_type" />
                </div>
                <div class="my-2">
                    <label for="guestlist_paid">Bezahlt</label>
                    <InputSwitch v-model="form.guestlist_paid" id="guestlist_paid" />
                </div>
                <div class="my-2">
                    <label for="guestlist_notifications">Benachrichtigungen</label>
                    <Chips v-model="form.guestlist_notifications" id="guestlist_notifications" separator="," />
                </div>
                <input type="hidden" id="guestlist_barcode" v-model="form.guestlist_barcode" />
                <input type="hidden" id="guestlist_list_id" v-model="form.guestlist_list_id" />
            </div>
            <div class="flex my-5">
                <div class=" w-1/2 p-2">
                    <Button type="submit" icon="pi pi-save" label="Eintrag speichern" class="p-button-success mx-2" />
                </div>
                <div class=" w-1/2 p-2">
                    <Button type="button" label="Formular Schließen" @click="guestlistForm = false" class="p-button-warning" />
                </div>
            </div>
        </form>
    </div>
</template>
<script setup>
import { FilterMatchMode } from 'primevue/api'
import { ref, reactive, onMounted, watch, defineProps, defineEmits } from 'vue'
import { getRandomId } from '@/core/var/tools'
const props = defineProps(['list', 'title', 'id'])
const emits = defineEmits(['save', 'title', 'guestlist_id'])
const mode = ref('new');
const guestlist = ref(props.list);
const filter_dataset = ref({ 'global': { value: null, matchMode: FilterMatchMode.CONTAINS } })

const new_list_id = ref(false);
const listName = ref(false);
const editMode = ref(false);


onMounted(
    () => {
        generate_list_id();
        listName.value = props.title !== undefined ? props.title : `${new_list_id.value}`;
        //emits('title', listName.value);
        //emits('guestlist_id', new_list_id.value);
    }
);

const editTitle = () => {
    editMode.value = false;
    emits('title', { title: listName.value, guestlist_id: new_list_id.value });
}

const form = reactive({
    guestlist_event_id: '',
    guestlist_list_id: '',
    guestlist_firstname: '',
    guestlist_lastname: '',
    guestlist_company: '',
    guestlist_address: '',
    guestlist_housenumber: '',
    guestlist_city: '',
    guestlist_zip: '',
    guestlist_country: '',
    guestlist_registered: '',
    guestlist_total_entry: '',
    guestlist_email: '',
    guestlist_phone: '',
    guestlist_price: '',
    guestlist_status: false,
    guestlist_payment_type: '',
    guestlist_paid: false,
    guestlist_notifications: '',
    guestlist_barcode: ''
});
const guestlistForm = ref(false);

const generate_list_id = () => {

    const newid = props.id !== undefined ? new_list_id.value = props.id : getRandomId(6)
    new_list_id.value = newid;
    return new_list_id.value;
}

const deleteGuestlist = (guest) => {

    mode.value = 'delete';

    const index = guestlist.value.findIndex(g => g.guestlist_firstname === guest.guestlist_firstname && g.guestlist_lastname === guest.guestlist_lastname);
    if (index !== -1) {
        guestlist.value.splice(index, 1);
    }
    emitItems();
}

const newGuestlist = () => {

    mode.value = 'new';

    const list_id = new_list_id.value;

    form.guestlist_event_id = '',
    form.guestlist_list_id = list_id,
    form.guestlist_barcode = list_id,
    form.guestlist_firstname = '';
    form.guestlist_lastname = '';
    form.guestlist_company = '';
    form.guestlist_address = '';
    form.guestlist_housenumber = '';
    form.guestlist_city = '';
    form.guestlist_zip = '';
    form.guestlist_country = '';
    form.guestlist_registered = '';
    form.guestlist_total_entry = '';
    form.guestlist_email = '';
    form.guestlist_phone = '';
    form.guestlist_price = '';
    form.guestlist_status = '';
    form.guestlist_payment_type = '';
    form.guestlist_paid = false;
    form.guestlist_notifications = '';
    guestlistForm.value = true;
}

const editGuestlist = (guest) => {

    mode.value = 'edit';
    // lade Daten in Formular
    form.guestlist_firstname = guest.guestlist_firstname;
    form.guestlist_lastname = guest.guestlist_lastname;
    form.guestlist_company = guest.guestlist_company;
    form.guestlist_address = guest.guestlist_address;
    form.guestlist_housenumber = guest.guestlist_housenumber;
    form.guestlist_city = guest.guestlist_city;
    form.guestlist_zip = guest.guestlist_zip;
    form.guestlist_country = guest.guestlist_country;
    form.guestlist_registered = guest.guestlist_registered;
    form.guestlist_total_entry = guest.guestlist_total_entry;
    form.guestlist_email = guest.guestlist_email;
    form.guestlist_phone = guest.guestlist_phone;
    form.guestlist_price = guest.guestlist_price;
    form.guestlist_status = guest.guestlist_status;
    form.guestlist_payment_type = guest.guestlist_payment_type;
    form.guestlist_paid = guest.guestlist_paid;
    form.guestlist_notifications = guest.guestlist_notifications;
    form.guestlist_barcode = guest.guestlist_barcode;
    form.guestlist_list_id = guest.guestlist_list_id;

    guestlistForm.value = true;
}

const addGuestlist = () => {

    const newGuest = {
        guestlist_firstname: form.guestlist_firstname,
        guestlist_lastname: form.guestlist_lastname,
        guestlist_list_id: getRandomId(6),
        guestlist_barcode: getRandomId(6),
        guestlist_company: form.guestlist_company,
        guestlist_address: form.guestlist_address,
        guestlist_housenumber: form.guestlist_housenumber,
        guestlist_city: form.guestlist_city,
        guestlist_zip: form.guestlist_zip,
        guestlist_country: form.guestlist_country,
        guestlist_registered: form.guestlist_registered,
        guestlist_total_entry: form.guestlist_total_entry,
        guestlist_email: form.guestlist_email,
        guestlist_phone: form.guestlist_phone,
        guestlist_price: form.guestlist_price,
        guestlist_status: form.guestlist_status,
        guestlist_payment_type: form.guestlist_payment_type,
        guestlist_paid: form.guestlist_paid,
        guestlist_notifications: form.guestlist_notifications
    };
    guestlist.value.unshift(newGuest);
    guestlistForm.value = false;
}

const saveGuestlist = () => {

    if (mode.value === 'edit') {

        const index = guestlist.value.findIndex(g => g.guestlist_list_id === form.guestlist_list_id);
        console.log(index);
        // editiere guestlist.value by index:
        guestlist.value[index].guestlist_list_id = form.guestlist_list_id;
        guestlist.value[index].guestlist_firstname = form.guestlist_firstname;
        guestlist.value[index].guestlist_lastname = form.guestlist_lastname;
        guestlist.value[index].guestlist_company = form.guestlist_company;
        guestlist.value[index].guestlist_address = form.guestlist_address;
        guestlist.value[index].guestlist_housenumber = form.guestlist_housenumber;
        guestlist.value[index].guestlist_city = form.guestlist_city;
        guestlist.value[index].guestlist_zip = form.guestlist_zip;
        guestlist.value[index].guestlist_country = form.guestlist_country;
        guestlist.value[index].guestlist_registered = form.guestlist_registered;
        guestlist.value[index].guestlist_total_entry = form.guestlist_total_entry;
        guestlist.value[index].guestlist_email = form.guestlist_email;
        guestlist.value[index].guestlist_phone = form.guestlist_phone;
        guestlist.value[index].guestlist_price = form.guestlist_price;
        guestlist.value[index].guestlist_status = form.guestlist_status;
        guestlist.value[index].guestlist_payment_type = form.guestlist_payment_type;
        guestlist.value[index].guestlist_paid = form.guestlist_paid;
        guestlist.value[index].guestlist_notifications = form.guestlist_notifications;
        guestlist.value[index].guestlist_barcode = form.guestlist_barcode;

        emitItems();

    } else if (mode.value === 'new') {
        addGuestlist();
    }
    guestlistForm.value = false;
    emitItems();
}

const onRowReorder = (event) => {
    const { value } = guestlist;
    const draggedItem = value[event.dragIndex];

    value.splice(event.dragIndex, 1);
    value.splice(event.dropIndex, 0, draggedItem);
    guestlist.value = [...value];
    emitItems();
};

const emitItems = () => {
    emits('save', guestlist.value);
};
</script>