<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import { Cropper, Preview } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';

const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

const weinalarm = ref([])
const filter_weinalarm = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})

onMounted( async () => {
    await axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/get-weinalarm-stats")
    .then(response => {
        weinalarm.value = response.data
    })
})

const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}

</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <Toast />
    <Toolbar class="mt-2">
        <template #start>
            <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filter_weinalarm['global'].value" placeholder="Suche" style="width: 400px" class="mr-2" />
            </span>
        </template>
    </Toolbar>
    <!--------------------------------------------------->
    <!------------------ DataTable ---------------------->
    <!--------------------------------------------------->
    <DataTable class="mt-3" v-model:filters="filter_weinalarm" :value="weinalarm" :rows="50" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[50,100,200]"  :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
        <Column field="sku" header="Artikelnr."></Column>
        <Column field="name" header="Weinnamen"></Column>
        <Column field="subscribers" header="Abonniert" sortable></Column>
        <Column field="stats.quantityWithAlarm" header="Total Verkauft (mit Weinalarm)" sortable>
            <template #body="slotProps">
                <strong>{{ slotProps.data.stats.quantityWithAlarm }}</strong> ({{ format_currency(slotProps.data.stats.amountWithAlarm)}})
            </template>
        </Column>
        <Column field="stats.quantity" header="Total Verkauft" sortable>
            <template #body="slotProps">
                <strong>{{ slotProps.data.stats.quantity }}</strong> ({{ format_currency(slotProps.data.stats.amount)}})
            </template>
        </Column>
    </DataTable>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
</template>