<script setup>
import Categories from './Gerstl-Events-Categories.vue';
import Blog from './Gerstl-Events-Blog.vue';
import Event from './Gerstl-Events-Event.vue';
import Lists from './Events/Events-Lists.vue';
import { ref } from 'vue';

const listsKey = ref(0);

const refreshLists = () => {
    listsKey.value= Math.random();
};
</script>

<template>
    
<ConfirmDialog /> 
    <div class="p-fluid formgrid grid">
        <TabView class="col-12" ref="tabview4">
            <TabPanel>
                <template #header>
                    <i class="pi pi-flag mr-2"></i>
                    <span class="mr-3">Beiträge</span>
                </template>
                <Blog />
            </TabPanel>
            <TabPanel>
                <template #header>
                    <i class="pi pi-flag mr-2"></i>
                    <span class="mr-3" @click="refreshLists">Events</span>
                </template>
                <Event :key="listsKey" />
            </TabPanel>
            <TabPanel>
                <template #header>
                    <i class="pi pi-filter mr-2"></i>
                    <span class="mr-3">Kategorien</span>
                </template>
                <Categories />
            </TabPanel>
            <TabPanel>
                <template #header>
                    <i class="pi pi-filter mr-2"></i>
                    <span class="mr-3" @click="refreshLists">Listen</span>
                </template>
                <Lists :key="listsKey" />
            </TabPanel>
        </TabView>
    </div>
</template>
