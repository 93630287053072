<script setup>
import { ref, onMounted, watch, defineProps, defineEmits } from 'vue'
import { Cropper, Preview } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import axios from 'axios'
import { useConfirm } from "primevue/useconfirm"

const confirm = useConfirm()

// local variables
const editGalleryDialog = ref(false)
// define props
const props = defineProps({
    template_type: { type: String, required: true },
    index: { type: Number, required: false },
    data: { type: Object, required: true },
    image_library: { type: Object, required: true},
    workflow_id: { type: String, required: true}
})
// define emits
const emits = defineEmits(['blockCallback'])

// fields
const template = ref("gallery_block")
const gallery = ref(props.data.gallery)
const croppy = ref(null)
const random_number = ref(String(Math.floor(Math.random() * 100000000)))
const image = ref()

const edit = ref(false)

// functions
const edit_block = () => {
    editGalleryDialog.value = true
    edit.value = false
    gallery.value = props.data.gallery
}

const save_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "template": template.value,
                "gallery": gallery.value
            },
            "action": "save",
            "index": props.index
        }
    )
    editGalleryDialog.value = false
}

const delete_block = () => {
    emits('blockCallback',
        {
            "action": "delete",
            "index": props.index
        }
    )
    editGalleryDialog.value = false
}

const add_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "template": template.value,
                "gallery": []
            },
            "action": "add",
            "index": props.index
        }
    )
}

const crop_image = () => {
    image.value = croppy.value.getResult().canvas.toDataURL()
    upload_image()
    edit.value = false
}

const on_row_reorder = (e) => {
    gallery.value = e.value
}

const on_row_edit = (e) => {
    if (gallery.value[e.index][e.field] != e.newValue){
        gallery.value[e.index][e.field] = e.newValue
    }
}

const delete_row = (e) => {
    confirm.require({
        message: 'Wollen Sie wirklich das Bild löschen?',
        header: 'Bild löschen',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Bild löschen',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            gallery.value.splice(e.index, 1)
        }
    })
}

const upload_image = async () => {
    let { canvas } = croppy.value.getResult()
    await canvas.toBlob((blob) => {
        let reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = async () => {
            random_number.value = String(Math.floor(Math.random() * 100000000))
            let payload = {"file": reader.result, "image_type": String(props.index) + "_g_" + random_number.value, "workflow_id": props.workflow_id }
            await axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gerstl/upload-image", payload)
            .then(response => {
                gallery.value.push({"image": response.data.image_url, "image_caption": ""})
                image.value = null
            })
        }
    })
}

</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <!--------------------------------------------------->
    <!------------------ Menu View ---------------------->
    <!--------------------------------------------------->
    <div v-if="props.template_type == 'menu'" class="card shadow-2 border-round-md py-3 px-3 mt-2">
        <div class="flex flex-row flex-wrap card-container">
            <div class="flex align-items-center justify-content-center mr-3">
                <Button v-tooltip.bottom="'Block hinzufügen'" class="p-button-success" icon="pi pi-plus" @click="add_block()" />
            </div>
            <div class="flex align-items-center justify-content-center mr-3 w-5rem border-gray-400 border-solid border-round-md border-1">
                <i class="pi pi-image" style="color:#2196F3;"></i>
            </div>
            <div class="flex align-items-center justify-content-center font-bold">
                Gallerie
            </div>
        </div>
    </div>
    <!--------------------------------------------------->
    <!------------------ Data View ---------------------->
    <!--------------------------------------------------->
    <div v-if="props.template_type == 'data'" class="shadow-2 border-round-md px-3 py-1" @click="edit_block()" v-bind:style="{cursor: 'pointer'}">
        <div class="mt-3">
            <i class="pi pi-box mr-2 ml-1"></i>
            <span class="mt-1 font-bold" style="font-size: 1em; color: #9e9e9e">Gallerie</span>
        </div>
        <Divider />
        <div v-if="gallery" class="grid">
            <div v-for="(image, index) of gallery" class="flex" :key="index">
                <img :src="image.image" class="shadow-2 m-2" style="width: 80px; height: 80px; object-fit: cover;"/>
            </div>
        </div>
    </div>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->

    <!--------------------------------------------------->
    <!------------------- Edit Image -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editGalleryDialog" :style="{width: '80%'}" header="Block bearbeiten" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-1">
                <Button v-if="edit == false" label="Speichern" @click="save_block()" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                <Button label="Abbrechen" @click="editImageDialog = false" class="w-auto mr-3 mb-1 p-button-danger" icon="pi pi-times" />
                <Button label="Löschen" @click="delete_block()" class="w-auto mr-3 mb-1 p-button-danger" icon="pi pi-trash" />
            </div>
            <div class="field col-12 mt-1">
                <DataTable :value="gallery" :rows="20" responsiveLayout="scroll" :rowHover="true" editMode="cell" @cell-edit-complete="on_row_edit" @rowReorder="on_row_reorder">
                    <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                    <Column field="image" header="Bild" style="width: 200px">
                        <template #body="slotProps">
                            <img :src="slotProps.data.image" style="vertical-align: middle; width: 150px; height: 100px; object-fit: cover; border-radius: 5%; box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)" />
                        </template>
                    </Column>
                    <Column field="image_caption" header="Bildunterschrift">
                        <template #editor="{ data, field }">
                            <InputText v-model="data[field]" class="w-full" />
                        </template>
                        <template #body="{ data, field }">
                            <span>{{ data[field] }}</span>
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <Button @click="delete_row(slotProps)" v-tooltip.top="'Bild löschen'" class="p-button-rounded p-button-text" icon="pi pi-trash" />
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="field col-12 mt-1">
                <Button v-if="edit == false" label="Gallerie Bild hinzufügen" @click="edit = true" class="w-auto mr-3 mb-1 p-button" icon="pi pi-plus" />
            </div>
            <div v-if="edit == true" class="field col-12 mt-3">
                <Button label="Bild Speichern" @click="crop_image()" class="w-auto mr-3 mb-1 p-success" icon="pi pi-save" />
            </div>
            <div v-if="edit == true" class="field col-12 mt-3">
                <cropper
                    ref="croppy"
                    :src="image"
                    :stencil-props="{aspectRatio: 1.5 / 1}"
                    :auto-zoom="true"
                    :auto-center="true"
                />
            </div>
            <div v-if="edit == true" class="field col-12 mt-3">
                <div v-if="image_library" class="flex">
                    <template v-for="(img, index) of image_library" :key="index">
                        <img @click="image = img.url" :src="img.url" class="shadow-2 m-2" style="cursor: pointer; height: 100px; width: 100px; object-fit: cover; display:inline;" />
                    </template>
                </div>
            </div>
        </div>
    </Dialog>
</template>