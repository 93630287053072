<script setup>
import { ref, onMounted, watch, defineProps, defineEmits } from 'vue'


// local variables
const editImageDialog = ref(false)

// define props
const props = defineProps({
    template_type: { type: String, required: true },
    index: { type: Number, required: false },
    data: { type: Object, required: true }
})
// define emits
const emits = defineEmits(['blockCallback'])

// fields
const content = ref(props.data.content)
const template = ref("cta_button")
const target = ref(props.data.target)
const button_text = ref(props.data.button_text)
const button_url = ref(props.data.button_url)
const alignment = ref(props.data.alignment)
const targets = ref([
    {label: 'Im gleichen Fenster öffnen', value: '_self'},
    {label: 'In einem neuen Tab öffnen', value: '_blank'}
])
const alignments = ref([
    {label: 'Links', value: 'left'},
    {label: 'Rechts', value: 'right'},
    {label: 'Zentriert', value: 'center'}
])

// functions
const edit_block = () => {
    editImageDialog.value = true
}

const save_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "content": content.value,
                "template": template.value,
                "button_text": button_text.value,
                "button_url": button_url.value,
                "target": target.value,
                "alignment": alignment.value
            },
            "action": "save",
            "index": props.index
        }
    )
    editImageDialog.value = false
}

const delete_block = () => {
    emits('blockCallback',
        {
            "action": "delete",
            "index": props.index
        }
    )
    editImageDialog.value = false
}

const add_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "content": "Einen Standard Titel",
                "template": template.value,
                "button_text": "Button Text",
                "button_url": "https:/",
                "target": "self",
                "alignment": "left"
            },
            "action": "add",
            "index": props.index
        }
    )
}

const open_url = (url) => {
    window.open(url, target.value)
}

const align_preview = (alignment) => {
    if (alignment == 'left') {
        return 'start'
    } else if (alignment == 'right') {
        return 'end'
    } else {
        return 'center'
    }
}

</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <!--------------------------------------------------->
    <!------------------ Menu View ---------------------->
    <!--------------------------------------------------->
    <div v-if="props.template_type == 'menu'" class="card shadow-2 border-round-md py-3 px-3 mt-2">
        <div class="flex flex-row flex-wrap card-container">
            <div class="flex align-items-center justify-content-center mr-3">
                <Button v-tooltip.bottom="'Block hinzufügen'" class="p-button-success" icon="pi pi-plus" @click="add_block()" />
            </div>
            <div class="flex align-items-center justify-content-center mr-3 w-5rem border-gray-400 border-solid border-round-md border-1">
                <i class="pi pi-external-link" style="color:#2196F3;"></i>
            </div>
            <div class="flex align-items-center justify-content-center font-bold">
                CTA Button
            </div>
        </div>
    </div>
    <!--------------------------------------------------->
    <!------------------ Data View ---------------------->
    <!--------------------------------------------------->
    <div v-if="props.template_type == 'data'" class="shadow-2 border-round-md px-3 py-1" @click="edit_block()" v-bind:style="{cursor: 'pointer'}">
        <div class="mt-3">
            <i class="pi pi-box mr-2 ml-1"></i>
            <span class="mt-1 font-bold" style="font-size: 1em; color: #9e9e9e">CTA Button</span>
        </div>
        <Divider />
        <div :class="'flex justify-content-'+align_preview(alignment)+' align-items-'+align_preview(alignment)+' p-2'">
            <Button class="w-auto mr-3" :label="button_text" @click="open_url(button_url)"></Button>
        </div>
    </div>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editImageDialog" :style="{width: '80%'}" header="Block bearbeiten" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-1">
                <Button label="Speichern" @click="save_block()" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                <Button label="Abbrechen" @click="editImageDialog = false" class="w-auto mr-3 mb-1 p-button-danger" icon="pi pi-times" />
                <Button label="Löschen" @click="delete_block()" class="w-auto mr-3 mb-1 p-button-danger" icon="pi pi-trash" />
            </div>
            <div class="field col-6 mt-3">
                <label for="content">Button Text</label>
                <InputText v-model="button_text" id="content" type="text" />
            </div>
            <div class="field col-6 mt-3">
                <label for="button_url">Button URL</label>
                <InputText v-model="button_url" id="button_url" type="text" />
            </div>
            <div class="field col-6 mt-3">
                <label for="content">Verhalten</label>
                <Dropdown v-model="target" :options="targets" optionLabel="label" optionValue="value" />
            </div>
            <div class="field col-6 mt-3">
                <label for="content">Ausrichtung</label>
                <Dropdown v-model="alignment" :options="alignments" optionLabel="label" optionValue="value" />
            </div>
        </div>
    </Dialog>
</template>